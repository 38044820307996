import React from 'react'
import { Button } from 'react-bootstrap'
import { Detalles } from '../../../../Models/General'
import { Producto } from '../../../../Models/Productos'
interface PropsFila {
    producto: Producto,
    add: (iten: Detalles) => void
    color?: string 
}
export default class FilaSeleccion extends React.Component<PropsFila>{
    render() {
        let { producto: { codigo, descripcion } } = this.props
        return <tr>
            <th>{codigo}</th>
            <th>{descripcion}</th>
            <th>
                <Button data-tip="AGREGAR PRODUCTO" onClick={() => { this.props.add({ ...this.props.producto, index: 99999, unidades: 1, comentario: '', precio: 1 }) }} variant="primary">
                    <i className="fa fa-plus" />
                </Button>
            </th>
        </tr >
    }

}