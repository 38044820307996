import { CardMain, SizeViewSelector, Title, ButtonColor, TitleCard, Guardar, CardConfig, RowConfig } from '../../Component';
import { CropModal } from '../../../Media/CropModal';
import { State } from './index'
import { ButtonConfig } from '@victorequena22/component-bootstrap';
interface Props {
    save: () => void,
    title: string;
    config: State;
    setTitle: (d: string) => void;
    setConfig: (d: any) => void
}
export function Config({ save, title, config, setTitle, setConfig }: Props) {
    const { size, backgroundColor: c, img, fluid } = config;
    return <CardMain>
        <Guardar guardar={save} />
        <CardConfig width='256px' >
            <RowConfig>
                <Title setData={setTitle} title={title} width='250px' />
            </RowConfig>
            <RowConfig top={2}>
                <CropModal aspect={16/9} img={img} setImg={(img) => setConfig({ img })} />
                <ButtonConfig action={() => setConfig({ fluid: !fluid })}
                    icon='fas fa-expand-alt' active={fluid} className='ml-1'
                />
                {fluid ? <></> : <ButtonColor gradiend tip='COLOR DE FONDO DEL PANEL'
                    color={c} setData={c => setConfig({ backgroundColor: c })}
                    className='ml-1' style={{ width: 32 }}>
                    <i className="fas fa-paint-roller"></i>
                </ButtonColor>}
            </RowConfig>
            <div className='mt-2' style={{ height: '24' }}></div>
            <TitleCard>CONFIGURACION GENERAL</TitleCard>
        </CardConfig>
        <SizeViewSelector setSize={(d) => setConfig({ size: d })} size={size} />
    </CardMain >
}