import React, { useContext } from 'react'
import { SeccionContext } from '../../Context/Seccion';
interface Props {
    change: (r: number) => void
    defaultValue: number
}
export default function UsuarioSelector(p: Props) {
    const context = useContext(SeccionContext)
    const setRol = (Rol: React.ChangeEvent<HTMLSelectElement>) => { p.change(parseInt(Rol.currentTarget.value)) }
    return <select onChange={setRol} className="form-control" value={p.defaultValue}>
        <option value={-1}>TODOS LOS USUARIOS</option>
        {(context !== null) ? context.usuarios.map(c => {
            return <option key={"user" + c.codigo} value={c.codigo}>{c.nombre}</option>
        }) : <></>}
    </select>
}