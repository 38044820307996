import { Detalles } from '../General';
import { Cliente as cli, ClienteLabel as cl, ClienteNull, conCliente } from './Cliente'
import { Cotizacion as c, CotizacionNull, sentCotizacion, conCotizacion } from './Cotizacion'
import { Factura as f, FacturaNull, sentFactura, conFactura } from './Factura'
import { Nota as n, conNota, sentNota } from './Salidas'
export type Cliente = cli;
export type ClienteLabel = cl;
export { ClienteNull, conCliente }
export type Cotizacion = c;
export { CotizacionNull, sentCotizacion, conCotizacion }
export type Factura = f;
export { FacturaNull, sentFactura, conFactura }
export type Nota = n;
export { conNota, sentNota }

export function updatePrecio(data: Cotizacion, tasa2: number, dolar: boolean): any {
    let detalles: Detalles[] = [];
    const { nota } = data;
    if (data.tasa === 0 || !dolar) detalles = data.detalles.map((p, i) => ({ ...p, index: i + 1 }));
    else detalles = data.detalles.map(({ imagen, codigo, departamento, descripcion, estatus, enser, tipo, costo, precio1, precio2,
        precio3, cantidad, unidad, exento, fecha_creacion, tasa, medida, inventario, unidades, comentario, precio, }, i) => {
        return {
            codigo, imagen, departamento, descripcion, estatus, enser, tipo, costo, precio1, precio2, precio3,
            cantidad, unidad, exento, fecha_creacion, tasa, medida, inventario, unidades, comentario, index: i + 1,
            precio: parseFloat((precio * tasa2 / data.tasa).toFixed(2)),
        };
    });
    return { nota, detalles };
}