import { Button, ButtonGroup } from 'react-bootstrap';
import { CardMain, SizeViewSelector, Title, Guardar, ButtonColor, TitleCard, CardConfig, RowConfig, AddItens, ButtonConfig } from '../../Component';
import { ConfigPosicion, TextMenu } from '../../Component/Commons';
import { State, iten } from './index'
import { ConfigIten } from './Itens/Config';
interface Props {
    save: () => void
    iten: iten;
    title: string;
    config: State;
    setTitle: (d: string) => void;
    setConfig: (d: any) => void,
    setIten: (d: Partial<iten>) => void
    itenUp: (d: number) => void
    itenDown: (d: number) => void
    addIten: () => void
    removeItem: (d: number) => void
}
export function Config({ save, title, config, setTitle, setConfig, setIten, iten, itenUp, itenDown, addIten, removeItem }: Props) {
    const { size, color, tap, tapPosition, variant, itens: { length } } = config;
    return <CardMain>
        <Guardar guardar={save} />
        <CardConfig width="250px">
            <RowConfig >
                <Title setData={setTitle} title={title} width='204px' />
                <AddItens tip='AGREGAR UNA PESTAÑA' add={addIten} />
            </RowConfig>
            <RowConfig top={2}>
                <ButtonColor gradiend tip='COLOR DETRAS DE LA PESTAÑA' className='ml-1' setData={color => setConfig({ color })} color={color}>
                    <i className="fas fa-paint-roller"></i>
                </ButtonColor>
                <ButtonColor gradiend tip='COLOR DE FONDO DE LA PESTAÑA' className='ml-1' setData={tap => setConfig({ tap })} color={tap}>
                    <i className="fas fa-ad"></i>
                </ButtonColor>
                <ButtonGroup className='ml-1'>
                    <Button data-tip='PESTAÑAS REDONDEADAS' size='sm' active={variant === 'tab'} style={{ width: 32, height: 24 }} onClick={() => setConfig({ variant: 'tab' })} variant='outline-dark'>
                        <i className='fas fa-question' style={{ fontSize: 10, width: 16, height: 16, paddingTop: 1, border: 'solid 2px currentColor', borderRadius: '5px 5px 0 0' }} />
                    </Button>
                    <Button data-tip='PESTAÑAS RECTANGULARES' size='sm' active={variant === 'mat'} style={{ width: 32, height: 24 }} onClick={() => setConfig({ variant: 'mat' })} variant='outline-dark'>
                        <i className='fas fa-question' style={{ fontSize: 10, width: 16, height: 16, paddingTop: 1, border: 'solid 2px currentColor' }} />
                    </Button>
                </ButtonGroup>
            </RowConfig>
            <RowConfig top={2}>
                <ButtonGroup>
                    <ButtonConfig tip='PESTAÑAS A LA IZQUIERDA' active={tapPosition === 'row'} action={() => setConfig({ tapPosition: 'row' })} icon='fas fa-arrow-left' />
                    <ButtonConfig tip='PESTAÑAS ARRIBA' active={tapPosition === 'column'} action={() => setConfig({ tapPosition: 'column' })} icon='fas fa-arrow-up' />
                    <ButtonConfig tip='PESTAÑAS A LA DERECHA' active={tapPosition === 'row-reverse'} action={() => setConfig({ tapPosition: 'row-reverse' })} icon='fas fa-arrow-right' />
                    <ButtonConfig tip='PESTAÑAS ABAJO' active={tapPosition === 'column-reverse'} action={() => setConfig({ tapPosition: 'column-reverse' })} icon='fas fa-arrow-down' />
                </ButtonGroup>
            </RowConfig>
            <TitleCard>CONFIGURACION GENERAL</TitleCard>
        </CardConfig>
        <CardConfig width="132px">
            <ConfigPosicion config={config} max={12} step={.1} min={3} setConfig={setConfig} />
            <TitleCard>CONFIGURACION DE ICONOS</TitleCard>
        </CardConfig>
        <ConfigIten cont={length} config={config} iten={iten} addItem={addIten}
            setConfig={setIten} itenUp={itenUp} itenDown={itenDown} removeItem={removeItem} />
        <TextMenu />
        <SizeViewSelector setSize={(size) => setConfig({ size })} size={size} />
    </CardMain >
}