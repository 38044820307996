import UnAutorization from '../../Utiles/Componentes/UnAutorization';
import { SeccionContext, SeccionType } from '../../Context/Seccion';
import { RutasImpresiones, MenuImpresiones } from './Impresion';
import { RutasGenerales, MenuGenerales } from './Generales';
import { RutasProductos, MenuProductos } from './Productos';
import { Route } from "react-router-dom";
import { Col, Row } from 'react-bootstrap';
import { RutasWeb, MenuWeb } from './Web';
import { useContext } from 'react';

export default function MasterConfig() {
    const { data: { Permisos } } = useContext(SeccionContext) as SeccionType;
    if (Permisos.some(p => p === 'CONFIG'))
        return (<Row style={{ minHeight: "calc(100vh - 145px)" }}>
            <Col md={3} style={{ minHeight: "calc(100vh - 145px)" }}>
                <MenuGenerales />
                <MenuImpresiones />
                <MenuProductos />
                <MenuWeb />
            </Col>
            <Col md={9} style={{ minHeight: "calc(100vh - 145px)" }}>
                <RutasGenerales />
                <RutasImpresiones />
                <RutasProductos />
                <RutasWeb />
            </Col>
        </Row>)
    return <UnAutorization />
}
export function Ruta({ component, path, permiso }: { component: any, path: string, permiso: string }) {
    const context = useContext(SeccionContext);
    if (context?.data.Permisos.some(p => p === permiso))
        return <Route path={path} component={component} />
    return <Route path={path} component={UnAutorization} />

}