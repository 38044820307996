import { Redirect } from "react-router-dom";
import { SizeViewConteiner } from '../Component';
import { Single, StateS as SS } from './Single'
import SlateProvider from '../Component/SlateCustom';
import { defaultText, setText, BlockElement, getText } from './DefaultData';

export interface Iten {
    id: number;
    html: BlockElement[];
    color: string;
    back: string;
}
export interface Sta<i> {
    seleted: number;
    itens: (Iten & i)[];
}
export type StateS<i> = SS & Sta<i>;
export const defaultIten: Iten = {
    id: 0,
    html: defaultText,
    color: '#FFFFFF',
    back: '#00000088',
}
export class GalIten<s, i> extends Single<Sta<i> & s> {
    blockIten = true;
    randon = Math.random();
    initGal = (def: s, itens: (Iten & i)[]) => {
        this.init({ itens, ...def, seleted: 1 });
    }
    componentDidMount(): void {
        super.componentDidMount();
        const { data, copy } = this.props;
        if (data !== undefined) this.setInitStateIten(data);
        else if (copy !== undefined) this.setInitStateIten(copy);

    }

    setInitStateIten = ({ itens }: any) => {
        this.random = Math.random();
        this.setState({
            itens: itens.map(({ props, data, html: h, title, indice }: any) => {
                const html = h !== null ? setText(h) : '';
                return { ...props, ...data, html, title, id: indice };
            })
        })
    }
    addIten = (i: i) => {
        const { itens } = this.state;
        const its = [...itens, { ...defaultIten, ...i }].map((x, d) => {
            x.id = d + 1;
            return x;
        });
        this.setData({ itens: its })
    }
    removeItem = (id: number) => {
        const { itens } = this.state;
        if (itens.length > 1) this.setData({
            itens: itens.filter(i => i.id !== id).map((a, i) => { a.id = i + 1; return a }),
            seleted: 1
        })
    }
    itenUp = (id: number) => {
        const { itens } = this.state;
        if (id !== 1) {
            const rest = itens[id - 2];
            itens[id - 2] = itens[id - 1];
            itens[id - 1] = rest;
        }
        this.setData({
            itens: itens.map((a, i) => { a.id = i + 1; return a }),
            seleted: id === 1 ? 1 : id - 1
        })
    }
    itenDown = (id: number) => {
        const { itens } = this.state;
        if (id !== itens.length) {
            const rest = itens[id];
            itens[id] = itens[id - 1];
            itens[id - 1] = rest;
        }
        this.setData({
            itens: itens.map((a, i) => { a.id = i + 1; return a }),
            seleted: id === itens.length ? id : id + 1
        })
    }
    seleted = (seleted: number) => {
        this.setData({ seleted })
    }
    getIten = (i: i) => {
        const { itens, seleted } = this.state;
        for (let i = 0; i < itens.length; i++)
            if (seleted === itens[i].id) return itens[i];
        return { ...defaultIten, ...i };
    }
    getText = () => {
        const { itens, seleted } = this.state;
        for (let i = 0; i < itens.length; i++)
            if (seleted === itens[i].id) return itens[i];
        return { html: [], id: 0 } as { html: BlockElement[], id: number };
    }
    setIten = (p: any) => {
        const id = (p.id !== undefined) ? p.id : this.state.seleted;
        const { itens } = this.state;
        type k = keyof Iten | keyof i;
        this.setData({
            itens: itens.map(it => {
                const iten = { ...it };
                if (it.id === id) Object.keys(it).forEach(
                    (t) =>
                        iten[t as k] = p[t] !== undefined ? p[t] : it[t as k]
                );
                return iten;
            }).sort((a: any, b: any) => a.id - b.id)
        });
    }
    setText = ({ text }: { text: any[] }) => {
        this.setIten({ html: getText(text) });
    }
    copy = (id: number) => {
        this.addIten(this.state.itens.find(x => x.id === id) as any);
    }
    render() {
        const { Config, Panel, Top, Left, props: { data, edit }, state: { id: i } } = this;
        const to = (edit ? edit : '/panel/edit/') + i
        if (data === undefined && i !== undefined) return <Redirect to={to} />;
        const { html, id } = this.getText();
        return <div key={`i${this.random}`} style={{ height: '100%', maxHeight: 'calc(100vh - 180px)' }}>
            <SlateProvider key={`stale${id}${this.randon}`} value={html} setValue={text => this.setText({ text })}>
                {Config()}
                <SizeViewConteiner key={`c${this.random}`} top={Top()} left={Left()} size={this.state.size}>
                    {Panel()}
                </SizeViewConteiner>
            </SlateProvider>
        </div>
    }
}
