import { CondicionesData, Estado, GeneralData, Impreciones } from "./Interface"

export var imprecionNull: Impreciones = {
    margen_sup: 15,
    margen_inf: 15,
    margen_izq: 15,
    margen_der: 15,
    version: 'nuevo',
    encabezado: true,
    piepagina: true,
    tipo_papel: 'LETTER'
}
export var generalNull: GeneralData = {
    codigo: 0,
    nota: '',
    fecha: '',
    subtotal: 0,
    total: 0,
    impuesto: 0,
    tasa: 0,
    detalles: []
}
export var condicionesNull: CondicionesData = {
    ...generalNull,
    forma_pago: '',
    tiempo_entrega: '',
    validez: '',
}
export const EstadoNull: Estado = { status: 1, hora: 'CARGADO DESDE CACHE' }
