/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext } from 'react';
import { compare } from './compare';
import { conConfig } from '@victorequena22/conneccion';
import { useSemaforo } from '../../Utiles/ReactUtils';
export interface PageType {
    pages: Page[];
    page: number
    setPage: (d: number) => void
    setPages: (p: any) => void
}
interface Page {
    title: string;
    visible: boolean;
    id: number;
    ocultar: number[];
}
export const PageContext = createContext<PageType>({ page: 1, setPage: () => { }, setPages: () => { }, pages: [] })
export default function PageProvider({ children }: { children: any }) {
    const [pages, setPages] = useState<Page[]>([]);
    const [page, setPage] = useState(1);
    useSemaforo("Page", async () => {
        await conConfig('page').pull(d => {
            if (d !== null && JSON.stringify(d) !== '[]') {
                if (compare(pages, d.paginas)) setPages(d.paginas);
            } else setPages([{ title: 'Inicio', visible: true, id: 1, ocultar: [] }]);
        });
    })
    return <PageContext.Provider value={{ setPages, page, setPage, pages }}>{children}</PageContext.Provider>
}