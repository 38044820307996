import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

import { ControlNumber } from '../../../Utiles/FormControl'
import { conConfig, don } from '@victorequena22/conneccion'
export default function NuevoEquilibrio({ open, close }: { open: boolean, close: () => void }) {
    const [pto, setValue] = useState(0)
    const get = () => {
        conConfig('').post(don + '/api/Factura/equilibrio/', { pto }, () => close(), () => close());
    }
    return <Modal animation show={open} size='lg' centered onHide={close}>
        <Modal.Header className='bg-primary' closeButton >
            <h4 className='mr-auto'>NUEVO PUNTO DE EQUILIBRIO</h4>
        </Modal.Header >
        <Modal.Body>
            <ControlNumber before='INGRESE EL NUEVO PUNTO DE EQUILIBRIO'
                value={pto} setData={setValue} />
        </Modal.Body>
        <Modal.Footer>
            <Button className="float-right" onClick={get} variant="primary">
                <span className="fa fa-floppy-disk" /> GUARDAR
            </Button>
        </Modal.Footer>
    </Modal >
}