import { useContext, useEffect, useState } from 'react'
import { conPanel } from '../../Panel/Models';
import { useParams } from "react-router-dom";
import Copiar from '../../Panel/Ver/Copiar';
import { PageContext } from '../PageContext';
export function CopiarPanel() {
    let { id } = useParams<{ id: any }>();
    const [panel, setPanel] = useState<any>([]);
    useEffect(() => { conPanel().detalle(id, setPanel); }, [id]);
    const { page } = useContext(PageContext);
    switch (panel.type) {
        default: return <Copiar key={'panel' + id} panel={panel} props={{ page }} />
    }
} 