import React from 'react'
import { Button } from 'react-bootstrap'
import { ProductosContext, ProductosType } from '../../../../Context/Producto'
import { Departamento } from '../../../../Models/Productos'
import THFila, { THInput } from '../../../../Utiles/Componentes/THFila'
import { InputNumber } from '../../../../Utiles/Inputs'

interface Props {
    departamento: Departamento,
    add: (d: { precio1: number, precio2: number, precio3: number, codigo: string }) => void
}
class FilaUtilidad extends React.Component<Props, { precio1: number, precio2: number, precio3: number }>{
    constructor(p: Props) {
        super(p)
        this.state = { precio1: 10, precio2: 20, precio3: 30 };
        this.setState = this.setState.bind(this);
    }
    render() {
        let { state: { precio1, precio2, precio3 }, props: { departamento: { codigo, descripcion } }, setState } = this;
        return <tr className={codigo === 'AAAA' ? 'bg-warning' : ''}>
            <THFila width='90px'>{codigo}</THFila>
            <THFila width='calc(100% - 610px)'>{descripcion}</THFila>
            <THInput width='150px'><InputNumber min={.01} step='0.01' setData={setState} value={precio1} update="precio1" /></THInput>
            <THInput width='150px'><InputNumber min={.01} step='0.01' setData={setState} value={precio2} update="precio2" /></THInput>
            <THInput width='150px'><InputNumber min={.01} step='0.01' setData={setState} value={precio3} update="precio3" /></THInput>
            <THInput width='70px'>
                <Button data-tip="PLICAR A LA CATEGORIA" onClick={() => { this.props.add({ codigo, precio1, precio2, precio3 }) }} variant="primary">
                    <i className="fa fa-plus" />
                </Button>
            </THInput>
        </tr>
    }
    componentDidMount() {
        let { precio1, precio2, precio3 } = this.context as ProductosType;
        this.setState({ precio1, precio2, precio3 })
    }
}
FilaUtilidad.contextType = ProductosContext;
export default FilaUtilidad