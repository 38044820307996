import { CropModal } from '../../../Media/CropModal';
import { SizeViewSelector, Title, CardMain, ButtonColor, TitleCard, Guardar, CardConfig, RowConfig } from '../../Component';
import { Orientacion } from '../../Component/Commons'
import { TBElement } from '../../Component/SlateCustom';
import { State } from './index'
import { Selector } from './Selector';
interface Props {
    save: () => void
    title: string
    config: State
    setTitle: (d: string) => void
    setConfig: (d: any) => void
}
export function Config({ save, title, config, setTitle, setConfig }: Props) {
    const { color, variant, size, img, ori } = config;
    return <CardMain>
        <Guardar guardar={save} />
        <CardConfig width='287px'>
            <RowConfig>
                <Title setData={setTitle} title={title} width='281px' />
            </RowConfig>
            <RowConfig top={1}>
                <Selector ori={ori} set={d => setConfig({ variant: d })} variant={variant} />
            </RowConfig>
            <TitleCard>CONFIGURACION GENERAL</TitleCard>
        </CardConfig>
        <CardConfig width='71px'>
            <RowConfig>
                <CropModal aspect={16 / 9} img={img} setImg={(img) => setConfig({ img })} />
            </RowConfig>
            <RowConfig top={2}>
                <Orientacion text='TEXTO' config={{ ...config, texto: true }} setConfig={setConfig} />
            </RowConfig>
            <RowConfig top={2}>
                <ButtonColor tip='COLOR DE FONDO' disableAlpha={true} color={color} setData={(color) => setConfig({ color })}>
                    <i className="fas fa-paint-roller"></i>
                </ButtonColor>
            </RowConfig>
            <TitleCard>BASICOS</TitleCard>
        </CardConfig>
        <TBElement />
        <SizeViewSelector setSize={(d) => setConfig({ size: d })} size={size} />
    </CardMain>
}