import { ControlText, RowConfig } from "@victorequena22/component-bootstrap";
import { useCallback, useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Page } from "./Page";
import { PageContext } from "../PageContext";
import { conConfig } from "@victorequena22/conneccion";


export function Pages() {
    const { pages } = useContext(PageContext);
    const [open, setOpen] = useState(false)
    return <RowConfig>
        <Nuevo open={open} close={() => setOpen(false)} />
        <Button onClick={() => setOpen(true)} data-place='bottom' data-tip='CREAR UNA PAGINA' size='sm' variant='success'>
            <i className='fas fa-plus' />
        </Button>
        {pages.map((p, i) => p !== null ? <Page key={`page${p.id}${i}`} {...p} ident={i} /> : <></>)}
        <Last />
    </RowConfig>
}
function Last() {
    const { pages, setPages } = useContext(PageContext);
    const [color, setColor] = useState('');
    const allowDrop = (ev: any) => {
        setColor('bg-info');
        ev.preventDefault();
    }
    const allowDropOut = (_ev: any) => {
        setColor('');
    }
    const drop = (ev: any) => {
        ev.preventDefault();
        setColor('');
        var data = ev.dataTransfer.getData("ident");
        const chance = pages[data];
        const newP = [...pages.filter(p => p.id !== chance.id), chance];
        setPages(newP);
        conConfig('page').push({ paginas: newP }, () => { })
    }
    return <div style={{ height: '24px', width: '1fr' }} className={color} onDragLeave={allowDropOut} onDragOver={allowDrop} onDrop={drop}>
    </div>
}
function Nuevo({ open, close }: any) {
    const { pages, setPages } = useContext(PageContext);
    const [nombre, setNombre] = useState('');
    const guardar = useCallback(() => {
        const id = Math.max(...pages.map(({ id }) => id)) + 1;
        const newP = [...pages, { title: nombre, visible: true, ocultar: [], id }]
        setPages(newP);
        conConfig('page').push({ paginas: newP }, () => { })
        close();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nombre])
    return <Modal size="sm" animation centered show={open} onHide={close}>
        <Modal.Header closeButton>
            <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center" >
                NUEVA PAGINA
            </h6>
        </Modal.Header>
        <Modal.Body>
            <ControlText data-tip='NOMBRE' maxLength={15} before="NOMBRE" setData={setNombre} value={nombre} /><br />
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={guardar} className="float-right" variant="primary">
                <span className="fa fa-floppy-o" /> GUARDAR
            </Button>
        </Modal.Footer>
    </Modal >
}