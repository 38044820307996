import React from "react"
import Barras from "../../../Utiles/Charts/Barras";
import { don } from "@victorequena22/conneccion";
export default class Mejor extends React.Component<{}, { mejor: { ventas: number, mes: string, ano: number }, actual: number }> {
    state = { mejor: { ventas: 0, mes: '', ano: 0 }, actual: 0 };
    render() {
        const { mejor: { ventas, mes }, actual } = this.state;
        return <div className="mejor">
            <Barras title="MES ACTUAL VS MEJOR MES"
                label={['', 'ACTUAL', mes]}
                values={[['VENTAS', actual, ventas]]}
            />
        </div>
    }
    componentDidMount() {
        this.send()
    }
    async send() {
        try {
            let token = localStorage.getItem('Authorization');
            let response = await fetch(don + '/api/Factura/mejor_mes', { headers: { 'Authorization': 'Bearer ' + token } });
            let datos = await response.json();
            this.setState(datos);
        } catch (error) {
            console.log(error);
            this.setState({ mejor: { ventas: 0, mes: '', ano: 0 }, actual: 0 });
        }
    }
}