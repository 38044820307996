import React from "react";
import { tipoData } from "../../../Models/General";
import EstadosLabel from "../../Componentes/EstadosLabel";
import THFila from "../../Componentes/THFila";
interface Props {
    type: tipoData,
    status: number
}
export default function StatusTh({ type, status }: Props) {
    return <THFila width="160px" align='right'>
        <EstadosLabel type={type} status={status} />
    </THFila>
}