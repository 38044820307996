import { useState, CSSProperties, useContext } from 'react'
import { Modal, Button, InputGroup } from 'react-bootstrap';
import { css } from '@emotion/css';
import { Selector as SelectorImage } from '../../../App/Media/Selector';
import { InputText, ButtonColor as BC } from "@victorequena22/component-bootstrap";
import { ColorContext } from '../../../Template/Colores';
interface colorProps {
    setData: (d: string) => void,
    invert?: boolean
    style?: CSSProperties
    color: string, 
    children: any,
    disable?: boolean
    className?: string
    disableAlpha?: boolean
    tip?: string
    click?: () => void
    gradiend?: boolean
}
export function ButtonColor(p: colorProps) {
    const { colores } = useContext(ColorContext);
    return <BC colores={colores} {...p} />
}
export function ButtonImage({ setData, className }: { className?: string, setData: (d: string) => void }) {
    const [open, setOpen] = useState(false);
    return <>
        <SelectorImage type='image' set={setData} open={open} close={() => setOpen(false)} />
        <ButtonConfig className={className} tip='SELECIONAR IMAGEN' action={() => setOpen(true)} icon='fas fa-image' />
    </>
}
export function ButtonVideo({ setData }: { setData: (d: string) => void }) {
    const [open, setOpen] = useState(false);
    return <>
        <SelectorImage type='video' set={setData} open={open} close={() => setOpen(false)} />
        <ButtonConfig tip='SELECIONAR VIDEO' action={() => setOpen(true)} icon='fas fa-video' />
    </>
}
export function Guardar({ guardar }: { guardar: () => void }) {
    return <Button variant='default' onClick={guardar} data-tip='GUARDAR'
        className={css`color: #28a745;padding: 1rem 1rem 1rem 1rem;border-color: #28a745;
        &:hover{color: white;background-color: #28a745;}`} >
        <i className="fa fa-save fa-4x"></i>
    </Button>
}
interface configProps {
    className?: string,
    style?: CSSProperties,
    action: () => void,
    tip?: string,
    icon: string,
    active?: boolean
    disabled?: boolean
    place?: string
    variant?: 'default' | 'outline-dark' | 'outline-primary' | 'outline-success' | 'outline-danger' | 'outline-warning' | 'outline-info' | 'outline-secondary' | 'dark' | 'primary' | 'success' | 'danger' | 'warning' | 'info' | 'secondary'
}
export function ButtonConfig({ disabled, action, place = 'bottom', tip, className, icon, active, style, variant = 'outline-dark' }: configProps) {
    return <Button size='sm' disabled={disabled} className={className} data-place={place} data-tip={tip} active={active} style={{ width: 32, height: 24, ...style, }} onClick={action} variant={variant}>
        <i className={icon} />
    </Button>
}
export function AddVideo({ setVideo, v }: { setVideo: (d: string) => void, v: string }) {
    const setImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        let f = e.currentTarget.files;
        let reader = new FileReader();
        reader.onloadend = () => setVideo(reader.result as string);
        if (f != null) reader.readAsDataURL(f[0]);
    }
    function setLink(s: string) {
        if (s.indexOf('youtube') > -1)
            s = s.replace('watch?v=', 'embed/');
        if (s.indexOf('drive.google.com/file/d') > -1)
            s = s.replace('view?usp=sharing', 'preview');
        return s;
    }
    const [open, setOpen] = useState(false);
    return <>
        <Modal animation show={open} onHide={() => setOpen(false)}>
            <Modal.Body>
                <InputGroup className='input-group-sm' style={{ width: 150, height: '24px' }}>
                    <InputGroup.Prepend style={{ height: '24px', width: '110px' }}>
                        TITULO MENU:
                    </InputGroup.Prepend>
                    <InputText style={{ height: '24px' }} className='form-control-sm' setData={(s) => setVideo(setLink(s))} value={v} />
                </InputGroup>
                <Button variant='outline-dark' as='label' size='sm' style={{ margin: '0 0 0 0', width: 32, height: 24 }}>
                    <i className="fas fa-image"></i>
                    <input style={{ display: "none" }} onChange={setImage} type="file" />
                </Button>
            </Modal.Body>
        </Modal>
        <ButtonConfig action={() => setOpen(true)} icon='fas fa-video' />
    </>
}
export const Cursos = () => <svg xmlns="http://www.w3.org/2000/svg"
    style={{ zIndex: 25, position: 'absolute', left: 13, top: 7 }}
    width=".7rem" height=".7rem" viewBox="0 0 320 512">
    <path fill='#FFFFFF' stroke-width="50" stroke="currentColor"
        d="M302.189 329.126H196.105l55.831 135.993c3.889 9.428-.555 19.999-9.444 23.999l-49.165 21.427c-9.165 4-19.443-.571-23.332-9.714l-53.053-129.136-86.664 89.138C18.729 472.71 0 463.554 0 447.977V18.299C0 1.899 19.921-6.096 30.277 5.443l284.412 292.542c11.472 11.179 3.007 31.141-12.5 31.141z"></path>
</svg>