import { Panel } from './Panel';
import { Config } from './Config';
import { Single, StateS } from '../../ProtoPanes/Single';
import { getText } from '../../ProtoPanes/DefaultData';
interface S {
    img: string;
    color: string;
    ori: boolean;
    variant: 'diagonal' | 'arrow' | 'semi-circle' | 'diagonal-i' | 'arrow-i' | '';
}
export type State = StateS & S;
export class Paralax1 extends Single<S> {
    constructor(p: any) {
        super(p);
        this.init({ img: '/public/panels/default.jpg', color: '#ffffff', ori: true, variant: '' });
    }
    Config = () => <Config save={this.save} setConfig={this.setData} title={this.state.title} setTitle={this.setTitle} config={this.state} />
    Panel = () => <Panel config={this.state} />
    getDataSave = () => {
        const { color, ori, variant, img, title, html} = this.state;
        const data = JSON.stringify({});
        const props = JSON.stringify({ color, ori, variant, img});
        return { title, html:getText(html), data, props, type: 'paralax1' };
    }
}