/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useContext, useCallback } from 'react'
import { Estado, EstadoNull } from '../../Models/General';
import { formatoHora } from '../../Utiles/Formatos';
import { Producto, conProducto, ProductoVenta } from '../../Models/Productos'
import { DolarContext, DolarType } from './Dolar';
import { ConfigContext, ConfigType } from './Config';
import { SeccionContext, SeccionType } from '../Seccion';
import { sleep, useSemaforo } from '../../Utiles/ReactUtils';
export interface ProductosType extends DolarType, ConfigType, SeccionType {
    estProducto: Estado
    Productos: Producto[]
    actProducto: () => void
    findProVen: (p: Producto, dolar: boolean) => ProductoVenta
}
function arrayToProducto(p: any[]): Producto[] {
    return p.map(a => {
        return {
            codigo: a[0],
            departamento: a[1],
            descripcion: a[2],
            estatus: a[3],
            enser: a[4],
            tipo: a[5],
            unidad: a[6],
            medida: a[7],
            costo: a[8],
            precio1: a[9],
            precio2: a[10],
            precio3: a[11],
            cantidad: a[12],
            fecha_creacion: a[13],
            inventario: a[14],
            exento: a[15],
            tasa: a[16],
            imagen: a[17],
        }
    })
}
function setCambioProductos(data: any[], p: Producto[]) {
    const push = arrayToProducto(data).filter((d => {
        const i = p.findIndex(p => p.codigo === d.codigo);
        if (i > -1) {
            p[i] = d;
            return false;
        }
        return true;
    }));
    const Productos = [...p, ...push];
    return Productos;
}
export const ProductosContext = createContext<ProductosType | null>(null)
export default function ProductosProvider({ children }: { children: any }) {
    const [Productos, setProductos] = useState<Producto[]>([]);
    const [est, setest] = useState(EstadoNull);
    const d = useContext(DolarContext);
    const c = useContext(ConfigContext) as ConfigType;
    const se = useContext(SeccionContext) as SeccionType;
    const [con] = useState(conProducto());
    const update = useCallback(async () => {
        con.cambios((data: any[]) => {
            setest({ status: 3, hora: formatoHora(con.state) })
            setProductos(setCambioProductos(data, Productos))
        },)
        await sleep(50);
    }, [Productos])
    const findProVen = useCallback((pr: Producto, dolares: boolean) => {
        let pro = Productos.find(p => pr.codigo === p.codigo)
        if (pro === undefined) pro = pr;
        let { costo, precio1, precio2, precio3, tasa } = pro;
        if (dolares) {
            if (tasa !== 0) costo /= tasa;
            else if (d.tasa !== 0) costo /= d.tasa;
        } else {
            if (tasa !== 0 && d.tasa !== 0) costo = parseFloat((costo / tasa).toFixed(2)) * d.tasa;
        }
        return {
            ...pro, costo,
            monto1: costo * (1 + (precio1 / 100)),
            monto2: costo * (1 + (precio2 / 100)),
            monto3: costo * (1 + (precio3 / 100))
        }
    }, [Productos, d.tasa])
    useSemaforo('Productos', async () => {
        await update()
    })
    return <ProductosContext.Provider value={{
        estProducto: est, actProducto: update,
        Productos, findProVen, ...d, ...c, ...se
    }}>
        {children}
    </ProductosContext.Provider>
}
export const SeccionConsumer = ProductosContext.Consumer;
export const findProVen = (pr: Producto, dolares?: boolean) => {
    const { Productos } = useContext(ProductosContext) as ProductosType;
    const { tasa: t } = useContext(DolarContext);
    let pro = Productos.find(p => pr.codigo === p.codigo)
    if (pro === undefined) pro = pr;
    let { costo, precio1, precio2, precio3, tasa } = pro;
    if (dolares) {
        if (tasa !== 0) costo /= tasa;
        else if (t !== 0) costo /= t;
    } else {
        if (tasa !== 0 && t !== 0) costo = parseFloat((costo / tasa).toFixed(2)) * t;
    }
    return {
        ...pro, costo,
        monto1: costo * (1 + (precio1 / 100)),
        monto2: costo * (1 + (precio2 / 100)),
        monto3: costo * (1 + (precio3 / 100))
    }
}