/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useContext, useCallback } from 'react'
import { AuthSome } from '../../Config/Permisos';
import { conCotizacion } from '../../Models/Ventas'
import { Estado, EstadoNull, GeneralFila, updateOrPush } from '../../Models/General';
import { formatoHora } from '../../Utiles/Formatos';
import { ClientesContext, ClientesType } from './Cliente';
import { ProductosContext, ProductosType } from '../Producto';
import { sleep, useSemaforo } from '../../Utiles/ReactUtils';
export interface CotizacionType extends ClientesType, ProductosType {
    estCotizacion: Estado
    Cotizaciones: GeneralFila[]
    actCotizacion: () => void
}
export const CotizacionContext = createContext<CotizacionType | null>(null)
export default function CotizacionProvider({ children }: { children: any }) {
    const [Cotizacion, setCotizacion] = useState<GeneralFila[]>([]);
    const cliente = useContext(ClientesContext) as ClientesType;
    const pruducto = useContext(ProductosContext) as ProductosType;
    const [est, setest] = useState(EstadoNull);
    const [con] = useState(conCotizacion());
    const update = useCallback(async () => {
        if (AuthSome(['FACTURA', 'NOTA', 'COTIZACION'])) con.cambios((data: any[]) => {
            setest({ status: 3, hora: formatoHora(con.state) })
            setCotizacion(updateOrPush(data, Cotizacion))
        })
        await sleep(50);
    }, [Cotizacion]);
    useSemaforo('Cotizacion', async () => {
        await update()
    })
    return <CotizacionContext.Provider value={{
        ...cliente, ...pruducto,
        estCotizacion: est,
        Cotizaciones: Cotizacion,
        actCotizacion: update
    }}>
        {children}
    </CotizacionContext.Provider>
}
export const SeccionConsumer = CotizacionContext.Consumer;