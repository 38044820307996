import { getCSS } from './props';
import { Row, Container } from 'react-bootstrap';
import { State } from './index';
import { Itens } from './Itens';

export function Panel({ config, seled }: { config: State, seled: (i: number) => void }) {
    const { color, itens, width, gap } = config;
    const m = { margin: '0 0 0 0', padding: '0 0 0 0' };
    return <Row style={{ background: color, ...m }}>
        <Container className={getCSS(width, gap)}>
            {itens.map((i) => <Itens key={`iten${i.img}${i.id}`} iten={i} config={config} seled={seled} />)}
        </Container>
    </Row>
}