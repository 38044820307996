import { MobileUserAgent } from '@victorequena22/utiles';
import { useState, createContext, useEffect } from 'react';
export interface MenuType {
    set: (d: string) => void,
    open: () => void
    menu: string
}
export const MenuContext = createContext<MenuType>({
    set: (_d: string) => { },
    open: () => { }, menu: 'open'
})
export default function MenuProvider({ children }: { children: any }) {
    const [menu, setMeu] = useState('open');
    const set = (d: string) => {
        setMeu(d);
        localStorage.setItem('menu', d)
    }
    const open = () => {
        if (menu === 'open') set('cerrado');
        else set('open');
    }
    useEffect(() => {
        let body = document.getElementsByTagName('body')[0];
        if (body) {
            if (menu === 'cerrado') body.classList.add('sidebar-collapse')
            else body.classList.remove('sidebar-collapse')
        }
    }, [menu]);
    useEffect(() => {
        const menu = localStorage.getItem('menu');
        if (menu === null) set('open');
        if (MobileUserAgent()) set('cerrado');
    }, [])
    return <MenuContext.Provider value={{ set, open, menu }}>{children}</MenuContext.Provider>
}