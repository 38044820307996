import React from 'react'
import { Table, Modal, Row, Button, Col, InputGroup, FormControl } from 'react-bootstrap';
import { conCotizacion } from '../../Models/Ventas';
import { formatoFecha, formatoHora } from '../Formatos';
interface Props {
    open: boolean,
    close: () => void,
    id: number,
    type: 'Cotizacion' | 'Orden'
}
interface Seguimiento {
    descripcion: string,
    usuario: string,
    fecha: string,
    correo: string
}
interface State {
    mensajes: Seguimiento[],
    msn: string
}
class FilaSeguimiento extends React.Component<{ seguimiento: Seguimiento }> {
    render() {
        let { descripcion, usuario, fecha, correo } = this.props.seguimiento;
        return <tr>
            <th style={{ width: "300px", maxWidth: "300px" }}>
                {usuario}<br />{correo}
            </th>
            <th style={{ width: "100px" }}>
                {formatoFecha(fecha)}<br />
                {formatoHora(fecha)}
            </th>
            <th>
                {descripcion}
            </th>
        </tr>
    }
}
export default class Seguimientos extends React.Component<Props, State> {
    ref = React.createRef<HTMLDivElement>()
    msn: any = undefined
    con = conCotizacion();
    constructor(p: Props) {
        super(p)
        this.state = { msn: "", mensajes: [] };
        this.setState = this.setState.bind(this);
    }
    render() {
        let { mensajes } = this.state
        return <Modal size="xl" animation show={this.props.open} onHide={this.props.close}>
            <Modal.Header closeButton>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    <span><b>BITACORA DE COTIZACIÓN N° {this.props.id}</b></span>
                </h6>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12}>
                        <Table>
                            <thead>
                                <tr>
                                    <th style={{ width: "300px" }}>USUARIO</th>
                                    <th style={{ width: "100px" }}>FECHA</th>
                                    <th>MENSAJE</th>
                                </tr>
                            </thead>
                        </Table>
                        <div ref={this.ref} style={{ overflowY: "auto", height: 400, maxHeight: 400, width: "100%" }}>
                            <Table>
                                <tbody>
                                    {mensajes.map((s, i) => {
                                        return <FilaSeguimiento key={s.usuario + i} seguimiento={s} />
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>MENSAJE</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl onKeyUp={this.setMsn.bind(this)} defaultValue={this.state.msn} />
                            <InputGroup.Append>
                                <Button onClick={this.enviar.bind(this)}>
                                    <span className="fa fa-send"></span>
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    }
    setMsn(e: React.KeyboardEvent<HTMLInputElement>) {
        let { value } = e.currentTarget
        if (this.msn === undefined)
            this.msn = e.currentTarget
        this.setState({ msn: value })
        if (e.keyCode === 13) {
            this.enviar()
        }
    }
    enviar() {
        this.sent();
        let m = this.state.mensajes,
            date = new Date().toDateString();
        let fecha = formatoFecha(date) + " " + formatoHora(date);
        this.setState({
            mensajes: [...m, {
                descripcion: this.state.msn,
                fecha, usuario: "", correo: ""
            }]
        })
        this.clear()
    }
    clear() {
        if (this.msn !== undefined)
            this.msn.value = ''
    }
    componentWillReceiveProps(p: Props) {
        if (this.props.open !== p.open && p.open === true) this.set();
    }
    actualizar() {
        this.set();
        setTimeout(() => {
            this.actualizar()
        }, 36000);
    }
    sent() {
        this.con.post('/api/' + this.props.type + '/seguimiento_nuevo/' + this.props.id,
            { descripcion: this.state.msn },
            data => { this.set(); });
    }
    async set() {
        this.con.forceGetWeb('/api/' + this.props.type + '/seguimiento/' + this.props.id, (data) => {
            this.setState({ mensajes: data })
        });
    }
}