import { TitleCard, CardConfig } from '../../../Component';
import { ItenIconBox } from '../../../Component/Commons';
import { iten, State } from '../index';
interface Props {
    iten: iten,
    config: State,
    setConfig: (d: Partial<iten>) => void,
    itenUp: (d: number) => void,
    itenDown: (d: number) => void,
    removeItem: (d: number) => void
    addItem: () => void,
    cont: number
}
export function ConfigIten(p: Props) {
    const { iten: { id } } = p;
    return <CardConfig width='142px'>
        <ItenIconBox {...p} />
        <TitleCard>OPCIONES ITEN {id}</TitleCard>
    </CardConfig>
}