import CardBuscador from '../../../../Utiles/Buscadores/Card';
import { conCompra, conNotasDC } from '../../../../Models/Compra';
import { EstadoNull } from '../../../../Models/General';
import FilaDebito from './FilaDebito';
import { formatoHora } from '../../../../Utiles/Formatos';
export default class ListCreditos extends CardBuscador {
    permiso = 'AJUSTE';
    estado = EstadoNull;
    con = conNotasDC();
    refe = conCompra();
    placeholder = "BUSCAR NOTA DE DEBITO EN COMPRA";
    buscarEn = ["codigo", "nombre", 'fecha'];
    Avansado() {
        return <></>
    }
    Title() {
        return <>
            <h4 className='mr-auto'><i className="fa fa-search"></i> BUSCAR NOTA DE DEBITO EN COMPRA</h4>
        </>
    }
    Header() {
        const { Th } = this;
        return <tr className="info">
            <Th label='codigo' width='100px'>N°</Th>
            <Th label='doc' width='100px'>FAC</Th>
            <Th label='fecha' width='120px'>FECHA</Th>
            <Th label='nombre' width='calc(100% - 470px)'>USUARIO</Th> 
            <Th width='100px'>TIPO</Th>
            <Th width='100px'>ESTADO</Th>
            <Th width='70px'>{undefined}</Th>
        </tr>
    }
    Row(p: any) {
        return <FilaDebito refe={this.refe} con={this.con} key={"cot" + p.codigo + p.estado} data={p} />
    }
    getEstado() { return this.estado; }
    set() {
        this.estado = { status: 2, hora: '...ACTUALIZANDO' };
        this.con.cargarListaWeb((itens) => {
            this.estado = { status: 3, hora: formatoHora(undefined) };
            this.setState({ itens });
        });
    }
}
