import { Button, ButtonGroup } from 'react-bootstrap';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { Panel, conPanel } from '../Models';
import { PanelContext } from '../Context/Panel';
import { useContext, useState } from 'react';
import { ModalAccion } from '@victorequena22/component-bootstrap';
export default function GestionPanel({ panel: { id, estado, type, }, children }: { panel: Panel, children?: any }) {
    const { updatePanel } = useContext(PanelContext);
    const [open, setOpen] = useState(false);
    const con = conPanel();
    return <>
        <ButtonGroup>
            <Button data-tip='EDITAR PANEL' as={Link} to={`/panel/edit/${id}`} variant="default">
                <i className='fas fa-edit' />
            </Button>
            <Button data-tip='COPIAR PANEL' as={Link} to={`/panel/copy/${id}`} variant="default">
                <i className='fas fa-copy' />
            </Button>
            {estado === 2 ? <></> : <>
                <Button data-tip='SUBIR PANEL' onClick={() => con.up(id, updatePanel, () => { })} variant="default">
                    <i className='fas fa-arrow-up' />
                </Button>
                <Button data-tip='BAJAR PANEL' onClick={() => con.down(id, updatePanel, () => { })} variant="default">
                    <i className='fas fa-arrow-down' />
                </Button>
            </>}
        </ButtonGroup>
        {children}
        {estado === 2 ? <></> : <>
            <ReactTooltip key={`tooltipsPanel${id}${estado}`} />
            <ModalAccion open={open} close={() => setOpen(false)}
                title={`ELIMINAR PANEL ${id}`}
                msn={`DESEA ELIMINAR PANEL ${id} de tipo ${type}`}
                accion={() => con.delete(id, updatePanel, () => { })} />
            <ButtonGroup className='ml-auto'>
                <Button data-tip={`${estado === 0 ? 'VER' : 'OCULTAR'} PANEL`} onClick={() => con.cancelar(id, updatePanel)} variant={estado === 0 ? "warning" : "success"}>
                    <i className={`fas fa-eye${estado === 0 ? '-slash' : ''}`} />
                </Button>
                <Button data-tip='ELIMINAR PANEL' onClick={() => setOpen(true)} variant="danger">
                    <i className='fas fa-trash' />
                </Button>
            </ButtonGroup>
        </>}
    </>
}