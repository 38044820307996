import { useState } from "react";
import { Button } from "react-bootstrap";
import { usePermiso } from "@/Context/Seccion";
import { Producto } from "@/Models/Productos";
import FormProductos from "../FormProductos";
interface Props {
    add: (p: Producto) => void
    dolar?: boolean
}
export default function CrearProductoButon(p: Props) {
    const [o, s] = useState(false);
    const pr = usePermiso('PRODUCTO');
    return pr ? <>
        <FormProductos  {...p} open={o} close={() => s(false)} />
        <Button data-tip="NUEVO PRODUCTO" onClick={() => s(true)} variant="info">
            <i className="fa fa-edit"></i>
        </Button>
    </> : <></>
}