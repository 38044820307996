import { useState } from "react";
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField';
export interface Props extends OutlinedTextFieldProps {
    setData: (data: any) => void,
    value: string
    mask?: string
    update?: string
    upper?: boolean
    lower?: boolean
    reg?: RegExp
    only?: 'letter' | 'alphanumeric' | 'number'
    next?: () => void
}

export default function FieldText(p: Props) {
    const [select, setSelect] = useState(false);
    const value = () => {
        if (select) return { defaultValue: p.value };
        else return { value: p.value }
    }
    const Props = () => {
        let props: any = { ...p };
        props.value = undefined;
        return { ...props, ...value() };
    }
    const match = (val: string, only: 'letter' | 'alphanumeric' | 'number') => {
        let nval = '';
        if (val.length > 0) {
            val.split('').forEach((m: string) => {
                switch (only) {
                    case 'alphanumeric':
                        if (/[A-Za-z0-9]/g.test(m)) nval += m;
                        break;
                    case 'letter':
                        if (/[A-Za-z]/g.test(m)) nval += m;
                        break;
                    case 'number':
                        if (/[0-9]/g.test(m)) nval += m;
                        break;
                }
            });
            return nval;
        }
        return '';
    }
    const Mask = (val: string, mask: string) => {
        let nval = '';
        if (val.length > 0) {
            mask.split('').forEach((m: string, i: number) => {
                if (m === 'a') {
                    if (/[A-Za-z]/g.test(val[i])) {
                        nval += val[i];
                    }
                } else if (m === 'A') {
                    if (/[A-Za-z]/g.test(val[i])) {
                        nval += val[i].toUpperCase();
                    }
                } else if (m === '9') {
                    if (/[0-9]/g.test(val[i])) {
                        nval += val[i];
                    }
                } else if (m === '*') {
                    if (/[A-Za-z0-9]/g.test(val[i])) {
                        nval += val[i];
                    }
                } else if (val[i] !== undefined) {
                    if (nval.length === i)
                        nval += m;
                } else if (val[i] === undefined) {
                    if (m !== 'a' && m !== 'A' && m !== '9' && m !== '*')
                        if (nval.length === i)
                            nval += m;
                }
            });
            return nval;
        } else {
            return '';
        }
    };
    return <TextField {...Props()}
        onFocus={() => { setSelect(true) }}
        onBlur={() => { setSelect(false) }}
        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.keyCode === 13) if (p.next) p.next();
            if (p.onKeyUp) p.onKeyUp(e);
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            let { value } = e.currentTarget;
            if (p.only) value = match(value, p.only);
            if (p.mask) value = Mask(value, p.mask);
            if (p.upper) value = value.toUpperCase();
            if (p.lower) value = value.toLowerCase();
            e.currentTarget.value = value;
            if (p.update) p.setData({ [p.update]: value });
            else p.setData(value);
        }}
    />
}