import React from 'react';
import { conMoneda, Moneda } from '../../../Models/General';
import { FilaMoneda } from './Fila';
import ReactTooltip from 'react-tooltip';
import NuevoMoneda from './Nuevo';
import CardBuscador from '../../../Utiles/Buscadores/Card';
import { Button, Col, InputGroup, Row } from 'react-bootstrap';
import { InputText } from '../../../Utiles/Inputs';
export default class ListMoneda extends CardBuscador {
    con = conMoneda();
    mostrar = 6;
    placeholder = "BUSCAR MONEDA";
    estatus = [{ value: 0, label: 'CANCELADO' }, { value: 1, label: 'SIN FACTURA' }, { value: 2, label: 'FACTURADA' }];
    buscarEn = ["codigo", "descripcion"];
    Title() {
        return <h4 className='mr-auto'><i className="fa fa-search"></i> BUSCAR MONEDA</h4>
    }
    Avansado() {
        return <></>;
    }
    Buscador() {
        return <>
            <ReactTooltip />
            <Row className='d-flex' style={{ paddingBottom: '15px' }}>
                <Col md={9} className='mr-auto'>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                BUSCAR
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <InputText upper setData={this.setState} update='buscar' value={this.state.buscar} />
                        <InputGroup.Append>
                            <Button variant="primary"><i className="fa fa-search"></i> BUSCAR</Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
                <Button size='sm' onClick={this.set.bind(this)} data-tip="ACTUALIZAR LISTA" variant="info"
                ><i className="fa fa-refresh"></i> ACTUALIZAR</Button>
            </Row>
            <NuevoMoneda update={this.set.bind(this)} />
        </>
    }
    Header() {
        const { Th } = this;
        return <tr className="info">
            <Th label='codigo' width='100px'>COD</Th>
            <Th label='descripcion' width='calc(100% - 270px)'>DESCRIPCION</Th>
            <Th width='100px'>ESTATUS</Th>
            <Th width='70px'>ACCIONES</Th>
        </tr>
    }
    Row(p: Moneda) {
        return <FilaMoneda key={'moneda' + p.codigo} d={p} update={this.set.bind(this)} />
    }
}
