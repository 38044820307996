import React from "react"
import Tortas, { PieProps } from "../../../../Utiles/Charts/Torta";
export interface rank {
    cantidad: number,
    estatus: number
}
export default class TortasMaster extends React.Component<{ lugar: string }, PieProps>{
    estados = ['CANCELADO', 'PENDIENTE', 'PROCESADA'];
    api = 'Cotizacion';
    title = '';
    constructor(p: any) {
        super(p);
        this.state = { title: '', values: this.estados.map((s) => [s, 0]) };
    }
    render() {
        const { title, values } = this.state;
        return <div className={'center ' + this.props.lugar}>
            <div style={{ textAlign: 'center' }}>
                {this.isData() ? <Tortas title={title} values={values} /> :
                    <div style={{ gridAutoRows: '18rem' }} className='center'>
                        <div style={{ gridAutoRows: '12rem' }} className='no-data bg-black center'>
                            <b>SIN REGISTROS<br /> EN EL MES</b>
                        </div>
                    </div>}
                <h5>{this.title}</h5>
            </div>
        </div>
    }
    isData() {
        if (this.state.values.length > 0)
            for (let i = 0; i < 3; i++)
                if (this.state.values[i][1] > 0)
                    return true;
        return false;
    }
    componentDidMount() {
        this.send()
    }
    async send() {
        let date = new Date();
        let año = date.getFullYear(),
            mes = date.getMonth() + 1;
        try {
            let token = localStorage.getItem('Authorization');
            let response = await fetch('/api/' + this.api + '/torta/mes/' + año + '/' + mes, { headers: { 'Authorization': 'Bearer ' + token } });
            let datos: rank[] = await response.json();
            this.setState({
                values: this.estados.map((s, i) => {
                    const d = datos.find(d => d.estatus === i);
                    return [s, d ? d.cantidad : 0]
                })
            });
        } catch (error) {
            console.log(error);
            this.setState({ values: this.estados.map((s) => [s, 0]) });
        }
    }
}