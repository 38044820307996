import { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { conCotizacion } from '../../../Models/Ventas';
import Seguimientos from '../../../Utiles/Componentes/Seguimiento';
import FilaVentas from '../../../Utiles/Ventas/FilaVentas';
import BTNCancelar from '../../../Utiles/Prototipos/Fila/Botones/BTNCancelar';
import BTNImprimir from '../../../Utiles/Prototipos/Fila/Botones/BTNImprimirDolar';
import DetallesTh from './ModalDetalle';
function BTNSegiomiento({ id }: { id: number }) {
    const [open, setOpen] = useState(false);
    return <>
        <Button data-tip="VER IMPRESION" variant="outline-dark"
            onClick={() => setOpen(true)}>
            <i className="fa fa-eye"></i>
        </Button>
        <Seguimientos key={'SeguimientosCotizacion' + id} type='Cotizacion'
            close={() => setOpen(false)} open={open} id={id} />
    </>
}
export default class FilaCotizacion extends FilaVentas {
    con = conCotizacion();
    print = true;
    constructor(p: any) {
        super(p);
        this.label = 'COTIZACIÓN';
        this.type = 'Cotizacion';
    }
    Detalles() {
        const { label, data: { codigo } } = this;
        return <DetallesTh label={label} id={codigo} />;
    }
    Botones() {
        const { type, con, label, cancelado, data: { status, codigo } } = this;
        return <th style={{ minWidth: "135px", maxWidth: "135px" }}>
            <ButtonGroup className="float-right">
                <BTNSegiomiento id={codigo} />
                <BTNImprimir label={label} id={codigo} tipo={type} />
                {(status !== 1) ? <></> :
                    <BTNCancelar con={con} label={label} id={codigo} msn={cancelado()} />}
            </ButtonGroup>
        </th>
    }
    cancelado() {
        return 'COTIZACION N° ' + this.props.data.codigo + ' CANCELADA ESXITOSAMENTE';
    }
}