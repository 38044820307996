import { semaforo } from "@victorequena22/utiles";
import { useCallback, useEffect } from "react";


const s = semaforo();

export const useSemaforo = (tur: string, update: () => Promise<void>) => {
    const actualizar = useCallback(async () => {
        if (s.isTurn(tur)) {
            await update();
            s.next();
        }
    }, [update, tur])
    useEffect(() => {
        const interval = setInterval(actualizar, 1000);
        return () => clearInterval(interval);
    }, [actualizar])
    useEffect(() => { s.add(tur); return () => s.clean(tur); }, [tur])
}

export const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}