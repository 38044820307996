import { Card, Row, ButtonGroup, InputGroup } from 'react-bootstrap';
import { InputNumber } from "@victorequena22/component-bootstrap";
import { ButtonColor, TitleCard, Guardar, CardConfig, RowConfig, ButtonConfig } from '../../Component';
import { Inserts } from '../../Component/SlateCustom';
import { CropModal } from '../../../Media/CropModal';
import { State } from './index'
import { ColorButton, FontStyle, MarkMenu } from "@victorequena22/slate-bootstrap";
import { TabsMenu } from "@victorequena22/slate-bootstrap";
import { Cursos } from '../../Component/Buttons';
interface Props { save: () => void, config: State; setConfig: (d: Partial<State>) => void }
export function Config({ config, setConfig, save }: Props) {
    const { height, backgroundColor, img, color, backgroundColorHover, colorHover, orientacion } = config;
    return <Card style={{ backgroundColor: '#ccc', padding: '7px 7px 7px 7px' }}>
        <Row style={{ margin: '0 0 0 0', padding: '0 0 0 0' }}>
            <Guardar guardar={save} />
            <CardConfig width='69px' >
                <RowConfig>
                    <CropModal img={img} setImg={(img) => setConfig({ img })} aspect={1} />
                </RowConfig>
                <RowConfig top={2}>
                    <InputGroup className='input-group-sm' style={{ height: '24px', width: 63 }}>
                        <InputGroup.Prepend style={{ height: '24px', width: '22px' }}>
                            <InputGroup.Text style={{ height: '24px', width: '22px', paddingLeft: '.40rem' }}>
                                <i className="fas fa-arrows-v"></i>
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <InputNumber decimal={0} min={25} max={100} style={{ height: 24 }} value={height} setData={height => setConfig({ height })} />
                    </InputGroup>
                </RowConfig>
                <RowConfig top={2}>
                    <ButtonColor style={{ width: 32 }} color={backgroundColor}
                        tip='COLOR DE MENU' gradiend
                        setData={(backgroundColor) => setConfig({ backgroundColor })}>
                        <i className="fas fa-paint-roller"></i>
                    </ButtonColor>
                </RowConfig>
                <TitleCard>OPCIONES</TitleCard>
            </CardConfig>
            <CardConfig width='243px' >
                <RowConfig>
                    <TabsMenu left={0} />
                    <MarkMenu left={1} />
                    <ColorButton left={1} />
                    <Inserts />
                </RowConfig>
                <RowConfig top={2}><FontStyle left={0} width='100%' /></RowConfig>
                <div className='mt-2' style={{ height: 24 }} />
                <TitleCard>MARCA</TitleCard>
            </CardConfig>
            <CardConfig width='106px' >
                <RowConfig>
                    <ButtonColor invert={true} style={{ width: 32 }}
                        tip='COLOR DE FUENTE DE LOS VINCULOS'
                        color={color} setData={(color) => setConfig({ color })}>
                        <i className="fas fa-font"></i>
                    </ButtonColor>
                    <ButtonGroup className='ml-1'>
                        <ButtonColor style={{ width: 32 }} gradiend
                            tip='COLOR DE FONDO DE LOS VINCULOS CUANDO ESTA EN HOVER'
                            color={backgroundColorHover} setData={(backgroundColorHover) => setConfig({ backgroundColorHover })}>
                            <i className="fas fa-paint-roller"></i>
                            <Cursos />
                        </ButtonColor>
                        <ButtonColor invert={true} style={{ width: 32 }}
                            tip='COLOR DE FUENTE DE LOS VINCULOS CUANDO ESTA EN HOVER'
                            color={colorHover} setData={(colorHover) => setConfig({ colorHover })}>
                            <i className="fas fa-font"></i>
                            <Cursos />
                        </ButtonColor>
                    </ButtonGroup>
                </RowConfig>
                <RowConfig top={2}>
                    <ButtonGroup>
                        <ButtonConfig active={!orientacion} action={() => setConfig({ orientacion: false })} icon='fas fa-caret-square-left' />
                        <ButtonConfig active={orientacion} action={() => setConfig({ orientacion: true })} icon='fas fa-caret-square-right' />
                    </ButtonGroup>
                </RowConfig>
                <div className='mt-2' style={{ height: 24 }} />
                <TitleCard>MENU</TitleCard>
            </CardConfig>
        </Row >
    </Card >
}