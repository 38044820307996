import React, { useContext, useEffect } from 'react'
import { FormControl } from 'react-bootstrap';
import { UTDContext, UTDType } from '../../../Context/Producto/UTD';
interface Props { change: (r: number) => void, defaultValue: number, isInvalid?: boolean }
export default function UnidadSelector(p: Props) {
    const context = useContext(UTDContext) as UTDType;
    const setRol = (Rol: React.ChangeEvent<HTMLSelectElement>) => { p.change(parseInt(Rol.currentTarget.value)) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { context.actUnidad() }, [])
    return <FormControl as='select' onChange={setRol} value={p.defaultValue} isInvalid={p.isInvalid}>
        <option value={-1}>SELECCIONAR UNIDAD</option>
        {context.Unidades.filter(d => d.estatus === 1).map(c => <option key={"user" + c.codigo} value={c.codigo}>{c.descripcion}</option>)}
    </FormControl>
}