import { CSSProperties } from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';
import { BlockElement, TextToHTML } from "@victorequena22/slate-bootstrap";
import { EditArea } from '../../../Component/SlateCustom';
import { iten, State } from '../index';
interface Props {
    iten?: iten,
    config: State,
    setConfig: (s: Partial<State>) => void
}
interface IsHTML {
    html: false | BlockElement[];
    select: 'top' | 'bottom' | 'left' | 'right';
    width?: string
}
export function Itens({ config, iten, setConfig }: Props) {
    if (!iten) return <></>
    const { edit } = config;
    const { id, top, left, right, bottom, img, color, back, marco, borde, width } = iten;
    const style: CSSProperties = { background: back };
    const IsHTML = ({ html, select: s, width }: IsHTML) => {
        if (!html) return <></>
        const w = { width, maxWidth: width };
        if (edit === s) return <Row className='m-0 p-0' style={{ ...w, border: '2px solid red' }}>
            <EditArea key={'edit' + s + id} />
        </Row>
        return <Row style={w} className='m-0 p-0' onClick={() => setConfig({ edit: s })}>
            <TextToHTML key={'edit' + Math.random()} text={html} />
        </Row>
    }
    return <Col xs={12}>
        <Card style={style}>
            <Card.Body>
                <div className='m-0 p-0 d-flex flex-column'>
                    <IsHTML select='top' html={top} />
                    <Row className='d-flex flex-row justify-content-center'>
                        <IsHTML width={getWidth(!!left, !!right, width)} select='left' html={left} />
                        <Row className='m-0 p-0'>
                            <Image style={{ maxWidth: width + 'px', background: color, border: '0px' }} className={`${marco} ${borde ? 'img-thumbnail' : ''}`} src={img} />
                        </Row>
                        <IsHTML width={getWidth(!!left, !!right, width)} select='right' html={right} />
                    </Row>
                    <IsHTML select='bottom' html={bottom} />
                </div>
            </Card.Body>
        </Card>
    </Col >
}
const getWidth = (l: boolean, r: boolean, w: number) => {
    if (l && r) return `calc(50% - ${w / 2}px)`;
    return `calc(100% - ${w}px)`;
}
