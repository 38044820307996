import { useState, createContext, useEffect, useCallback } from 'react'

export interface VideoType {
    load: boolean,
    time: number, setTime: (time: number) => void,
    start: number, setStart: (time: number) => void,
    end: number, setEnd: (time: number) => void,
    play: boolean, setPlay: (time: boolean) => void,
    loop: boolean, setLoop: (time: boolean) => void,
    mute: boolean, setMute: (time: boolean) => void,
}
export const VideoContext = createContext<VideoType>({
    time: 0, setTime: (_time: number) => { },
    start: 0, setStart: (_time: number) => { },
    end: 1, setEnd: (_time: number) => { },
    play: false, setPlay: (_time: boolean) => { },
    loop: false, setLoop: (_time: boolean) => { },
    mute: false, setMute: (_time: boolean) => { },
    load: false,
})
export default function VideoProvider({ children, }: { children: any }) {
    const [time, setTime] = useState(0);
    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(1);
    const [play, setPlay] = useState(false);
    const [loop, setLoop] = useState(false);
    const [mute, setMute] = useState(false);
    const [load, setLoad] = useState(false);
    const timeupdate = useCallback(() => {
        const video = document.getElementById('video') as HTMLVideoElement;
        if (video !== null) {
            if (video.currentTime < start)
                video.currentTime = start + .0000001;
            if (video.currentTime > end) {
                video.currentTime = start + .0000001;
                if (!loop) video.pause();
            }
            setTime(video.currentTime);
        }
    }, [start, end, loop]);
    const init = useCallback(() => {
        const video = document.getElementById('video') as HTMLVideoElement | null;
        if (video !== null) {
            video.addEventListener('loadedmetadata', () => {
                setEnd(video.duration);
                video.addEventListener('pause', () => setPlay(false), false);
                video.addEventListener('play', () => setPlay(true), false);
                video.ontimeupdate = timeupdate;
                setLoad(true);
            }, false);
        } else setTimeout(init, 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        const video = document.getElementById('video') as HTMLVideoElement;
        if (video !== null) video.ontimeupdate = timeupdate
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [start, end, loop]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, []);
    return <VideoContext.Provider value={{
        time, setTime, start, setStart, end, setEnd, load,
        play, setPlay, loop, setLoop, mute, setMute
    }}>
        {children}
    </VideoContext.Provider>
}
export const VideoConsumer = VideoContext.Consumer;