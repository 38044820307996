import React from 'react';
import { Button, ButtonGroup, Card, InputGroup } from 'react-bootstrap';
import ModalReport from '../../Utiles/Componentes/ModalReport';
import { InputNumber } from '../../Utiles/Inputs';
import DepartamentoSelector from '../Inventario/Departamento/Selector';
import TipoSelector from '../Inventario/Tipos/Selector';
import { don } from '@victorequena22/conneccion';
interface State {
    departamento: string
    uso: string
    tipo: number
    min: number
    max: number
    modal: boolean
}
export default class Inventario extends React.Component<any, State> {
    state = { modal: false, departamento: 'TODO', uso: '2', min: -1, max: -1, tipo: -1 }
    render() {
        let { departamento, uso, min, max, modal, tipo } = this.state;
        return (<Card>
            <ModalReport title={"REPORTE DE INVENTARIO"} open={modal}
                url={don + "/api/Producto/inventario/" + departamento + '/' + uso + '/' + tipo + '/' + min + '/' + max}
                close={() => { this.setState({ modal: !modal }) }} />
            <Card.Header className='bg-info'>
                <h3>REPORTE DE INVENTARIO</h3>
            </Card.Header>
            <Card.Body>
                <Card>
                    <Card.Header className='bg-info'>
                        <h6>FILTRAR POR DEPARTAMENTO</h6>
                    </Card.Header>
                    <Card.Body>
                        <DepartamentoSelector change={(departamento) => { this.setState({ departamento }) }} defaultValue={departamento} />
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header className='bg-info'>
                        <h6>FILTRAR POR USO DE PRODUCTO</h6>
                    </Card.Header>
                    <Card.Body>
                        <TipoSelector change={(tipo) => { this.setState({ tipo }) }} defaultValue={tipo} />
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header className='bg-info'>
                        <h6>FILTRAR POR USO DE PRODUCTO</h6>
                    </Card.Header>
                    <Card.Body>
                        <ButtonGroup style={{ width: '100%' }}>
                            <Button style={{ width: '33.333333333333%' }} onClick={() => { this.setState({ uso: '2' }) }} variant={uso === '2' ? 'success' : 'default'}>
                                TODOS LO PRODUCTOS
                            </Button>
                            <Button style={{ width: '33.333333333333%' }} onClick={() => { this.setState({ uso: '1' }) }} variant={uso === '1' ? 'success' : 'default'}>
                                PARA LA VENTA
                            </Button>
                            <Button style={{ width: '33.333333333333%' }} onClick={() => { this.setState({ uso: '0' }) }} variant={uso === '0' ? 'success' : 'default'}>
                                PARA USO INTERNO
                            </Button>
                        </ButtonGroup>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header className='bg-info'>
                        <h6>FILTRAR POR USO DE EXISTENCIA EN INVENTARIO</h6>
                    </Card.Header>
                    <Card.Body>
                        <InputGroup>
                            <InputGroup.Prepend><InputGroup.Text>DISPONIBILIDAD DESDE</InputGroup.Text></InputGroup.Prepend>
                            <InputNumber min={0} step='0.01' setData={this.setState.bind(this)} value={min} update="min" />
                            <InputGroup.Prepend><InputGroup.Text>HASTA</InputGroup.Text></InputGroup.Prepend>
                            <InputNumber min={0} step='0.01' setData={this.setState.bind(this)} value={max} update="max" />
                        </InputGroup>
                        <p>-1 NO PALICARA EL FILTRO</p>
                    </Card.Body>
                </Card>
                <Button className="float-right" onClick={() => { this.setState({ modal: !modal }) }} variant='primary'>
                    <span className="glyphicon glyphicon-print"></span> IMPRIMIR
                </Button>
            </Card.Body>
        </Card>)
    }
}