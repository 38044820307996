import  { useState, useMemo, useEffect } from "react";
import { Button, Modal, Table, Row } from "react-bootstrap";

import { generalNull } from "../../../Models/General";
import { numberFormat, zfill } from "../../Formatos";
interface Props {
    con: Conexion
    id: number
    label: string
}
interface PropsModal {
    id: number
    open: boolean,
    close: () => void,
    titulo: string
    con: Conexion
}
export function ModalDetalle({ id, open, close, titulo, con }: PropsModal) {
    const [detalles, setDetalles] = useState(generalNull.detalles);
    useEffect(() => { con.detalle(id, data => setDetalles(data.detalles)); }, [id,con])
    return <Modal size='xl' centered animation show={open} onHide={close}>
        {useMemo(() => {
            let suma = 0;
            return <>
                <Modal.Header closeButton>
                    <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                        <span><b>{titulo}</b></span>
                    </h6>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ padding: '0px 30px 0px 30px' }}>
                        <Table>
                            <thead>
                                <tr>
                                    <th style={{ width: "100px" }}>COD</th>
                                    <th>DESCRIPCION</th>
                                    <th style={{ width: "40px", textAlign: "right" }}>CANT</th>
                                    <th style={{ width: "80px", textAlign: "right" }}>PRE/UNT</th>
                                    <th style={{ width: "150px", textAlign: "right" }}>PRECIO</th>
                                </tr>
                            </thead>
                            <tbody>
                                {detalles.map((s) => {
                                    suma += s.precio * s.unidades
                                    return <tr>
                                        <td style={{ width: "100px" }}>{s.codigo}</td>
                                        <td>{s.descripcion}</td>
                                        <td style={{ width: "40px" }}>{numberFormat(s.unidades)}</td>
                                        <td style={{ width: "80px", textAlign: "right" }}>{numberFormat(s.precio)}</td>
                                        <td style={{ width: "150px", textAlign: "right" }}>{numberFormat(s.precio * s.unidades)}</td>
                                    </tr>
                                })}
                                <tr>
                                    <td colSpan={3}></td>
                                    <td style={{ width: "80px", textAlign: "right" }}><b>TOTAL:</b></td>
                                    <td style={{ width: "150px", textAlign: "right" }}>{numberFormat(suma)}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                </Modal.Body>
            </>
        }, [detalles,titulo])}
    </Modal>
}
export default function Detalles2Th({ con, id, label }: Props) {
    const [open, setOpen] = useState(false);
    return <th style={{ minWidth: "90px", width: "90px", maxWidth: "90px" }}>
        <ModalDetalle key={'ModalDetalle' + label + id}
            con={con} titulo={"DETALLES DE LA " + label + " N° " + zfill(id)}
            id={id} open={open} close={() => setOpen(false)}
        />
        <Button data-tip="VER DETALLES" onClick={() => setOpen(true)} variant="link" >
            {zfill(id)}
        </Button>
    </th>
}