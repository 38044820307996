import { getImagenURL } from '@/Utiles/Formatos';
import { EditArea } from '../../Component/SlateCustom'
import { SVGR, SVGL, d } from './SVG';
import './style.css';
interface Props {
    config: {
        img: string;
        color: string;
        ori: boolean;
        variant: 'diagonal' | 'arrow' | 'semi-circle' | 'diagonal-i' | 'arrow-i' | '';
        size: string
    }
}
export function Panel({ config: { img, color, ori, variant, size } }: Props) {
    return <div style={{ backgroundColor: color }} className="box">
        <div className={`paralax ${d.some(a => a === size) ? 'min' : 'max'}`}>
            <div style={{ width: size, float: ori ? 'left' : 'right', backgroundImage: `url(${getImagenURL(img)})` }} className="c-bg-parallax c-feature-bg c-content" />
            <div className="c-content-area c-content-ori"></div>
            <div style={{ float: ori ? 'right' : 'left' }} className="c-feature-content c-ori">
                <SVGL size={size} variant={variant} fill={color} ori={ori} />
                <div style={{ background: `${color}`, float: ori ? 'right' : 'left' }} className="c-content-v-center w-100 d-flex justify-content-center">
                    <div className="c-wrapper w-100 h-100  d-flex flex-row align-items-center">
                        <div className={`c-body w-100`}><EditArea /></div>
                    </div>
                </div>
                <SVGR size={size} variant={variant} fill={color} ori={ori} />
            </div>
        </div>
    </div >
}