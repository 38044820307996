import { Panel } from './Panel';
import { Config } from './Config';
import { icon, defaultIcon } from "@victorequena22/icon-selector-bootstrap";
import { GalIten, StateS, Iten, defaultIten } from '../../ProtoPanes/GalIten';
import { getText } from '../../ProtoPanes/DefaultData';
interface I { icon: icon; aux: string; fill: string; panel: number }
interface S {
    font: number
    color: string;
    border: boolean;
    back: 'none' | 'circle' | 'rounded' | 'rectangle';
    tap: string,
    tapTitle: string,
    orientation: 'row' | 'row-reverse' | 'column' | 'column-reverse',
    tapPosition: 'row' | 'row-reverse' | 'column' | 'column-reverse',
    variant: 'tab' | 'mat';
}
export type State = StateS<I> & S;
export type iten = Iten & I;
const aux: I = { icon: defaultIcon, aux: '#cccc00', fill: '#00bbbb', panel: 0 }
export const dIten: Iten & I = { ...aux, ...defaultIten }
export class Pestanas extends GalIten<S, I> {
    constructor(p: { data?: any }) {
        super(p);
        this.initGal({
            tap: '#669977', tapTitle: '#444444', tapPosition: 'column',
            color: '#f0f0f0', border: true, variant: 'tab',
            back: 'circle', orientation: 'row', font: 2
        }, [
            { ...dIten, id: 1 },
            { ...dIten, id: 2 },
            { ...dIten, id: 3 },
        ]);
    }
    Config = () => <Config
        save={this.save}
        key='config'
        iten={this.getIten(aux)}
        setIten={this.setIten}
        setConfig={this.setData}
        title={this.state.title}
        setTitle={this.setTitle}
        removeItem={this.removeItem}
        itenUp={this.itenUp}
        itenDown={this.itenDown}
        addIten={() => this.addIten(aux)}
        config={this.state} />
    Panel = () => <Panel key='panel'
        config={this.state}
        setConfig={this.setData}
        seled={this.seleted} />
    saveItens = () => this.state.itens.map(({ icon, aux, fill, color, id, html, back, panel }) => {
        const props = JSON.stringify({ icon, aux, fill, color, id, back, panel });
        return { data: '{}', props, html: JSON.stringify(getText(html)), id };
    })
    getDataSave = () => {
        const { title, color, back, orientation, border, font, tap, tapTitle, tapPosition, variant } = this.state;
        const props = JSON.stringify({ color, back, orientation, font, tap, tapTitle, tapPosition, variant, border });
        return { title, html: '[]', data: '{}', props, itens: this.saveItens(), type: 'pestanas' };
    }
}