import { css } from '@emotion/css';
import { useState, useContext } from 'react'
import PreviewSize from '../Ver/PreviewSize'
import { Button, Col, Row } from 'react-bootstrap';
import { Html } from '../Component/Commons';
import { Icon2 } from '../Component/Icons/IconSelector';
import { Link } from 'react-router-dom';
import { PanelContext } from '../Context/Panel';
export function Pestanas({ data: { props, itens, id }, size }: any) {
    const [select, setSelect] = useState(1);
    const { color, tap, tapTitle, tapPosition, font, variant } = props;
    const selectIten = itens.find((i: any) => i.indice === select) as any;
    const minHeight = (tapPosition === 'row' || tapPosition === 'row-reverse') ? itens.length * 60 : undefined;
    return <div className={`${getCSS(tap, tapTitle, font)} m-0 d-flex flex-${tapPosition}`}
        style={{ background: color, padding: '10px 0 10px 0' }}>
        <Row as='ul' className={`m-0 p-0 ${variant}`} style={{ minHeight }}>
            {itens.map((iten: any, i: number) => <Tap key={`itens${id}${i}`}
                selected={select} config={props} select={setSelect} iten={iten} />)}
        </Row>
        <Itens key={`iten${id}${selectIten.id}`} size={size} iten={selectIten} />
    </div>
}
function Itens({ iten, size }: any) {
    const { panels } = useContext(PanelContext);
    const p = panels.find(p => p.id === iten.props.panel)
    return (p === undefined) ? <></> : <div className='w-100 m-0 p-0'>
        <Row className='m-0 p-0' style={{ position: 'relative', top: 20, right: 20, left: 20, zIndex: 500 }}>
            <Button data-tip='EDITAR PANEL' as={Link} to={`/panel/edit/${p.id}`} variant="default">
                <i className='fas fa-edit' />
            </Button>
        </Row>
        <PreviewSize key={p.type + p.id} panel={p} size={size} />
    </div>
}
function Tap({ config, select, iten, selected }: any) {
    const { indice, html, props: { icon, fill, aux } } = iten;
    const { orientation, back, border, font } = config;
    const zIndex = selected === indice ? 20 : indice > selected ? 20 - (indice - selected) : 20 - (selected - indice);
    return <Col style={{ zIndex }} onClick={() => select(indice)} as='li'
        className={`p-1 d-flex flex-${orientation} ${selected === indice ? 'active' : ''}`}>
        <div className={"icon-content text-center"}>
            <Icon2 icon={icon} font={font} fill={fill} back={back}
                border={border} aux={aux} />
        </div>
        <Html style={{ minWidth: 'calc(100% - 3rem)' }} html={html}
            className='d-flex align-items-center h-100 p-1' />
    </Col>
}
function getCSS(back: string, color: string, font: number) {
    return css`
    &> ul> li{
        background: ${back};
        color: ${color};
        border: 1px solid  ${color};
    }
    &.flex-row,
    &.flex-row-reverse {
        &> ul{
            width: 20%;
            height: 100%;
            flex-direction: column !important;
            &> li{max-height: ${(font * 1.65) + 1}rem;}
            &>.flex-column-reverse,&>.flex-column{max-height: ${(font + 1.5) + 2}rem;}
        }
        &> div{width: 80%;}
        &> .tab> li{
            margin: -10px 0 -10px 0;
            padding: 10px 5px 10px 5px !important;
            &> .p-1{margin-top: 5px;}
            &:first-child{margin-top: 0;}
            &:last-child{margin-bottom: 0;}
        }
    }
    &.flex-column-reverse,
    &.flex-column{
        &> .tab> li{
            margin: 0 -10px 0 -10px;
            padding: 5px 30px 5px 30px !important;
            &:first-child{margin-left: 0;}
            &:last-child{margin-right: 0;}
        }
    }
    &.flex-column> .tab> li{border-radius: 25px 25px 0 0;}
    &.flex-column-reverse> .tab> li{border-radius: 0 0 25px 25px;}
    &.flex-row> .tab> li{border-radius: 25px 0 0 25px;}
    &.flex-row-reverse> .tab> li{border-radius: 0 25px 25px 0;}
    `;
}

