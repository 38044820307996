import { InputText } from "@victorequena22/component-bootstrap";
import { Button, Col, InputGroup, Row } from "react-bootstrap";

interface Props {
    max: number;
    row: number;
    pagina: number;
    children: any;
    buscar: string;
    setData: (d: any) => void;
}

export const Buscar = ({ buscar, max, row, pagina, setData, children }: Props) => {
    const pages = Math.ceil(row / 40);
    return <>
        <br /><Row>{children}</Row><hr />
        <Row className='d-flex pb-4'>
            <Col md={7} className='mr-auto'>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text><i className='fa fa-search'></i></InputGroup.Text>
                    </InputGroup.Prepend>
                    <InputText name='buscar' upper setData={buscar => setData({ buscar, pagina: 0 })} value={buscar} />
                </InputGroup>
            </Col>
            <Col lg={{ span: 2, offset: 1 }}  >
                {row > 60 ? <InputGroup>
                    <InputGroup.Prepend>
                        <Button onClick={() => setData({ pagina: pagina > 0 ? pagina - 1 : pagina })}>
                            <i className='fa fa-backward' />
                        </Button>
                    </InputGroup.Prepend>
                    <InputGroup.Text style={{ fontSize: 12 }}><b>{pagina + 1} de {pages}</b></InputGroup.Text>
                    <InputGroup.Append>
                        <Button onClick={() => setData({ pagina: pagina < (pages - 1) ? pagina + 1 : pagina })}>
                            <i className='fa fa-forward' />
                        </Button>
                    </InputGroup.Append>
                </InputGroup> : <></>}
            </Col>
            <Col lg={{ span: 1, offset: 1 }} className='text-right'>{row} de {max}</Col>
        </Row>
    </>
}