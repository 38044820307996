import React from "react"
import Chart from "react-google-charts"
type val = string | number
export interface PieProps {
    title: string
    values: val[][]
}
export default function Pie({ title, values }: PieProps) {
    return <Chart
        width='18rem'
        height='18rem'
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={[['ESTADOS', 'CANT'], ...values]}
        options={{ title, legend: 'none', slices: [{ color: '#dc3545' }, { color: '#ffc107' }, { color: '#28a745' }] }}
        rootProps={{ 'data-testid': '3' }}
    />

}