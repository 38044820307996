import { useState } from 'react';
import { getCSS } from './props';
import { Row } from 'react-bootstrap';
import { State } from './index'
import { Select, NoSelect } from './Itens'

export function Panel({ config, seled }: { config: State, seled: (i: number) => void }) {
    const { itens, seleted, animated } = config;
    const prev = seleted === 1 ? itens.length - 1 : seleted - 2;
    const sele = seleted - 1;
    const next = seleted === itens.length ? 0 : seleted;
    const [trans, setTrans] = useState('');
    const setPrev = () => {
        setTrans(' prev');
        setTimeout(() => { setTrans(''); seled(prev + 1) }, 250)
    };
    const setNext = () => {
        setTrans(' next');
        setTimeout(() => { setTrans(''); seled(next + 1) }, 250)
    };
    return <Row style={{ margin: '0 0 0 0', padding: '0 0 0 0' }}>
        <div style={{ aspectRatio: '16/7', width: '100%' }} className={"carousel slide " + getCSS(animated)}>
            <ol className="carousel-indicators">
                {itens.map((i) => <li className={seleted === i.id ? 'active' : ''}></li>)}
            </ol>
            <div className={"carousel-inner" + trans}>
                <NoSelect position={1} key={`iten${itens[prev].img}${prev}${itens[prev].id}`} iten={itens[prev]} />
                <Select position={2} key={`iten${itens[sele].img}${sele}${itens[sele].id}`} iten={itens[sele]} />
                <NoSelect position={3} key={`iten${itens[next].img}${next}${itens[next].id}`} iten={itens[next]} />
            </div>
            <div style={{ zIndex: 5 }} className="carousel-control-prev" role="button" onClick={setPrev}>
                <span aria-hidden="true" className="carousel-control-prev-icon"></span>
                <span className="sr-only">Previous</span>
            </div>
            <div style={{ zIndex: 5 }} className="carousel-control-next" role="button" onClick={setNext}>
                <span aria-hidden="true" className="carousel-control-next-icon"></span>
                <span className="sr-only">Next</span>
            </div>
        </div>
    </Row >
}
