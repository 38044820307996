import { Paralax1 } from '../EditPanel/Paralax1';
import { Paralax2 } from '../EditPanel/Paralax2';
import { Video } from '../EditPanel/Video';
import { Galeria } from '../EditPanel/Galeria';
import { Carrusel } from '../EditPanel/Carrusel';
import { Cargos } from '../EditPanel/Cargos';
import { Servicio } from '../EditPanel/Servicio';
import { Servicio2 } from '../EditPanel/Servicio2';
import { Preguntas } from '../EditPanel/Preguntas';
import { Title } from '../EditPanel/Title';
import { Imagen } from '../EditPanel/Imagen';
import { Pestanas } from '../EditPanel/Pestanas';
import { Mapa } from '../EditPanel/Mapa';
import { Contact } from '../EditPanel/Contact';
import { Footer } from '../Template/Footer';
import { Suscribir } from '../EditPanel/Suscribir';
import { Menu } from '../Template/Menu';
import { Chats } from '../Template/Chats';
import { Route } from 'react-router-dom';
import { ImagenSola } from '../EditPanel/ImagenSola';
import { Testimonio } from '../EditPanel/Testimonio';
import { BannerImagen } from '../EditPanel/BannerImagen';


export default function Crear({ props }: { props?: any }) {
    return <>
        <Route path="/panel/suscribir" exact><Suscribir props={props} /></Route>
        <Route path="/panel/titulo" exact><Title props={props} /></Route>
        <Route path="/panel/paralax1" exact><Paralax1 props={props} /></Route>
        <Route path="/panel/paralax2" exact><Paralax2 props={props} /></Route>
        <Route path="/panel/video" exact ><Video props={props} /></Route>
        <Route path="/panel/imagen" exact><Imagen props={props} /></Route>
        <Route path="/panel/imagenSola" exact><ImagenSola props={props} /></Route>
        <Route path="/panel/galeria" exact><Galeria props={props} /></Route>
        <Route path="/panel/carrusel" exact><Carrusel props={props} /></Route>
        <Route path="/panel/cargos" exact><Cargos props={props} /></Route>
        <Route path="/panel/servicio" exact><Servicio props={props} /></Route>
        <Route path="/panel/servicio2" exact><Servicio2 props={props} /></Route>
        <Route path="/panel/preguntas" exact><Preguntas props={props} /></Route>
        <Route path="/panel/pestanas" exact><Pestanas props={props} /></Route>
        <Route path="/panel/mapa" exact><Mapa props={props} /></Route>
        <Route path="/panel/pie" exact><Footer props={props} /></Route>
        <Route path="/panel/menu" exact><Menu props={props} /></Route>
        <Route path="/panel/chats" exact><Chats props={props} /></Route>
        <Route path="/panel/contacto" exact><Contact props={props} /></Route>
        <Route path="/panel/testimonio" exact><Testimonio props={props} /></Route>
        <Route path="/panel/BannerImagen" exact><BannerImagen props={props} /></Route>
    </>
}