/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useContext, useCallback } from 'react'
import { AuthSome } from '../../Config/Permisos';
import { conNota } from '../../Models/Ventas'
import { Estado, EstadoNull, GeneralFila, updateOrPush } from '../../Models/General';
import { formatoHora } from '../../Utiles/Formatos';
import { CotizacionContext, CotizacionType } from './Cotizacion';
import { sleep, useSemaforo } from '../../Utiles/ReactUtils';
export interface NotasType extends CotizacionType {
    estNota: Estado
    Notas: GeneralFila[]
    actNota: () => void
}
export const NotasContext = createContext<NotasType | null>(null)
export default function NotasProvider({ children }: { children: any }) {
    const [Notas, setNotas] = useState<GeneralFila[]>([]);
    const cotizacion = useContext(CotizacionContext) as CotizacionType;
    const [est, setest] = useState(EstadoNull);
    const [con] = useState(conNota());
    const update = useCallback(async () => {
        if (AuthSome(['FACTURA', 'NOTA'])) con.cambios((data: any[]) => {
            setest({ status: 3, hora: formatoHora(con.state) })
            setNotas(updateOrPush(data, Notas))
        })
        await sleep(50);
    }, [Notas]);
    useSemaforo('Notas', async () => {
        await update()
    })
    return <NotasContext.Provider value={{
        ...cotizacion,
        estNota: est, Notas,
        actNota: update
    }}>
        {children}
    </NotasContext.Provider>
}
export const SeccionConsumer = NotasContext.Consumer;