import { useContext } from 'react';
import { css } from '@emotion/css';
import { Card, Row, Button } from "react-bootstrap";
import { RowConfig } from '../../../Panel/Component';
import { Play } from './Play';
import { Grabber } from './Grabber';
import { VideoContext } from '../Context/Video';
import { conMedia } from '../../Models';
export function Config({ close, id, save }: { close: () => void, save: (d: any) => void, id: any }) {
    const { start, end } = useContext(VideoContext);
    const post = async () => {
        try {
            conMedia().post(`/api/Media/clip/${id}`, { start, end },
                d => { save(`/api/Media/short/${d}`); close(); })
        } catch (e) {
        }
    }
    return <Card className='mr-1' style={{ width: 151, backgroundColor: '#ccc', padding: '5px 5px 5px 5px' }}>
        <Row className='d-flex flex-column' style={{ margin: '0 0 0 0', padding: '0 0 0 0' }}>
            <RowConfig >
                <Guardar guardar={post} />
                <Close close={close} />
            </RowConfig>
            <Play />
            <Grabber />
        </Row>
    </Card>
}
function Guardar({ guardar }: { guardar: () => void }) {
    const CN = css`
    color: #28a745;width: 67px;padding: .3rem .3rem .3rem .3rem;border-color: #28a745;
    &:hover{color: white;background-color: #28a745;}`;
    return <Button variant='default' onClick={guardar} className={CN} >
        <i className="fa fa-save fa-3x"></i>
    </Button>
}
function Close({ close }: { close: () => void }) {
    const CN = css`
    color: #dc3545;width: 67px;padding: .3rem .3rem .3rem .3rem;border-color: #dc3545;margin: 0 0 0 5px;
    &:hover{color: white;background-color: #dc3545;}`;
    return <Button variant='default' onClick={close} className={CN} >
        <i className="fas fa-window-close fa-3x"></i>
    </Button>
}