import React from "react";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Button } from "react-bootstrap";

export default class AjustesExcel extends React.Component<{ ajustes: any[] }, {}>{
    _export = React.createRef<any>();
    excelExport() {
        if (this._export.current)
            this._export.current.save();
    };
    render() {
        const { ajustes } = this.props;
        if (ajustes.length === 0) return <></>
        return <>
        <Button variant='success' onClick={this.excelExport.bind(this)} style={{marginRight:'15px'}}>
        <i className="fa fa-file-excel"/> 
        </Button>
        <ExcelExport data={ajustes} ref={this._export} fileName="Ajustes">
        <ExcelExportColumn field="codigo" title="N°" width={120} />
        <ExcelExportColumn field="fecha" title="FECHA" width={120} />
        <ExcelExportColumn field="usuarios" title="USUARIO" width={120} />
        <ExcelExportColumn field="tipo" title="TIPO" width={120} />

        </ExcelExport></>
    }

}