import { useState, createContext, useEffect, useCallback } from 'react';
import { conShort, Shor } from '../Models';
const compare = (a: any, b: any) => {
    return JSON.stringify(a) !== JSON.stringify(b)
}
export interface ShortType {
    shors: Shor[];
    updateShort: () => void;
}
export const ShortContext = createContext<ShortType>({ shors: [], updateShort: () => { } })
export default function ShortProvider({ children, lista = [] }: { children: any, lista?: Shor[] }) {
    const [shors, setShorts] = useState<Shor[]>([]);
    const updateShort = useCallback(() => conShort().lista(d => { if (compare(d, shors)) setShorts(d); }), [shors]);
    const update = useCallback(() => updateShort(), [updateShort])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { update() }, [])
    return <ShortContext.Provider value={{
        shors: [...shors, ...lista],
        updateShort
    }}>{children}</ShortContext.Provider>
}
export const SeccionConsumer = ShortContext.Consumer;