import { Button, Modal, Row, Col, InputGroup, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import { Cliente, ClienteNull, conCliente } from '../../../Models/Ventas';
import { ControlArea, ControlText } from '../../../Utiles/FormControl';
import { InputNumber } from '../../../Utiles/Inputs';
import { toast } from 'react-toastify';
import React from 'react';
interface Props {
    open: boolean
    cliente: Cliente | null
    title: string
    close: () => void
    add?: (c: Cliente) => void
}
interface State extends Cliente {
    activeError: boolean;
}
export default class FormCliente extends React.Component<Props, State> {
    error: any = [];
    con = conCliente();
    constructor(p: Props) {
        super(p)
        this.setState = this.setState.bind(this);
        let state = p.cliente;
        if (state === null) state = ClienteNull;
        this.state = { ...state, activeError: false };
    }
    render() {
        let { setData, state: s } = this
        return <Modal animation show={this.props.open} size='lg' centered onHide={this.props.close}>
            <Modal.Header className='bg-primary' closeButton>
                <h4 className='mr-auto'>{this.props.title}</h4>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col as={FormGroup} xs={12}>
                        <ControlText upper mask='A-99999999-9' maxLength={20}
                            before="RIF" value={s.codigo} setData={setData} update="codigo" disabled={(this.props.cliente !== null)} error={this.error} />
                    </Col>
                    <Col as={FormGroup} hasValidation={this.error['nivel'] ? true : false} xs={12}>
                        <ControlText error={this.error} upper before="RAZON SOCIAL" maxLength={150}
                            value={s.nombre} setData={setData} update="nombre" />
                    </Col>
                    <Col as={FormGroup} hasValidation={this.error['nivel'] ? true : false} xs={12}>
                        <FormLabel>DIRECCION</FormLabel>
                        <ControlArea error={this.error} value={s.direccion}
                            className='mt-1' placeholder='DIRECCION' upper
                            setData={setData} update="direccion" maxLength={300} />
                    </Col>
                    <Col as={FormGroup} xs={12}>
                        <ControlText upper maxLength={120}
                            before="TELÉFONO" value={s.telefono} setData={setData} update="telefono" error={this.error} />
                    </Col>
                    <Col as={FormGroup} xs={12}>
                        <ControlText upper before="PERSONA DE CONTACTO"
                            maxLength={50} value={s.contacto} setData={setData} update="contacto" error={this.error} />
                    </Col>
                    <Col as={FormGroup} hasValidation={this.error['nivel'] ? true : false} xs={12}>
                        <ControlText error={this.error} upper before="CORREO" maxLength={150}
                            value={s.correo} setData={setData} update="correo" />
                    </Col>
                    <Col as={FormGroup} xs={12}>
                        <InputGroup style={{ width: '100%' }} className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>TIPO DE CONTRIBUYENTE</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl as='select' style={{ width: '120px' }} onChange={this.setContribuyente.bind(this)} value={s.tipo_contribuyente}>
                                <option value="ORDINARIO">ORDINARIO</option>
                                <option value="ESPECIAL">ESPECIAL</option>
                            </FormControl>
                            <InputNumber value={s.retencion} setData={setData} update="retencion" step={0.01}
                                disabled={(s.tipo_contribuyente !== 'ESPECIAL')} />
                        </InputGroup>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button className="float-right" onClick={this.get.bind(this)} variant="primary">
                    <span className="fa fa-floppy-disk" /> GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    }
    isInvalid(s: string) {
        if (s === "") return true
        return false
    }
    get() {
        let error: Partial<State> = {};
        let { state: s } = this
        let procesar = true;
        if (s.codigo === "") error.codigo = 'DEBE COLOCAR EL DOCUMENTO LEGAL DEL CLIENTE';
        if (s.nombre === "") error.nombre = 'DEBE COLOCAR LA RAZON SOCIAL';
        if (s.telefono === "") error.telefono = 'DEBE COLOCAR NUMERO DE TELEFONO';
        if (s.direccion === "") error.direccion = 'DEBE COLOCAR UNA DIRECCION';
        if (s.contacto === "") error.contacto = 'DEBE COLOCAR LA PERSONA DE CONTACTO';
        if (Object.keys(error).length > 0) { this.setError([error]); procesar = false; }
        if (procesar) {
            if (this.props.cliente === null) this.con.nuevo(s, this.action, this.setError)
            else this.con.actualizar(this.props.cliente.codigo, s, this.action, this.setError)
        }
    }
    setError = (err: any) => {
        err.forEach((err: any) => {
            if (err.sql) {
                err.sql.forEach((er: string) => toast(er, { type: toast.TYPE.ERROR }));
            } else {
                Object.keys(err).forEach(t => this.error[t] = err[t])
            }
        })
        this.forceUpdate();
    }
    action = (data: any) => {
        if (this.props.add) this.props.add(data);
        if (this.props.cliente === null) this.setState(ClienteNull);
        this.props.close();
    }
    setContribuyente(Rol: React.ChangeEvent<HTMLSelectElement>) {
        let value = Rol.currentTarget.value as string;
        this.setState({ tipo_contribuyente: value });
    }
    setData = (data: any) => {
        Object.keys(data).forEach(t => this.error[t] = '');
        this.setState(data);
    }
}