import React from "react";
import { Button } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
interface Props {
    nombre: string
    tip: string
}
export default function NombreTh({ tip, nombre }: Props) {
    return <th>
        <ReactTooltip />
        <Button style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: '30vw' }}
            data-class="toolbody" data-html="true" data-tip={tip} variant="link" >
            {nombre}
        </Button>
    </th>
}