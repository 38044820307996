import Ajuste from '@/Models/Ajuste';
import { Detalles } from '@/Models/General';
import AuxiliarNuevo from '@/Utiles/Prototipos/AuxiliarNuevo';
import React from 'react'
import { Col } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { ProductosModal } from '@/Utiles/Prototipos/AuxiliarNuevo/Interface';
import FilaEntrada from './FilaEntrada';
import FilaSalida from './FilaSalida';
import FilaPrecio from './FilaPrecio';
import FilaUtilidad from './FilaUtilidad';
import BuscarProductoBoton from '../../Productos/Botones/Buscar';
import { Dolar } from '@/Utiles/Prototipos/AuxiliarNuevo/Botones';
import CategoriaBuscarBoton from '../../Departamento/Buscar';
import { THead } from './TH';

export default class AuxiliarAjuste extends AuxiliarNuevo {
    Row = ({ data }: { data: Detalles }) => {
        const { tipo, dolar } = this.props.data as Ajuste;
        switch (tipo) {
            case 'ENTRADA':
                return <FilaEntrada key={'USD' + data.codigo} producto={data} edit={this.addProductos.bind(this)} remove={this.removeProductos.bind(this)} />;
            case 'SALIDA':
                return <FilaSalida key={'USD' + data.codigo} producto={data} edit={this.addProductos.bind(this)} remove={this.removeProductos.bind(this)} />;
            case 'COSTO':
                return <FilaPrecio dolar={dolar} key={'USD' + data.codigo} producto={data} edit={this.addProductos.bind(this)} remove={this.removeProductos.bind(this)} />;
            case 'UTILIDAD':
                return <FilaUtilidad dolar={dolar} key={'USD' + data.codigo} producto={data} edit={this.addProductos.bind(this)} remove={this.removeProductos.bind(this)} />;
        }
        return <></>
    }
    BotonesLeft = () => {
        const { tipo } = this.props.data as Ajuste;
        if (tipo !== '') return <Col md={6}><b>TIPO DE AJUSTE: </b> {tipo}</Col>
        return <Col md={6}>
            <select data-tip="FILTRAR POR EL ESTADO" onChange={this.setStatus} className="form-control" value={tipo}>
                <option>SELECCIONE TIPO DE AJUSTE</option>
                <option>ENTRADA</option>
                <option>SALIDA</option>
                <option>COSTO</option>
                <option>UTILIDAD</option>
            </select>
        </Col>
    }
    BotonesRight = () => {
        const { tipo, dolar } = this.props.data as Ajuste;
        const { BTNProcesar, setData } = this;
        if (tipo === '') return <></>;
        return <Col xs={6} style={{ textAlign: 'right' }} className="float-right">
            <ReactTooltip />
            <BTNProcesar />
            {(tipo === 'COSTO' || tipo === 'UTILIDAD') ? <>
                <Dolar dolar={!!dolar} setData={dolar => setData({ dolar })} />
                <CategoriaBuscarBoton type={tipo} add={this.addGrup} />
            </> : <></>
            }
            <BuscarProductoBoton type={tipo} add={this.addProductos} />
        </Col >
    }
    THead = () => <THead tipo={this.getTipo()} />
    getTipo = () => {
        const { tipo } = this.props.data as Ajuste;
        return tipo;
    }
    setStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.setData({ tipo: e.currentTarget.value })
    }
    Calcular = () => <></>
    addGrup = (pr: Detalles[]) => {
        console.log(pr)
        let { props: { data: { detalles } } } = this;
        let data = detalles.filter(p => pr.some(d => p.codigo !== d.codigo));
        console.log(data)
        this.setDetalles([...data, ...pr])
    }
    componentWillReceiveProps = (p: any) => {
        const { tipo } = p.data as Ajuste;
        if (this.productos !== tipo) this.productos = tipo as ProductosModal;
    }

}