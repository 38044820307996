import { Button, Row } from 'react-bootstrap';
import { GestionPanel } from '../../Panel/Utiles';
import SelectPage from './SelectPage';
export function Opciones({ panel }: { left?: any, top?: any, panel: any }) {
    const { top, left } = panel;
    return <Row className='m-0 p-0' style={{ position: 'absolute', top: top ? top : undefined, right: 20, left: left ? left : 20, zIndex: 500 }}>
        <GestionPanel panel={panel} >
            <SelectPage panel={panel} />
        </GestionPanel>
    </Row>
}
export function Slect({ panel, select }: { panel: any, select: (d: any) => void }) {
    return <Row className='w-100' style={{ marginBottom: -30, zIndex: 500 }}>
        <Button variant="success" onClick={() => select(panel)}>
            <i className='fas fa-edit' />
        </Button>
    </Row>
}