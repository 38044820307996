import React, { useState } from 'react';
import { Button, Table, Col, Card } from 'react-bootstrap';
import { InputText } from '../../../Utiles/Inputs';
import { icon, IconSelector, defaultIcon, UseIcon } from "@victorequena22/icon-selector-bootstrap";
import { conConfig } from '@victorequena22/conneccion';
interface Social {
    id: number;
    icon: icon,
    url: string
    nombre: string
}
interface State {
    socials: Social[]
}
export default class SocialClass extends React.Component<any, State> {
    con = conConfig('social');
    state = { socials: [] as Social[] };
    render() {
        let { socials } = this.state;
        return (<Card>
            <Card.Header className='bg-info'>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> CONFIGURACION DE REDES SOCIALES </h4>
            </Card.Header>
            <Card.Body>
                <SocialList socials={socials} setData={socials => this.setState({ socials })} />
                <Col md={12}><br />
                    <Button className="float-right" onClick={this.guardar.bind(this)} variant="primary">
                        <span className="fas fa-save" /> Guardar
                    </Button>
                </Col>
            </Card.Body>
        </Card>)
    }
    change(i: number, s: Social) {
        this.setState({ socials: this.state.socials.map((o, j) => j !== i ? o : s) });
    }
    componentDidMount() {
        this.con.pull(this.setState.bind(this));
    }
    guardar() {
        this.con.push(this.state, this.setState.bind(this));
    }
}


function SocialCreator({ add, next }: { add: (d: Social) => void, next: number }) {
    const [icon, setIcon] = useState(defaultIcon);
    const [url, setUrl] = useState('');
    const [nombre, setNombre] = useState('');
    return <tr>
        <td>
            <IconSelector style={{}} value={icon} onChange={setIcon} />
        </td>
        <td>
            <InputText value={nombre} setData={setNombre} />
        </td>
        <td>
            <InputText value={url} setData={setUrl} />
        </td>
        <td>
            <Button variant='success' onClick={() => {
                add({ id: next, icon, url, nombre });
                setIcon(defaultIcon);
                setUrl('');
                setNombre('');
            }}>
                <i className="fas fa-plus"></i>
            </Button>
        </td>
    </tr>
}

export function SocialList({ socials, setData }: { socials: Social[], setData: (d: Social[]) => void }) {
    const mx = Math.max(...socials.map(s => s.id), 0);
    const change = (i: number, s: Social) => {
        setData(socials.map((o, j) => j !== i ? o : s));
    }
    return <Table striped hover>
        <thead>
            <tr>
                <th style={{ width: '32px' }}>ICONO</th>
                <th style={{ width: '25%' }}>NOMBRE</th>
                <th>URL</th>
                <th style={{ width: '32px' }}></th>
            </tr>
        </thead>
        <tbody>
            <SocialCreator next={mx + 1} add={s => setData([...socials, s])} />
            {socials.map((s, i) => <tr key={i}>
                <td>
                    <UseIcon width='2rem' height='2rem' icon={s.icon} fill='black' />
                </td>
                <td>
                    <InputText value={s.nombre} setData={(nombre) => change(i, { ...s, nombre })} />
                </td>
                <td>
                    <InputText value={s.url} setData={(url) => change(i, { ...s, url })} />
                </td>
                <td>
                    <Button variant='danger' onClick={() => setData(socials.filter((s, j) => j !== i))}>
                        <i className="fa fa-trash"></i>
                    </Button>
                </td>
            </tr>)}
        </tbody>
    </Table>
}