import { useContext, useEffect, useState } from 'react';
import { SeccionContext, SeccionType } from '../Context/Seccion';
import Tasa from './Tasa';
import User from './User';

const admin = ['COMPRA', 'ORDEN', 'PROVEEDOR', 'FACTURA', 'NOTA', 'COTIZACION', 'CLIENTE', 'AJUSTE']
export default function Header() {
    const { data: { Permisos } } = useContext(SeccionContext) as SeccionType;
    const [menu, setMeu] = useState(localStorage.getItem('menu'));
    const set = (d: string) => {
        setMeu(d);
        localStorage.setItem('menu', d)
    }
    if (menu === null) set('open');
    const open = () => {
        if (menu === 'open') set('cerrado');
        else set('open');
    }
    useEffect(() => {
        let body = document.getElementsByTagName('body')[0];
        if (body) {
            if (menu === 'cerrado') body.classList.add('sidebar-collapse')
            else body.classList.remove('sidebar-collapse')
        }
    }, [menu]);
    return <nav className="main-header navbar navbar-expand navbar-success navbar-light">
        <ul className="navbar-nav">
            <li className="nav-item">
                <div className="nav-link" onClick={open}><i className="fa fa-bars"></i></div>
            </li>
        </ul>
        <ul className="navbar-nav ml-auto">
            {(!Permisos.some(p => admin.includes(p))) ? <></> : <Tasa />}
            <User />
        </ul>
    </nav >
}