
import React from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import { Detalles } from '../../../../Models/General'
import { InputNumber } from '../../../../Utiles/Inputs'
import { ControlText } from '../../../../Utiles/FormControl'
import THFila, { THInput } from '../../../../Utiles/Componentes/THFila'

interface Props {
    producto: Detalles,
    edit: (iten: Detalles) => void,
    remove: (iten: Detalles) => void
}
interface State extends Detalles {
    open: boolean,
}
export default class FilaEntrada extends React.Component<Props, State>{
    constructor(p: Props) {
        super(p)
        this.state = { ...p.producto, open: false }
        this.setState = this.setState.bind(this)
    }
    render() {
        let { unidades, descripcion, codigo, open, comentario } = this.state
        return <>
            <tr>
                <THFila width='90px'>{codigo}</THFila>
                <THFila width='auto'>{descripcion}</THFila>
                <THInput width='120px'><InputNumber min={.01} step='0.01' setData={this.setState} value={unidades} update="unidades" /></THInput>
                <THInput width='110px'>
                    <ButtonGroup>
                        <Button variant="info" onClick={() => { this.setState({ open: !this.state.open }) }} data-tip="DEJAR NOTA">
                            <span className="fa fa-edit"></span>
                        </Button>
                        <Button variant="danger" onClick={() => { this.props.remove(this.state) }} data-tip="QUITAR PRODUCTO">
                            <i className="fa fa-ban"></i>
                        </Button>
                    </ButtonGroup>
                </THInput>
            </tr>
            <tr style={{ display: open ? '' : 'none' }}>
                <td colSpan={7}>
                    <ControlText maxLength={250} before="NOTA INTERNA" setData={this.setState} value={comentario} update="comentario" />
                </td>
            </tr>
        </>
    }
    componentWillUpdate(p: Props, s: State) {
        if (s.unidades !== this.state.unidades || s.comentario !== this.state.comentario)
            this.props.edit(s)
    }

}