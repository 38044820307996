import { Panel } from './Panel';
import { Config } from './Config';
import { Single, StateS } from '../../ProtoPanes/Single';
import { getText } from '../../ProtoPanes/DefaultData';
export interface S {
    color: string;
}
export type State = StateS & S;
export class Title extends Single<S> {
    constructor(p: any) {
        super(p);
        this.init({ color: '#ffffffaa' });
    }
    Config = () => <Config save={this.save} setConfig={this.setData} title={this.state.title} setTitle={this.setTitle} config={this.state} />
    Panel = () => <Panel config={this.state} />
    getDataSave = () => {
        const { title, html, color } = this.state;
        const data = JSON.stringify({});
        const props = JSON.stringify({ color });
        return { title, html: getText(html), data, props, type: 'titulo' };
    }
}
