import { conConfig } from '@victorequena22/conneccion';
import { useState, createContext, useEffect, useContext } from 'react';
export interface ColorType {
    colores: string[]
}
export const ColorContext = createContext<ColorType>({ colores: [] })
export default function ColorProvider({ children }: { children: any }) {
    const [colores, setColores] = useState<string[]>([]);
    useEffect(() => { conConfig('colores').pull(({ colores }) => setColores(colores)) }, []);
    return <ColorContext.Provider value={{ colores }}>{children}</ColorContext.Provider>
}
export const SeccionConsumer = ColorContext.Consumer;
export function getColors() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { colores } = useContext(ColorContext);
    return colores;
}