import FilaProducto from './Productos'
import AuxiliarNuevo from '../Prototipos/AuxiliarNuevo';
import { Detalles } from '@/Models/General';
import { Producto } from '@/Models/Productos';
export default class AuxiliarVentas<p = {}> extends AuxiliarNuevo<p> {
    venta = true;
    Row = ({ data, add, remove }: { data: Detalles, add: (pr: Detalles) => void, remove: (pr: Detalles) => void }) => {
        const { props: { data: { dolar } }, state: { nota } } = this;
        return <FilaProducto
            key={data.codigo + 'venta' + this.productos}
            data={data} edit={add} remove={remove}
            dolar={dolar} venta={this.venta} nota={nota}
        />;
    }
    nuevoProductos = (p: Producto) => {
        this.add({ ...p, index: this.nextIndex(), unidades: 1, precio: p.costo * (1 + (p.precio2 / 100)), comentario: "" })
    }
}