import { useState } from "react";
import { Button, ButtonGroup, Col, Modal, Row } from "react-bootstrap";
import ReactTooltip from "react-tooltip"
import { toast } from "react-toastify";
import { THFila } from "@victorequena22/tabla-bootstrap";
import { conContac, Contact } from "../../Models";
import { ModalAccion } from "@victorequena22/component-bootstrap";

export default function Fila({ data, add }: { data: Contact, add: () => void }) {
    return <tr>
        <THFila width='50%'>{data.nombre}</THFila>
        <THFila width='calc(25% - 75px)'>{data.correo}</THFila>
        <THFila width='calc(25% - 75px)'>{data.telefono}</THFila>
        <td style={{ textAlign: 'right', width: '150px' }}>
            <ReactTooltip />
            <ButtonGroup>
                <BTNDetallles data={data} />
                <BTNCancelar data={data} add={add} />
            </ButtonGroup>
        </td>
    </tr>
}

function BTNCancelar({ add, data: { id, nombre } }: { data: Contact, add: () => void }) {
    const con = conContac();
    const [open, setOpen] = useState(false);
    const cancelar = () => con.cancelar(id, () => {
        add();
        toast(`EL MENSAJE DE ${nombre} FUE ELIMINADO`, { type: toast.TYPE.SUCCESS });
    });
    return <>
        <ModalAccion open={open} accion={cancelar} close={() => setOpen(false)}
            title={`ELIMINAR EL MENSAJE DE ${nombre}`} key={`ModalAlertMSN${id}`}
            msn={`ESTA SEGURO QUE ELIMINARA EL MENSAJE DE ${nombre}?`} />
        <Button data-tip="ELIMINAR" onClick={() => setOpen(true)} variant="danger" >
            <i className="fa fa fa-ban"></i>
        </Button>
    </>
}

function BTNDetallles({ data: { nombre, asunto, correo, mensaje, telefono } }: { data: Contact }) {
    const [open, setOpen] = useState(false);
    return <>
        <Button data-tip="VER DETALLES" onClick={() => setOpen(true)} variant="success" >
            <i className="fa fa-eye"></i>
        </Button>
        <Modal size="xl" show={open} onHide={() => setOpen(false)} centered className=' modal-warning' >
            <Modal.Header style={{ borderRadius: 0 }} closeButton>
                <h4 className="m-0 font-weight-bold mr-auto align-self-center">
                    <span><b></b></span>
                </h4>
            </Modal.Header>
            <Modal.Body >
                <Row><Col>NOMBRE: {nombre}</Col><Col>CORREO: {correo}</Col><Col>TELF:{telefono}</Col></Row>
                <hr />
                <Row><Col>ASUNTO: {asunto}</Col></Row>
                <hr />
                <Row><Col>MENSAJE: <br />{mensaje}</Col></Row>
            </Modal.Body>
            <Modal.Footer className="float-right">
                <Button onClick={() => setOpen(false)} variant="danger">
                    <span className="fa fa-remove-circle"></span> Cancelar
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}