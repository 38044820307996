import { useState } from "react";
import { Button } from "react-bootstrap";
import ModalDetalle from "../../Componentes/ModalDetalle";
import { zfill } from "../../Formatos";
import { ConexionCompleta } from "@victorequena22/conneccion";
interface Props {
    con: ConexionCompleta
    id: number
    label: string
}
export default function DetallesTh({ con, id, label }: Props) {
    const [open, setOpen] = useState(false);
    return <th style={{ minWidth: "90px", width: "90px", maxWidth: "90px" }}>
        <ModalDetalle key={'ModalDetalle' + label + id}
            con={con} titulo={"DETALLES DE LA " + label + " N° " + zfill(id)}
            id={id} open={open} close={() => setOpen(false)}
        />
        <Button data-tip="VER DETALLES" onClick={() => setOpen(true)} variant="link" >
            {zfill(id)}
        </Button>
    </th>
}