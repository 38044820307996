import { Component } from "react";
import { Config, Zoom } from '../Config'
import { EasyCrop } from './Crop'
import { css } from '@emotion/css';
import getImageManager, { ImageManager, Crop as CropData, DataImage, DataNull } from './ImageManager';
import { GPUTrans } from '../GPU/Manager'
import { Cargando } from "@victorequena22/component-bootstrap";
import { conMedia } from "../../Models";
import { toast } from "react-toastify";
interface Props {
    img: string
    setImg: (img: string) => void
    close: () => void
    aspect: number
}
export interface State extends DataImage {
    preview: string,
    edit: 'crop' | 'filter' | 'color' | 'pre-crop' | 'none' | 'upload' | 'setting' | 'processed',
    rotate: number
    zoom: number
    cut: boolean;
    cropData: CropData
    block: boolean
    aspect: number
    position: number
    cargando: boolean
    trans: GPUTrans[]
    calc: boolean
}
export class Main extends Component<Props, State> {
    img: ImageManager;
    origin: { hsl: number[][][] } = { hsl: [] };
    constructor(p: Props) {
        super(p);
        this.img = getImageManager();
        this.state = {
            edit: 'crop', cut: false, preview: p.img, block: true, position: 0,
            rotate: 0, zoom: 1, ...DataNull, aspect: p.aspect, cargando: false,
            cropData: { x: 0, y: 0, width: 0, height: 0, }, trans: [], calc: true
        };
        this.draw();
    }
    render() {
        const { transform, crop, save, aplyTrans, defaultData, props: { close } } = this;
        const { aspect, edit, zoom, hsl, cargando } = this.state;
        const as = hsl.length / hsl[0].length;
        const hidden: React.CSSProperties = (cargando || edit === 'crop') ? { height: '0px', overflow: 'hidden' } : { height: '100%' };
        const car = cargando ? { opacity: 1 } : {};
        return <div className='d-flex' style={{ width: '100vw', }} >
            <Config default={defaultData} close={close} trans={aplyTrans} draw={transform} crop={crop} save={save} config={this.state} setConfig={this.setState.bind(this)} />
            <div className="flex-grow-1" style={{ width: 'calc(100vh - 270px)', height: 'calc(100vh - 40px)', border: 'orange 5px solid' }}>
                {cargando ?
                    <div style={{ zIndex: 999999999, maxWidth: '100%', margin: '0 auto 0 auto', padding: '25% 25% 25% 25%' }}>
                        <Cargando />
                    </div> : <></>}
                {edit === 'crop' ? <div className={cssCrop} style={{ ...car, aspectRatio: (aspect) + '/1' }}>
                    <EasyCrop config={this.state} aspect={this.props.aspect} setConfig={this.setState.bind(this)} />
                </div> : <></>}
                <div className='d-flex' style={{ ...hidden, aspectRatio: (aspect) + '/1', maxWidth: '100%', margin: '0 auto 0 auto' }}>
                    <canvas id='canvaImage' className={`m-auto ${as > .6 ? 'h' : 'w'}-100`} />
                </div>
            </div>
            {edit === 'crop' ? <Zoom value={zoom} setData={(zoom) => this.setState({ zoom })} /> : <></>}
            <div style={{ width: 32 }}></div>
        </div>
    }
    save = async () => {
        const { state: { preview, edit }, props: { img, setImg } } = this;
        if (preview === img) {
            toast('NO SE HAN APLICADO CAMBIOS A LA IMAGEN', { type: toast.TYPE.ERROR });
            return;
        }
        this.setState({ cargando: true, calc: true });
        if (edit === 'crop') {
            this.setState({ edit: 'none' });
            await this.draw();
        }
        conMedia().nuevo({
            data: await this.img.getString(),
            tags: [-2, -3]
        }, (i) => setImg(`/api/Media/ver/${i}`))
    }
    aplyTrans = (tr: GPUTrans) => {
        this.setState({ cargando: true, calc: true });
        const { trans } = this.state;
        if (trans.some(t => t === tr)) this.setState({ trans: trans.filter(t => t !== tr) });
        else this.setState({ trans: [...trans, tr] });
    }
    drawTrans = async () => {
        this.setState({ calc: true });
        this.setState({ hsl: this.img.apliyTransfor(this.origin.hsl, this.state.trans) as number[][][] });
        setTimeout(this.preDraw, 100)
    }
    preDraw = async () => {
        const { hsl, red, green, blue, hue, cont, saturate, ling } = this.state;
        await this.img.imageTransforn(hsl, [-hue, saturate, ling, red - 100, green - 100, blue - 100, (cont - 100) / 100]);
        this.setState({ cargando: false, calc: false });
    }
    crop = async () => {
        this.setState({ cargando: true, calc: true });
        const { cropData: pixelCrop, rotate: rotation, preview: src } = this.state;
        const preview = await this.img.cropImage({ src, pixelCrop, rotation });
        this.setState({ rotate: 0, zoom: 1, preview, edit: 'none', cut: true, calc: false });
    }
    drawBlock = true;
    transform = async (r: number, g: number, b: number, h: number, s: number, l: number, c: number) => {
        if (this.drawBlock) {
            this.drawBlock = false;
            const { hsl } = this.state;
            await this.img.imageTransforn(hsl, [-h, s, l, r - 100, g - 100, b - 100, (c - 100) / 100]);
            this.setState({ red: r, green: g, blue: b, hue: h, saturate: s, ling: l, cont: c });
            this.drawBlock = true;
        }
    }
    preview = async () => {
        this.setState({ cargando: true, calc: true });
        await this.preDraw();
        this.setState({ edit: 'crop', preview: await this.img.preview(), cargando: false, calc: false });
    }
    draw = async () => {
        this.setState({ cargando: true, calc: true });
        const { preview } = this.state;
        const hsl = await this.img.initCanvas(preview) as any;
        this.setState({
            ...DataNull,
            hsl: hsl ? hsl : [[[0, 0, 0, 0]]],
            preview: await this.img.preview(),
            cargando: false,
            calc: false
        })
        this.origin = { hsl };
    }
    defaultData = async () => {
        const { img } = this.props;
        await this.img.initCanvas(img);
        this.setState({ preview: img, edit: 'none' });
    }
    componentDidUpdate(_p: Props, s: State) {
        const { edit, trans } = this.state;
        if (s.edit !== edit)
            if (edit === 'pre-crop') this.preview();
            else if (s.edit === 'crop') this.draw();
        if (trans.length !== s.trans.length) this.drawTrans();
    }
}

const cssCrop = css`
height: 100%;
margin: 0 auto 0 auto;
&> .reactEasyCrop_Container{
    position: absolute;
    top: 22px;
    left: 177px;
    right: 52px;
    bottom: 28px;
}`;