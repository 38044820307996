import { Col, ButtonGroup } from 'react-bootstrap'
import { Detalles, GeneralFila } from '../../../../Models/General'
import { conCotizacion, conNota, Cotizacion, Factura, Nota, sentNota, updatePrecio } from '../../../../Models/Ventas'
import { toast } from 'react-toastify';
import AuxiliarVentas from '../../../../Utiles/Ventas/AuxiliarVentas';
import { ProductosType } from '../../../../Context/Producto'
import { VentasContext } from "../../../../Context/Ventas";
import { Procesar } from './ModalPagos';
import Switch from '@mui/material/Switch';
import { Productos, Dolar } from '@/Utiles/Prototipos/AuxiliarNuevo/Botones'
import { ProductosModal } from '@/Utiles/Prototipos/AuxiliarNuevo/Interface'
import BuscarCotizacionButton from '../../Cotizacion/BuscarButton'
import BuscarNotaButton from '../../Salidas/BuscarButton'
import { CalcularImpuesto } from './CalcularImpuesto';

class AuxiliarFactura extends AuxiliarVentas<{ procesar: () => void }> {
    productos: ProductosModal = 'Factura';
    addProductos = (pr: Detalles) => {
        let data = this.props.data as Factura;
        if (data.id_nota !== undefined) toast("UNA NOTA DE ENTREGA NO PUEDE SER EDITADA AL FACTURARLA", { type: toast.TYPE.ERROR })
        else this.add(pr);
    }
    removeProductos = (pr: Detalles) => {
        let data = this.props.data as Factura;
        if (data.id_nota !== undefined)
            toast("UNA NOTA DE ENTREGA NO PUEDE SER EDITADA AL FACTURARLA", { type: toast.TYPE.ERROR })
        else this.remove(pr)
    }
    BotonesLeft = () => <Col xs={6}>
        <ButtonGroup>
            <BuscarCotizacionButton add={this.cargarCotizacion} />
            <BuscarNotaButton add={this.cargarNota} />
        </ButtonGroup>
        <Switch defaultChecked={this.getExento()} color="primary" className="ml-2" onChange={(_e, exento) => this.props.setData({ exento })} />
        {this.getExento() ? <b>EXENTO DE IMPUESTO</b> : <>EXENTO DE IMPUESTO</>}
    </Col>

    context: any = {};
    BotonesRight = () => {
        const { props: { setData, data: { dolar } } } = this;
        const { exento } = this.props.data as any;
        return <Col xs={6} style={{ textAlign: 'right' }} className="float-right">
            <Dolar dolar={!!dolar} setData={dolar => setData({ dolar })} />
            <Procesar accion={this.props.procesar} data={this.props.data as Factura} setData={this.setData} iva={exento ? 0 : this.context.impuesto as number} />
            <Productos nuevo={this.nuevoProductos} add={this.addProductos} productos={this.productos} dolar={dolar} />
        </Col>
    }
    Calcular = () => <CalcularImpuesto dolar={this.props.data.dolar} ext={this.getExento()} imponible={this.imponible()} exento={this.exento()} />;
    cargarCotizacion = ({ codigo, rif }: GeneralFila) => {
        const { tasa, dolar } = this.context as ProductosType;
        this.setData({ cod_cliente: rif, id_cotizacion: codigo });
        conCotizacion().detalle(codigo, (data: Cotizacion) => this.setData(updatePrecio(data, tasa, dolar)));
    }
    cargarNota = (d: GeneralFila) => {
        const { tasa, dolar } = this.context as ProductosType;
        conNota().detalle(d.codigo, (data: Nota) => {
            let p: any = sentNota(data, 0);
            p.detalles = updatePrecio(data as any, tasa, dolar).detalles;
            p.id_nota = data.codigo;
            p.codigo = 0;
            if (this.props.data.nota !== '') p.nota = this.props.data.nota;
            this.setData(updatePrecio(data as any, tasa, dolar))
            this.setData(p)
            this.setState({ nota: true })
        });
    }
    getExento = () => {
        const { exento } = this.props.data as any;
        return exento as boolean;
    }
}
AuxiliarFactura.contextType = VentasContext;
export default AuxiliarFactura;
