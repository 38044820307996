import React from "react"
import { Button, Table } from "react-bootstrap"
import { toast } from "react-toastify"
import ReactTooltip from "react-tooltip"
import { conUnidad, Unidad } from "../../../Models/Productos"
import { InputText } from "../../../Utiles/Inputs"
interface Props {
    update?: () => void
}
export default class NuevoUnidad extends React.Component<Props, Unidad> {
    con = conUnidad()
    state = { codigo: 0, descripcion: '', estatus: 1 };
    render() {
        let { descripcion } = this.state
        return <>
            <br />
            <b>NUEVA UNIDAD DE MEDIDA</b>
            <Table>
                <tr>
                    <th style={{ width: "calc(100% - 170px)" }}>
                        <ReactTooltip />
                        <InputText setData={this.setState.bind(this)} value={descripcion} update="descripcion" />
                    </th>
                    <th style={{ width: "70px" }}>
                        <Button style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} data-tip="EDITAR" variant="primary" onClick={this.nuevo.bind(this)}>
                            <i className="fa fa-edit"></i> GUARDAR
                        </Button>
                    </th>
                </tr>
            </Table>
        </>
    }
    nuevo() {
        let { descripcion } = this.state;
        if (descripcion === '') {
            toast('DEBE COLOCAR UNA DESCRIPCION', { type: toast.TYPE.ERROR })
        } else {
            let { update } = this.props;
            this.con.nuevo(this.state, () => {
                if (update) update();
                this.setState({ codigo: 0, descripcion: '', estatus: 1 });
                toast('CREADO EXITOSAMENTE', { type: toast.TYPE.SUCCESS })
            });
        }
    }
}