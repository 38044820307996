import { useState, createContext, useEffect, useCallback } from 'react'
import { AuthSome } from '../../Config/Permisos';
import { Estado, EstadoNull } from '../../Models/General';
import { formatoHora } from '../../Utiles/Formatos';
import { Departamento, Tipo, Unidad, conDepartamento, conUnidad, conTipo } from '../../Models/Productos'
export interface UTDType {
    estDepartamentos: Estado
    estTipos: Estado
    estUnidades: Estado
    Departamentos: Departamento[]
    Tipos: Tipo[]
    Unidades: Unidad[]
    actDepartamento: () => void
    actUnidad: () => void
    actTipo: () => void
}
export const UTDContext = createContext<UTDType | null>(null)
export default function UTDProvider({ children }: { children: any }) {
    const [Departamentos, setDepartamentos] = useState<Departamento[]>([]);
    const [Tipos, setTipos] = useState<Tipo[]>([]);
    const [Unidades, setUnidades] = useState<Unidad[]>([]);
    const [estDepartamentos, setestDepartamentos] = useState(EstadoNull);
    const [estTipos, setestTipos] = useState(EstadoNull);
    const [estUnidades, setestUnidades] = useState(EstadoNull);
    const actDepartamento = useCallback(() => {
        conDepartamento().lista((d) => {
            setDepartamentos(d);
            setestDepartamentos({ status: 3, hora: formatoHora(undefined) });
        });
    }, [])
    const actUnidad = useCallback(() => {
        if (AuthSome(['UNIDAD', 'PRODUCTO'])) conUnidad().lista((u) => {
            setUnidades(u);
            setestUnidades({ status: 3, hora: formatoHora(undefined) });
        });
    }, [])
    const actTipo = useCallback(() => {
        if (AuthSome(['TIPO', 'PRODUCTO'])) conTipo().lista((t) => {
            setTipos(t);
            setestTipos({ status: 3, hora: formatoHora(undefined) });
        });
    }, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { actDepartamento(); actUnidad(); actTipo(); }, [])
    return <UTDContext.Provider value={{
        Departamentos, estDepartamentos, actDepartamento,
        Tipos, estTipos, actUnidad,
        Unidades, estUnidades, actTipo
    }}>
        {children}
    </UTDContext.Provider>

}