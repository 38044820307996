import { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Header from './Template/Header';
import Menu from './Template/MenuViejo';
import Menu2 from './Template/MenuNuevo';
import Footer from './Template/Footer';
import ListaUsuarios from './App/Usuarios';
import Panel from './App/Dashboard';
import Login from './App/Login';
import { SeccionContext, SeccionType } from './Context/Seccion';
import ProductosProvider from './Context/Producto';
import DolarProvider from './Context/Producto/Dolar';
import UTDProvider from './Context/Producto/UTD';
import ConfigProvider from './Context/Producto/Config';
import MasterVentas from './Masters/Ventas';
import MasterCompras from './Masters/Compras';
import MasterConfig from './Masters/Config';
import MasterReportes from './Masters/Reporte';
import MasterProductos from './Masters/Productos';
import { Card, Col } from 'react-bootstrap';
import Cargando from './Utiles/Componentes/Cargando';
import Panels from './App/PanelProxi/index';
import { Media } from './App/Media';
import './Utiles/Noti.css';
import MasterContacto from './Masters/Contacto';
import ColorProvider from './Template/Colores';
import MenuProvider from './Template/MenuContext';
export default function App() {
  const valStorage = localStorage.getItem('valStorage');
  const date = (new Date()).getDate() + '';
  if (valStorage !== date) {
    localStorage.clear();
    localStorage.setItem('valStorage', date);
  }
  const { data: { seccion, cargando, Permisos } } = useContext(SeccionContext) as SeccionType;
  if (cargando)
    return <Card style={{ height: '99.9999vh', padding: '20% 20% 20% 20%' }}>
      <Cargando />
    </Card>
  if (!seccion) return <Login />
  return <Router><MenuProvider>
    <DolarProvider>
      <ToastContainer position='bottom-right' />
      <Header key='headerBar' />
      {Permisos.some(p => ['MEDIA', 'PANEL', 'PROGRAMA'].some(k => p === k)) ?
        <Menu2 key={'MenuAside' + Math.random()} /> :
        <Menu key={'MenuAside' + Math.random()} />
      }
      <section className="content-wrapper" style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 175px)' }}>
        <Col md={12} className="mt-3" >
          <ColorProvider><ConfigProvider>
            <Switch>
              <UTDProvider>
                <ProductosProvider>
                  <Route path="/" exact component={Panel} />
                  <Route path={["/cotizacion", "/factura", "/nota", "/clientes",]} component={MasterVentas} />
                  <Route path={["/orden", "/compra", "/proveedores",]} component={MasterCompras} />
                  <Route path='/producto' component={MasterProductos} />
                  <Route path='/reporte' component={MasterReportes} />
                  <Route path='/config' component={MasterConfig} />
                </ProductosProvider>
                <Route path="/usuarios"><ListaUsuarios /></Route>
                <Route path="/panel" component={Panels} />
                <Route path="/contacto" component={MasterContacto} />
                <Route path="/media" component={Media} />
              </UTDProvider>
            </Switch>
          </ConfigProvider></ColorProvider>
        </Col>
      </section>
      <Footer />
    </DolarProvider>
  </MenuProvider></Router >;
}