interface Props {
    tipo: string
}
export const THead = (p: Props) => {
    return <tr className='info'>
        <th style={{ width: "100px", textAlign: 'center' }}>CODIGO</th>
        <th style={{ textAlign: 'center' }}>DESCRIPCIÓN</th>
        <Th {...p} />
        <th style={{ width: "110px", textAlign: 'center' }}>ACCIONES</th>
    </tr>
}
const Th = ({ tipo }: Props) => {
    switch (tipo) {
        case 'ENTRADA': return <th style={{ width: "100px", textAlign: 'center' }}>CANTIDAD</th>;
        case 'SALIDA': return <>
            <th style={{ width: "100px", textAlign: 'center' }}>CANTIDAD</th>
            <th style={{ width: "150px", textAlign: 'center' }}>DISPONIBLE</th>
        </>;
        case 'COSTO': return <th style={{ width: "300px", textAlign: 'center' }}>COSTO</th>;
        case 'UTILIDAD': return <>
            <th style={{ width: "200px", textAlign: 'center' }}>% UTILIDAD 1</th>
            <th style={{ width: "200px", textAlign: 'center' }}>% UTILIDAD 2</th>
            <th style={{ width: "200px", textAlign: 'center' }}>% UTILIDAD 3</th>
        </>;
    }
    return <></>
}