import React from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { OrdenesContext, OrdenesType } from "../../../../Context/Compras/Ordenes";
import { conOrden, Orden, ordenNull, sentOrden } from "../../../../Models/Compra";
import Condiciones from "../../../../Utiles/Componentes/Condiciones";
import CreateOperacion from "../../../../Utiles/Componentes/CreateOperacion";
import ProveedorSelector from "../../proveedores/Selector";
import AuxiliarOrden from "./AuxiliarOrden";
class NuevaOrden extends CreateOperacion {
    con = conOrden();
    title = 'ORDEN DE COMPRA';
    msn = 'LA ORDEN DE COMPRA';
    constructor(p: any) {
        super(p);
        this.tipo = 'Orden';
        this.state = { ...ordenNull, procesar: false };
    }
    Top() {
        const data = this.getData();
        return <>
            <ProveedorSelector Proveedor={data.cod_proveedor} add={this.setProveedor.bind(this)} />
            <Accordion>
                <Accordion.Toggle eventKey='0' as={Button} variant='primary'>DATOS OPCIONALES</Accordion.Toggle>
                <Accordion.Collapse eventKey='0'><Card>
                    <Card.Body>
                        <Condiciones data={data} setData={this.setState} />
                    </Card.Body>
                </Card>
                </Accordion.Collapse>
            </Accordion>
        </>
    }
    Productos() {
        return <AuxiliarOrden key="auxOrden" setData={this.setState} data={this.state} />
    }
    Procesar() {
        let procesar = true;
        const { impuesto } = this.context as OrdenesType;
        const data = this.getData() as Orden;
        if (data.cod_proveedor === "") {
            toast('DEBE SELECCIONAR UN PROVEEDOR', { type: toast.TYPE.ERROR });
            procesar = false;
        } procesar = this.validacionGenerica();
        if (procesar)
            this.con.nuevo(sentOrden(data, impuesto),
                data => this.setState({ codigo: data }),
                data => this.procesar = true);
    }
    setProveedor(p: string) {
        this.setState({ cod_proveedor: p });
    }

}
NuevaOrden.contextType = OrdenesContext;
export default NuevaOrden;