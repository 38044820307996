import { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Arriba, Abajo, Izquierda, Derecha, VentanaH, VentanaV, Remolino, Disolver, Zoom } from './variants'
import { animatedList } from './Itens/props';

export function Selector({ set, ani }: { set: (s: string) => void, ani: animatedList }) {
    const [page, setPage] = useState(getDefault(ani))
    return <ButtonGroup size="sm">
        <Page page={page} set={setPage} />
        {page === 1 ? <>
            <Button data-tip='ANIMACION DE ENTRAR DESDE ARRIBA' onClick={() => set('top')} active={(ani === 'top')} variant='outline-info'><Arriba /></Button>
            <Button data-tip='ANIMACION DE ENTRAR DESDE ABAJO' onClick={() => set('bottom')} active={(ani === 'bottom')} variant='outline-info'><Abajo /></Button>
            <Button data-tip='ANIMACION DE ENTRAR DESDE IZQUIERDA' onClick={() => set('right')} active={(ani === 'right')} variant='outline-info'><Derecha /></Button>
        </> : page === 2 ? <>
            <Button data-tip='ANIMACION DE ENTRAR DESDE DERECHA' onClick={() => set('left')} active={(ani === 'left')} variant='outline-info'><Izquierda /></Button>
            <Button data-tip='ANIMACION DE ROTAR' onClick={() => set('rotate')} active={(ani === 'rotate')} variant='outline-info'><Remolino /></Button>
            <Button data-tip='ANIMACION DE APARECER' onClick={() => set('simple')} active={(ani === 'simple')} variant='outline-info'><Disolver /></Button>
        </> : <>
            <Button data-tip='ANIMACION DE ZOOM' onClick={() => set('scaled')} active={(ani === 'scaled')} variant='outline-info'><Zoom /></Button>
            <Button data-tip='ANIMACION DE VENTANA VERTICAL' onClick={() => set('scaledY')} active={(ani === 'scaledY')} variant='outline-info'><VentanaH /></Button>
            <Button data-tip='ANIMACION DE VENTANA HORZONTAL' onClick={() => set('scaledX')} active={(ani === 'scaledX')} variant='outline-info'><VentanaV /></Button>
        </>}
    </ButtonGroup >
}
function getDefault(ani: animatedList) {
    switch (ani) {
        case 'top':
        case 'bottom':
        case 'right':
            return 1;
        case 'left':
        case 'rotate':
        case 'simple':
            return 2;
        default:
            return 3;
    }

}
function Page({ set, page }: { set: (s: number) => void, page: number }) {
    return <ButtonGroup vertical size="sm">
        <Button data-tip='ARRIBA' style={{ padding: '0 0 0 0' }} onClick={() => set(page === 1 ? 3 : page - 1)} variant='outline-dark'>
            <i className="fas fa-arrow-up" />
        </Button>
        <Button data-tip='ABAJO' style={{ padding: '0 0 0 0' }} onClick={() => set(page === 3 ? 1 : page + 1)} variant='outline-dark'>
            <i className="fas fa-arrow-down" />
        </Button>
    </ButtonGroup>

}