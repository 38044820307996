import { useEffect, useState } from 'react'
import { conPanel } from '../../Panel/Models';
import { useParams } from "react-router-dom";
import Editar from '../../Panel/Ver/Editar';
export function EditarPanel() {
    let { id } = useParams<{ id: any }>();
    const [panel, setPanel] = useState<any>([])
    useEffect(() => { conPanel().detalle(id, setPanel); }, [id]) 
    switch (panel.type) {
        default: return <Editar key={'panel' + id} panel={panel} />
    }
} 