// import { CanvasToHSL, HSLToCanvas, HSLToRGB, RGBToHSL, calido, envejecer, frio, gris, gris2, gris3, h8, hv, img, invert, invierno, mirrorX, mirrorY, sepia, sepia2, v255, v8 } from './Algoritmos'
import { img } from './Algoritmos'
import { GPU } from './GPU';
// import { GPU } from '@victorequena22/gpu.js';
export class GPUManager {
    // gpu: any = {};
    gpu = new GPU();
    width;
    height;
    settings;
    constructor(width: number, height: number) {
        this.width = width;
        this.height = height;
        console.log(width, height)
        this.settings = {
            constants: { width, height },
            output: [width, height],
            tactic: 'speed',
            strictIntegers: true
        };
        this.addFunction('v8');
        this.addFunction('hv');
        this.addFunction('h8');
        this.addFunction('v255');
        this.addFunction('HSLToRGB');
        this.addFunction('RGBToHSL');
    }
    addFunction = (Func: any) => {
        // eslint-disable-next-line no-new-func
        const KernelFunc = Function(`return ${Func}`)();
        this.gpu.addFunction(KernelFunc);
    }
    EjecuteKernel = (image: any, Func: any) => {
        // eslint-disable-next-line no-new-func
        const KernelFunc = Function(`return ${Func}`)();
        console.log(Func)
        const { settings } = this;
        const kernel = this.gpu.createKernel(KernelFunc, settings as any);
        const result = kernel(image);
        kernel.destroy();
        return result;
    }
    imageTransforn = (image: img, data: number[]) => {
        // eslint-disable-next-line no-new-func
        const KernelFunc = Function(`return HSLToCanvas`)();
        console.log('HSLToCanvas')
        const { width, height } = this;
        const kernel = this.gpu.createKernel(KernelFunc, {
            constants: { width, height },
            output: [width, height],
            graphical: true, pipeline: true
        });
        kernel(image, data);
        const result = kernel.getPixels(true);
        kernel.destroy();
        return result;
    }
    HSLToCanvas = (image: any) => this.EjecuteKernel(image, "CanvasToHSL");
    mirrorX = (image: img) => this.EjecuteKernel(image, "mirrorX");
    mirrorY = (image: img) => this.EjecuteKernel(image, "mirrorY");
    invert = (image: img) => this.EjecuteKernel(image, "invert");
    gris = (image: img) => this.EjecuteKernel(image, "gris");
    gris2 = (image: img) => this.EjecuteKernel(image, "gris2");
    gris3 = (image: img) => this.EjecuteKernel(image, "gris3");
    sepia = (image: img) => this.EjecuteKernel(image, "sepia");
    sepia2 = (image: img) => this.EjecuteKernel(image, "sepia2");
    invierno = (image: img) => this.EjecuteKernel(image, "invierno");
    frio = (image: img) => this.EjecuteKernel(image, "frio");
    calido = (image: img) => this.EjecuteKernel(image, "calido");
    envejecer = (image: img) => this.EjecuteKernel(image, "envejecer");

}
export function getGPUManager(width: number, height: number) {
    return new GPUManager(width, height);
}