import { css } from '@emotion/css';
import { Button, InputGroup, FormControl } from "react-bootstrap";
import { RowConfig, CardConfig, TitleCard, BoxConfig, CardMain } from '../../../Component';
import { InputText, InputArea } from "@victorequena22/component-bootstrap";
import { Text } from './Text'
import { Link } from './Link'
import { Boton } from './Boton'
import { Link as l } from "react-router-dom";
export interface Props { data: any, setData: (d: any) => void, default: any }
export function Config(p: Props & { save: () => void }) {
    const { type, id } = p.data;
    return <CardMain width='540px'>
        <RowConfig className='w-100'>
            <BoxConfig width='145px' >
                <Guardar guardar={p.save} />
                <Close />
                <Retaurar default={() => {
                    p.setData({ type: '' });
                    setTimeout(() => p.setData(p.default), 10);
                }} />
            </BoxConfig>
            <CardConfig width='calc(100% - 150px)'>
                <RowConfig >
                    <InputGroup data-tip='NOMBRE DEL ATAJO' className='input-group-sm' style={{ width: '200px', height: '24px' }}>
                        <InputGroup.Prepend style={{ height: '24px', width: '55px' }}>
                            ATAJO:
                        </InputGroup.Prepend>
                        {id === 0 ?
                            <InputText maxLength={15} mask='****************' style={{ height: '24px' }} setData={(t) => p.setData({ clave: t })} value={p.data.clave} /> :
                            <InputGroup.Append>{p.data.clave}</InputGroup.Append>
                        }
                    </InputGroup>
                    {id === 0 ?
                        <Selector value={type} chance={e => p.setData({ type: e })} /> :
                        <>TIPO: {type}</>
                    }
                </RowConfig>
                <RowConfig top={1}>
                    DESCRIPCION:
                </RowConfig>
                <RowConfig>
                    <InputArea data-tip='DESCRIPCION DEL ATAJO GENERADA' className='form-control-sm' maxLength={255}
                        setData={(t) => p.setData({ descripcion: t })}
                        value={p.data.descripcion} />
                </RowConfig>
                <TitleCard>OPCIONES</TitleCard>
            </CardConfig>
        </RowConfig>
        {type === 'texto' ? <Text {...p} /> : type === 'link' ? <Link {...p} /> : type === 'boton' ? <Boton {...p} /> : <></>}
    </CardMain>
}
function Guardar({ guardar }: { guardar: () => void }) {
    const CN = css`
    color: #28a745;width: 70px;padding: .3rem .3rem .3rem .3rem;
    border-color: #28a745;height: 80px;
    &:hover{color: white;background-color: #28a745;}`;
    return <Button data-tip='GUARDAR' variant='default' onClick={guardar} className={CN} >
        <i className="fa fa-save fa-3x"></i>
    </Button>
}
function Close() {
    const CN = css`
    color: #dc3545;width: 70px;padding: 1rem .3rem .3rem .3rem;
    border-color: #dc3545;margin: 0 0 0 5px;height: 80px;
    &:hover{color: white;background-color: #dc3545;}`;
    return <Button data-tip='CANCELAR OPERACION' as={l} to='/panel/short' variant='default' className={CN} >
        <i className="fas fa-window-close fa-3x"></i>
    </Button>
}
function Retaurar(p: { default: () => void }) {
    const CN = css`
    color: #ffc107;border-color: #ffc107;margin: 5px 0 0 0;
    &:hover{color: white;background-color: #ffc107;}`;
    return <Button data-tip='VOLVER AL PRINCIPIO' variant='default' block onClick={p.default} className={CN} >
        <i className="fas fa-trash" />
        RESTAURAR
        <i className="fas fa-trash" />
    </Button >

}
function Selector({ chance, value }: { chance: (d: string) => void, value: string }) {
    const set = (e: React.ChangeEvent<HTMLSelectElement>) => chance(e.currentTarget.value);
    return <FormControl as='select' onChange={set} value={value}
        data-tip='TIPOS DE CLAVES POSIBLES'
        style={{ height: '24px', width: '152px', fontSize: '10' }}
        className='form-control-sm ml-1'>
        <option value=''>SELECCIONAR CLAVE</option>
        <option value='texto'>TEXTO ENRIQUECIDO</option>
        <option value='boton'>BOTON</option>
        <option value='link'>LINK</option>
    </FormControl>
}