import React from 'react';
import { Button, ButtonGroup, Modal, Row } from 'react-bootstrap';
interface Props {
    open: boolean,
    close: () => void,
    title: string
    msn: string
    accion: () => void
    variant?: string
    isValid?: boolean
}
export default function ModalAlert({ isValid = true, title, close, msn, open, accion, variant = 'warning' }: Props) {
    return <Modal show={open} onHide={close} centered className=' modal-warning' >
        <Modal.Header style={{ borderRadius: 0 }} className={'bg-' + variant} closeButton>
            <h4 className="m-0 font-weight-bold mr-auto align-self-center">
                <span><b>{title}</b></span>
            </h4>
        </Modal.Header>
        <Modal.Body >
            <Row style={{ padding: "10px 10px 10px 10px" }} dangerouslySetInnerHTML={{ __html: msn }} />
        </Modal.Body>
        <Modal.Footer >
            <ButtonGroup className="float-right">
                {isValid ? <Button onClick={() => { accion(); close() }} variant="success">
                    <span className="fa fa-check-circle-o"></span> Aceptar
                </Button> : <></>}
                <Button onClick={close} variant="danger">
                    <span className="fa fa-remove-circle"></span> Cancelar
                </Button>
            </ButtonGroup>
        </Modal.Footer>
    </Modal>
}