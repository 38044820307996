import { Row } from "react-bootstrap";
import Seled from "./SelectedCreatePAnel";
import { getImagenURL } from "../../../Utiles/Formatos";



export default function MenuCreacion() {
    return <Row style={{ display: 'grid', gap: '5px', gridAutoFlow: 'dense', gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))' }}>
        <Seled to="/panel/carrusel" src={getImagenURL("/static/panels/var/1.jpg")} title="CARRUSEL BANNER" />
        <Seled to="/panel/paralax1" src={getImagenURL("/static/panels/pax/3.jpg")} title="PARALAX A" />
        <Seled to="/panel/paralax2" src={getImagenURL("/static/panels/pax/1.jpg")} title="PARALAX B" />
        <Seled to="/panel/titulo" src={getImagenURL("/static/panels/pax/9.jpg")} title="TITULO" />
        <Seled to="/panel/video" src={getImagenURL("/static/panels/gal/5.jpg")} title="VIDEO" />
        <Seled to="/panel/imagen" src={getImagenURL("/static/panels/gal/5.jpg")} title="IMAGEN" />
        <Seled to="/panel/imagenSola" src={getImagenURL("/static/media/fondo.svg")} title="IMAGEN SOLA" />
        <Seled to="/panel/galeria" src={getImagenURL("/static/panels/gal/1.jpg")} title="GALERIA" />
        <Seled to="/panel/cargos" src={getImagenURL("/static/panels/var/4.jpg")} title="CARRUSEL CARTA" />
        <Seled to="/panel/servicio" src={getImagenURL("/static/panels/ser/1.jpg")} title="CARTA CON ICONO A" />
        <Seled to="/panel/servicio2" src={getImagenURL("/static/panels/ser/5.jpg")} title="CARTA CON ICONO B" />
        <Seled to="/panel/preguntas" src={getImagenURL("/static/panels/var/5.jpg")} title="PREGUNTAS" />
        <Seled to="/panel/pestanas" src={getImagenURL("/static/panels/tab/5.jpg")} title="PESTAÑAS" />
        <Seled to="/panel/mapa" src={getImagenURL("/static/panels/var/mapa.png")} title="MAPA" />
        <Seled to="/panel/suscribir" src={getImagenURL("/static/panels/var/suscribir.png")} title="SUSCRIBIR" />
        <Seled to="/panel/contacto" src={getImagenURL("/static/panels/var/contact.png")} title="CONTACTO" />
        <Seled to="/panel/testimonio" src={getImagenURL("/static/panels/var/testimonio.jpg")} title="TESTIMONIOS" />
        <Seled to="/panel/BannerImagen" src={getImagenURL("/static/panels/var/BannerImagen.jpg")} title="BANNER ALIADOS" />
    </Row>
}