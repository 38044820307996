import React from "react";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Button } from "react-bootstrap";

export default class CompraExcel extends React.Component<{ compra: any[] }, {}>{
    _export = React.createRef<any>();
    excelExport() {
        if (this._export.current)
            this._export.current.save();
    };
    render() {
        const { compra } = this.props;
        if (compra.length === 0) return <></>
        return <>
            <Button variant='success' onClick={this.excelExport.bind(this)} style={{ marginRight: '15px' }}>
            <i className="fa fa-file-excel"/>
            </Button>
            <ExcelExport data={compra} ref={this._export} fileName="Compra">
                <ExcelExportColumn field="codigo" title="N°" width={120} />
                <ExcelExportColumn field="fecha" title="FECHA" width={150} />
                <ExcelExportColumn field="nombre" title="PROVEEDOR" width={500} />
                <ExcelExportColumn field="monto" title="MONTO" width={120} />
            </ExcelExport></>
    }

}