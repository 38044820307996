import React from 'react'
import { Button, Modal, Row, Col, InputGroup, FormControl } from 'react-bootstrap';
import { conDepartamento, conProducto, conTipo, Producto } from '../../../Models/Productos';
import UnidadesSelector from '../Unidad/Selector';
import { toast } from 'react-toastify';
import { InputArea } from '../../../Utiles/Inputs';
import { numberFormat } from '../../../Utiles/Formatos';
interface Props {
    open: boolean
    close: () => void
    add: (p: Producto) => void
    producto: Producto;
}
interface State extends Producto {
    cateName?: string
}
export default class EditProductos extends React.Component<Props, State>{
    con = conProducto();
    departamento = '';
    tipo = '';
    constructor(p: Props) {
        super(p)
        this.state = p.producto;
        this.setState = this.setState.bind(this)
    }

    setUnidad(unidad: number) {
        this.setState({ unidad })
    }

    render() {
        let { setState, state: { descripcion, codigo, unidad, precio1, precio2, precio3, costo, enser, exento }, props: { open, close }, tipo, departamento } = this;
        return <Modal size="xl" animation show={open} onHide={close}>
            <Modal.Header closeButton>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> EDITAR PRODUCTO</h4>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={4}>
                        <InputGroup style={{ width: '100%' }}>
                            <InputGroup.Prepend><InputGroup.Text>CODIGO</InputGroup.Text></InputGroup.Prepend>
                            <FormControl disabled value={codigo} />
                        </InputGroup>
                    </Col>
                    <Col xs={8}>
                        <InputGroup style={{ width: '100%' }} className="mb-3">
                            <InputGroup.Prepend><InputGroup.Text>DEPARTAMENTO</InputGroup.Text></InputGroup.Prepend>
                            <FormControl disabled value={departamento} />
                        </InputGroup>
                    </Col>
                    <Col xs={12}><br />
                        <b>DESCRIPCIÓN</b>
                        <InputArea rows={3} maxLength={300} value={descripcion} setData={setState} update="descripcion" />
                    </Col>
                    <Col md={4} style={{ height: 54 }}><br />
                        <Button block onClick={() => { this.setState({ enser: enser === 1 ? 0 : 1 }) }} variant={enser === 0 ? 'success' : 'danger'}>
                            PARA LA VENTA: {enser === 0 ? 'SI' : 'NO'}
                        </Button>
                    </Col>
                    <Col xs={4}><br />
                        <UnidadesSelector defaultValue={unidad} change={this.setUnidad.bind(this)} />
                    </Col>
                    <Col xs={4} style={{ height: 54 }}><br />
                        <InputGroup style={{ width: '100%' }} className="mb-3">
                            <InputGroup.Prepend><InputGroup.Text>TIPO</InputGroup.Text></InputGroup.Prepend>
                            <FormControl disabled value={tipo} />
                        </InputGroup>
                    </Col>
                    <Col md={4}><br />
                        <Button block onClick={() => { this.setState({ exento: !exento }) }} variant={exento ? 'success' : 'primary'}>
                            EXENTO: {exento ? 'SI' : 'NO'}
                        </Button>
                    </Col>
                    <Col xs={8} style={{ height: 54 }}><br />
                        <InputGroup style={{ width: '100%' }} className="mb-3">
                            <InputGroup.Prepend><InputGroup.Text>COSTO</InputGroup.Text></InputGroup.Prepend>
                            <FormControl style={{ textAlign: 'right' }} disabled value={numberFormat(costo)} />
                        </InputGroup>
                    </Col>
                    {/* utilidades de venta */}
                    <Col xs={4}><br />
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend><InputGroup.Text>UTILIDAD 1</InputGroup.Text></InputGroup.Prepend>
                            <FormControl style={{ textAlign: 'right' }} disabled value={numberFormat(precio1)} />
                        </InputGroup>
                    </Col>
                    <Col xs={4}><br />
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend><InputGroup.Text>UTILIDAD 2</InputGroup.Text></InputGroup.Prepend>
                            <FormControl style={{ textAlign: 'right' }} disabled value={numberFormat(precio2)} />
                        </InputGroup>
                    </Col>
                    <Col xs={4}><br />
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend><InputGroup.Text>UTILIDAD 3</InputGroup.Text></InputGroup.Prepend>
                            <FormControl style={{ textAlign: 'right' }} disabled value={numberFormat(precio3)} />
                        </InputGroup>
                    </Col>
                    {/* precios de venta */}
                    <Col xs={4}><br />
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend><InputGroup.Text>PRECIO 1</InputGroup.Text></InputGroup.Prepend>
                            <FormControl style={{ textAlign: 'right' }} disabled value={numberFormat(this.getPrecio(precio1))} />
                        </InputGroup>
                    </Col>
                    <Col xs={4}><br />
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend><InputGroup.Text>PRECIO 2</InputGroup.Text></InputGroup.Prepend>
                            <FormControl style={{ textAlign: 'right' }} disabled value={numberFormat(this.getPrecio(precio2))} />
                        </InputGroup>
                    </Col>
                    <Col xs={4}><br />
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend><InputGroup.Text>PRECIO 3</InputGroup.Text></InputGroup.Prepend>
                            <FormControl style={{ textAlign: 'right' }} disabled value={numberFormat(this.getPrecio(precio3))} />
                        </InputGroup>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.actualizar.bind(this)} variant="primary">
                    <span className="fa fa-floppy-disk" /> Guardar
                </Button>
            </Modal.Footer>
        </Modal >
    }
    actualizar() {
        let { descripcion, unidad, exento } = this.state;
        if (descripcion === '') {
            toast('DEBE COLOCAR UNA DESCRIPCION', { type: toast.TYPE.ERROR })
        } else if (unidad === -1) {
            toast('DEBA SELECCIONAR UNA UNIDAD DE MEDIDA', { type: toast.TYPE.ERROR })
        } else {
            this.con.actualizar(this.state.codigo, { ...this.state, exento: exento ? 1 : 0 },
                data => { this.props.add(data); this.props.close(); });
        }
    }
    getPrecio(price: number) {
        let utilidad = (price / 100) + 1
        return this.state.costo * utilidad;
    }
    componentDidMount() {
        let { departamento, tipo, } = this.props.producto;
        conDepartamento().detalle(departamento, data => {
            this.departamento = data.descripcion; this.forceUpdate();
        });
        conTipo().detalle(tipo, data => {
            this.tipo = data.descripcion; this.forceUpdate();
        });
    }
}
