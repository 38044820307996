import { useContext } from "react";
import Admin from './Daministrativo'
import { SeccionContext, SeccionType } from "../../Context/Seccion";
import Web from "./Web";
const admin = ['COMPRA', 'ORDEN', 'PROVEEDOR', 'FACTURA', 'NOTA', 'COTIZACION', 'CLIENTE', 'AJUSTE'];
const web = ['PANEL'];
export default function Dasboard() {
    const { data: { Permisos } } = useContext(SeccionContext) as SeccionType;
    return <>
        {Permisos.some(p => admin.includes(p)) ? <Admin /> : <></>}
        {Permisos.some(p => web.includes(p)) ? <Web /> : <></>}
    </>
}