import React from 'react';
import { Modal } from 'react-bootstrap';
import PrintComponent from './PrintCompinent';
interface Props {
    open: boolean,
    close: () => void,
    codigo: string,
    title: string
}
export default function ModalPrint({ title, close, codigo, open }: Props) {
    return <Modal show={open} onHide={close} size='xl'>
        <Modal.Header closeButton>
            <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                <span><b>{title}</b></span>
            </h6>
        </Modal.Header>
        <Modal.Body>
            <PrintComponent codigo={codigo} />
        </Modal.Body>
    </Modal>
}