import { useState, useEffect, useContext, useMemo } from 'react'
import { Button, Modal, Row, Table, ButtonGroup } from 'react-bootstrap';
import { conCotizacion } from '../../../Models/Ventas';
import ReactTooltip from 'react-tooltip';
import { ProductosContext, ProductosType } from '../../../Context/Producto';
import { generalNull } from '../../../Models/General';
import { numberFormat, zfill } from '../../../Utiles/Formatos';
interface Props {
    id: number
    open: boolean,
    close: () => any,
    titulo: string
}
function ModalDetalle({ id, open, close, titulo }: Props) {
    const { dolar } = useContext(ProductosContext) as ProductosType;
    const [detalles, setDetalles] = useState(generalNull.detalles);
    const [tasa, setTasa] = useState(1);
    const [usd, setUSD] = useState(dolar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { conCotizacion().detalle(id, (d) => { setDetalles(d.detalles); setTasa(d.tasa); }) }, [])
    return <Modal size='xl' centered animation show={open} onHide={close}>
        {useMemo(() => {
            const cDolar = (p: number) => parseFloat((usd ? p / tasa : p).toFixed(2))
            let suma = 0;
            if (Array.isArray(detalles))
                return <>
                    <Modal.Header closeButton>
                        <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                            <span><b>{titulo}</b></span>
                        </h6>
                    </Modal.Header>
                    <Modal.Body>
                        <Row style={{ padding: '0px 30px 0px 30px' }}>
                            <ReactTooltip />
                            <Row className='col-12'>
                                <h4 className='mr-auto'>
                                    <span className="fa fa-print" />
                                    MOSTRAR VISTA
                                </h4>
                                <ButtonGroup>
                                    <Button onClick={() => setUSD(false)} data-tip="CAMBIAR A PRECIO EN BOLIVARES" variant={(usd ? 'outline-' : '') + "primary"}>
                                        PRECIO EN BOLIVARES
                                    </Button>
                                    <Button onClick={() => setUSD(true)} data-tip="CAMBIAR A PRECIO EN DOLARES" variant={(!usd ? 'outline-' : '') + "warning"}>
                                        PRECIO EN DOLARES
                                    </Button>
                                </ButtonGroup>
                            </Row>
                            <br />
                            <Table>
                                <thead>
                                    <tr>
                                        <th style={{ width: "100px" }}>COD</th>
                                        <th>DESCRIPCION</th>
                                        <th style={{ width: "40px", textAlign: "right" }}>CANT</th>
                                        <th style={{ width: "80px", textAlign: "right" }}>PRE/UNT</th>
                                        <th style={{ width: "150px", textAlign: "right" }}>PRECIO</th>
                                        <th style={{ maxWidth: "300px", textAlign: "center" }}>NOTA</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {detalles.map((s) => {
                                        suma += s.precio * s.unidades
                                        return <tr>
                                            <td style={{ width: "100px" }}>{s.codigo}</td>
                                            <td>{s.descripcion}</td>
                                            <td style={{ width: "40px" }}>{numberFormat(s.unidades)}</td>
                                            <td style={{ width: "80px", textAlign: "right" }}>{numberFormat(cDolar(s.precio))}</td>
                                            <td style={{ width: "150px", textAlign: "right" }}>{numberFormat(cDolar(s.precio) * s.unidades)}</td>
                                            <td style={{ maxWidth: "300px", overflowX: 'auto' }}>{s.comentario}</td>
                                        </tr>
                                    })}
                                    <tr>
                                        <td colSpan={3}></td>
                                        <td style={{ width: "80px", textAlign: "right" }}><b>TOTAL:</b></td>
                                        <td style={{ width: "150px", textAlign: "right" }}>{numberFormat(cDolar(suma))}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Row>
                    </Modal.Body>
                </>
            return <></>;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [detalles, tasa, usd])}
    </Modal>
}
interface PropsTh {
    id: number
    label: string
}
export default function DetallesTh({ id, label }: PropsTh) {
    const [open, setOpen] = useState(false);
    return <th style={{ minWidth: "90px", width: "90px", maxWidth: "90px" }}>
        <ModalDetalle key={`ModalDetalle${label}${id}`} id={id}
            titulo={`DETALLES DE LA ${label}  N°  ${zfill(id)}`}
            open={open} close={() => setOpen(false)} />
        <Button data-tip="VER DETALLES" onClick={() => setOpen(true)} variant="link" >
            {zfill(id)}
        </Button>
    </th>
}
