import React from 'react';
import { Button, Card } from 'react-bootstrap';
import ModalReport from '../../../Utiles/Componentes/ModalReport';
import UsuarioSelector from '../../Usuarios/Select';
import Fechas from '../Fechas';
import { don } from '@victorequena22/conneccion';
interface State {
    date1: string
    date2: string
    aux: string
    user: number
    modal: boolean
}

let date = new Date();

export default class Vendedores extends React.Component<any, State> {
    state = { user: -1, aux: "mes", date1: date.getFullYear() + '', date2: (date.getMonth() + 1) + '', modal: false }
    render() {
        let { aux, date2, date1, modal, user } = this.state;
        const fe = aux + '/' + date1 + '/' + date2
        return (<Card>
            <ModalReport title={"REPORTE DE VENDEDORES"} open={modal}
                url={don + `/api/Factura/reporteVendedor${user === -1 ? 'es' : `/${user}`}/${fe}`}
                close={() => { this.setState({ modal: !modal }) }} />
            <Card.Header className='bg-info'>
                <h3>REPORTE DE VENDEDORES</h3>
            </Card.Header>
            <Card.Body>
                <Card>
                    <Card.Header className='bg-info'>
                        <h6>SELECCIONAR USUARIO</h6>
                    </Card.Header>
                    <Card.Body>
                        <UsuarioSelector change={(user) => { this.setState({ user }) }} defaultValue={user} />
                    </Card.Body>
                </Card>
                <Fechas aux={aux} date2={date2} date1={date1} setDate={this.setState.bind(this)} />
                <Button className="float-right" onClick={() => { this.setState({ modal: !modal }) }} variant='primary'>
                    <span className="glyphicon glyphicon-print"></span> IMPRIMIR
                </Button>
            </Card.Body>
        </Card>)
    }
}