import React from "react"
import Chart from "react-google-charts"
export type data = string | number
export interface AreaProps {
    label: string[]
    values: data[][]
    title?: string
    subtitle?: string
}
export default function Barras({ label, values, title }: AreaProps) {
    return <Chart
        width={'1fr'}
        height={'37rem'}
        chartType="ComboChart"
        loader={<div>Loading Chart</div>}
        data={[label, ...values,]}
        options={{ title, seriesType: 'bars', legend: { position: 'none' } }}
        rootProps={{ 'data-testid': '2' }}
    />

}