import React from "react"
import { Button, ButtonGroup } from "react-bootstrap"
import { toast } from "react-toastify"
import ReactTooltip from "react-tooltip"
import { conTipo, Tipo } from "../../../Models/Productos"
import { InputText } from "../../../Utiles/Inputs"
interface Props {
    d: Tipo
    update?: () => void
}
export class FilaTipo extends React.Component<Props, Tipo> {
    con = conTipo()
    constructor(p: Props) {
        super(p)
        this.state = p.d
    }
    render() {
        let { codigo, descripcion, inventario } = this.state
        const inv = [undefined, 'NO GENERA', 'LOGICO', 'FISICO'];
        return <tr>
            <th style={{ width: "100px" }}>
                {codigo}
            </th>
            <th style={{ width: "calc(100% - 270px)" }}>
                <ReactTooltip />
                <InputText setData={this.setState.bind(this)} value={descripcion} update="descripcion" />
            </th>
            <th style={{ width: "120px" }}>
                {inv[inventario]}
            </th>
            <th style={{ width: "100px" }}>
                {this.getStatus()}
            </th>
            <th style={{ width: "70px" }}>
                <ButtonGroup className="float-right">
                    <Button data-tip="EDITAR" variant="primary"
                        onClick={this.edit.bind(this)}>
                        <i className="fa fa-edit"></i>
                    </Button>
                    {(this.props.d.estatus === 1) ?
                        <Button data-tip="ELIMINAR" variant="danger"
                            onClick={this.cancelar.bind(this)} >
                            <i className="fa fa-ban"></i>
                        </Button> : <Button data-tip="ACTIVAR" variant="success"
                            onClick={this.cancelar.bind(this)} >
                            <i className="fa fa-check-circle-o"></i>
                        </Button>}
                </ButtonGroup>
            </th>
        </tr>
    }
    getStatus() {
        if (this.props.d.estatus === 1)
            return <span className="badge badge-success">ACTIVO</span>
        return <span className="badge badge-danger">INACTIVO</span>
    }
    cancelar() {
        let { update, d: { codigo } } = this.props;
        this.con.cancelar(codigo, update);
    }
    edit() {
        let { descripcion } = this.state;
        let { update, d: { codigo } } = this.props;
        if (descripcion === '') {
            toast('DEBE COLOCAR UNA DESCRIPCION', { type: toast.TYPE.ERROR })
        } else {
            this.con.actualizar(codigo, this.state, update);
        }
    }
}