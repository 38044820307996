import React from "react";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Button } from "react-bootstrap";

export default class OrdenExcel extends React.Component<{ orden: any[] }, {}>{
    _export = React.createRef<any>();
    excelExport() {
        if (this._export.current)
            this._export.current.save();
    };
    render() {
        const { orden } = this.props;
        if (orden.length === 0) return <></>
        return <>
        <Button variant='success' onClick={this.excelExport.bind(this)} style={{marginRight:'15px'}}>
        <i className="fa fa-file-excel"/>
        </Button>
        <ExcelExport data={orden} ref={this._export} fileName="Orden">
        <ExcelExportColumn field="codigo" title="N°" width={120} />
        <ExcelExportColumn field="fecha" title="FECHA" width={150} />
        <ExcelExportColumn field="nombre" title="PROVEEDOR" width={500} />
        <ExcelExportColumn field="monto" title="MONTO" width={120} />

        </ExcelExport></>
    }

}