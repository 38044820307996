import React from 'react'
import { Modal, Row, Button, Col } from 'react-bootstrap'
import { conUsuario, Permisos, Rol } from '../../../Models/Usuario';
interface Props {
    rol: Rol
    title: string
    open: boolean
    add: (d: any) => void
    close: () => void
}
interface State {
    permisos: number[],
    Permisos: Permisos[]
}
export default class EditarRol extends React.Component<Props, State> {
    con = conUsuario();
    state = { permisos: [], Permisos: [] }
    componentDidMount() {
        this.con.listaPermisos((Permisos: Permisos[]) => {
            this.setState({ Permisos })
        });
        this.con.PermisosRol(this.props.rol.id, (rol: Rol) => {
            this.setState({ permisos: rol.permisos.map(d => { return d.id }) })
        });
    }
    render() {
        let { props: { close, open, title }, state: { Permisos } } = this;
        return <Modal animation centered show={open} onHide={close}>
            <Modal.Header closeButton>
                {title}
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12}>
                        {Permisos.map((p: Permisos) => {
                            return <Button onClick={this.click.bind(this, p.id)}
                                variant={this.selected(p.id)}>{p.nombre}</Button>
                        })}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button className="float-right" onClick={this.save.bind(this)} variant="primary">
                    <span className="fa fa-floppy-o" /> GUARDAR
                </Button>
            </Modal.Footer>
        </Modal >
    }
    selected(id: number) {
        if (this.state.permisos.some(p => p === id)) return "success"
        return "default";
    }
    click(id: number) {
        const { permisos } = this.state;
        if (permisos.every(p => p !== id)) this.setState({ permisos: [...permisos, id] });
        else this.setState({ permisos: permisos.filter(p => p !== id) });
    }
    save() {
        let { props: { rol: { id } }, state: { permisos } } = this;
        this.con.actualizarRol(id,
            { permisos }, data => {
                this.props.add(data)
                this.props.close()
            })
    }
}