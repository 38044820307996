import React, { useContext } from 'react';
import { Route } from "react-router-dom"
import ListaCotizaciones from '../App/Ventas/Cotizacion';
import NuevaCotizacion from '../App/Ventas/Cotizacion/Nuevo';
import ListaClientes from '../App/Ventas/Clientes';
import ListaFactura from '../App/Ventas/Facturas';
import NuevaFactura from '../App/Ventas/Facturas/Nuevo';
import ListaNotas from '../App/Ventas/Salidas';
import NuevaNota from '../App/Ventas/Salidas/Nuevo';
import { SeccionContext } from '../Context/Seccion';
import ClientesProvider from '../Context/Ventas/Cliente'
import CotizacionProvider from '../Context/Ventas/Cotizacion'
import NotasProvider from '../Context/Ventas/Notas'
import VentasProvider from '../Context/Ventas'
import UnAutorization from '../Utiles/Componentes/UnAutorization';
export default function MasterVentas() {
    return (<ClientesProvider>
        <Route path="/clientes" component={Cliente} />
        <Route path={["/cotizacion", "/factura", "/nota"]} component={CotizacionContent} />
    </ClientesProvider>);
}

function NotasContent() {
    return <NotasProvider>
        <Route path="/nota" component={Nota} />
        <Route path="/factura" component={Factura} />
    </NotasProvider>
}
function CotizacionContent() {
    return <CotizacionProvider>
        <Route path="/cotizacion" component={Cotizacion} />
        <Route path={["/factura", "/nota"]} component={NotasContent} />
    </CotizacionProvider>
}
function Cotizacion() {
    const contex = useContext(SeccionContext);
    if (contex?.data.Permisos.some(p => p === 'COTIZACION'))
        return <>
            <Route path="/cotizacion/lista"><ListaCotizaciones /></Route>
            <Route path="/cotizacion/nueva"><NuevaCotizacion /></Route>
        </>
    return <UnAutorization />
}
function Factura() {
    const contex = useContext(SeccionContext);
    if (contex?.data.Permisos.some(p => p === 'FACTURA'))
        return <VentasProvider>
            <Route path="/factura/lista"><ListaFactura /></Route>
            <Route path="/factura/nueva"><NuevaFactura /></Route>
        </VentasProvider>
    return <UnAutorization />
}
function Nota() {
    const contex = useContext(SeccionContext);
    if (contex?.data.Permisos.some(p => p === 'NOTA'))
        return <>
            <Route path="/nota/lista"><ListaNotas /></Route>
            <Route path="/nota/nueva"><NuevaNota /></Route>
        </>
    return <UnAutorization />
}
function Cliente() {
    const contex = useContext(SeccionContext);
    if (contex?.data.Permisos.some(p => p === 'CLIENTE'))
        return <ListaClientes />
    return <UnAutorization />
}