
import { TitleCard, CardConfig, RowConfig, ButtonConfig } from '../../../Component';
import { CropModal } from '../../../../Media/CropModal';
import { ButtonColor } from '../../../Component';
import { ButtonGroup, InputGroup } from 'react-bootstrap';
import { iten } from '../index';
import { InputNumber } from '@victorequena22/component-bootstrap';
interface Props {
    iten: iten,
    setIten: (iten: Partial<iten>) => void
}
export function ItenImagen(p: Props) {
    const { setIten, iten } = p
    const { img, marco, borde, color, width } = iten;
    return <CardConfig width='150px'>
        <RowConfig>
            <CropModal aspect={1} img={img} setImg={img => setIten({ img })} />
            <ButtonConfig active={borde} action={() => setIten({ borde: !borde })} className='ml-2' icon='fas fa-image' />
            {borde ? <ButtonColor className='ml-auto' gradiend invert color={color} setData={color => setIten({ color })}>
                <i className='far fa-image' />
            </ButtonColor> : <></>}
        </RowConfig>
        <RowConfig top={2}>
            <ButtonGroup>
                <ButtonConfig active={marco === ''} tip='QUITAR REDONDEO DE LA IMAGEN' icon='fal fa-image' action={() => setIten({ marco: '' })} />
                <ButtonConfig active={marco === 'rounded-circle'} tip='REDONDEAR IMAGEN' icon='fal fa-circle' action={() => setIten({ marco: 'rounded-circle' })} />
            </ButtonGroup>
        </RowConfig>
        <RowConfig top={2} >
            <SetNumber tip='TAMAÑO DE LA IMAGEN' text="TAMAÑO" val={width} set={width => setIten({ width })} />
        </RowConfig>
        <TitleCard>CONFIGURACION DE IMAGEN</TitleCard>
    </CardConfig>
}
interface P {
    val: number,
    set: (n: number) => void,
    text: string,
    tip: string,
    letf?: string
}
function SetNumber({ val, set, text, tip, letf }: P) {
    return <InputGroup data-tip={tip} className={`input-group-sm ${letf}`} style={{ height: '24px' }}>
        <InputGroup.Prepend style={{ height: '24px' }}>
            <InputGroup.Text style={{ height: '24px' }}>
                {text}
            </InputGroup.Text>
        </InputGroup.Prepend>
        <InputNumber decimal={0} min={100} max={320} style={{ height: 24 }} value={val} setData={set} />
    </InputGroup>
}