import { Row, Container, Col } from 'react-bootstrap';
import { State } from './index'
import { Itens } from './Itens'

export function Panel({ config, seled }: { config: State, seled: (i: number) => void }) {
    const { color, itens, id } = config;
    const Rows: JSX.Element[] = [];
    for (let i = 0; i < itens.length; i += 2) {
        Rows.push(<RowItens
            left={<Itens key={'iten' + id + itens[i].id} iten={itens[i]} config={config} seled={seled} />}
            right={<Itens key={'iten' + id + itens[i].id} iten={itens[i + 1]} config={config} seled={seled} />} />)
    }
    return <Row style={{ background: color, margin: '0 0 0 0', padding: '10px 0 10px 0' }}>
        <Container>{Rows}</Container>
    </Row>
}
const RowItens = ({ left, right }: { left: JSX.Element, right: JSX.Element }) => {
    return <Row><Col className='m-2'>{left}</Col><Col  className='m-2'>{right}</Col></Row>
}