
import React from "react";
export default function EstadosLabel({ status, type }: { status: number, type: 'Orden' | 'Compra' | 'Cotizacion' | 'Nota' | 'Factura' }) {
    const cl = ['danger', 'warning', 'success'];
    const ty = {
        'Orden': ['CANCELADA', 'EN ESPERA', 'PROCESADA'],
        'Compra': ['CANCELADA', 'SIN FACTURA', 'FACTURADA'],
        'Cotizacion': ['CANCELADA', 'PENDIENTE', 'PROCESADA'],
        'Nota': ['CANCELADA', 'ENTREGADO', 'FACTURADA'],
        'Factura': ['CANCELADA', 'ENTREGADO', 'PROCESADA'],
        'notas': ['CANCELADA', 'ENTREGADO', 'PROCESADA'],
    };
    return <span className={`badge badge-${cl[status]}`} >{ty[type][status]}</span>
}