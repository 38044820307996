import { conConfig } from '@victorequena22/conneccion';
import React from 'react';
import { Button, ButtonGroup, Col, Card } from 'react-bootstrap';
interface State {
    costo: number
    tasa: boolean
}
export default class Costos extends React.Component<any, State> {
    con = conConfig('costo');
    state = { costo: 1, tasa: false };
    render() {
        let { costo, tasa } = this.state;
        return (<Card>
            <Card.Header className='bg-info'>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> CONFIGURACION DE PRECIOS </h4>
            </Card.Header>
            <Card.Body>
                <Col md={12}>
                    <p><b> ACTUALIZAR COSTOS DE LOS PRODUCTOS: </b></p>
                    <p>INDICA COMO EL SISTEMA MANEJARA EL COSTO DE SUS PRODUCTOS</p>
                    <ButtonGroup vertical>
                        <Button onClick={() => { this.setState({ costo: 1 }) }} variant={(costo === 1) ? 'success' : 'default'}>
                            EL COSTO A REGISTRAR SERA EL DE LA ULTIMA COMPRA
                        </Button>
                        <Button onClick={() => { this.setState({ costo: 2 }) }} variant={(costo === 2) ? 'success' : 'default'}>
                            EL COSTO A REGISTRAR SERA EL DE LA COMPRA CON PRECIO MAS ALTO
                        </Button>
                        <Button onClick={() => { this.setState({ costo: 3 }) }} variant={(costo === 3) ? 'success' : 'default'}>
                            EL COSTO NO SE VE AFECTADO POR LA COMPRA, <br />SU VALOR ES EL REGISTRADO EN SU CREACION Y ES MODIFICADO MANUALMENTE
                        </Button>
                    </ButtonGroup>
                </Col>
                <Col md={12}><br />
                    <p><b> COSTO BASADO EN REFERENCIA A LA TASA CAMBIARIA:</b></p>
                    <p>OPCION EN BOLIVARES: </p>
                    <ul>
                        <li>COMPRA: SE MANTIENEN TODOS LOS COSTOS EN BOLIVARES.</li>
                        <li>VENTAS: SI SE COTIZA EN DOLARES SE TOMA EL PRECIO DE VENTA EN BOLIVARES ENTRE LA TASA.</li>
                    </ul>
                    <p>OPCION EN DOLARES: </p>
                    <ul>
                        <li>COMPRA: SE GUARDA EL COSTO EN BOLIVARES Y LA TASA DEL DIA DE LA COMPRA.</li>
                        <li>VENTAS: SI SE COTIZA SE TOMA EL COSTO EN DOLARES DEL DIA DE LA COMPRA Y SE CALCULA SU UTILIDAD; SI LA COTIZACION ES EN BOLIVARES SE MULTIPLICA POR LA TASA.</li>
                    </ul>
                    <ButtonGroup style={{ width: '100%' }}>
                        <Button style={{ width: '50%' }} onClick={() => { this.setState({ tasa: false }) }} variant={!tasa ? 'success' : 'default'}>
                            BOLIVARES
                        </Button>
                        <Button style={{ width: '50%' }} onClick={() => { this.setState({ tasa: true }) }} variant={tasa ? 'success' : 'default'}>
                            DOLARES
                        </Button>
                    </ButtonGroup>
                </Col>
                <Col md={12}><br />
                    <Button variant="success">
                        <i className="glyphicon glyphicon-check-circle-o"></i>
                    </Button> EL COLOR VERDE INDICA LA OPCION SELECCIONADA
                </Col>
                <Col md={12}><br />
                    <Button className="float-right" onClick={this.guardar.bind(this)} variant="primary">
                        <span className="fa fa-floppy-o" /> Guardar
                    </Button>
                </Col>
            </Card.Body>
        </Card>)
    }
    componentDidMount() {
        this.con.pull(this.setState.bind(this));
    }
    guardar() {
        this.con.push(this.state, this.setState.bind(this));
    }
}