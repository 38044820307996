import React from "react"
import { Button, Table } from "react-bootstrap"
import { toast } from "react-toastify"
import ReactTooltip from "react-tooltip"
import {  conMoneda, Moneda } from "../../../Models/General"
import { InputText } from "../../../Utiles/Inputs"
interface Props {
    update?: () => void
}
export default class NuevoMoneda extends React.Component<Props, Moneda> {
    con = conMoneda()
    state = { codigo: 0, descripcion: '', estatus: 1 };
    render() {
        let { descripcion } = this.state
        return <>
            <br />
            <b>NUEVO MONEDA</b>
            <Table>
                <tr>
                    <th style={{ width: "calc(100% - 170px)" }}>
                        <ReactTooltip />
                        <InputText placeholder='DESCRIPCION' setData={this.setState.bind(this)} value={descripcion} update="descripcion" />
                    </th>
                    <th style={{ width: "70px" }}>
                        <Button variant="primary" onClick={this.nuevo.bind(this)}>
                            <i className="fa fa-edit"></i> GUARDAR
                        </Button>
                    </th>
                </tr>
            </Table>
        </>
    }
    nuevo() {
        let { descripcion } = this.state;
        if (descripcion === '') {
            toast('DEBE COLOCAR UNA DESCRIPCION', { type: toast.TYPE.ERROR })
        } else {
            let { update } = this.props;
            this.con.nuevo(this.state, update);
        }
    }
}