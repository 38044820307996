import { content } from './props';
import { Card, Accordion, Row } from 'react-bootstrap';
import { EditArea } from '../../../Component/SlateCustom';
import { iten, State } from '../index';
import { UseIcon } from "@victorequena22/icon-selector-bootstrap";
import { TextToHTML } from "@victorequena22/slate-bootstrap";
interface Props {
    iten?: iten;
    config: State;
    setConfig: (p: Partial<State>) => void
}
export function Itens({
    config: { seleted, border: b, back: iback, orientation, edit }, setConfig, iten }: Props) {
    if (iten === undefined) return <></>;
    const { id, html, title, icon, color, back, aux, fill } = iten;
    const minWidth = 'calc(100% - 3rem)', border = '2px solid red';
    return <Card className={content(back, color, fill, iback, b, aux)}>
        <Card.Header>
            <Accordion.Toggle as={Row} className={`d-flex flex-${orientation}`} eventKey={`${id}`}>
                <div className={"icon-content text-center"}>
                    <UseIcon icon={icon} width={`2rem`} height={`2rem`} fill={fill} />
                </div>
                {(seleted === id) && (edit === 'header') ?
                    <div style={{ minWidth, border }} className="p-1" >
                        <EditArea key={'edit' + id} />
                    </div> :
                    <div style={{ minWidth }} className="p-1" onClick={() => setConfig({ edit: 'header', seleted: id })}>
                        <TextToHTML text={title} />
                    </div>
                }
            </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={`${id}`}>
            {(seleted === id) && (edit === 'body') ?
                <Card.Body style={{ border }} > <EditArea key={'edit' + id} /></Card.Body> :
                <Card.Body onClick={() => setConfig({ edit: 'body' })} style={{ color }}><TextToHTML text={html} /></Card.Body>
            }
        </Accordion.Collapse>
    </Card>
}