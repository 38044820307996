import React from 'react';
type es = string | undefined
export interface EstadosProps {
    setData: (d: number) => void
    estatus: es[]
    status: number
}
export default function Estados({ status, estatus, setData }: EstadosProps) {
    const setStatus = (e: React.ChangeEvent<HTMLSelectElement>) => { setData(parseInt(e.currentTarget.value)) };
    return <select data-tip="FILTRAR POR EL ESTADO" onChange={setStatus} className="form-control" value={status}>
        <option value={-1}>TODOS LOS ESTADOS</option>
        {estatus[0] ? <option value={0}>{estatus[0]}</option> : <></>}
        {estatus[1] ? <option value={1}>{estatus[1]}</option> : <></>}
        {estatus[2] ? <option value={2}>{estatus[2]}</option> : <></>}
    </select>
}