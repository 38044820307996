import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { Conexion } from "../../../../Config/Coneccion";
import ModalAlert from "../../../Componentes/ModalAlert";
import { zfill } from "../../../Formatos";
interface Props {
    con: Conexion
    id: number
    label: string
    msn: string
}
export default function BTNCancelar({ con, id, label, msn }: Props) {
    const [open, setOpen] = useState(false);
    const cancelar = () => con.cancelar(id, () => {
        toast(msn, { type: toast.TYPE.SUCCESS });
    })
    return <>
        <ModalAlert
            title={"ELIMINAR " + label + " N° " + zfill(id)} key={'ModalAlert' + label + id}
            msn={"ESTA SEGURO QUE ELIMINARA LA " + label + " N°" + zfill(id) + "?"}
            open={open} close={() => setOpen(false)} accion={cancelar} />
        <Button data-tip="VER DETALLES" onClick={() => setOpen(true)} variant="danger" >
            <i className="fa fa fa-ban"></i>
        </Button>
    </>
}