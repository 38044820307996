import React from 'react'
import { Button } from 'react-bootstrap'
import { GeneralFila } from '../../Models/General'
import { formatoFecha, zfill } from '../Formatos'
interface PropsFila {
    data: GeneralFila,
    add: (iten: GeneralFila) => void
    color?: string
    type: 'Cotizacion' | 'Orden' | 'Nota' | 'Factura' | 'Compra'
}
export default class FilaGeneraModal extends React.Component<PropsFila>{
    render() {
        let { data } = this.props
        return <tr>
            <th>{zfill(data.codigo)}</th>
            <th>{formatoFecha(data.fecha)}</th>
            <th>{data.nombre}</th>
            <th>{this.getStatus()}</th>
            <th style={{ textAlign: 'center' }}>
                <Button data-tip="Agregar Producto" onClick={() => { this.props.add(data) }} variant="primary">
                    <i className="fa fa-plus" />
                </Button>
            </th>
        </tr > 
    }
    getStatus() {
        switch (this.props.data.status) {
            case 0:
                return <span className="badge badge-danger">CANCELADA</span>
            case 1:
                return <span className="badge badge-warning">{this.getStatus1()}</span>
            case 2:
                return <span className="badge badge-success">{this.getStatus2()}</span>
        }
    }
    getStatus1() {
        let { type } = this.props;
        switch (type) {
            case 'Orden': return 'EN ESPERA';
            case 'Compra': return 'SIN FACTURA';
            case 'Cotizacion': return 'PENDIENTE';
            case 'Nota': return 'ENTREGADO';
        }
    }
    getStatus2() {
        let { type } = this.props;
        switch (type) {
            case 'Compra': case 'Nota':
                return 'FACTURADA';
            case 'Orden': case 'Cotizacion': case 'Factura':
                return 'PROCESADA';
        }
    }

}