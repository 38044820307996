import BuscarProductoBoton from "@/App/Inventario/Productos/Botones/Buscar";
import CrearProductoButon from "@/App/Inventario/Productos/Botones/Crear";
import { Detalles } from "@/Models/General";
import { ButtonGroup, Button } from "react-bootstrap";
import { ProductosModal } from "./Interface";
import { Producto } from "@/Models/Productos";
interface Props {
    add: (d: Detalles) => void;
    nuevo: (d: Producto) => void;
    dolar?: boolean
    productos: ProductosModal
}
export function Productos({ dolar, add, nuevo, productos }: Props) {
    return <ButtonGroup className="float-right mr-2">
        <CrearProductoButon dolar={dolar} add={p => nuevo(p)} />
        <BuscarProductoBoton type={productos} dolar={dolar} add={add} />
    </ButtonGroup>
}
export const Dolar = ({ dolar, setData }: { dolar: boolean, setData: (d: boolean) => void }) => <Button
    data-tip={"CAMBIAR A " + (dolar ? 'BOLIVARES' : 'DOLARES')} style={{ marginRight: "15px" }}
    variant={dolar ? 'warning' : "primary"} onClick={() => setData(!dolar)} size='sm'>
    {dolar ? <b>$</b> : <b>BS</b>} DOCUMENTO EN {dolar ? 'DOLARES' : 'BOLIVARES'}
</Button>