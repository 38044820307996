/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useContext, useCallback } from 'react'
import { Auth } from '../../Config/Permisos';
import { conCompra } from '../../Models/Compra';
import { Estado, EstadoNull, GeneralFila, updateOrPush } from '../../Models/General';
import { formatoHora } from '../../Utiles/Formatos';
import { OrdenesContext, OrdenesType } from './Ordenes';
import { sleep, useSemaforo } from '../../Utiles/ReactUtils';
export interface ComprasType extends OrdenesType {
    estCompras: Estado
    Compras: GeneralFila[]
    actCompra: () => void
}
export const ComprasContext = createContext<ComprasType | null>(null)
export default function ComprasProvider({ children }: { children: any }) {
    const [compras, setCompras] = useState<GeneralFila[]>([]);
    const ordenes = useContext(OrdenesContext) as OrdenesType;
    const [est, setest] = useState(EstadoNull);
    const [con] = useState(conCompra());
    const update = useCallback(async () => {
        if (Auth('COMPRA')) con.cambios((data: any[]) => {
            setest({ status: 3, hora: formatoHora(con.state) });
            setCompras(updateOrPush(data, compras))
        })
        await sleep(50);
    }, [compras])
    useSemaforo('Compras', async () => {
        await update()
    })
    return <ComprasContext.Provider value={{
        ...ordenes,
        estCompras: est,
        Compras: compras,
        actCompra: update
    }}>
        {children}
    </ComprasContext.Provider>
}
export const SeccionConsumer = ComprasContext.Consumer;