import FilaProducto from './Productos'
import { Col, Button, ButtonGroup } from 'react-bootstrap'
import { conCotizacion, conNota, Cotizacion, Nota, sentNota, updatePrecio } from '../../../../Models/Ventas';
import BuscarNota from '../BuscarNota';
import AuxiliarVentas from '@/Utiles/Ventas/AuxiliarVentas';
import BuscarCotizacion from '../../Cotizacion/BuscarCotizacion';
import { numberFormat } from '@victorequena22/utiles';
import { Detalles, GeneralFila } from '@/Models/General';
import { ProductosType } from '@/Context/Producto';
import { Dolar, Productos } from '@/Utiles/Prototipos/AuxiliarNuevo/Botones';
import { ProductosModal } from '@/Utiles/Prototipos/AuxiliarNuevo/Interface';
import { useDolar } from '@/Context/Producto/Dolar';
export default class AuxiliarNotas extends AuxiliarVentas {
    productos: ProductosModal = 'Nota';
    BotonesLeft = () => {
        const { cargarCotizacion, cargarNota, state: { aux2, aux } } = this;
        return <Col xs={6}>
            <BuscarCotizacion open={aux} add={cargarCotizacion.bind(this)} close={() => this.setState({ aux: false })} />
            <BuscarNota open={aux2} add={cargarNota.bind(this)} close={() => this.setState({ aux2: false })} />
            <ButtonGroup>
                <Button onClick={() => this.setState({ aux: true })}
                    data-class="toolbody" data-html="true"
                    data-tip="CARGAR COTIZACION" variant="primary">
                    <span className="fa fa fa-calculator" />
                </Button>
                <Button onClick={() => this.setState({ aux2: true })}
                    data-class="toolbody" data-html="true"
                    data-tip="COPIAR NOTA" variant="primary">
                    <span className="fa fa-paper-plane" />
                </Button>
            </ButtonGroup>
        </Col>
    }
    BotonesRight = () => {
        const { BTNProcesar, BTNota, props: { setData, data: { dolar } } } = this;
        return <Col xs={6} style={{ textAlign: 'right' }} className="float-right">
            <Dolar dolar={!!dolar} setData={dolar => setData({ dolar })} />
            <BTNota />
            <BTNProcesar />
            <Productos nuevo={this.nuevoProductos} add={this.addProductos} productos={this.productos} dolar={dolar} />
        </Col>
    }
    BTNota = () => {
        const { props: { setData, data: { id_nota } } } = this;
        return <Button data-tip={(id_nota ? 'QUITAR' : 'AGREGAR') + ' SERIALES'} style={{ marginRight: "15px" }}
            variant={id_nota ? 'success' : "outline-dark"} onClick={() => setData({ id_nota: !id_nota })}>
            <i className="fa fa-barcode"></i>
        </Button>
    }
    Calcular = () => <Total total={this.imponible() + this.exento()} dolar={this.props.data.dolar} />;
    cargarCotizacion = ({ codigo, rif }: GeneralFila) => {
        const { tasa, dolar } = this.context as ProductosType;
        this.setData({ cod_cliente: rif, id_cotizacion: codigo });
        conCotizacion().detalle(codigo, (data: Cotizacion) => {
            const d = updatePrecio(data, tasa, dolar);
            this.setData(d)
        });
    }
    cargarNota = (d: GeneralFila) => {
        conNota().detalle(d.codigo, (data: Nota) => {
            let p: any = sentNota(data, 0);
            p.detalles = data.detalles.map((p, i) => ({ ...p, index: i + 1 }));
            p.codigo = 0;
            if (this.props.data.nota !== '') p.nota = this.props.data.nota;
            this.setData(p)
        });
    }
    Row = ({ data, add, remove }: { data: Detalles, add: (pr: Detalles) => void, remove: (pr: Detalles) => void }) => {
        let { dolar, id_nota } = this.props.data;
        return <FilaProducto key={data.codigo + 'venta' + this.productos}
            dolar={!!dolar} open={!!id_nota} data={data} edit={add} remove={remove} />;
    }
}


const Total = ({ total, dolar = false }: { total: number, dolar?: boolean }) => {
    const { tasa } = useDolar();
    return <tr>
        <th colSpan={4}></th>
        <th style={{ textAlign: "right" }} colSpan={2}>TOTAL:</th>
        <th style={{ textAlign: "right" }}>{numberFormat((dolar) ? total / tasa : total)}</th>
    </tr>
}