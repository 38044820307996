import { useState } from "react";
import { Button } from "react-bootstrap";
import BuscarNota from "./BuscarNota";



export default function BuscarNotaButton({ add }: { add: (d: any) => void }) {
    const [o, s] = useState(false);
    return <>
        <BuscarNota add={add} open={o} close={() => s(false)} />
        <Button onClick={() => s(true)}
            data-class="toolbody" data-html="true"
            data-tip="CARGAR NOTA" variant="primary">
            <span className="fa fa-paper-plane" />
        </Button>
    </>
}