import { useState, useCallback, useEffect } from 'react';
import { css } from '@emotion/css';
import { Col, Button, InputGroup, Row } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { ControlText } from "@victorequena22/component-bootstrap";
import { FieldText } from '../../Components/Field';
import { conMedia, Media, Tags } from './Models';
const borde = '3px solid #17a2b8';
const con = conMedia();
interface Props {
    ver?: number
    imgs: Media[],
    actualizar: () => void,
    GetImagen: (i: any) => JSX.Element,
    type?: string
}
export function Buscador({ imgs, actualizar, GetImagen, ver = 60, type: t }: Props) {
    const [page, setPage] = useState(1);
    const [nombre, setNombre] = useState('');
    const [type, setType] = useState(t ? t : '');
    const [tagName, setTagName] = useState('');
    const [tagsList, setTagsList] = useState<Tags[]>([]);
    const [tags, setTags] = useState<number[]>([]);
    const selected = useCallback((id: number) => (tags.some(p => p === id)) ? "success" : "default", [tags]);
    const click = useCallback((i: number) => setTags(tags.some(p => p === i) ? tags.filter(p => p !== i) : [...tags, i]), [tags]);
    const cargarTags = useCallback(() => { con.tags(setTagsList) }, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { actualizar(); cargarTags(); }, []);
    const imageList = imgs.filter(i => {
        const en = nombre.split(" ");
        const con = en.filter(e => (i.nombre.toUpperCase().indexOf(e) > -1 || (`${i.id}`).indexOf(e) > -1));
        const nomRes = en.length === con.length;
        const tagRes = tags.length ? i.tags.some(t => tags.some(p => p === t)) : true;
        const typeRes = i.type.indexOf(type) > -1;
        return nomRes && tagRes && typeRes;
    });
    const total = Math.ceil(imageList.length / ver);
    const init = (page - 1) * ver;
    return <Row>
        <Col xs={10}>
            <Row className='pr-3' >
                <div className='mr-auto' style={{ width: '500px' }}>
                    <Adorno style={{ zIndex: 10, margin: '12px 6px 6px 477px' }} />
                    <ControlText maxLength={20} upper before="BUSCAR IMAGEN POR NOMBRE" setData={setNombre} value={nombre} />
                </div>
                <div className='ml-auto'>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <Button variant='default' onClick={() => setPage(page > 1 ? page - 1 : total)}>
                                <i className='fas fa-arrow-left' />
                            </Button></InputGroup.Prepend>
                        <InputGroup.Text>{page}/{total}</InputGroup.Text>
                        <InputGroup.Append>
                            <Button variant='default' onClick={() => setPage(page < total ? page + 1 : 1)}>
                                <i className='fas fa-arrow-right' />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </div>
            </Row><br />
            <Row style={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'auto' }}>
                {imageList.filter((_a, i) => (i >= init && i < init + ver)).map(
                    (i) => <GetImagen key={`imagen${i.id}`} img={i} tagsList={tagsList} cargarTags={cargarTags} />
                )}
            </Row>
        </Col>
        <Col xs={2} style={{ borderLeft: borde, height: 'calc(100vh - 235px)' }}>
            {!t ? <Row style={{ borderBottom: borde, borderTop: borde }} className="mb-1 p-2">
                <TextField className={input} size='small' variant="outlined" select fullWidth
                    id="TIPOS-DE-ARCHIVOS" label="TIPOS DE ARCHIVOS"
                    value={type} onChange={(event) => setType(event.target.value)}>
                    {typesFile.map((t) => (
                        <MenuItem key={t.value} value={t.value}>
                            {t.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Row> : <></>}
            <h6 style={{ color: '#17a2b8' }}>FILTRAR POR ETIQUETAS</h6>
            <Row style={{ borderBottom: borde }} className="mb-1 p-2">
                <FieldText className={input} inputProps={{ maxLength: 20 }}
                    size="small" upper setData={setTagName} value={tagName}
                    fullWidth id="BUSCAR-ETIQUETAS" label="BUSCAR ETIQUETAS"
                    variant="outlined" />
                <Adorno />
            </Row>
            <Row className='d-flex' style={{ maxHeight: 'calc(100vh - 338px)', overflowY: 'auto' }}>
                {tagsList.filter(i => {
                    const en = tagName.split(" ");
                    const con = en.filter(e => (i.nombre.toUpperCase().indexOf(e) > -1));
                    return en.length === con.length;
                }).map((p) => <Button className="ml-1 mb-1" style={{ borderRadius: '1rem', height: 40 }} onClick={() => click(p.id)} variant={selected(p.id)}>{p.nombre}</Button>)}
            </Row>
        </Col>
    </Row>
}
function Adorno({ style }: { style?: React.CSSProperties }) {
    return <i style={{ position: 'absolute', margin: '6px 6px 6px calc(100% - 40px)', ...style }} className='fas fa-search' />
}
const typesFile = [
    {
        value: '',
        label: 'TODOS LOS TIPOS',
    },
    {
        value: 'image',
        label: 'SOLO IMAGENES',
    },
    {
        value: 'video',
        label: 'SOLO VIDEOS',
    }
];
const input = css`
font-size: 15px;height: 30px;
&> div{font-size: 10px;height: 30px;}
&> label{font-size: 10px;height: 30px;}
`