import { ConexionCompleta, don } from "@victorequena22/conneccion";

export interface Media {
    id: number,
    nombre: string,
    type: string,
    creacion: string,
    size: number,
    tags: number[]
}
export interface Tags {
    id: number,
    nombre: string
}
export function arrayToMedia(f: any[]): Media[] {
    return f.map(([a, b, c, d, e, f]) => {
        return {
            id: a,
            nombre: b && b !== null ? b : '',
            type: c,
            creacion: d,
            size: e,
            tags: f ? f : []
        }
    });
}
class MediaConecction extends ConexionCompleta {
    clase = 'Media';
    persistencia = 1000;
    delete = (id: number, setData: (d: any) => void, setError: (d: any) => void) => {
        this.get(don + `/api/Media/delete/${id}`, setData, setError)
    }
    tags = (setData: (d: any) => void, setError?: (d: any) => void) => {
        this.get(don + `/api/Media/tags`, setData, setError)
    }
    newTag = (nombre: string, setData: (d: any) => void, setError?: (d: any) => void) => {
        if (nombre !== '') this.post(don + '/api/Media/newTag', { nombre }, setData, setError);
    }
    setDisk = (setData: (d: any) => void, setError?: (d: any) => void) => {
        this.get(don + `/api/Media/disk`, setData, setError)
    }
}
var conOr: MediaConecction;
export function conMedia() {
    if (conOr === undefined) conOr = new MediaConecction();
    return conOr;
}

export function getSize(size: number): string {
    if (size < 1024) return size + 'B';
    else if (size < 1024 * 1024) return (size / 1024).toFixed(0) + 'KB';
    else if (size < 1024 * 1024 * 1024) return (size / 1024 / 1024).toFixed(0) + 'MB';
    else return (size / 1024 / 1024 / 1024).toFixed(0) + 'GB';
}