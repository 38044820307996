import { TitleCard, CardConfig, RowConfig, AddItens, ButtonConfig, ButtonColor } from '../../../Component';
import { InputNumber } from "@victorequena22/component-bootstrap";
import { InputGroup, ButtonGroup } from 'react-bootstrap';
import { iten } from '../index';
import { CropModal } from '../../../../Media/CropModal';
interface Props {
    iten: iten,
    setConfig: (d: Partial<iten>) => void,
    itenUp: (d: number) => void,
    itenDown: (d: number) => void,
    removeItem: (d: number) => void,
    addItem: () => void
    size: number
    gap: number
    cont: number
};
export function ConfigIten(p: Props) {
    const { iten, gap, size, setConfig, cont, addItem, itenUp, itenDown, removeItem } = p;
    const { id, row, col, img, back } = iten;
    const width = ((col * size) + ((col - 1) * gap));
    const height = ((row * size) + ((row - 1) * gap));
    return <CardConfig width='169px'>
        <RowConfig>
            <ButtonGroup>
                <ButtonConfig tip='MOVER A LA IZQUIERDA' icon='fas fa-arrow-left' action={() => itenUp(id)} />
                <ButtonConfig tip='MOVER A LA DERECHA' icon='fas fa-arrow-right' action={() => itenDown(id)} />
            </ButtonGroup>
            <ButtonConfig className='ml-1' tip='QUITAR' icon='fas fa-trash' action={() => removeItem(id)} />
            <SetNumber tip='NUMERO DE FILAS OCUPARA LA IMAGEN'
                icon="fas fa-arrows-v" val={row} set={row => setConfig({ row, id })} />
        </RowConfig>
        <RowConfig top={2}>
            <CropModal aspect={width / height} img={img} setImg={img => setConfig({ img })} />
            <AddItens tip='AGREGAR UNA IMAGEN' add={addItem} />
            <SetNumber tip='NUMERO DE COLUMNAS OCUPARA LA IMAGEN' letf='ml-1'
                icon="fas fa-arrows-h" val={col} set={col => setConfig({ col, id })} />
        </RowConfig>
        <RowConfig top={2}>
            <ButtonColor gradiend disableAlpha tip='COLOR DE FONDO DEL PANEL' setData={back => setConfig({ back, id })} color={back}>
                <i className="fas fa-paint-roller"></i>
            </ButtonColor>
            <span className='ml-auto mr-auto'>
                {id} DE {cont}
            </span>
        </RowConfig>
        <TitleCard>IMAGEN {id}</TitleCard>
    </CardConfig>
}
interface P {
    val: number,
    set: (n: number) => void,
    icon: string,
    tip: string,
    letf?: string
}

function SetNumber({ val, set, icon, tip, letf }: P) {
    return <InputGroup data-tip={tip} className={`input-group-sm ${letf}`} style={{ height: '24px', width: 60 }}>
        <InputGroup.Prepend style={{ height: '24px', width: '30px' }}>
            <InputGroup.Text style={{ height: '24px', width: '30px' }}>
                <i className={icon} />
            </InputGroup.Text>
        </InputGroup.Prepend>
        <InputNumber decimal={0} min={1} max={9} style={{ height: 24 }} value={val} setData={set} />
    </InputGroup>
}