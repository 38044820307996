import { Card } from 'react-bootstrap';
import { Config } from './Config';
import { Chat } from './Chat';
import { getCSS } from './Props';
import { Single, StateS } from '../../ProtoPanes/Single';
import SlateProvider from '../../Component/SlateCustom'
import { icon } from "@victorequena22/icon-selector-bootstrap";
import { getText } from '../../ProtoPanes/DefaultData';
import { conConfig } from '@victorequena22/conneccion';
export interface S {
    estilo: string;
    backHeader: string;
    colorHeader: string;
    backChat: string;
    backComment: string;
    colorComment: string;
    backChatButton: string;
    colorChatButton: string;
    backBotton: string;
    colorBotton: string;
    img: string;
    company?: any;
    icon: icon;
    linkText: string;
    link: string;
    tiempo: string;
    activo: boolean;
}
export type State = StateS & S;
export class Chats extends Single<S> {
    constructor(p: {}) {
        super(p);
        this.init({
            estilo: 'whatsapp', activo: false, tiempo: 'hace unos momentos',
            backHeader: '#095E54', colorHeader: '#FFFFFF',
            backChat: '#E6DDD4',
            backComment: '#FFFFFF', colorComment: '#111111',
            backChatButton: '#4FCE5D', colorChatButton: '#FFFFFF',
            backBotton: '#FFFFFF', colorBotton: '#4AD504',
            linkText: 'COMENZAR CHAT', link: 'https://api.whatsapp.com/send?phone=',
            img: "/logos/user.jpg", icon: { width: 448, height: 512, path: "M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" }
        });
    }
    componentDidMount(): void {
        conConfig('Chats').pull(d => {
            if (d !== null && JSON.stringify(d) !== '[]') this.setData(d);
            conConfig('empresa').pull(company => {
                this.setData({ company });
                if (d === null || JSON.stringify(d) === '[]')
                    this.setData({ img: '/public/imagenes/' + company.logo });
            });
        });
    }
    render() {
        const { setTexto, state } = this;
        const { html, company, img } = state;
        return <Card>
            <Card.Header className='bg-info'>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> CHAT</h4>
            </Card.Header>
            <Card.Body className='d-flex' style={{ height: 'calc(100vh - 210px)' }}>
                <SlateProvider key={`chatSlate${img}`} value={html} setValue={setTexto}>
                    <Config save={this.guardar} data={this.state} setData={this.setData.bind(this)} />
                    <div className='ml-5' style={{ width: 'calc(100% - 950px)', height: 'calc(100vh - 300px)' }}>
                        <Card className='h-100 w-100'>
                            <Card.Header>
                                <h6 className='mr-auto'>VISTA EN EDICION</h6>
                            </Card.Header>
                            <Card.Body className={`${getCSS(state)} h-100  w-100`}>
                                <Chat data={state} company={company} />
                            </Card.Body>
                        </Card>
                    </div>
                </SlateProvider>
            </Card.Body>
        </Card>
    }
    guardar = () => conConfig('Chats')
        .push({ ...this.state, html: getText(this.state.html) },
            this.setState.bind(this));
} 