import React, { useState } from 'react';
import { Button, ButtonGroup, Row } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
interface Props {
    codigo: string,
}
export default function PrintComponent({ codigo }: Props) {
    const [precio, setPrecio] = useState(0);
    const pdf = ['PDFSP', 'PDF', 'PDFD'];
    return <Row>
        <ReactTooltip />
        <Row className='col-12'>
            <h4 className='mr-auto'>
                <span className="fa fa-print" />
                MOSTRAR VISTA
            </h4>
            <ButtonGroup>
                <Button onClick={() => setPrecio(0)} data-tip="CAMBIAR A SIN PRECIO" variant={(precio !== 0 ? 'outline-' : '') + "success"}>
                    SIN PRECIO
                </Button>
                <Button onClick={() => setPrecio(1)} data-tip="CAMBIAR A PRECIO EN BOLIVARES" variant={(precio !== 1 ? 'outline-' : '') + "primary"}>
                    PRECIO EN BOLIVARES
                </Button>
                <Button onClick={() => setPrecio(2)} data-tip="CAMBIAR A PRECIO EN DOLARES" variant={(precio !== 2 ? 'outline-' : '') + "warning"}>
                    PRECIO EN DOLARES
                </Button>
            </ButtonGroup>
        </Row>
        <iframe src={"/api/Nota/" + pdf[precio] + "/" + codigo}
            width='100%' height='700wh' title="print" scrolling='no' frameBorder='0' />
    </Row>
}