import { Conexion } from '../../../Config/Coneccion';
import DetallesTh from './Detalles2Th';
import FechaTh from './FechaTh';
import FilaProto from './Proto';

export default class FilaNotas extends FilaProto<{ refe: Conexion }, {}> {
    refe: Conexion;
    parent: 'FACTURA' | 'COMPRA';
    constructor(p: any) {
        super(p)
        this.refe = p.refe;
        this.parent = 'FACTURA';
        this.DetallesRef = this.DetallesRef.bind(this)
    }
    render() {
        const { Detalles, DetallesRef, Nombre, Monto, Status, Botones, data: { fecha } } = this;
        return <tr>
            <Detalles />
            <DetallesRef />
            <FechaTh fecha={fecha} />
            <Nombre />
            <Monto />
            <Status />
            <Botones />
        </tr>
    }
    DetallesRef() {
        const { refe, parent, data: { doc } } = this;
        return <DetallesTh con={refe} label={parent} id={doc} />;
    }
    Detalles() {
        const { con, label, data: { codigo } } = this;
        return <DetallesTh con={con} label={label} id={codigo} />;
    }
}