
import { ConexionCompleta } from "@victorequena22/conneccion";
import { Proveedor, ProveedorNull } from "../../Compra";
export type ClienteLabel = 'codigo' | 'nombre' | 'telefono' | 'correo' | 'contacto' | 'direccion' | 'estatus' | 'tipo_contribuyente' | 'retencion'
export interface Cliente extends Proveedor {
    tipo_contribuyente: string,
    retencion: number
}
export var ClienteNull: Cliente = {
    ...ProveedorNull,
    tipo_contribuyente: "ORDINARIO",
    retencion: 0
}

class CienteConexion extends ConexionCompleta {
    clase = 'Cliente';
    persistencia = 1000;
}
export function conCliente() {
    return new CienteConexion();
}