import { CotizacionContext } from '@/Context/Ventas/Cotizacion';
import { GeneralFila } from '@/Models/General';
import ModalBusqueda from '@/Utiles/Buscadores/Modal';
import Estados from '@/Utiles/Componentes/Estados';
import FilaGeneraModal from '@/Utiles/Prototipos/FilaGeneraModal';
import { InputText } from '@victorequena22/component-bootstrap';
import { Button, Col, InputGroup, Row } from 'react-bootstrap';
class BuscarCotizacion extends ModalBusqueda {
    context: any = {};
    buscarEn = ['codigo', 'nombre', 'fecha',];
    status = -1;
    Buscador() {
        return <Row style={{ paddingBottom: '15px' }}>
            <Col className="col-md-9 mr-auto">
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            {this.buscarLabel}
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <InputText upper setData={this.setState} update='buscar' value={this.state.buscar} />
                    <Estados status={this.status} estatus={['CANCELADO', 'PENDIENTE', 'PROCESADA']} setData={d => { this.status = d; this.forceUpdate() }} />
                    <InputGroup.Append>
                        <Button variant="primary">
                            <i className="fa fa-search" /> BUSCAR
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </Col>
            {this.Actualizar()}
        </Row>
    }
    filter() {
        let itens = this.ordenar();
        return itens.filter((c: GeneralFila) => {
            if (c.status !== this.status && this.status !== -1) return false;
            return true;
        });
    }
    Title() {
        return <h4 className='mr-auto'>BUSCAR COTIZACION</h4>
    }
    Header() {
        const { Th } = this;
        return <tr className="info">
            <Th label='codigo' width='100px'>N°</Th>
            <Th label='fecha' width='90px'>FECHA</Th>
            <Th label='nombre' width='auto'>CLIENTE</Th>
            <Th width='100px'>ESTATUS</Th>
            <Th width='135px'>ACCIONES</Th>
        </tr>
    }
    Row(c: GeneralFila) {
        return <FilaGeneraModal key={c.codigo + c.status} data={c} type='Cotizacion' add={this.add.bind(this)} />
    }
    add(c: GeneralFila) {
        this.props.add(c);
        this.props.close();
    }
    getItens() { return this.context.Cotizaciones; }
    set() { this.context.actCotizacion(); }
    getEstado() { return this.context.estCotizacion; }
    reset() { this.context.resetVenta() }
}
BuscarCotizacion.contextType = CotizacionContext;
export default BuscarCotizacion