import { useContext } from "react";
import { PageContext } from "../PageContext";
import { conPanel } from "../../Panel/Models";
import { FormControl } from "react-bootstrap";

export default function SelectPage({ panel: { id, pagina } }: { panel: any }) {
    const { pages } = useContext(PageContext);
    const set = (e: React.ChangeEvent<HTMLSelectElement>) => { conPanel().page(id, e.currentTarget.value, () => { }) }
    return pages.length === 1 ? <></> :
        <FormControl as='select' style={{ width: '200px' }} onChange={set} value={pagina}>
            {pages.map(c => <option key={"user" + c.id} value={c.id}>{c.title}</option>)}
        </FormControl>
}