import ListaCompra from '../App/Compra/Facturas';
import NuevaCompra from '../App/Compra/Facturas/Nuevo';
import ListaOrden from '../App/Compra/Orden';
import NuevaOrden from '../App/Compra/Orden/Nuevo';
import ListCreditos from '../App/Compra/Notas/Credito';
import ListDebito from '../App/Compra/Notas/Debito';
import NuevaNota from '../App/Compra/Notas/Nuevo';
import { Route } from "react-router-dom"
import ComprasProvider from '../Context/Compras'
import OrdenesProvider from '../Context/Compras/Ordenes'
import ProveedoresProvider from '../Context/Compras/Proveedor'
import ListaProveedores from '../App/Compra/proveedores';
import UnAutorization from '../Utiles/Componentes/UnAutorization';
import { AuthSome } from '../Config/Permisos'

export default function MasterCompras() {
    return (<ProveedoresProvider>
        <Route path={["/orden", "/compra"]} component={OrdenContent} />
        <Route path="/proveedores" component={Proveedor} />
    </ProveedoresProvider>);
}
function OrdenContent() {
    return <OrdenesProvider>
        <Route path="/compra" component={Compras} />
        <Route path="/orden" component={Orden} />
    </OrdenesProvider>
}
function Orden() {
    if (AuthSome(['ORDEN']))
        return <>
            <Route path="/orden/lista"><ListaOrden /></Route>
            <Route path="/orden/nueva"><NuevaOrden /></Route>
        </>
    return <UnAutorization />
}
function Compras() {
    if (AuthSome(['COMPRA']))
        return <ComprasProvider>
            <Route path="/compra/lista"><ListaCompra /></Route>
            <Route path="/compra/nueva"><NuevaCompra /></Route>
            <Route path={["/compra/credito/lista", "/compra/credito/lista", "/compra/{id}/nota/nueva"]} component={Notas} />
        </ComprasProvider>
    return <UnAutorization />
}
function Notas() {
    if (AuthSome(['NOTASCOMPRAS']))
        return <>
            <Route path="/compra/credito/lista"><ListCreditos /></Route>
            <Route path="/compra/credito/lista"><ListDebito /></Route>
            <Route path="/compra/{id}/nota/nueva"><NuevaNota /></Route>
        </>
    return <UnAutorization />
}
function Proveedor() {
    if (AuthSome(['PROVEEDOR']))
        return <ListaProveedores />
    return <UnAutorization />
}