import React from "react"
import { Button, Col, InputGroup, Card, Row, FormControl } from "react-bootstrap"
import { InputDate } from "../../Utiles/Inputs";
interface Props {
    date1: string
    date2: string
    aux: string
    setDate: (d: any) => void
}
let date = new Date();
export default class Fechas extends React.Component<Props> {
    render() {
        let { aux, date2, date1, setDate } = this.props;
        return <Card>
            <Card.Header className='bg-info'>
                <h6>
                    SELECCIONES RANGO DE TIEMPO
                </h6>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col xs={12}>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend><Button className='pull-left' variant={(aux === "rango") ? 'success' : 'default'}
                                style={{ width: '300px' }} onClick={() => {
                                    setDate({
                                        aux: "rango",
                                        date1: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + (date.getDate() + 1),
                                        date2: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + (date.getDate() + 1)
                                    })
                                }}>
                                POR RANGO DE FECHAS
                            </Button></InputGroup.Prepend>
                            <InputGroup.Prepend><InputGroup.Text>DEL</InputGroup.Text></InputGroup.Prepend>
                            <InputDate disabled={aux !== 'rango'} setData={setDate} update="date1" value={date1} />
                            <InputGroup.Text>AL</InputGroup.Text>
                            <InputDate disabled={aux !== 'rango'} setData={setDate} update="date2" value={date2} />
                        </InputGroup>
                    </Col><br /><br /><br />
                    <Col xs={12}>
                        <InputGroup style={{ width: '100%' }} className="mb-3">
                            <Button className='pull-left' variant={(aux === "mes") ? 'success' : 'default'}
                                style={{ width: '160px' }} onClick={() => {
                                    setDate({
                                        aux: "mes",
                                        date1: date.getFullYear() + '',
                                        date2: (date.getMonth() + 1) + ''
                                    })
                                }}>
                                POR MES
                            </Button>
                            <FormControl as='select' disabled={aux !== 'mes'}  onChange={this.setChangeMont.bind(this)} value={date2}>
                                {(aux === "mes") ? <>
                                    <option value='1'>ENERO </option>
                                    <option value='2'>FEBRERO </option>
                                    <option value='3'>MARZO </option>
                                    <option value='4'>ABRIL </option>
                                    <option value='5'>MAYO </option>
                                    <option value='6'>JUNIO </option>
                                    <option value='7'>JULIO </option>
                                    <option value='8'>AGOSTO </option>
                                    <option value='9'>SEPTIEMBRE </option>
                                    <option value='10'>OCTUBRE </option>
                                    <option value='11'>NOVIEMBRE </option>
                                    <option value='12'>DICIEMBRE </option>
                                </> : <></>}
                            </FormControl>
                            <InputGroup.Prepend><InputGroup.Text>DEL</InputGroup.Text></InputGroup.Prepend>
                            <FormControl as='select' disabled={aux !== 'mes'}  onChange={this.setChangeYear.bind(this)} value={date1}>
                                {(aux === "mes") ? <>
                                    {this.getYear()}
                                </> : <></>}
                            </FormControl>
                        </InputGroup>
                    </Col><br /><br /><br />
                    <Col xs={12}>
                        <InputGroup style={{ width: '100%' }} className="mb-3">
                            <Button className='pull-left' variant={(aux === "ano") ? 'success' : 'default'}
                                style={{ width: '170px' }} onClick={() => {
                                    setDate({
                                        aux: "ano",
                                        date1: date.getFullYear() + '',
                                    })
                                }}>
                                POR AÑO
                            </Button>
                            <FormControl as='select' disabled={aux !== 'ano'}  onChange={this.setChangeYear.bind(this)} value={date1}>
                                {(aux === "ano") ? <>
                                    {this.getYear()}
                                </> : <></>}
                            </FormControl>
                        </InputGroup>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    }
    getYear() {
        let date = new Date();
        let yearActual = date.getFullYear();
        let years: any[] = [];
        for (let i = 2017; i < yearActual; i++) {
            years.push(<option value={i}>{i}</option>);
        }
        years.push(<option value={yearActual}>{yearActual}</option>);
        return years;
    }
    setChangeYear(e: React.ChangeEvent<any>) {
        let date1 = e.currentTarget.value
        this.props.setDate({ date1 })
    }
    setChangeMont(e: React.ChangeEvent<any>) {
        let date2 = e.currentTarget.value
        this.props.setDate({ date2 })
    }
}