import { ProductosContext, ProductosType } from '../../../../Context/Producto';
import { ControlNumber } from '../../../../Utiles/FormControl';
import { Col } from 'react-bootstrap';
import { useContext } from 'react';
interface Props {
    valor: number,
    col: number,
    setData: (d: any) => void
}
export function InputBS({ valor, col, setData, error }: Props & { error: any }) {
    return <Col xs={col} className='mt-2'>
        <ControlNumber error={error} after='BS' min={.01} step=".01" update='costo' value={valor} setData={setData} />
    </Col>
}
export function InputDolar({ valor, col, setData, error }: Props & { error: any }) {
    const { tasa } = useContext(ProductosContext) as ProductosType;
    return <Col xs={col} className='mt-2'>
        <ControlNumber error={error} after='USD' min={.01} step=".01" update='costo' value={valor / tasa} setData={({ costo }) => setData({ costo: costo * tasa })} />
    </Col>
}
export function UtilBS({ valor, col, setData, costo, update }: Props & { costo: number, update: string }) {
    return <Col xs={col} className='mt-2'>
        <ControlNumber after='BS' min={.01} step=".01"
            value={getPrecio(valor, costo)}
            setData={d => setData({ [update]: getUtilidad(d, costo) })} />
    </Col>
}
export function UtilDolar({ valor, col, setData, costo, update }: Props & { costo: number, update: string }) {
    const { tasa } = useContext(ProductosContext) as ProductosType;
    return <Col xs={col} className='mt-2'>
        <ControlNumber after='USD' min={.01} step=".01"
            value={getPrecio(valor, costo / tasa)}
            setData={d => setData({ [update]: getUtilidad(d * tasa, costo) })} />
    </Col>
}
function getPrecio(p: number, costo: number) {
    return costo * ((p / 100) + 1);
}
function getUtilidad(p: number, costo: number) {
    return ((p / costo) - 1) * 100;
}