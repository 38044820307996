import React from "react"
import { Button, Table } from "react-bootstrap"
import { toast } from "react-toastify"
import ReactTooltip from "react-tooltip"
import { conTipo, Tipo } from "../../../Models/Productos"
import { InputText } from "../../../Utiles/Inputs"
interface Props {
    update?: () => void
}
export default class NuevoTipo extends React.Component<Props, Tipo> {
    con = conTipo()
    state = { codigo: 0, descripcion: '', estatus: 1, inventario: 3 };
    render() {
        let { descripcion } = this.state
        return <>
            <br />
            <b>NUEVO TIPO DE PRODUCTO</b>
            <Table>
                <tr>
                    <th style={{ width: "calc(100% - 290px)" }}>
                        <ReactTooltip />
                        <InputText setData={this.setState.bind(this)} value={descripcion} update="descripcion" />
                    </th>
                    <th style={{ width: "220px" }}>
                        {this.TipoInventario()}
                    </th>
                    <th style={{ width: "70px" }}>
                        <Button style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} data-tip="EDITAR" variant="primary" onClick={this.nuevo.bind(this)}>
                            <i className="fa fa-edit"></i> GUARDAR
                        </Button>
                    </th>
                </tr>
            </Table>
        </>
    }
    nuevo() {
        let { descripcion } = this.state;
        if (descripcion === '') {
            toast('DEBE COLOCAR UNA DESCRIPCION', { type: toast.TYPE.ERROR })
        } else {
            let { update } = this.props;
            this.con.nuevo(this.state, () => {
                if (update) update();
                this.setState({ codigo: 0, descripcion: '', estatus: 1, inventario: 3 }) ;
                toast('CREADO EXITOSAMENTE', { type: toast.TYPE.SUCCESS })
            });
        }
    }

    TipoInventario() {
        const setStatus = (e: React.ChangeEvent<HTMLSelectElement>) => { this.setState({ inventario: parseInt(e.currentTarget.value) }) }
        return <select onChange={setStatus} className="form-control" value={this.state.inventario} >
            <option value='3'>INVENTARIO FISICO</option>
            <option value='2'>INVENTARIO LOGICO</option>
            <option value='1'>NO GENERA INVENTARIO</option>
        </select >
    }
}