import { useContext, useState, useMemo, memo } from 'react';
import { Button, ButtonGroup, FormControl } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip';
import { ProductosContext, ProductosType, findProVen } from '../../Context/Producto';
import { SeccionContext, SeccionType } from '../../Context/Seccion';
import THFila, { THInput } from '../Componentes/THFila';
import { Detalles } from '../../Models/General';
import { numberFormat } from '../Formatos';
import { ControlText } from '../FormControl';
import { InputNumber } from '../Inputs';
interface Props {
    data: Detalles
    edit: (d: Detalles) => void
    remove: (iten: Detalles) => void
    dolar?: boolean
    venta?: boolean
    nota?: boolean
}
const ProductosVentas = memo(({ data, edit, remove, dolar = false, venta = false, nota = false }: Props) => {
    const { tasa } = useContext(ProductosContext) as ProductosType;
    const { data: { User: { nivel } } } = useContext(SeccionContext) as SeccionType;
    const p = useMemo(() => findProVen(data, dolar), [data, dolar]);
    const { codigo, descripcion, costo, cantidad, monto1, monto2, monto3, inventario } = p;
    const [open, setOpen] = useState(false);
    // eslint-disable-next-line
    const { index, precio, unidades, comentario } = data;
    const pre = (dolar) ? precio / tasa : precio;
    const [className, tip] = valStock(p, pre, unidades, venta, nota);
    return <>
        <tr className={className}>
            <THFila tip={tip} width='70px'>{index}</THFila>
            <THFila tip={tip} width='110px'>{codigo}</THFila>
            <THFila tip={tip} width='auto'>{descripcion}</THFila>
            <THInput tip={tip} width='120px'><InputNumber min={0.01} value={unidades} setData={unidades => edit({ ...data, unidades })} /></THInput>
            <THInput tip={tip} width='120px'><FormControl style={{ textAlign: 'right' }} disabled value={(inventario === 1) ? 'NO APLICA' : numberFormat(cantidad)} /></THInput>
            <THInput tip={tip} width='180px'>
                <InputNumber value={pre} min={(nivel === 0) ? costo : monto1} step='0.01' list={costo + 'list'}
                    data-tip={'MINIMO: ' + numberFormat((nivel === 0) ? costo : monto1)}
                    setData={p => edit({ ...data, precio: (dolar) ? p * tasa : p })} />
                <datalist id={costo + 'list'} key={costo + 'list' + dolar}>
                    <option>{monto1.toFixed(2)}</option>
                    <option>{monto2.toFixed(2)}</option>
                    <option>{monto3.toFixed(2)}</option>
                </datalist>
            </THInput>
            <THInput tip={tip} width='230px'><InputNumber disabled setData={() => { }} value={parseFloat(pre.toFixed(2)) * unidades} update="p" /></THInput>
            <THInput tip={tip} width='110px'>
                <ReactTooltip />
                <ButtonGroup>
                    {venta ? <></> : <Button variant="info" onClick={() => setOpen(!open)} data-tip="DEJAR NOTA">
                        <span className="fa fa-edit"></span>
                    </Button>}
                    <Button variant="danger" onClick={() => remove(data)} data-tip="QUITAR PRODUCTO">
                        <i className="fa fa-ban"></i>
                    </Button>
                </ButtonGroup>
            </THInput>
        </tr><tr></tr>
        {open ? <tr className={className}>
            <td colSpan={7}>
                <ControlText maxLength={250} before="NOTA INTERNA" setData={comentario => edit({ ...data, comentario })} value={comentario} />
            </td>
        </tr> : <tr></tr>}
    </>
})

const valStock = ({ cantidad, inventario, monto1 }: any, pre: number, unidades: number, venta: boolean, nota: boolean) => {
    if (nota || !venta) return ['', ''];
    const valStock = ((unidades > cantidad) && (inventario !== 1));
    const valPrecio = (monto1 > pre);
    const className = valStock ? 'bg-danger' : valPrecio ? 'bg-warning' : undefined;
    const tip = valStock ? 'NO TIENES DISPONIBILIDAD SUFICIENTE' : valPrecio ? 'ESTAS POR DEBAJO DEL PRECIO MINIMO' : undefined;
    return [className, tip]
}

export default ProductosVentas;