import { icon, UseIcon } from "@victorequena22/icon-selector-bootstrap";
 
export function Icon2({ font, fill, icon, back, border, aux }: { aux: string, border: boolean, fill: string, font: number, icon: icon, back: string }) {
    return <div className="text-center mr-auto ml-auto" style={{
        width: `${font * 1.3}rem`, height: `${font * 1.3}rem`,
        borderRadius: back === 'circle' ? `${font}rem` : back === 'rounded' ? `${font * .3}rem` : undefined,
        background: back !== 'none' ? aux : undefined, padding: `${font * .1}rem`,
        border: (border && back !== 'none') ? `${font * .05}rem solid ${fill}` : undefined,
    }}>
        <UseIcon icon={icon} width={`${font}rem`} height={`${font}rem`} fill={fill} />
    </div>
}