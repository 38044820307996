import { GeneralFila } from '../../../../Models/General';
import AuxiliarVentas from '../../../../Utiles/Ventas/AuxiliarVentas'
import { Cotizacion, conCotizacion, sentCotizacion, updatePrecio } from '../../../../Models/Ventas'
import { Button, ButtonGroup, Col } from 'react-bootstrap';
import { ProductosContext, ProductosType } from '../../../../Context/Producto';
import { Producto } from '../../../../Models/Productos';
import BuscarCotizacion from '../BuscarCotizacion';
import BuscarGuardado from '../BuscarGuardado';
import { toast } from 'react-toastify';
import { don } from '@victorequena22/conneccion';
import { ProductosModal } from '@/Utiles/Prototipos/AuxiliarNuevo/Interface';
class AuxiliarCotizacion extends AuxiliarVentas {
    productos: ProductosModal = 'Cotizacion';
    nuevo = true;
    venta = false;
    con = conCotizacion();
    BotonesLeft = () => {
        const { guardar, cargar, copiar, state: { aux, aux2 } } = this;
        return <Col xs={6}>
            <BuscarCotizacion open={aux} close={() => this.setState({ aux: false })} add={copiar.bind(this)} />
            <BuscarGuardado open={aux2} close={() => this.setState({ aux2: false })} add={cargar.bind(this)} />
            <Button onClick={() => this.setState({ aux: true })}
                data-class="toolbody" data-html="true"
                data-tip="COPIAR COTIZACION" variant="primary">
                <i className="fa fa-copy" />
            </Button>
            <ButtonGroup style={{ marginLeft: '15px' }}>
                <Button variant="info" onClick={guardar.bind(this)} data-tip="GUARDAR">
                    <i className="fa fa-save" />
                </Button>
                <Button variant="warning" onClick={() => this.setState({ aux2: true })} data-tip="CARGAR">
                    <i className="fa fa-folder-open" />
                </Button>
            </ButtonGroup>
        </Col>
    }
    createProducto = (p: Producto) => {
        const index = this.nextIndex();
        this.addProductos({ ...p, index, unidades: 1, precio: p.costo * (1 + (1 * p.precio2 / 100)), comentario: "" });
    }
    copiar(d: GeneralFila) {
        const { tasa, dolar } = this.context as ProductosType;
        this.con.detalle(d.codigo, data => {
            data.codigo = 0;
            this.setData({ ...data, detalles: [] })
            this.setData(updatePrecio(data, tasa, dolar));
        });
    }
    cargar({ codigo }: GeneralFila) {
        const { tasa, dolar } = this.context as ProductosType;
        this.con.get(don + '/api/Cotizacion/cargar/' + codigo, data => {
            data.plantilla = data.codigo;
            data.codigo = 0;
            this.setData({ ...data, detalles: [] })
            this.setData(updatePrecio(data, tasa, dolar));
        })
    }
    context: any = {};
    guardar() {
        this.con.post(don + '/api/Cotizacion/guardar', sentCotizacion(this.props.data as Cotizacion, this.context.impuesto), data => {
            this.setData({ plantilla: data });
            toast("DATOS GUARDADOS EN N° " + data, { type: toast.TYPE.SUCCESS })
        }, _error => {
            toast("ERROR AL CONECTAR CON SEL SERVIDOR", { type: toast.TYPE.ERROR })
        });
    }
}
AuxiliarCotizacion.contextType = ProductosContext;
export default AuxiliarCotizacion;