import React from "react";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Button } from "react-bootstrap";

export default class UsuariosExcel extends React.Component<{ usuarios: any[] }, {}>{
    _export = React.createRef<any>();
    excelExport() {
        if (this._export.current)
            this._export.current.save();
    };
    render() {
        const { usuarios } = this.props;
        if (usuarios.length === 0) return <></>
        return <>
        <Button variant='success' onClick={this.excelExport.bind(this)} style={{marginRight:'15px'}}>
        <i className="fa fa-file-excel"/>
        </Button>
        <ExcelExport data={usuarios} ref={this._export} fileName="Usuarios">
        <ExcelExportColumn field="nombre" title="NOMBRE" width={500} />
        <ExcelExportColumn field="correo" title="CORREO" width={500} />
        <ExcelExportColumn field="rol" title="ROL" width={120} />
        </ExcelExport></>
    }

}