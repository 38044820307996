import { Panel } from './Panel';
import { Config } from './Config';
import { icon, defaultIcon } from "@victorequena22/icon-selector-bootstrap";
import { GalIten, StateS, Iten, defaultIten } from '../../ProtoPanes/GalIten';
import { BlockElement, defaultText, getText, setText } from '../../ProtoPanes/DefaultData';
interface I {
    title: BlockElement[];
    icon: icon;
    aux: string;
    fill: string;
}
interface S {
    cols: 1 | 2 | 3,
    color: string;
    border: boolean;
    edit: 'header' | 'body';
    back: 'none' | 'circle' | 'rounded' | 'rectangle';
    orientation: 'row' | 'row-reverse',
}
export type State = StateS<I> & S;
export type iten = Iten & I;
const aux: I = {
    icon: defaultIcon,
    aux: '#cccc00', fill: '#00bbbb', title: defaultText
}
export const dIten: Iten & I = { ...aux, ...defaultIten, back: '#aaaaaa', color: 'cccc00' }
export class Preguntas extends GalIten<S, I> {
    constructor(p: any) {
        super(p);
        this.initGal({ edit: 'header', color: '#f0f0f0', border: true, back: 'circle', orientation: 'row', cols: 2 }, [
            { ...dIten, id: 1 },
            { ...dIten, id: 2 },
            { ...dIten, id: 3 },
            { ...dIten, id: 4 },
            { ...dIten, id: 5 },
            { ...dIten, id: 6 }
        ]);
        if (p.data !== undefined) setTimeout(() => this.setData(p.data.props), 10)
    }
    componentDidMount(): void {
        super.componentDidMount();
        const { data } = this.props;
        if (data !== undefined) {
            const { itens, props: { cols } } = data;
            this.setData({
                itens: itens.map(({ props, data, html: h, indice }: any) => {
                    const html = setText(h);
                    const title = setText(props.title);
                    return { ...props, ...data, html, title, id: indice };
                })
            })
            setTimeout(() => this.setData({ cols }), 1000)
        }
    }
    getText = () => {
        const { itens, seleted, edit } = this.state;
        const res: { html: BlockElement[], id: number } = { html: [], id: 0 };
        for (let i = 0; i < itens.length; i++)
            if (seleted === itens[i].id) {
                if (edit === 'header') {
                    res.id = 2000 + seleted;
                    res.html = itens[i].title;
                    return res;
                } else {
                    res.id = 3000 + seleted;
                    res.html = itens[i].html;
                    return res;
                }
            }
        return res;
    }
    setText = (p: { text: any[] }) => {
        if (this.state.edit === 'header') {
            this.setIten({ title: getText(p.text) });
        } else {
            this.setIten({ html: getText(p.text) });
        }
    }
    Config = () => <Config
        save={this.save}
        key='config'
        iten={this.getIten(aux)}
        setIten={this.setIten}
        setConfig={this.setData}
        title={this.state.title}
        setTitle={this.setTitle}
        removeItem={this.removeItem}
        itenUp={this.itenUp}
        itenDown={this.itenDown}
        addIten={() => this.addIten(aux)}
        copy={this.copy}
        config={this.state} />
    Panel = () => <Panel key='panel' config={this.state} setConfig={this.setData} />
    saveItens = () => this.state.itens.map(p => {
        let { title, icon, aux, fill, color, id, html, back } = p
        const data = JSON.stringify({});
        console.log(p)
        const props = JSON.stringify({ icon, aux, fill, color, id, back, title: getText(title) });
        return { data, props, html: JSON.stringify(getText(html)), id };
    })
    getDataSave = () => {
        const { title, color, back, orientation, cols, border } = this.state;
        const data = JSON.stringify({});
        const props = JSON.stringify({ color, back, orientation, cols, border });
        return { title, html: '', data, props, itens: this.saveItens(), type: 'preguntas' };
    }
}