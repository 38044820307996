
import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import FechaTh from './FechaTh';
import MontoTh from './MontoTh';
import NombreTh from './NombreTh';
import StatusTh from './StatusTh';
import DetallesTh from './DetallesTh';
import BTNCancelar from './Botones/BTNCancelar';
import BTNImprimir from './Botones/BTNImprimirDolar';
import { tipoData } from '../../../Models/General';
import { ConexionCompleta } from '@victorequena22/conneccion';
interface Props {
    data: any
    con: ConexionCompleta
}
export default class FilaProto<P, S> extends React.Component<Props & P, S> {
    con: ConexionCompleta;
    label: string;
    data: any;
    type: tipoData = 'Factura';
    constructor(p: Props & P) {
        super(p);
        this.label = '';
        this.con = p.con;
        this.data = p.data;
        this.type = 'Factura';
        this.tip = this.tip.bind(this)
        this.Monto = this.Monto.bind(this)
        this.Status = this.Status.bind(this)
        this.Nombre = this.Nombre.bind(this)
        this.Botones = this.Botones.bind(this)
        this.Detalles = this.Detalles.bind(this)
        this.cancelado = this.cancelado.bind(this)
    }

    render() {
        const { Detalles, Nombre, Monto, Status, Botones, data: { fecha } } = this;
        return <tr>
            <Detalles />
            <FechaTh fecha={fecha} />
            <Nombre />
            <Monto />
            <Status />
            <Botones />
        </tr>
    }
    Detalles() {
        const { con, label, data: { codigo } } = this;
        return <DetallesTh con={con} label={label} id={codigo} />;
    }
    Nombre() {
        const { tip, data: { nombre } } = this;
        return <NombreTh tip={tip()} nombre={nombre} />
    }
    Monto() {
        const { data: { monto } } = this;
        return <MontoTh monto={monto} />
    }
    Status() {
        const { type, data: { status } } = this;
        return <StatusTh type={type} status={status} />
    }
    Botones() {
        const { type, con, label, cancelado, data: { status, codigo } } = this;
        return <th style={{ minWidth: "135px", maxWidth: "135px" }}>
            <ButtonGroup className="float-right">
                <BTNImprimir label={label} id={codigo} tipo={type} />
                {(status !== 1) ? <></> : <BTNCancelar con={con} label={label} id={codigo} msn={cancelado()} />}
            </ButtonGroup>
        </th>
    }
    tip() {
        return '';
    }
    cancelado() {
        return 'COMPRA N° ' + this.data.codigo + ' CANCELADA ESXITOSAMENTE';
    }
}