import { useState } from "react";
import BuscarProducto, { PropsBuscarProducto } from "../BuscarProducto";
import { Button } from "react-bootstrap";




export default function BuscarProductoBoton(p: PropsBuscarProducto) {
    const [o, s] = useState(false);
    return <>
        <BuscarProducto  {...p} open={o} close={() => s(false)} />
        <Button data-tip="BUSCAR PRODUCTO" onClick={() => s(true)} variant="primary">
            <span className="fa fa-search"></span>
        </Button>
    </>
}