import { SeccionContext, SeccionType } from '../../Context/Seccion';
import { Menus, MenuIten } from '../../Utiles/Componentes/Menus';
import Cotizacion from '../../App/Config/Impreciones/Cotizacion';
import Factura from '../../App/Config/Impreciones/Factura';
import Reporte from '../../App/Config/Impreciones/Reporte';
import Ajuste from '../../App/Config/Impreciones/Ajuste';
import Compra from '../../App/Config/Impreciones/Compra';
import Orden from '../../App/Config/Impreciones/Orden';
import Nota from '../../App/Config/Impreciones/Nota';
import { useContext } from 'react';
import { Ruta } from './index';
const PP = ['ORDEN', 'COMPRA', 'COTIZACION', 'NOTA', 'FACTURA', 'REPORTES', 'AJUSTE'];
export function MenuImpresiones() {
    const { data: { Permisos } } = useContext(SeccionContext) as SeccionType;
    if (Permisos.some(p => PP.some(p2 => p2 === p)))
        return <Menus title='CONFIGURACIONES DE IMPRESIÓN'>
            <MenuIten p={Permisos} to='/config/orden' permiso='ORDEN' >ORDEN DE COMPRA</MenuIten>
            <MenuIten p={Permisos} to='/config/compra' permiso='COMPRA' >COMPRA</MenuIten>
            <MenuIten p={Permisos} to='/config/cotizacion' permiso='COTIZACION' >COTIZACION</MenuIten>
            <MenuIten p={Permisos} to='/config/nota' permiso='NOTA' >NOTA DE ENTREGA</MenuIten>
            <MenuIten p={Permisos} to='/config/factura' permiso='FACTURA' >FACTURA</MenuIten>
            <MenuIten p={Permisos} to='/config/ajustes' permiso='AJUSTE' >AJUSTE DE INVENTARIO</MenuIten>
            <MenuIten p={Permisos} to='/config/reportes' permiso='REPORTE' >REPORTES</MenuIten>
        </Menus>
    return <></>;
}
export function RutasImpresiones() {
    return <>
        <Ruta path="/config/orden" component={Orden} permiso='ORDEN' />
        <Ruta path="/config/compra" component={Compra} permiso='COMPRA' />
        <Ruta path="/config/cotizacion" component={Cotizacion} permiso='COTIZACION' />
        <Ruta path="/config/nota" component={Nota} permiso='NOTA' />
        <Ruta path="/config/factura" component={Factura} permiso='FACTURA' />
        <Ruta path="/config/ajustes" component={Ajuste} permiso='AJUSTE' />
        <Ruta path="/config/reportes" component={Reporte} permiso='REPORTE' />
    </>
}