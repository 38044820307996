import { ButtonGroup } from 'react-bootstrap';
import { ButtonConfig, TitleCard, CardConfig, RowConfig } from '@victorequena22/component-bootstrap';
import { sizeWidth } from '../Component';

export default function MenuZise({ size, setSize }: { size: string, setSize: (s: sizeWidth) => void }) {
    return <CardConfig className='ml-auto' width='193px'>
        <RowConfig>
            <ButtonGroup size='sm'>
                <ButtonConfig tip='TAMAÑO PEQUENO(CELULARES)' active={size === '570px'} action={() => setSize('570px')} icon='fas fa-mobile-alt' />
                <ButtonConfig tip='TAMAÑO PEQUENO(TABLET VERTICAL)' active={size === '780px'} action={() => setSize('780px')} icon='fas fa-tablet-alt' />
                <ButtonConfig tip='TAMAÑO MEDIANO(TABLET HORIZONTAL)' active={size === '990px'} action={() => setSize('990px')} icon='fas fa-tablet-alt fa-rotate-90' />
                <ButtonConfig tip='TAMAÑO MEDIANO(LAPTOP)' active={size === '1080px'} action={() => setSize('1080px')} icon='fas fa-chalkboard' />
                <ButtonConfig tip='TAMAÑO GRANDE(MONITOR 17")' active={size === '1320px'} action={() => setSize('1320px')} icon='fas fa-desktop' />
                <ButtonConfig tip='TAMAÑO GRANDE(MONITOR 19" O MAS)' active={size === '1440px'} action={() => setSize('1440px')} icon='fas fa-tv' />
            </ButtonGroup>
        </RowConfig>
        <TitleCard>RESOLUCION</TitleCard>
    </CardConfig>
}