import { Col, Card, Row, Button, InputGroup, Accordion, Spinner } from "react-bootstrap";
import { InputText } from "../Inputs";
import { conCotizacion } from "../../Models/Ventas";
import { EstadoNull } from "../../Models/General";
import { ProductosContext, ProductosType } from "../../Context/Producto";
import { Tabla } from "@victorequena22/tabla-bootstrap";
class CardBuscador extends Tabla {
    permiso = '';
    con: any = conCotizacion();
    tasa = true;
    constructor(p: any) {
        super(p);
        this.Avansado = this.Avansado.bind(this);
        this.Actualizar = this.Actualizar.bind(this);
        this.Title = this.Title.bind(this);
    }
    Title() {
        return <><Button className="float-right" onClick={() => { this.setState({ modal: true }) }}> <i className="fa fa-edit"></i> NUEVO</Button></>;
    }
    Avansado() { return <></> }
    render() {
        this.dolar();
        let { Body, Buscador, Avansado, Title } = this;
        return <Card as={Accordion}>
            <Card.Header className='bg-info d-flex'>
                <Title />
            </Card.Header>
            <Card.Body>
                <Buscador />
                <Accordion.Collapse eventKey="0">
                    <Card>
                        <Card.Body>
                            <Avansado />
                        </Card.Body>
                    </Card>
                </Accordion.Collapse>
                <Col md={12}>
                    <Body />
                </Col>
            </Card.Body>
        </Card>
    }
    Buscador() {
        return <Row className='d-flex' style={{ paddingBottom: '15px' }}>
            <Col md={9} className='mr-auto'>
                <InputGroup >
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            {this.buscarLabel}
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <InputText upper setData={this.setState} update='buscar' value={this.state.buscar} />
                    <InputGroup.Append>
                        <Button variant="primary">
                            <i className="fa fa-search"></i> BUSCAR
                        </Button>
                        <Button as={Accordion.Toggle} eventKey="0" variant='default' data-tip="BUSQUEDA DETALLADA" onClick={() => { this.setState({ open: !this.state.open }) }}>
                            <i className="fa fa-wrench"></i> DETALLADO
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </Col>
            {this.Actualizar()}
        </Row>
    }
    Actualizar() {
        const { status, hora } = this.getEstado();
        return <Col md={3} className='mr-auto d-flex flex-row-reverse'>
            <Button size='sm' onClick={this.set.bind(this)} disabled={status === 2} data-tip="ACTUALIZAR LISTA" variant="info">
                {(status === 2) ? <><Spinner animation="border" size='sm' role="status" /></> : <><i className="fa fa-refresh"></i> ACTUALIZAR</>}
            </Button>
            <div style={{ fontSize: '12px', width: "200px", paddingLeft: '15px' }}>
                <strong>ACTUALIZADO:</strong> <br />
                {hora}
            </div>
        </Col>
    }
    set() { this.con.cargarListaWeb((itens: any) => this.setState({ itens })) }
    componentDidMount() { this.set(); }
    getEstado() { return EstadoNull }
    dolar() {
        const { dolar, tasa } = this.context as ProductosType;
        if (tasa !== 0 && this.tasa) {
            this.setState({ dolar });
            this.tasa = false;
        }
    }
    componentWillUnmount() {
        this.tasa = true;
    }
}
CardBuscador.contextType = ProductosContext;
export default CardBuscador;