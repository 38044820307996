/* eslint-disable jsx-a11y/anchor-is-valid */
import MenuIten from './MenuIten'
import { Link } from "react-router-dom"
import { getImagenURL } from '../Utiles/Formatos';
import { useContext } from 'react';
import { MenuContext } from './MenuContext';
import { MobileUserAgent } from '@victorequena22/utiles';

export default function Menu() {
    const { open } = useContext(MenuContext);
    return <aside className="main-sidebar sidebar-dark-primary" style={{ maxHeight: 'calc(100vh - 170px)' }}>
        <Link to="/" className="brand-link bg-success" style={{ width: '100%', height: '57px' }}>
            <img width={33} height={33} src={getImagenURL("/logos/logo.svg")} alt="logo" className="brand-image img-circle" />
            <img src={getImagenURL("/logos/texto.svg")} alt="letras" className="brand-image brand-text" style={{ transitionDelay: '.2s' }} />
        </Link>
        <div className="sidebar">
            <nav className="mt-2">
                <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    <li className="nav-item">
                        <Link to="/" className="nav-link">
                            <i className="nav-icon fa fa-home"></i> <p>INICIO</p>
                        </Link>
                    </li>
                    <MenuIten to="/orden/lista" permiso="ORDEN">
                        <i className="nav-icon fa fa-paperclip"></i> <p style={{ width: "200px" }}> ORDENES DE COMPRA </p>
                    </MenuIten>
                    <MenuIten to="/compra/lista" permiso="COMPRA">
                        <i className="nav-icon fa fa-shopping-cart"></i> <p> COMPRAS </p>
                    </MenuIten>
                    <MenuIten to="/cotizacion/lista" permiso="COTIZACION">
                        <i className="nav-icon fa fa-calculator"></i> <p> COTIZACIONES </p>
                    </MenuIten>
                    <MenuIten to="/nota/lista" permiso="NOTA">
                        <i className="nav-icon fa fa-paper-plane"></i> <p> NOTAS DE ENTREGA </p>
                    </MenuIten>
                    <MenuIten to="/factura/lista" permiso="FACTURA">
                        <i className="nav-icon fa fa-tag"></i> <p> FACTURAS </p>
                    </MenuIten>
                    <MenuIten to="/proveedores" permiso="PROVEEDOR">
                        <i className="nav-icon fa fa-truck"></i> <p> PROVEEDORES</p>
                    </MenuIten>
                    <MenuIten to="/clientes" permiso="CLIENTE">
                        <i className="nav-icon fa fa-user"></i><p> CLIENTES</p>
                    </MenuIten>
                    <MenuIten to="/producto" permiso="PRODUCTO">
                        <i className="nav-icon fa fa-laptop"></i> <p> INVENTARIO</p>
                    </MenuIten>
                    <MenuIten to="/producto/ajuste/lista" permiso="AJUSTE">
                        <i className="nav-icon fa fa-th-large"></i> <p>AJUSTES</p>
                    </MenuIten>
                    <MenuIten to="/usuarios" permiso="USUARIO">
                        <i className="nav-icon fa fa-users"></i><p> USUARIOS</p>
                    </MenuIten>
                    <MenuIten to="/reporte" permiso="REPORTE">
                        <i className="nav-icon fa fa-chart-line"></i><p> REPORTES</p>
                    </MenuIten>
                    <MenuIten to="/config" permiso="CONFIG">
                        <i className="nav-icon fa fa-wrench"></i><p> CONFIGURACION</p>
                    </MenuIten>
                    {MobileUserAgent() ? <li className="nav-item mt-2" onClick={open}>
                        <a className="nav-link fa-2x" >
                            <i className="fas fa-caret-down fa-rotate-90" /> <p>CERRAR</p>
                        </a>
                    </li> : <></>}
                </ul>
            </nav>
        </div>
    </aside>
}