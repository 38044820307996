import { SizeViewSelector, Title, CardMain, TitleCard, Guardar, CardConfig, RowConfig } from '../../Component';
import { TBElement } from '../../Component/SlateCustom';
import { NotaMenu, FormColor } from '../../Component/Commons';
import { State } from './index'
import { InputGroup } from 'react-bootstrap';
import { InputNumber } from '@victorequena22/component-bootstrap';
interface Props { save: () => void, title: string; config: State; setTitle: (d: string) => void; setConfig: (d: any) => void }
export function Config({ save, title, config, setTitle, setConfig }: Props) {
    const { size, texto, padding } = config;
    return <CardMain>
        <Guardar guardar={save} />
        <CardConfig width='256px' >
            <RowConfig>
                <Title setData={setTitle} title={title} width='250px' />
            </RowConfig>
            <FormColor config={config} setConfig={setConfig} />
            <RowConfig top={2}>
                <InputGroup data-tip='ESPACIO ENTRE LAS IMAGENES' className='input-group-sm' style={{ height: '24px', width: 70 }}>
                    <InputGroup.Prepend style={{ height: '24px', width: '38px' }}>
                        <InputGroup.Text style={{ height: '24px', width: '38px' }}>
                            <i style={{ borderBlock: 'solid 3px currentColor', width: '12px' }} className="fas fa-arrows-v"></i>
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <InputNumber step={1} min={0} decimal={0} style={{ height: 24 }} value={padding} setData={padding => setConfig({ padding })} />
                </InputGroup>
            </RowConfig>
            <TitleCard>CONFIGURACION GENERAL</TitleCard>
        </CardConfig>
        <NotaMenu config={config} setConfig={setConfig} />
        {texto ? <TBElement /> : <></>}
        <SizeViewSelector setSize={(d) => setConfig({ size: d })} size={size} />
    </CardMain >
}