import FilaShort from './FilaShort';
import { Link } from 'react-router-dom';
import { CardBuscador } from "@victorequena22/tabla-bootstrap";
import { conShort, Shor } from '../Models';
import { ShortContext } from '../Context/Shorts';
class ListShorts extends CardBuscador {
    con = conShort();
    placeholder = "BUSCAR CLAVE"; buscarEn = ["html", "clave", 'descripcion'];
    Title() {
        return <>
            <h4 className='mr-auto'><i className="fa fa-search"></i> BUSCAR ATAJO</h4>
            <Link to="/panel/short/nueva" className="btn btn-primary"><span className="fa fa-plus"></span>NUEVA ATAJO</Link>
        </>
    }
    Header() {
        const { Th } = this;
        return <tr className="info">
            <Th label='clave' width='150px'>CLAVE</Th>
            <Th label='descripcion' width='300px'>DESCRIPCION</Th>
            <Th width='calc(100% - 520px)'>CAMBIO</Th>
            <Th width='70px'> </Th>
        </tr>
    }
    Row(p: Shor) {
        return <FilaShort key={"cot" + p.clave + p.estatus} data={p} />
    }
    getItens() { return this.context.shors; }
    set() {
        this.context.updateShort()
    } context: any = {}
}
ListShorts.contextType = ShortContext;
export default ListShorts
