
import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { Cliente, conCliente } from '../../../Models/Ventas';
import THFila from '../../../Utiles/Componentes/THFila';
import FormCliente from './Form';
// import FormCliente from './FormCliente';

interface Props {
    data: Cliente
    update?: () => void
}
interface State {
    edit: boolean
}
export default class FilaCliente extends React.Component<Props, State>{
    con = conCliente();

    constructor(p: Props) {
        super(p);
        this.state = { edit: false };
    }
    render() {
        return <tr >
            <THFila width='150px'>
                {this.props.data.codigo}
            </THFila>
            <th>
                <ReactTooltip />
                <FormCliente open={this.state.edit}
                    key={'Editcliente' + this.props.data.codigo}
                    title={'EDITAR DATOS DE ' + this.props.data.nombre}
                    cliente={this.props.data}
                    close={() => { this.setState({ edit: false }) }}
                    add={this.props.update} />
                <Button style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: '45vw' }}
                    data-class="toolbody" data-html="true" data-tip={this.tooltipCliente()} variant="link" >
                    {this.props.data.nombre}
                </Button>
            </th>
            <THFila width='120px'>
                {this.getStatus()}
            </THFila>
            <th style={{ minWidth: "135px", maxWidth: "135px" }}>
                <ButtonGroup className="float-right">
                    <Button data-tip="EDITAR" variant="primary"
                        onClick={() => { this.setState({ edit: true }) }}>
                        <i className="fa fa fa-edit"></i>
                    </Button>
                    {(this.props.data.estatus === 1) ?
                        <Button data-tip="ELIMINAR" variant="danger"
                            onClick={this.cancelar.bind(this)} >
                            <i className="fa fa-ban"></i>
                        </Button> : <Button data-tip="ACTIVAR" variant="success"
                            onClick={this.cancelar.bind(this)} >
                            <i className="fa fa-check"></i>
                        </Button>}
                </ButtonGroup>
            </th>
        </tr >
    }
    getModal() {
        return <></>;
    }
    getStatus() {
        if (this.props.data.estatus === 1)
            return <span className="badge badge-success">ACTIVO</span>
        return <span className="badge badge-danger">INACTIVO</span>
    }
    tooltipCliente() {
        let cot = this.props.data
        return "<h9 style='color: #5bc0de'>DATOS DE CONTACTO</h9><br />" +
            "<b>PERSONA DE CONTACTO:</b><br />" +
            cot.contacto + "<br />" +
            "<b>CORREO ELECTRONICO:</b><br />" +
            cot.correo + "<br />" +
            "<b>TELEFONO:</b><br />" +
            cot.telefono
    }
    close() {
        this.setState({ edit: false });
    }
    cancelar() {
        this.con.cancelar(this.props.data.codigo, this.props.update)
        this.close();
    }
}