
import React, { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { conOrden } from '../../../Models/Compra';
import FilaCompras from '../../../Utiles/Compra/FilaCompras';
import Seguimientos from '../../../Utiles/Componentes/Seguimiento';
import BTNCancelar from '../../../Utiles/Prototipos/Fila/Botones/BTNCancelar';
import BTNImprimir from '../../../Utiles/Prototipos/Fila/Botones/BTNImprimirDolar';
function BTNSegiomiento({ id }: { id: number }) {
    const [open, setOpen] = useState(false);
    return <>
        <Button data-tip="VER IMPRESION" variant="outline-dark"
            onClick={() => setOpen(true)}>
            <i className="fa fa-eye"></i>
        </Button>
        <Seguimientos key={'SeguimientosOrden' + id} type='Orden'
            close={() => setOpen(false)} open={open} id={id} />
    </>
}
export default class FilaOrden extends FilaCompras {
    con = conOrden();
    constructor(p: any) {
        super(p);
        this.label = 'ORDEN DE COMPRA';
        this.type = 'Orden';
    }
    Botones() {
        const { type, con, label, cancelado, data: { status, codigo } } = this;
        return <th style={{ minWidth: "135px", maxWidth: "135px" }}>
            <ButtonGroup className="float-right">
                <BTNSegiomiento id={codigo} />
                <BTNImprimir label={label} id={codigo} tipo={type} />
                {(status !== 1) ? <></> :
                    <BTNCancelar con={con} label={label} id={codigo} msn={cancelado()} />}
            </ButtonGroup>
        </th>
    }
    cancelado() {
        return 'ORDEN DE COMPRA N° ' + this.props.data.codigo + ' CANCELADA ESXITOSAMENTE';
    }
}