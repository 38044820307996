import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { InputArea } from '../../Utiles/Inputs';
import { conConfig } from '@victorequena22/conneccion';
interface State {
    garantia: string,
    observacion: string
}
export default class Ventas extends React.Component<any, State> {
    con = conConfig('ventas');
    state = { observacion: '', garantia: '' };
    render() {
        return (<Card>
            <Card.Header className='bg-info'>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> CONFIGURACION DE VENTAS </h4>
            </Card.Header>
            <Card.Body>
                <label className="control-label">GARANTIA DE LOS PRODUCTOS:</label>
                <InputArea setData={this.setState.bind(this)} value={this.state.garantia} update="garantia" />
                <br /><label className="control-label">OBSERVACION GENERAL:</label>
                <InputArea setData={this.setState.bind(this)} value={this.state.observacion} update="observacion" />
                <br />
                <Button className="float-right" onClick={this.guardar.bind(this)} variant="primary">
                    <span className="fa fa-floppy-o" /> Guardar
                </Button>
            </Card.Body>
        </Card>)
    }
    componentDidMount() {
        this.con.pull(this.setState.bind(this));
    }
    guardar() {
        this.con.push(this.state, this.setState.bind(this));
    }
}