import { RowConfig, ButtonConfig, ButtonColor, CardConfig, TitleCard } from '../index'
import { EditArea } from '../SlateCustom';
import { ButtonGroup, Row } from 'react-bootstrap';
import { TextToHTML } from "@victorequena22/slate-bootstrap";
import { CSSProperties } from 'react';
interface Props {
    config: any,
    setConfig: (d: any) => void,
}
export function Orientacion({ config: { texto, ori }, setConfig, text = 'NOTA' }: Props & { text?: string }) {
    return <ButtonGroup size='sm'>
        <ButtonConfig tip={`${text} A LA IZQUIERDA`} disabled={!texto} active={!ori} action={() => setConfig({ ori: false })} icon='fas fa-caret-square-left' />
        <ButtonConfig tip={`${text} A LA DERECHA`} disabled={!texto} active={ori} action={() => setConfig({ ori: true })} icon='fas fa-caret-square-right' />
    </ButtonGroup>
}
export function NotaMenu(p: Props) {
    const { config: { texto, textColor, childColor = '#f0f0f000' }, setConfig } = p;
    return <CardConfig width='71px'>
        <RowConfig>
            <ButtonGroup size='sm'>
                <ButtonConfig tip={`COLOCAR NOTA: ${texto ? 'SI' : 'NO'}`} active={texto} action={() => setConfig({ texto: !texto })} icon='fas fa-align-justify' />
                <ButtonColor gradiend tip='COLOR DE FONDO DE LA NOTA' style={{ width: 32 }} disable={!texto} color={textColor} setData={(textColor) => setConfig({ textColor })}>
                    <i className="fas fa-font"></i>
                </ButtonColor>
            </ButtonGroup>
        </RowConfig>
        <RowConfig top={2}>
            <Orientacion {...p} />
        </RowConfig>
        <RowConfig top={2}>
            <ButtonColor gradiend invert tip='COLOR DE FONDO DEL MARCO' style={{ width: 32 }} color={childColor} setData={(childColor) => setConfig({ childColor })}>
                <i className="fas fa-image"></i>
            </ButtonColor>
        </RowConfig>
        <TitleCard>NOTA</TitleCard>
    </CardConfig>
}
export function Invert({ config: { texto, ori, textColor, childColor = 'transparent' }, width, children }: { config: any, width: number, children: any }) {
    const invert = (orie: boolean) => {
        const inver = ((width === 100) ? 100 : 100 - width);
        const commons: any = { position: 'relative', float: 'left', padding: '.25rem .25rem .25rem .25rem' };
        if (!texto || orie)
            return <div style={{ width: (texto ? (ori ? width : inver) : 100) + '%', ...commons, background: childColor }}>
                {children}
            </div >
        return <div style={{ width: (ori ? inver : width) + '%', ...commons, background: textColor, }}>
            <div style={{ margin: 0, width: '100%', height: '100%', ...commons, padding: '30px', }}>
                <EditArea />
            </div>
        </div>
    }
    return <Row className='d-flex'>
        {invert(ori)}
        {texto ? invert(!ori) : <></>}
    </Row>
}
export function FormColor({ config: { backgroundColor, input, inputText, button, buttonText }, setConfig }: Props) {
    return <RowConfig top={2}>
        <ButtonColor gradiend tip='COLOR DE FONDO DEL PANEL' color={backgroundColor} setData={(backgroundColor) => setConfig({ backgroundColor })}>
            <i className="fas fa-paint-roller"></i>
        </ButtonColor>
        <ButtonGroup className='input-group-sm ml-1'>
            <ButtonColor tip='COLOR DE LOS CAMPOS DE TEXTO' color={input} setData={(input) => setConfig({ input })}>
                <i className="fas fa-paint-roller"></i>
            </ButtonColor>
            <ButtonColor tip='COLOR DE FUENTE EN LOS CAMPOS DE TEXTO' invert={true} color={inputText} setData={(inputText) => setConfig({ inputText })}>
                <i className="fas fa-font"></i>
            </ButtonColor>
        </ButtonGroup>
        <ButtonGroup className='input-group-sm ml-1'>
            <ButtonColor gradiend tip='COLOR DEL BOTON' color={button} setData={(button) => setConfig({ button })}>
                <i className="fas fa-paint-roller"></i>
            </ButtonColor>
            <ButtonColor tip='COLOR DE FUENTE EN EL BOTON' invert={true} color={buttonText} setData={(buttonText) => setConfig({ buttonText })}>
                <i className="fas fa-font"></i>
            </ButtonColor>
        </ButtonGroup>
    </RowConfig>
}
export function Html({ className, style, html }: { className?: string; style?: CSSProperties; html: any }) {
    return <div style={style} className={`vivify animationObject ${className}`}>
        <TextToHTML text={html} />
    </div>
}