import { iten, State } from '../index';
interface Props {
    iten: iten,
    config: State,
    seled: (i: number) => void
}
export function Itens({ config: { seleted }, iten: { id, img }, seled }: Props) {
    return <div onClick={() => seled(id)} className='m-1' style={{ border: seleted === id ? '2px solid red' : undefined }}>
        <img style={{ height: '5rem' }} src={img} alt='banner' />
    </div>
}
