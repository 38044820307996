import { css } from '@emotion/css';
export function getWidthSize(xs: any, sm: any, md: any, lg: any, xl: any, xxl: any, size: string) {
    const l = (size === '570px') ? xs : (size === '780px') ? sm : (size === '990px') ? md : (size === '1080px') ? lg : (size === '1320px') ? xl : xxl;
    return css`
        &> .col{
            padding: 1.5rem  1.5rem  1.5rem  1.5rem;
            margin-left: ${l.offset}%;
            -webkit-flex: 0 0 ${l.span - l.offset}%;
            -ms-flex: 0 0 ${l.span - l.offset}%;
            flex: 0 0 ${l.span - l.offset}%;
            max-width: ${l.span - l.offset}%;
        }`;
}
export function getOrientacionSize(orie: boolean, xs: number, sm: number, md: number, lg: number, xl: number, xxl: number, size: string) {
    const l = (size === '570px') ? xs : (size === '780px') ? sm : (size === '990px') ? md : (size === '1080px') ? lg : (size === '1320px') ? xl : xxl;
    return orie ? css`width:${l}%;` : css`width:${getW(l)}%;`;
}
const getW = (w: number) => (w === 100) ? 100 : 100 - w