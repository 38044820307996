import React, { useContext } from "react"
import { Row } from "react-bootstrap"
import Dolar from "./Dolar"
import Equilibrio from "./Equilibrio"
import Utilidad from "./Utilidad"
import Contadores from "./Contadores"
import { SeccionContext } from '../../../Context/Seccion'
import "./Panel.css";
import CotizacionPie from "./Tortas/Cotizacion"
import CompraPie from "./Tortas/Compra"
import NotaPie from "./Tortas/Nota"
import FacturaPie from "./Tortas/Factura"
import Ventas from "./Ventas"
import Mejor from "./Mejor"
export default function Panel() {
    const context = useContext(SeccionContext);
    let p: string[] = []
    if (context !== null)
        p = context.data.Permisos;
    
    return <Row className="Dasboard">
        <Contadores p={p} />
        <Equilibrio p={p} />
        <Utilidad />
        <Dolar p={p} />
        <CompraPie lugar='tortar2' />
        <CotizacionPie lugar='tortar1' />
        <NotaPie lugar='tortar1' />
        <FacturaPie lugar='tortar1' />
        <Ventas />
        <Mejor />
    </Row>
}