import { useState, createContext, useContext } from 'react';
import { useSemaforo } from '../../Utiles/ReactUtils';
import { conConfig, don } from '@victorequena22/conneccion';
export interface DolarType {
    tasa: number,
    banco: number,
}
export const DolarContext = createContext<DolarType>({ tasa: 1, banco: 1 })
export default function DolarProvider({ children }: { children: any }) {
    const [tasa, setDolar] = useState(1);
    const [banco, setBanco] = useState(1);
    useSemaforo('Dolares', async () => {
        await conConfig('').get(don + '/api/Dolares/valor', (d) => {
            if (!(d.errores)) {
                const [dolar, banco2] = d;
                if (tasa !== dolar) setDolar(dolar);
                if (banco !== banco2) setBanco(banco2);
            }
        });
    })
    return <DolarContext.Provider value={{ tasa, banco }}>{children}</DolarContext.Provider>
}
export const SeccionConsumer = DolarContext.Consumer;

export const useDolar = () => useContext(DolarContext) as DolarType