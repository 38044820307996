import { css } from '@emotion/css';
import { Row, Col, Container, Accordion, Card } from 'react-bootstrap';
import { Html } from '../Component/Commons';
import { UseIcon } from "@victorequena22/icon-selector-bootstrap";
import { d } from '../EditPanel/Paralax1/SVG';
export function Preguntas({ data: { props, itens, id }, size }: any) {
    const { color, cols } = props;
    const Rows: JSX.Element[] = [];
    const Column = ({ itens }: { itens: any[] }) => {
        const md = cols === 3 ? 4 : cols === 2 ? 6 : 12;
        return <Col md={d.some(a => a === size) ? 12 : md}>
            <Accordion defaultActiveKey='0'>
                {itens.map((iten) => <Itens key={'iten' + iten.id} config={props} iten={iten} />)}
            </Accordion>
        </Col>
    }
    let init = 0;
    for (let i = 0; i < cols; i++) {
        // eslint-disable-next-line no-loop-func
        Rows.push(<Column key={'column' + id + i} itens={itens.filter((_a: any, c: number) => c >= init && (c < init + (itens.length / cols)))} />)
        init += (itens.length / cols);
    }
    return <Row className='m-0' style={{ background: color, padding: '10px 0 10px 0' }}>
        <Container><Row>{Rows}</Row></Container>
    </Row>
}
export function Itens({
    config: { back: iback, border, orientation }, iten }: any) {
    if (iten === undefined) return <></>;
    const { indice, html, props } = iten;
    const { title, icon, color, back, aux, fill } = props;
    return <Card className={content(back, color, fill, iback, border, aux)}>
        <Card.Header>
            <Accordion.Toggle as={Row} className={`d-flex flex-${orientation}`} eventKey={`${indice}`}>
                <div className={"icon-content text-center"}>
                    <UseIcon icon={icon} width="2rem" height="2rem" fill={fill} />
                </div>
                <Html className="p-1" style={{ minWidth: 'calc(100% - 3rem)' }} html={title} />
            </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={`${indice}`}>
            <Html className="card-body" style={{ color }} html={html} />
        </Accordion.Collapse>
    </Card>
}
function content(
    color: string, font: string, fill: string, back: string, border: boolean, iback: string) {
    return css`
        margin: 0 0 0 0 !important;
        background: ${color} !important;
        color: ${font};
        &>.card-header{
            &>.row>.icon-content {
                width: 3rem; height: 3rem; padding-top:${border ? '.2rem' : '.5rem'};
                ${back === 'circle' ? 'border-radius: 3rem;' : back === 'rounded' ? 'border-radius: 1rem;' : ''}
                ${border && back !== 'none' ? `border: .35rem solid ${fill};` : ''}
                ${back !== 'none' ? `background-color: ${iback};` : ''}
                &>svg>path { fill:  ${fill}; }
            }            
        }
        `;
}