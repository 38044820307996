
import { EstadoNull, generalNull, imprecionNull, condicionesNull } from './Nulls'
import { getCosto, sentDetalles, getSubtotal, arrayToFilaGeneral, updateOrPush, getExentoEImpuesto } from './Function'
import { CondicionesData, GeneralFila, Estado, Detalles, GeneralData, Impreciones, tipoData } from './Interface'
import { ConexionCompleta } from '@victorequena22/conneccion';

export { EstadoNull, generalNull, imprecionNull, condicionesNull, getExentoEImpuesto };
export { getCosto, sentDetalles, getSubtotal, arrayToFilaGeneral, updateOrPush };
export type { CondicionesData, GeneralFila, Estado, Detalles, GeneralData, Impreciones, tipoData };
export interface Moneda {
    codigo: number
    descripcion: string
    estatus: number
}
class MonedaConexion extends ConexionCompleta {
    clase = 'Moneda';
    persistencia = 1000;
}
export function conMoneda() {
    return new MonedaConexion();
}