
import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { conFactura } from '../../../Models/Ventas';
import FilaVentas from '../../../Utiles/Ventas/FilaVentas';
import BTNPrint from '../../../Utiles/Prototipos/Fila/Botones/BTNPrint';
import BTNCancelar from '../../../Utiles/Prototipos/Fila/Botones/BTNCancelar';
import Detalles2Th from '../../../Utiles/Prototipos/Fila/Detalles2Th';
export default class FilaFactura extends FilaVentas {
    con = conFactura();
    constructor(p: any) {
        super(p);
        this.label = 'FACTURA';
        this.type = 'Factura';
    }
    Detalles() {
        const { con, label, data: { codigo } } = this;
        return <Detalles2Th con={con} label={label} id={codigo} />;
    }
    Botones() {
        const { type, con, label, cancelado, data: { status, codigo } } = this;
        return <th style={{ minWidth: "135px", maxWidth: "135px" }}>
            <ButtonGroup className="float-right">
                <BTNPrint label={label} id={codigo} tipo={type} />
                {(status !== 2) ? <></> :
                    <BTNCancelar con={con} label={label} id={codigo} msn={cancelado()} />}
            </ButtonGroup>
        </th>
    }
    cancelado() {
        return 'FACTURA N° ' + this.props.data.codigo + ' CANCELADA ESXITOSAMENTE';
    }
}