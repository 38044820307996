import { Col, Row } from "react-bootstrap";
import Dipositivos from "./Dispositivos";
import Pais from "./Pais";
import Visitas from "./Visitas";

export default function Web() {
    return <Row className="Dasboard">
        {/* <Contadores p={p} /> */}
        <div className='Contadores' />
        <Col md={4}>
            <Pais />
            <Dipositivos />
        </Col>
        <Col md={8}>
            <Visitas />
        </Col>
    </Row>;
}