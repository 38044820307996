import React from 'react';
import { Button, Card } from 'react-bootstrap';
import Formulario from './Formulario'
import { Impreciones, imprecionNull } from '../../../Models/General';
import { InputArea } from '../../../Utiles/Inputs';
import { conConfig } from '@victorequena22/conneccion';
interface State extends Impreciones {
    nota: string
}
export default class Compra extends React.Component<any, State> {
    con = conConfig('compra');
    state = { ...imprecionNull, nota: ''};
    render() {
        let setState = this.setState.bind(this);
        return (<Card>
            <Card.Header className='bg-info'>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> CONFIGURACION DE COMPRA </h4>
            </Card.Header>
            <Card.Body>
                <Card>
                    <Card.Header className='bg-info'>
                        <h5>CONFIGURACION DE DATOS POR DEFECTO </h5>
                    </Card.Header>
                    <Card.Body>
                        <b>NOTA</b>
                        <InputArea rows={6} maxLength={600} value={this.state.nota} setData={setState} update="nota" />
                    </Card.Body>
                </Card>
                <Formulario version='compra' data={this.state} setData={setState} />
                <Button className="float-right" onClick={this.guardar.bind(this)} variant="primary">
                    <span className="fa fa-floppy-o" /> Guardar
                </Button>
            </Card.Body>
        </Card>)
    }
    componentDidMount() {
        this.con.pull(this.setState.bind(this));
    }
    guardar() {
        this.con.push(this.state, this.setState.bind(this));
    }
}