import React from "react";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Button } from "react-bootstrap";

export default class ProveedoresExcel extends React.Component<{ proveedores: any[] }, {}>{
    _export = React.createRef<any>();
    excelExport() {
        if (this._export.current)
            this._export.current.save();
    };
    render() {
        const { proveedores } = this.props;
        if (proveedores.length === 0) return <></>
        return <>
        <Button variant='success' onClick={this.excelExport.bind(this)} style={{marginRight:'15px'}}>
        <i className="fa fa-file-excel"/>
        </Button>
        <ExcelExport data={proveedores} ref={this._export} fileName="Proveedores">
        <ExcelExportColumn field="codigo" title="CI/RIF" width={120} />
        <ExcelExportColumn field="nombre" title="NOMBRE" width={500} />

        </ExcelExport></>
    }

}