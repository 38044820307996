import React from "react"
import { ControlText } from '@/Utiles/FormControl'
import { Button, Card, Col, FormGroup } from 'react-bootstrap'
import "./Login.css";
import { SeccionContext } from '@/Context/Seccion'
import { toast, ToastContainer } from "react-toastify";
import { conConfig, don } from "@victorequena22/conneccion";
import { getImagenURL } from "@/Utiles/Formatos";

class Login extends React.Component<{}, { correo: string, clave: string }> {
    state = { correo: "", clave: "" };
    logo = '/logos/logo.svg';
    render() {
        const { correo, clave } = this.state;
        return <div className="login-layoud" style={{ backgroundImage: `url(${getImagenURL("/static/media/fondo.svg")})` }}>
            <div className="login-box">
                <ToastContainer position='bottom-right' />
                <Card border="primary" className="card-outline" style={{ backgroundColor: "rgba(255, 255, 255, 0.75)" }} >
                    <Card.Header className="text-center">
                        <Card.Img variant="top" src={this.logo} alt="ImgLogin" />
                    </Card.Header>
                    <Card.Body>
                        <Col as={FormGroup} xs={12}>
                            <ControlText next={() => document.getElementById('clave')?.focus()} before="CORREO" maxLength={150} value={correo} update='correo' setData={this.setState.bind(this)} />
                        </Col>
                        <Col as={FormGroup} xs={12}>
                            <ControlText next={this.login.bind(this)} id='clave' before="CLAVE" maxLength={150} value={clave} update='clave' setData={this.setState.bind(this)} type="password" />
                        </Col>
                        <Button onClick={this.login.bind(this)} variant="primary" block>ENTRAR</Button>
                    </Card.Body>
                </Card>
            </div>
        </div>
    }
    context: any = {};
    login() {
        const con = conConfig('');
        con.post(don + '/api/Auth/login', this.state, (data) => {
            localStorage.setItem('Authorization', data.token);
            this.context.user();
            window.location.reload();
        }, e => {
            toast(e.response.error, { type: toast.TYPE.ERROR })
        })
    }
    async Logo() {
        try {
            const rest = await fetch(don + '/api/Auth/getConfig')
            const data = await rest.json();
            this.logo = don + '/public/imagenes/' + await data.logo;
            this.forceUpdate();
        } catch (e) {
            console.log(e);
        }
    }
    componentDidMount() {
        this.Logo();
    }
}
Login.contextType = SeccionContext;
export default Login;