
import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { conProducto, Producto } from '../../../Models/Productos';
import THFila from '../../../Utiles/Componentes/THFila';
import { numberFormat } from '../../../Utiles/Formatos';
import EditProductos from './EditProducto';

interface Props {
    dolar: boolean
    data: Producto
    update: () => void
}
interface State {
    edit: boolean
}
export default class FilaProducto extends React.Component<Props, State>{
    constructor(p: any) {
        super(p);
        this.state = { edit: false };
    }
    render() {
        const { data: { codigo, descripcion, cantidad, inventario, costo }, dolar } = this.props;
        return <tr >
            <THFila width='90px'>
                {codigo}
            </THFila>
            <THFila width='calc(100% - 500px)'>
                {descripcion}
            </THFila>
            <THFila width='160px' align='right'>
                {(inventario !== 1) ? numberFormat(cantidad) : 'NO APLICA'}
            </THFila>
            <THFila width='250px' align='right'>
                {numberFormat(costo)} {dolar ? <span className="fa fa-dollar-sign" /> : <b>BS</b>}
            </THFila>
            <THFila width='100px' align='right'>
                {this.getStatus()}
            </THFila>
            <th style={{ minWidth: "135px", maxWidth: "135px" }}>
                <ButtonGroup className="float-right">
                    <Button data-tip="EDITAR" variant="primary"
                        onClick={() => { this.setState({ edit: true }) }}>
                        <i className="fa fa-edit"></i>
                    </Button>
                    <ReactTooltip />
                    <EditProductos open={this.state.edit}
                        producto={this.props.data} key={'edit' + codigo}
                        close={() => { this.setState({ edit: false }) }}
                        add={this.props.update} />
                    {(this.props.data.estatus === 1) ?
                        <Button data-tip="ELIMINAR" variant="danger"
                            onClick={this.cancelar.bind(this)} >
                            <i className="fa fa-ban"></i>
                        </Button> : <Button data-tip="ACTIVAR" variant="success"
                            onClick={this.cancelar.bind(this)} >
                            <i className="fa fa-check"></i>
                        </Button>}
                </ButtonGroup>
            </th>
        </tr >
    }
    getStatus() {
        if (this.props.data.estatus === 1)
            return <span className="badge badge-success">ACTIVO</span>
        return <span className="badge badge-danger">INACTIVO</span>
    }
    close() {
        this.setState({ edit: false });
    }
    cancelar() {
        this.close();
        conProducto().cancelar(this.props.data.codigo, this.props.update);
    }
}