import { GeneralData, getSubtotal, sentDetalles } from "../../General";

export interface Nota extends GeneralData {
    cod_cliente: string
}
export function sentNota(e: Nota, porc_impuesto: number) {
    let subtotal = getSubtotal(e.detalles),
        impuesto = subtotal * (porc_impuesto / 100),
        total = subtotal + impuesto;

    let { cod_cliente, nota, id_cotizacion } = e;
    return {
        id_cotizacion,
        cod_cliente,
        impuesto,
        total,
        nota,
        detalles: sentDetalles(e.detalles)
    }
}