import { SeccionContext, SeccionType } from '../../Context/Seccion';
import { Menus, MenuIten, MenuIten2 } from '../../Utiles/Componentes/Menus';
import ListMoneda from '../../App/Config/Moneda';
import { Route, Link } from "react-router-dom";
import Empresa from '../../App/Config/Empresa';
import ListaRoles from '../../App/Config/Rol';
import Ventas from '../../App/Config/Ventas';
import Region from '../../App/Config/Region';
import { useContext } from 'react';
import { Ruta } from './index';
import Horarios from '../../App/Config/Horarios';
const admin = ['COMPRA', 'ORDEN', 'PROVEEDOR', 'FACTURA', 'NOTA', 'COTIZACION', 'CLIENTE', 'AJUSTE']
export function MenuGenerales() {
    const { data: { Permisos } } = useContext(SeccionContext) as SeccionType;
    return <Menus title='CONFIGURACIONES GENERALES'>
        <Link className='btn btn-default' to='/config/empresa'>EMPRESA</Link>
        <Link className='btn btn-default' to='/config/horarios'>HORARIOS</Link>
        <MenuIten2 p={Permisos} permiso={admin} to='/config/region'>REGION</MenuIten2>
        <MenuIten2 p={Permisos} permiso={admin} to='/config/ventas'>VENTAS</MenuIten2>
        <MenuIten p={Permisos} to='/config/moneda' permiso='MONEDA' >MONEDAS</MenuIten>
        <MenuIten p={Permisos} to='/config/roles' permiso='USUARIO' >ROLES</MenuIten>
    </Menus>
}
export function RutasGenerales() {
    return <>
        <Route path="/config/empresa"><Empresa /></Route>
        <Route path="/config/ventas"><Ventas /></Route>
        <Route path="/config/horarios"><Horarios /></Route>
        <Route path="/config/region"><Region /></Route>
        <Ruta path="/config/moneda" component={ListMoneda} permiso='MONEDA' />
        <Ruta path="/config/roles" component={ListaRoles} permiso='USUARIO' />
    </>
}