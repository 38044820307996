import { GeneralData, generalNull, getCosto, getSubtotal, sentDetalles } from "../../General";

export interface Factura extends GeneralData {
    cod_cliente: string
    condicion: string
    id_nota?: number
    id_cotizacion?: number
    exento: boolean
    pagos: { metodo: number, monto: number }[]
}
export const FacturaNull: Factura = {
    cod_cliente: '',
    condicion: 'CONTADO',
    pagos: [],
    exento: false,
    ...generalNull,
}
export function sentFactura(e: Factura, porc_impuesto: number) {
    let subtotal = getSubtotal(e.detalles), costo = getCosto(e.detalles),
        impuesto = subtotal * (porc_impuesto / 100),
        total = subtotal + impuesto;
    let { cod_cliente, nota, condicion, id_nota, id_cotizacion, pagos } = e;
    return {
        id_nota, id_cotizacion, cod_cliente, condicion,
        porc_impuesto, costo, impuesto, subtotal,
        total, nota, pagos,
        detalles: sentDetalles(e.detalles)
    }
}