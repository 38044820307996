import React from "react";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Button } from "react-bootstrap";


export default class ClientesExcel extends React.Component<{ clientes: any[] }, {}>{
    _export = React.createRef<any>();

    excelExport() {
        if (this._export.current)
            this._export.current.save();
    };
    render() {
        const { clientes } = this.props;
        if (clientes.length === 0) return <></>
        return <>
            <Button variant='success' onClick={this.excelExport.bind(this)} style={{ marginRight: '15px' }}>
                <i className="fa fa-file-excel" />
            </Button>
            <ExcelExport data={clientes} ref={this._export} fileName="Clientes">
                <ExcelExportColumn field="codigo" title="CI/RIF" width={120} />
                <ExcelExportColumn field="nombre" title="NOMBRE" width={500} />
                <ExcelExportColumn field="correo" title="CORREO" width={500} />
                <ExcelExportColumn field="telefono" title="TELEFONO" width={300} />
                <ExcelExportColumn field="direccion" title="DIRECCION" width={1100} />
                <ExcelExportColumn field="contacto" title="CONTACTO" width={500} />
            </ExcelExport>
        </>
    }

}