import { CSSProperties } from 'react';
import { css } from '@emotion/css';
export const imagen: CSSProperties = { display: 'block', width: '100%' };

export function content(
    color: string, font: string, margin: number,
    animated: animatedList, col: number, row: number, seled: boolean) {
    return css`
        ${seled ? 'border: 5px solid #FF0000;margin: -5px -5px -5px -5px;' : ''}
        position: relative;
        overflow: hidden;
        grid-row: span ${row};
        grid-column: span ${col};
        &>.caption{
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 1fr;
            height: 1fr;
            background: ${color};
            color: ${font};
            font-family: 'Quicksand', sans-serif;
            display: flex;
            flex-direction: column;
            justify-content: center;
            transition: all 0.25s;
            margin: ${margin}px ${margin}px ${margin}px ${margin}px;
            padding: 10px 10px 10px 10px;
        }
        ${seled ? '' : animate[animated]}
        `;
}
export type animatedList = 'simple' | 'top' | 'bottom' | 'right' | 'left' | 'scaled' | 'scaledX' | 'scaledY' | 'rotate';
const animate = {
    simple: `&>.caption{ opacity: 0;}&:hover> .caption{ opacity: 1;}`,
    top: `&>.caption{ transform: translateY(-110%);}&:hover> .caption{ transform: translateY(0);}`,
    bottom: `&>.caption{ transform: translateY(110%);}&:hover> .caption{ transform: translateY(0);}`,
    right: `&>.caption{ transform: translateX(110%);}&:hover> .caption{ transform: translateX(0);}`,
    left: `&>.caption{ transform: translateX(-110%);}&:hover> .caption{ transform: translateX(0);}`,
    scaled: `&>.caption{ transform: scale(0, 0);}&:hover> .caption{ transform: scale(1,1);}`,
    scaledX: `&>.caption{ transform: scaleX(0);}&:hover> .caption{ transform: scaleX(1);}`,
    scaledY: `&>.caption{ transform: scaleY(0);}&:hover> .caption{ transform: scaleY(1);}`,
    rotate: `&>.caption{ transform: scale(0) rotate(180deg);}&:hover> .caption{ transform: scale(1) rotate(0deg);}`
} 