import React, { memo } from 'react'
import FilaProducto from './Productos';
import { Detalles, GeneralFila } from '../../Models/General';
import AuxiliarNuevo from '../Prototipos/AuxiliarNuevo'
import { conOrden } from '../../Models/Compra';
import BuscarOrden from '../../App/Compra/Orden/BuscarOrden';
import { Button, Col } from 'react-bootstrap';


export default class AuxiliarCompras extends AuxiliarNuevo {
    nuevo = true;
    con = conOrden();
    ordenes = '';
    BotonesLeft = () => {
        const { ordenes, cargar } = this;
        return <Col xs={6}>
            <BuscarOrden open={this.state.aux} add={cargar.bind(this)} close={() => this.setState({ aux: false })} />
            <Button onClick={() => { this.setState({ aux: true }) }}
                data-class="toolbody" data-html="true"
                data-tip={ordenes + " ORDEN <br> DE COMPRA"} variant="primary">
                <span className="fa fa-folder-open" />
            </Button>
        </Col>
    }
    cargar = (d: GeneralFila) => {
        this.con.detalle(d.codigo, data => {
            data.id_orden = data.codigo;
            data.codigo = 0;
            this.setData(data)
            this.orden()
        })
    }
    Row = memo(({ data }: { data: Detalles }) => {
        const { addProductos, removeProductos, props: { data: { dolar } } } = this;
        return <FilaProducto
            key={data.codigo + 'Compra' + this.productos}
            data={data}
            edit={addProductos}
            remove={removeProductos}
            dolar={dolar}
        />;
    })
    THead = () => <tr className='info'>
        <th style={{ width: "50px", textAlign: 'center' }}>ID</th>
        <th style={{ width: "90px", textAlign: 'center' }}>COD</th>
        <th style={{ textAlign: 'center' }}>DESCRIPCIÓN</th>
        <th style={{ width: "120px", textAlign: 'center' }}>CANT</th>
        <th style={{ width: "120px", textAlign: 'center' }}>DISP</th>
        <th style={{ width: "180px", textAlign: 'center' }}>COSTO</th>
        <th style={{ width: "230px", textAlign: 'center' }}>TOTAL</th>
        <th style={{ width: "110px", textAlign: 'center' }}></th>
    </tr>

}