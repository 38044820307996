/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useContext, useCallback } from 'react'
import { AuthSome } from '../../Config/Permisos';
import { conOrden } from '../../Models/Compra';
import { Estado, EstadoNull, GeneralFila, updateOrPush } from '../../Models/General';
import { formatoHora } from '../../Utiles/Formatos';
import { ProveedoresType, ProveedoresContext } from './Proveedor';
import { ProductosContext, ProductosType } from '../Producto';
import { sleep, useSemaforo } from '../../Utiles/ReactUtils';
export interface OrdenesType extends ProveedoresType, ProductosType {
    estOrdenes: Estado
    Ordenes: GeneralFila[]
    actOrden: () => void
}
export const OrdenesContext = createContext<OrdenesType | null>(null)
export default function OrdenesProvider({ children }: { children: any }) {
    const [Ordenes, setOrdenes] = useState<GeneralFila[]>([]);
    const proveedores = useContext(ProveedoresContext) as ProveedoresType;
    const pruducto = useContext(ProductosContext) as ProductosType;
    const [est, setest] = useState(EstadoNull);
    const [con] = useState(conOrden());
    const update = useCallback(async () => {
        if (AuthSome(['COMPRA', 'ORDEN'])) con.cambios((data: any[]) => {
            setest({ status: 3, hora: formatoHora(con.state) });
            setOrdenes(updateOrPush(data, Ordenes))
        })
        await sleep(50);
    }, [Ordenes])
    useSemaforo('Ordenes', async () => {
        await update()
    })
    return <OrdenesContext.Provider value={{
        ...proveedores, ...pruducto, actOrden: update,
        estOrdenes: est, Ordenes,
    }}>
        {children}
    </OrdenesContext.Provider>
}
export const SeccionConsumer = OrdenesContext.Consumer;