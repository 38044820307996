import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { Cargando } from '@victorequena22/component-bootstrap';
import { conShort, Shor } from '../../Models';
import Nuevo from './index'
export function Edit() {
    let { id } = useParams<{ id: any }>();
    const [short, setShort] = useState({ id: 0 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { conShort().detalle(id, setShort); }, [])
    return short.id > 0 ? <Nuevo data={short as Shor} /> : <Cargando />;

}