import { GeneralData, getSubtotal, sentDetalles, condicionesNull } from "../../General";

export interface Compra extends GeneralData {
    id_orden: number
    cod_nota: string
    nun_control: string
    cod_proveedor: string
    cod_documento: string
    fecha_documento: string
}
export var compraNull: Compra = {
    ...condicionesNull,
    cod_proveedor: '',
    id_orden: 0,
    cod_documento: '',
    cod_nota: '',
    nun_control: '',
    fecha_documento: '',
}
export function sentCompra(e: Compra, iva: number) {
    let subtotal = getSubtotal(e.detalles),
        impuesto = subtotal * (iva / 100),
        total = subtotal + impuesto;
    let { cod_proveedor, nota, cod_documento, nun_control, fecha_documento, id_orden } = e;
    return {
        id_orden,
        cod_proveedor,
        cod_documento,
        nun_control,
        fecha_documento,
        nota,
        total,
        impuesto,
        subtotal,
        detalles: sentDetalles(e.detalles)
    }
}