import { css } from '@emotion/css';
export function getCSS(back: string, color: string, font: number) {
    return css`
        &> ul> li{
            background: ${back};
            color: ${color};
            border: 1px solid  ${color};
        }
        &.flex-row,
        &.flex-row-reverse {
            &> ul{
                width: 20%;
                height: 100%;
                flex-direction: column !important;
                &> li{max-height: ${(font * 1.65) + 1}rem;}
                &>.flex-column-reverse,&>.flex-column{max-height: ${(font + 1.5) + 2}rem;}
            }
            &> div{width: 80%;}
            &> .tab> li{
                margin: -10px 0 -10px 0;
                padding: 10px 5px 10px 5px !important;
                &> .p-1{margin-top: 5px;}
                &:first-child{margin-top: 0;}
                &:last-child{margin-bottom: 0;}
            }
        }
        &.flex-column-reverse,
        &.flex-column{
            &> .tab> li{
                margin: 0 -10px 0 -10px;
                padding: 5px 30px 5px 30px !important;
                &:first-child{margin-left: 0;}
                &:last-child{margin-right: 0;}
            }
        }
        &.flex-column> .tab> li{border-radius: 25px 25px 0 0;}
        &.flex-column-reverse> .tab> li{border-radius: 0 0 25px 25px;}
        &.flex-row> .tab> li{border-radius: 25px 0 0 25px;}
        &.flex-row-reverse> .tab> li{border-radius: 0 25px 25px 0;}
    `;
}

