import React, { useState } from 'react';
import { Button, Col, InputGroup, Row, Table } from 'react-bootstrap';
import BuscarProducto from '../Productos/BuscarProducto';
import UsuarioSelector from '../../Usuarios/Select';
import { Producto } from '../../../Models/Productos';
import { InputDate } from '../../../Utiles/Inputs';
type es = string | undefined
export interface AvanzadosProps {
    setData: (d: any) => void
    fecha_init: string
    fecha_fin: string
    productos: Producto[]
    estatus: es[]
    status: number
    user: number
    tipo: '' | 'SALIDA' | 'ENTRADA' | 'COSTO' | 'UTILIDAD'
}
export default function Avanzado({ fecha_init, fecha_fin, status, user, estatus, productos, tipo, setData }: AvanzadosProps) {
    const setStatus = (e: React.ChangeEvent<HTMLSelectElement>) => setData({ status: parseInt(e.currentTarget.value) });
    const setTipo = (e: React.ChangeEvent<HTMLSelectElement>) => setData({ tipo: e.currentTarget.value });
    const removeProducto = (cod: String) => setData({ productos: productos.filter(p => p.codigo !== cod) })
    const limpiarFechas = () => { setData({ fecha_ini: 'dd/mm/aaaa', fecha_fin: 'dd/mm/aaaa' }) };
    const addProducto = (p: Producto) => setData({ productos: [...productos, p] })
    const [open, setOpen] = useState(false);
    return <Row className="form">
        <BuscarProducto type='Seleccion' open={open} add={addProducto} close={() => { setOpen(false) }} />
        <Col md={12}>
            <InputGroup>
                <InputGroup.Prepend><InputGroup.Text>RANGO DE FECHAS DEL</InputGroup.Text></InputGroup.Prepend>
                <InputDate value={fecha_init} setData={setData} update='fecha_init' />
                <InputGroup.Prepend><InputGroup.Text>AL</InputGroup.Text></InputGroup.Prepend>
                <InputDate value={fecha_fin} setData={setData} update='fecha_fin' />
                <Button onClick={limpiarFechas} data-tip="LIMPIAR FECHAS" variant="danger"
                ><i className="fa fa-ban"></i></Button>
            </InputGroup>
        </Col>
        <Col md={6}><br />
            <select data-tip="FILTRAR POR TIPO DE AJUSTE" onChange={setTipo} className="form-control" value={tipo}>
                <option value=''>TODOS LOS TIPOS DE AJUSTE</option>
                <option value='SALIDA'>AJUSTE DE SALIDA</option>
                <option value='ENTRADA'>AJUSTE DE ENTRADA</option>
                <option value='COSTO'>AJUSTE DE COSTO</option>
                <option value='UTILIDAD'>AJUSTE DE UTILIDAD</option>
            </select>
        </Col>
        <Col md={6}><br />
            <select data-tip="FILTRAR POR EL ESTADO" onChange={setStatus} className="form-control" value={status}>
                <option value={-1}>TODOS LOS AJUSTES</option>
                {estatus[0] ? <option value={0}>{estatus[0]}</option> : <></>}
                {estatus[1] ? <option value={1}>{estatus[1]}</option> : <></>}
                {estatus[2] ? <option value={2}>{estatus[2]}</option> : <></>}
            </select>
        </Col>
        <Col data-tip="FILTRAR POR USUARIO QUE REALIZO LA OPERACION" md={9}><br />
            <UsuarioSelector change={(u: number) => { setData({ user: u }) }} defaultValue={user} />
        </Col>
        <Col md={3} style={{ textAlign: 'center' }}><br />
            <Button data-tip="FILTRAR POR LOS PRODUCTOS SELECCIONADOS" block onClick={() => { setOpen(true) }}
            ><i className="fa fa-search" /> BUSCAR PRODUCTOS</Button>
        </Col>
        <Col md={12}><br />
            <Table striped hover>
                <thead>
                    <tr>
                        <th style={{ width: "10%" }} className="text-center">CODIGO</th>
                        <th style={{ width: "80%" }} >DESCRIPCION</th>
                        <th style={{ width: "10%" }} >QUITAR</th>
                    </tr>
                </thead>
                <tbody>
                    {productos.map((iten) => {
                        return (<tr>
                            <td className="text-center">{iten.codigo}</td>
                            <td>{iten.descripcion}</td>
                            <td className="text-center">
                                <Button variant="danger" onClick={() => { removeProducto(iten.codigo) }}>
                                    <i className="fa fa-ban"></i>
                                </Button>
                            </td>
                        </tr>);
                    })}
                </tbody>
            </Table>
        </Col>
    </Row >
}