import { BlockElement, Child, t, t1, t2, t3, setText, getText } from "@victorequena22/slate-bootstrap";
export const defaulHtml = `<p class='text-left' style="width: 100%;"><span><span style="font-family: Arial;"><strong><em><span>¡</span></em></strong></span></span><span><span style="font-family: Arial;"><span>Este </span></span></span><span><span style="font-family: Arial;"><strong><span>texto </span></strong></span></span><span><span style="font-family: Arial;"><span>es </span></span></span><span><span style="font-family: Arial;"><u><em><span>editable</span></em></u></span></span><span><span style="font-family: Arial;"><strong><em><span>!</span></em></strong></span></span></p>`;
const child: Child = { color: 'currentColor', fontFamily: 'Arial', bold: false, italic: false, underline: false, text: '' };
const children: Child[] = [
    { ...child, text: "¡", bold: true, italic: true },
    { ...child, text: "Este " },
    { ...child, text: "texto ", bold: true },
    { ...child, text: "es " },
    { ...child, text: "editable", italic: true, underline: true },
    { ...child, text: "!", bold: true, italic: true }
]
export const defaultText: BlockElement[] = [{ type: "paragraph", list: 'paragraph', align: 'left', tabs: 0, children }];
export { t, t1, t2, t3, setText, getText };
export type { BlockElement };

export function getHTML(id: string) {
    const div = document.getElementById(id);
    if (div !== null) return div.innerHTML.replaceAll(t, '').replaceAll(t1, '').replaceAll(t2, '').replaceAll(t3, '');
    return '';
}
