import { CSSProperties } from "react"

export function Svg({ children, style }: any) {
    return <svg style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
        border: '1px solid black',
        ...style
    }} width="72" height="48" viewBox="0 0 595.3 375.2" version="1.1" xmlns="http://www.w3.org/2000/svg" >
        {children}
    </svg>
}
const st: CSSProperties = { fillRule: "evenodd", clipRule: "evenodd" };
const st0: CSSProperties = { ...st, fill: "#FFFFFF" };
const st1: CSSProperties = { ...st, fill: "#CDEEFB" };
const st2: CSSProperties = { ...st, fill: "#C6E284" };
const st3: CSSProperties = { ...st, fill: "#8CA404" };
const st5: CSSProperties = { stroke: "#1D1D1B", strokeMiterlimit: "10" };
const st6: CSSProperties = { fontFamily: 'TimesNewRomanPSMT', fontSize: "287.8806px" };
export function Abajo() {
    return <Svg>
        <g transform="matrix(1.5,0,0,1,166.5,-39)">
            <rect x="109.3" y="89" style={st0} width="95.2" height="90.8" />
        </g>
        <path id="path4" style={st1} d="M256.8,0.2l0.3,377h338.7l-1-377.4C594.9-0.2,257-0.1,256.8,0.2z M414.1,60.7
        c9.9,2.5,19.2,9.9,22.3,17.8c0.2,0.5,0.6,1.4,0.8,2c1.3,3.1,1.9,6.3,2.1,11.2l0.2,5.2l1-0.6c4.6-3.1,11.9-3.7,16.4-1.4
        c10.6,5.3,11.9,20.2,2.4,27.1c-4,3-99.5,2.8-99.5,2.8l-2.8-0.9c-3.9-1.3-9.9-5.5-9.9-6.9c0-0.3-0.2-0.5-0.3-0.5
        c-0.9,0-3.8-6-3.8-7.8c0-0.4-0.1-0.9-0.3-1c-0.2-0.1-0.4-1.3-0.5-2.6c-0.7-8.5,3.8-16.7,11.4-21.1c4.8-2.8,12.1-3.3,17.7-1.3
        c1.6,0.6,3.1,1.1,3.5,1.2s0.8-0.1,1-0.7c0.2-0.5,0.7-1.9,1.2-3.1C382.5,65.9,399.5,57,414.1,60.7"/>
        <path style={st2} d="M263.5,262.7c-3.9,0.3-7.4,0.6-7.7,0.7c-0.6,0.2-0.1,48.6,0.4,48.7c6.3,2.1,34.5,2.7,48.2,1
        c16.9-2,28-4.8,47.5-11.9c8.3-3,17.7-6.1,21.2-7c1.3-0.3,3.6-0.9,5.1-1.3c3.4-0.9,11.9-2.4,17.3-3.1c9.8-1.3,13.8-1.5,31.1-1.5
        c29.8,0,41.5,1.2,79.5,8c12.9,2.3,23.4,3.4,42.5,4.6c9.3,0.6,35.1,0.3,40.4-0.5c2.1-0.3,3.9-0.5,4.1-0.4c0.2,0.1,0.3,0,0.3-0.2
        c0-0.5-0.3-0.5-5.7,0.2c-3.7,0.5-7.8,0.6-20.3,0.6c-18.9,0-23.6-0.3-37-2.6c-4.7-0.8-17.9-4.3-21-5.5c-0.7-0.3-1.5-0.5-1.6-0.5
        c-0.4,0-5.3-1.7-7.8-2.7c-17.8-6.9-34.7-11.3-48.6-12.8c-2-0.2-5-0.5-6.8-0.7c-1.7-0.2-11.6-0.5-22-0.7c-17.1-0.3-26.5-0.8-38.9-1.9
        c-2.4-0.2-4.7-0.5-5.1-0.5c-0.3-0.1-2.8-0.4-5.5-0.7c-4.1-0.5-16.9-2.6-26.6-4.4C320.4,263,288.7,261,263.5,262.7"/>
        <g id="path0" transform="matrix(1.13373,0,0,1.48997,-24.0936,-85.498)">
            <path style={st3} d="M385.8,241.4c-10.1,0.6-22.8,2.6-30.1,4.7c-1.4,0.4-3.4,1-4.5,1.3c-3.1,0.9-11.5,4-18.9,7.2
            c-17.3,7.3-27.2,10.1-42.3,12.2c-12.2,1.7-37.3,1.1-42.9-1.1c-0.5-0.2-0.5,45.4-0.5,45.4l300.5-0.2c0,0,0.1-57.3-0.1-57.4
            c-0.2-0.2-1.8,0-3.7,0.3c-4.7,0.8-27.6,1.1-35.9,0.5c-17-1.2-26.4-2.4-37.8-4.8c-24.4-5-32.4-6.3-46.7-7.5
            C416.5,241.6,391.2,241.1,385.8,241.4"/>
        </g>
        <path id="path4_1_" style={st1} d="M1,0.8l0.2,377H257L256.3,0.4C256.2,0.4,1.1,0.6,1,0.8z M119.8,61.3c7.5,2.5,14.5,9.9,16.8,17.8
        c0.1,0.5,0.4,1.4,0.6,2c1,3.1,1.4,6.3,1.6,11.2l0.2,5.2l0.7-0.6c3.5-3.1,9-3.7,12.4-1.4c8,5.3,9,20.2,1.8,27.1
        c-3.1,3-75.1,2.8-75.1,2.8l-2.1-0.9c-3-1.3-7.4-5.5-7.4-6.9c0-0.3-0.1-0.5-0.3-0.5c-0.6,0-2.9-6-2.9-7.8c0-0.4-0.1-0.9-0.2-1
        s-0.3-1.3-0.4-2.6C65,97.2,68.3,89,74.1,84.6c3.6-2.8,9.1-3.3,13.3-1.3c1.2,0.6,2.4,1.1,2.6,1.2c0.3,0.1,0.6-0.1,0.7-0.7
        c0.1-0.5,0.6-1.9,0.9-3.1C95.9,66.5,108.7,57.7,119.8,61.3"/>
        <g id="path3_1_" transform="matrix(1.12373,0,0,1.41845,-22.3637,-71.0287)">
            <path style={st2} d="M243.6,235c-16.8-2.5-38,0.5-55.4,7.9c-6.5,2.8-15,6-17.8,6.8c-1.8,0.5-3.4,1-3.7,1.1
            c-0.2,0.1-1.8,0.5-3.4,0.8c-8.3,1.7-14.5,2.4-26,2.9c-6.9,0.3-13.5,0.8-14.7,1.1c-1.2,0.3-3.2,0.8-4.5,1.1
            c-9.3,2.2-20.6,9.1-32.5,19.8c-1.7,1.5-5,4.1-5.2,4.1c-0.1,0-0.6,0.4-1.1,0.8c-2,1.9-10.9,7.2-14,8.5c-9,3.5-12.1,4.1-24.7,4
            c-8.3,0-11.1-0.2-13.5-1c-3.6-1.1-3.8-1.1-3.8-0.4c0,0.3,0.1,0.5,0.2,0.3s1.4,0.1,2.8,0.6c3.5,1.2,20.8,1.7,27,0.8
            c12.8-1.8,19.8-3.6,28.4-7.2c25.4-10.5,33.2-12.3,53.1-12.4c11.5,0,14.2,0.3,20.8,2.3c3.6,1.1,9.3,3.5,11.6,4.8
            c1,0.6,2.6,1.5,3.4,2c2.4,1.3,8.6,6.1,14.2,10.8c13,10.9,20.4,15.3,31.7,18.4c9.1,2.6,28,1.6,32.2-1.6c0.3-0.3,0.6-75.1,0.3-75.4
            C248.5,235.9,246.2,235.4,243.6,235"/>
        </g>
        <g id="path0_1_" transform="matrix(1.13373,0,0,1.48997,-24.0936,-85.498)">
            <path style={st3} d="M126.7,257.1c-7.6,0.4-17.2,2-22.7,3.6c-1,0.3-2.6,0.8-3.4,1c-2.4,0.7-8.6,3.1-14.2,5.5
            c-13,5.6-20.5,7.8-31.9,9.4c-9.2,1.3-28.1,0.8-32.4-0.8c-0.4-0.1-0.4,35-0.4,35l226.5-0.1c0,0,0-44.2-0.1-44.3
            c-0.1-0.1-1.4,0-2.8,0.2c-3.6,0.6-20.8,0.9-27.1,0.4c-12.8-0.9-19.9-1.9-28.5-3.7c-18.4-3.9-24.4-4.9-35.2-5.8
            C149.8,257.2,130.7,256.8,126.7,257.1"/>
        </g>
        <rect x="112.3" y="114.5" style={st0} width="370.7" height="262.5" />
        <g>
            <g>
                <rect x="345.3" y="46.2" style={st5} width="13.6" height="64.4" />
                <path style={st5} d="M355.1,12.2L371.7,41c1.3,2.3-0.3,5.2-3,5.2h-33.3c-2.7,0-4.4-2.9-3-5.2L349,12.2
                C350.4,9.8,353.8,9.8,355.1,12.2z"/>
            </g>
            <g>
                <rect x="236.4" y="46.1" style={st5} width="13.6" height="64.4" />
                <path style={st5} d="M246.2,12.2L262.8,41c1.3,2.3-0.3,5.2-3,5.2h-33.3c-2.7,0-4.4-2.9-3-5.2l16.6-28.8
                C241.5,9.8,244.8,9.8,246.2,12.2z"/>
            </g>
        </g>
        <text transform="matrix(1 0 0 1 195.3439 337.5523)" style={st6}>A</text>
    </Svg>
}
export function Arriba() {
    return <Svg>
        <g transform="matrix(1.5,0,0,1,166.5,-39)">
            <rect x="109.3" y="89.2" style={st0} width="95.2" height="90.8" />
        </g>
        <path id="path4" style={st1} d="M256.8,0.4l0.3,377h338.7L594.8,0C594.9,0,257,0.1,256.8,0.4z M414.1,60.9
        c9.9,2.5,19.2,9.9,22.3,17.8c0.2,0.5,0.6,1.4,0.8,2c1.3,3.1,1.9,6.3,2.1,11.2l0.2,5.2l1-0.6c4.6-3.1,11.9-3.7,16.4-1.4
        c10.6,5.3,11.9,20.2,2.4,27.1c-4,3-99.5,2.8-99.5,2.8l-2.8-0.9c-3.9-1.3-9.9-5.5-9.9-6.9c0-0.3-0.2-0.5-0.3-0.5
        c-0.9,0-3.8-6-3.8-7.8c0-0.4-0.1-0.9-0.3-1c-0.2-0.1-0.4-1.3-0.5-2.6c-0.7-8.5,3.8-16.7,11.4-21.1c4.8-2.8,12.1-3.3,17.7-1.3
        c1.6,0.6,3.1,1.1,3.5,1.2s0.8-0.1,1-0.7c0.2-0.5,0.7-1.9,1.2-3.1C382.5,66.1,399.5,57.2,414.1,60.9"/>
        <path style={st2} d="M263.5,262.9c-3.9,0.3-7.4,0.6-7.7,0.7c-0.6,0.2-0.1,48.6,0.4,48.7c6.3,2.1,34.5,2.7,48.2,1
        c16.9-2,28-4.8,47.5-11.9c8.3-3,17.7-6.1,21.2-7c1.3-0.3,3.6-0.9,5.1-1.3c3.4-0.9,11.9-2.4,17.3-3.1c9.8-1.3,13.8-1.5,31.1-1.5
        c29.8,0,41.5,1.2,79.5,8c12.9,2.3,23.4,3.4,42.5,4.6c9.3,0.6,35.1,0.3,40.4-0.5c2.1-0.3,3.9-0.5,4.1-0.4c0.2,0.1,0.3,0,0.3-0.2
        c0-0.5-0.3-0.5-5.7,0.2c-3.7,0.5-7.8,0.6-20.3,0.6c-18.9,0-23.6-0.3-37-2.6c-4.7-0.8-17.9-4.3-21-5.5c-0.7-0.3-1.5-0.5-1.6-0.5
        c-0.4,0-5.3-1.7-7.8-2.7c-17.8-6.9-34.7-11.3-48.6-12.8c-2-0.2-5-0.5-6.8-0.7c-1.7-0.2-11.6-0.5-22-0.7c-17.1-0.3-26.5-0.8-38.9-1.9
        c-2.4-0.2-4.7-0.5-5.1-0.5c-0.3-0.1-2.8-0.4-5.5-0.7c-4.1-0.5-16.9-2.6-26.6-4.4C320.4,263.2,288.7,261.2,263.5,262.9"/>
        <g id="path0" transform="matrix(1.13373,0,0,1.48997,-24.0936,-85.498)">
            <path style={st3} d="M385.8,241.6c-10.1,0.6-22.8,2.6-30.1,4.7c-1.4,0.4-3.4,1-4.5,1.3c-3.1,0.9-11.5,4-18.9,7.2
            c-17.3,7.3-27.2,10.1-42.3,12.2c-12.2,1.7-37.3,1.1-42.9-1.1c-0.5-0.2-0.5,45.4-0.5,45.4l300.5-0.2c0,0,0.1-57.3-0.1-57.4
            c-0.2-0.2-1.8,0-3.7,0.3c-4.7,0.8-27.6,1.1-35.9,0.5c-17-1.2-26.4-2.4-37.8-4.8c-24.4-5-32.4-6.3-46.7-7.5
            C416.5,241.8,391.2,241.3,385.8,241.6"/>
        </g>
        <path id="path4_1_" style={st1} d="M1,1l0.2,377H257L256.3,0.6C256.2,0.6,1.1,0.8,1,1z M119.8,61.5c7.5,2.5,14.5,9.9,16.8,17.8
        c0.1,0.5,0.4,1.4,0.6,2c1,3.1,1.4,6.3,1.6,11.2l0.2,5.2l0.7-0.6c3.5-3.1,9-3.7,12.4-1.4c8,5.3,9,20.2,1.8,27.1
        c-3.1,3-75.1,2.8-75.1,2.8l-2.1-0.9c-3-1.3-7.4-5.5-7.4-6.9c0-0.3-0.1-0.5-0.3-0.5c-0.6,0-2.9-6-2.9-7.8c0-0.4-0.1-0.9-0.2-1
        s-0.3-1.3-0.4-2.6c-0.5-8.5,2.8-16.7,8.6-21.1c3.6-2.8,9.1-3.3,13.3-1.3c1.2,0.6,2.4,1.1,2.6,1.2c0.3,0.1,0.6-0.1,0.7-0.7
        c0.1-0.5,0.6-1.9,0.9-3.1C95.9,66.7,108.7,57.9,119.8,61.5"/>
        <g id="path3_1_" transform="matrix(1.12373,0,0,1.41845,-22.3637,-71.0287)">
            <path style={st2} d="M243.6,235.1c-16.8-2.5-38,0.5-55.4,7.9c-6.5,2.8-15,6-17.8,6.8c-1.8,0.5-3.4,1-3.7,1.1
            c-0.2,0.1-1.8,0.5-3.4,0.8c-8.3,1.7-14.5,2.4-26,2.9c-6.9,0.3-13.5,0.8-14.7,1.1c-1.2,0.3-3.2,0.8-4.5,1.1
            c-9.3,2.2-20.6,9.1-32.5,19.8c-1.7,1.5-5,4.1-5.2,4.1c-0.1,0-0.6,0.4-1.1,0.8c-2,1.9-10.9,7.2-14,8.5c-9,3.5-12.1,4.1-24.7,4
            c-8.3,0-11.1-0.2-13.5-1c-3.6-1.1-3.8-1.1-3.8-0.4c0,0.3,0.1,0.5,0.2,0.3s1.4,0.1,2.8,0.6c3.5,1.2,20.8,1.7,27,0.8
            c12.8-1.8,19.8-3.6,28.4-7.2c25.4-10.5,33.2-12.3,53.1-12.4c11.5,0,14.2,0.3,20.8,2.3c3.6,1.1,9.3,3.5,11.6,4.8
            c1,0.6,2.6,1.5,3.4,2c2.4,1.3,8.6,6.1,14.2,10.8c13,10.9,20.4,15.3,31.7,18.4c9.1,2.6,28,1.6,32.2-1.6c0.3-0.3,0.6-75.1,0.3-75.4
            C248.5,236,246.2,235.5,243.6,235.1"/>
        </g>
        <g id="path0_1_" transform="matrix(1.13373,0,0,1.48997,-24.0936,-85.498)">
            <path style={st3} d="M127.6,257.3c-7.6,0.4-17.2,2-22.7,3.6c-1,0.3-2.6,0.8-3.4,1c-2.4,0.7-8.6,3.1-14.2,5.5
            c-13,5.6-20.5,7.8-31.9,9.4c-9.2,1.3-28.1,0.8-32.4-0.8c-0.4-0.1-0.4,35-0.4,35l226.5-0.1c0,0,0-44.2-0.1-44.3
            c-0.1-0.1-1.4,0-2.8,0.2c-3.6,0.6-20.8,0.9-27.1,0.4c-12.8-0.9-19.9-1.9-28.5-3.7c-18.4-3.9-24.4-4.9-35.2-5.8
            C150.7,257.4,131.6,257,127.6,257.3"/>
        </g>
        <rect x="112.3" style={st0} width="370.7" height="262.5" />
        <g>
            <g>
                <rect x="345.3" y="268.2" style={st5} width="13.6" height="64.4" />
                <path style={st5} d="M355.1,366.6l16.6-28.8c1.3-2.3-0.3-5.2-3-5.2h-33.3c-2.7,0-4.4,2.9-3,5.2l16.6,28.8
                C350.4,368.9,353.8,368.9,355.1,366.6z"/>
            </g>
            <g>
                <rect x="236.3" y="268.2" style={st5} width="13.6" height="64.4" />
                <path style={st5} d="M246.2,366.6l16.6-28.8c1.3-2.3-0.3-5.2-3-5.2h-33.3c-2.7,0-4.4,2.9-3,5.2l16.6,28.8
                C241.5,368.9,244.8,368.9,246.2,366.6z"/>
            </g>
        </g>
        <text transform="matrix(1 0 0 1 195.3438 218.5342)" style={st6}>A</text>
    </Svg>
}
export function Derecha() {
    return <Svg>
        <g transform="matrix(1.5,0,0,1,166.5,-39)">
            <rect x="109.3" y="89.2" style={st0} width="95.2" height="90.8" />
        </g>
        <path id="path4" style={st1} d="M256.8,0.4l0.3,377h338.7L594.8,0C594.9,0,257,0.1,256.8,0.4z M414.1,60.9
        c9.9,2.5,19.2,9.9,22.3,17.8c0.2,0.5,0.6,1.4,0.8,2c1.3,3.1,1.9,6.3,2.1,11.2l0.2,5.2l1-0.6c4.6-3.1,11.9-3.7,16.4-1.4
        c10.6,5.3,11.9,20.2,2.4,27.1c-4,3-99.5,2.8-99.5,2.8l-2.8-0.9c-3.9-1.3-9.9-5.5-9.9-6.9c0-0.3-0.2-0.5-0.3-0.5
        c-0.9,0-3.8-6-3.8-7.8c0-0.4-0.1-0.9-0.3-1c-0.2-0.1-0.4-1.3-0.5-2.6c-0.7-8.5,3.8-16.7,11.4-21.1c4.8-2.8,12.1-3.3,17.7-1.3
        c1.6,0.6,3.1,1.1,3.5,1.2s0.8-0.1,1-0.7c0.2-0.5,0.7-1.9,1.2-3.1C382.5,66.1,399.5,57.2,414.1,60.9"/>
        <path style={st2} d="M263.5,262.9c-3.9,0.3-7.4,0.6-7.7,0.7c-0.6,0.2-0.1,48.6,0.4,48.7c6.3,2.1,34.5,2.7,48.2,1
        c16.9-2,28-4.8,47.5-11.9c8.3-3,17.7-6.1,21.2-7c1.3-0.3,3.6-0.9,5.1-1.3c3.4-0.9,11.9-2.4,17.3-3.1c9.8-1.3,13.8-1.5,31.1-1.5
        c29.8,0,41.5,1.2,79.5,8c12.9,2.3,23.4,3.4,42.5,4.6c9.3,0.6,35.1,0.3,40.4-0.5c2.1-0.3,3.9-0.5,4.1-0.4c0.2,0.1,0.3,0,0.3-0.2
        c0-0.5-0.3-0.5-5.7,0.2c-3.7,0.5-7.8,0.6-20.3,0.6c-18.9,0-23.6-0.3-37-2.6c-4.7-0.8-17.9-4.3-21-5.5c-0.7-0.3-1.5-0.5-1.6-0.5
        c-0.4,0-5.3-1.7-7.8-2.7c-17.8-6.9-34.7-11.3-48.6-12.8c-2-0.2-5-0.5-6.8-0.7c-1.7-0.2-11.6-0.5-22-0.7c-17.1-0.3-26.5-0.8-38.9-1.9
        c-2.4-0.2-4.7-0.5-5.1-0.5c-0.3-0.1-2.8-0.4-5.5-0.7c-4.1-0.5-16.9-2.6-26.6-4.4C320.4,263.2,288.7,261.2,263.5,262.9"/>
        <g id="path0" transform="matrix(1.13373,0,0,1.48997,-24.0936,-85.498)">
            <path style={st3} d="M385.8,241.6c-10.1,0.6-22.8,2.6-30.1,4.7c-1.4,0.4-3.4,1-4.5,1.3c-3.1,0.9-11.5,4-18.9,7.2
            c-17.3,7.3-27.2,10.1-42.3,12.2c-12.2,1.7-37.3,1.1-42.9-1.1c-0.5-0.2-0.5,45.4-0.5,45.4l300.5-0.2c0,0,0.1-57.3-0.1-57.4
            c-0.2-0.2-1.8,0-3.7,0.3c-4.7,0.8-27.6,1.1-35.9,0.5c-17-1.2-26.4-2.4-37.8-4.8c-24.4-5-32.4-6.3-46.7-7.5
            C416.5,241.8,391.2,241.3,385.8,241.6"/>
        </g>
        <path id="path4_1_" style={st1} d="M1,1l0.2,377H257L256.3,0.6C256.2,0.6,1.1,0.8,1,1z M119.8,61.5c7.5,2.5,14.5,9.9,16.8,17.8
        c0.1,0.5,0.4,1.4,0.6,2c1,3.1,1.4,6.3,1.6,11.2l0.2,5.2l0.7-0.6c3.5-3.1,9-3.7,12.4-1.4c8,5.3,9,20.2,1.8,27.1
        c-3.1,3-75.1,2.8-75.1,2.8l-2.1-0.9c-3-1.3-7.4-5.5-7.4-6.9c0-0.3-0.1-0.5-0.3-0.5c-0.6,0-2.9-6-2.9-7.8c0-0.4-0.1-0.9-0.2-1
        s-0.3-1.3-0.4-2.6c-0.5-8.5,2.8-16.7,8.6-21.1c3.6-2.8,9.1-3.3,13.3-1.3c1.2,0.6,2.4,1.1,2.6,1.2c0.3,0.1,0.6-0.1,0.7-0.7
        c0.1-0.5,0.6-1.9,0.9-3.1C95.9,66.7,108.7,57.9,119.8,61.5"/>
        <g id="path3_1_" transform="matrix(1.12373,0,0,1.41845,-22.3637,-71.0287)">
            <path style={st2} d="M243.6,235.1c-16.8-2.5-38,0.5-55.4,7.9c-6.5,2.8-15,6-17.8,6.8c-1.8,0.5-3.4,1-3.7,1.1
            c-0.2,0.1-1.8,0.5-3.4,0.8c-8.3,1.7-14.5,2.4-26,2.9c-6.9,0.3-13.5,0.8-14.7,1.1c-1.2,0.3-3.2,0.8-4.5,1.1
            c-9.3,2.2-20.6,9.1-32.5,19.8c-1.7,1.5-5,4.1-5.2,4.1c-0.1,0-0.6,0.4-1.1,0.8c-2,1.9-10.9,7.2-14,8.5c-9,3.5-12.1,4.1-24.7,4
            c-8.3,0-11.1-0.2-13.5-1c-3.6-1.1-3.8-1.1-3.8-0.4c0,0.3,0.1,0.5,0.2,0.3s1.4,0.1,2.8,0.6c3.5,1.2,20.8,1.7,27,0.8
            c12.8-1.8,19.8-3.6,28.4-7.2c25.4-10.5,33.2-12.3,53.1-12.4c11.5,0,14.2,0.3,20.8,2.3c3.6,1.1,9.3,3.5,11.6,4.8
            c1,0.6,2.6,1.5,3.4,2c2.4,1.3,8.6,6.1,14.2,10.8c13,10.9,20.4,15.3,31.7,18.4c9.1,2.6,28,1.6,32.2-1.6c0.3-0.3,0.6-75.1,0.3-75.4
            C248.5,236,246.2,235.5,243.6,235.1"/>
        </g>
        <g id="path0_1_" transform="matrix(1.13373,0,0,1.48997,-24.0936,-85.498)">
            <path style={st3} d="M127.6,257.3c-7.6,0.4-17.2,2-22.7,3.6c-1,0.3-2.6,0.8-3.4,1c-2.4,0.7-8.6,3.1-14.2,5.5
            c-13,5.6-20.5,7.8-31.9,9.4c-9.2,1.3-28.1,0.8-32.4-0.8c-0.4-0.1-0.4,35-0.4,35l226.5-0.1c0,0,0-44.2-0.1-44.3
            c-0.1-0.1-1.4,0-2.8,0.2c-3.6,0.6-20.8,0.9-27.1,0.4c-12.8-0.9-19.9-1.9-28.5-3.7c-18.4-3.9-24.4-4.9-35.2-5.8
            C150.7,257.4,131.6,257,127.6,257.3"/>
        </g>
        <rect x="225.1" y="58.1" style={st0} width="370.7" height="262.5" />
        <g>
            <rect x="93.2" y="176.9" style={st5} width="120.3" height="25.4" />
            <path style={st5} d="M25,186.7l63-36.3c2.3-1.3,5.2,0.3,5.2,3v72.7c0,2.7-2.9,4.4-5.2,3l-63-36.3C22.7,191.3,22.7,188,25,186.7z" />
        </g>
        <text transform="matrix(1 0 0 1 316.7042 281.2741)" style={st6}>A</text>
    </Svg>
}
export function Izquierda() {
    return <Svg>
        <g transform="matrix(1.5,0,0,1,166.5,-39)">
            <rect x="109.3" y="89.2" style={st0} width="95.2" height="90.8" />
        </g>
        <path id="path4" style={st1} d="M256.8,0.4l0.3,377h338.7L594.8,0C594.9,0,257,0.1,256.8,0.4z M414.1,60.9
        c9.9,2.5,19.2,9.9,22.3,17.8c0.2,0.5,0.6,1.4,0.8,2c1.3,3.1,1.9,6.3,2.1,11.2l0.2,5.2l1-0.6c4.6-3.1,11.9-3.7,16.4-1.4
        c10.6,5.3,11.9,20.2,2.4,27.1c-4,3-99.5,2.8-99.5,2.8l-2.8-0.9c-3.9-1.3-9.9-5.5-9.9-6.9c0-0.3-0.2-0.5-0.3-0.5
        c-0.9,0-3.8-6-3.8-7.8c0-0.4-0.1-0.9-0.3-1c-0.2-0.1-0.4-1.3-0.5-2.6c-0.7-8.5,3.8-16.7,11.4-21.1c4.8-2.8,12.1-3.3,17.7-1.3
        c1.6,0.6,3.1,1.1,3.5,1.2s0.8-0.1,1-0.7c0.2-0.5,0.7-1.9,1.2-3.1C382.5,66.1,399.5,57.2,414.1,60.9"/>
        <path style={st2} d="M263.5,262.9c-3.9,0.3-7.4,0.6-7.7,0.7c-0.6,0.2-0.1,48.6,0.4,48.7c6.3,2.1,34.5,2.7,48.2,1
        c16.9-2,28-4.8,47.5-11.9c8.3-3,17.7-6.1,21.2-7c1.3-0.3,3.6-0.9,5.1-1.3c3.4-0.9,11.9-2.4,17.3-3.1c9.8-1.3,13.8-1.5,31.1-1.5
        c29.8,0,41.5,1.2,79.5,8c12.9,2.3,23.4,3.4,42.5,4.6c9.3,0.6,35.1,0.3,40.4-0.5c2.1-0.3,3.9-0.5,4.1-0.4c0.2,0.1,0.3,0,0.3-0.2
        c0-0.5-0.3-0.5-5.7,0.2c-3.7,0.5-7.8,0.6-20.3,0.6c-18.9,0-23.6-0.3-37-2.6c-4.7-0.8-17.9-4.3-21-5.5c-0.7-0.3-1.5-0.5-1.6-0.5
        c-0.4,0-5.3-1.7-7.8-2.7c-17.8-6.9-34.7-11.3-48.6-12.8c-2-0.2-5-0.5-6.8-0.7c-1.7-0.2-11.6-0.5-22-0.7c-17.1-0.3-26.5-0.8-38.9-1.9
        c-2.4-0.2-4.7-0.5-5.1-0.5c-0.3-0.1-2.8-0.4-5.5-0.7c-4.1-0.5-16.9-2.6-26.6-4.4C320.4,263.2,288.7,261.2,263.5,262.9"/>
        <g id="path0" transform="matrix(1.13373,0,0,1.48997,-24.0936,-85.498)">
            <path style={st3} d="M385.8,241.6c-10.1,0.6-22.8,2.6-30.1,4.7c-1.4,0.4-3.4,1-4.5,1.3c-3.1,0.9-11.5,4-18.9,7.2
            c-17.3,7.3-27.2,10.1-42.3,12.2c-12.2,1.7-37.3,1.1-42.9-1.1c-0.5-0.2-0.5,45.4-0.5,45.4l300.5-0.2c0,0,0.1-57.3-0.1-57.4
            c-0.2-0.2-1.8,0-3.7,0.3c-4.7,0.8-27.6,1.1-35.9,0.5c-17-1.2-26.4-2.4-37.8-4.8c-24.4-5-32.4-6.3-46.7-7.5
            C416.5,241.8,391.2,241.3,385.8,241.6"/>
        </g>
        <path id="path4_1_" style={st1} d="M1,1l0.2,377H257L256.3,0.6C256.2,0.6,1.1,0.8,1,1z M119.8,61.5c7.5,2.5,14.5,9.9,16.8,17.8
        c0.1,0.5,0.4,1.4,0.6,2c1,3.1,1.4,6.3,1.6,11.2l0.2,5.2l0.7-0.6c3.5-3.1,9-3.7,12.4-1.4c8,5.3,9,20.2,1.8,27.1
        c-3.1,3-75.1,2.8-75.1,2.8l-2.1-0.9c-3-1.3-7.4-5.5-7.4-6.9c0-0.3-0.1-0.5-0.3-0.5c-0.6,0-2.9-6-2.9-7.8c0-0.4-0.1-0.9-0.2-1
        s-0.3-1.3-0.4-2.6c-0.5-8.5,2.8-16.7,8.6-21.1c3.6-2.8,9.1-3.3,13.3-1.3c1.2,0.6,2.4,1.1,2.6,1.2c0.3,0.1,0.6-0.1,0.7-0.7
        c0.1-0.5,0.6-1.9,0.9-3.1C95.9,66.7,108.7,57.9,119.8,61.5"/>
        <g id="path3_1_" transform="matrix(1.12373,0,0,1.41845,-22.3637,-71.0287)">
            <path style={st2} d="M243.6,235.1c-16.8-2.5-38,0.5-55.4,7.9c-6.5,2.8-15,6-17.8,6.8c-1.8,0.5-3.4,1-3.7,1.1
            c-0.2,0.1-1.8,0.5-3.4,0.8c-8.3,1.7-14.5,2.4-26,2.9c-6.9,0.3-13.5,0.8-14.7,1.1c-1.2,0.3-3.2,0.8-4.5,1.1
            c-9.3,2.2-20.6,9.1-32.5,19.8c-1.7,1.5-5,4.1-5.2,4.1c-0.1,0-0.6,0.4-1.1,0.8c-2,1.9-10.9,7.2-14,8.5c-9,3.5-12.1,4.1-24.7,4
            c-8.3,0-11.1-0.2-13.5-1c-3.6-1.1-3.8-1.1-3.8-0.4c0,0.3,0.1,0.5,0.2,0.3s1.4,0.1,2.8,0.6c3.5,1.2,20.8,1.7,27,0.8
            c12.8-1.8,19.8-3.6,28.4-7.2c25.4-10.5,33.2-12.3,53.1-12.4c11.5,0,14.2,0.3,20.8,2.3c3.6,1.1,9.3,3.5,11.6,4.8
            c1,0.6,2.6,1.5,3.4,2c2.4,1.3,8.6,6.1,14.2,10.8c13,10.9,20.4,15.3,31.7,18.4c9.1,2.6,28,1.6,32.2-1.6c0.3-0.3,0.6-75.1,0.3-75.4
            C248.5,236,246.2,235.5,243.6,235.1"/>
        </g>
        <g id="path0_1_" transform="matrix(1.13373,0,0,1.48997,-24.0936,-85.498)">
            <path style={st3} d="M127.6,257.3c-7.6,0.4-17.2,2-22.7,3.6c-1,0.3-2.6,0.8-3.4,1c-2.4,0.7-8.6,3.1-14.2,5.5
            c-13,5.6-20.5,7.8-31.9,9.4c-9.2,1.3-28.1,0.8-32.4-0.8c-0.4-0.1-0.4,35-0.4,35l226.5-0.1c0,0,0-44.2-0.1-44.3
            c-0.1-0.1-1.4,0-2.8,0.2c-3.6,0.6-20.8,0.9-27.1,0.4c-12.8-0.9-19.9-1.9-28.5-3.7c-18.4-3.9-24.4-4.9-35.2-5.8
            C150.7,257.4,131.6,257,127.6,257.3"/>
        </g>
        <rect x="0.1" y="58.1" style={st0} width="370.7" height="262.5" />
        <g>
            <rect x="378.1" y="176.6" style={st5} width="120.3" height="25.4" />
            <path style={st5} d="M566.6,186.3l-63-36.3c-2.3-1.3-5.2,0.3-5.2,3v72.7c0,2.7,2.9,4.4,5.2,3l63-36.3
            C568.9,191,568.9,187.7,566.6,186.3z"/>
        </g>
        <text transform="matrix(1 0 0 1 65.4504 282.2741)" style={st6}>A</text>
    </Svg>
}
export function Disolver() {
    return <Svg>
        <g transform="matrix(1.5,0,0,1,166.5,-39)">
            <rect x="109.3" y="86.1" style={st0} width="95.2" height="90.8" />
        </g>
        <path id="path4" style={st1} d="M256.8-2.7l0.3,377h338.7l-1-377.4C594.9-3.1,257-3,256.8-2.7z M414.1,57.8
        c9.9,2.5,19.2,9.9,22.3,17.8c0.2,0.5,0.6,1.4,0.8,2c1.3,3.1,1.9,6.3,2.1,11.2l0.2,5.2l1-0.6c4.6-3.1,11.9-3.7,16.4-1.4
        c10.6,5.3,11.9,20.2,2.4,27.1c-4,3-99.5,2.8-99.5,2.8L357,121c-3.9-1.3-9.9-5.5-9.9-6.9c0-0.3-0.2-0.5-0.3-0.5c-0.9,0-3.8-6-3.8-7.8
        c0-0.4-0.1-0.9-0.3-1c-0.2-0.1-0.4-1.3-0.5-2.6c-0.7-8.5,3.8-16.7,11.4-21.1c4.8-2.8,12.1-3.3,17.7-1.3c1.6,0.6,3.1,1.1,3.5,1.2
        s0.8-0.1,1-0.7c0.2-0.5,0.7-1.9,1.2-3.1C382.5,63,399.5,54.1,414.1,57.8"/>
        <path style={st2} d="M263.5,259.8c-3.9,0.3-7.4,0.6-7.7,0.7c-0.6,0.2-0.1,48.6,0.4,48.7c6.3,2.1,34.5,2.7,48.2,1
        c16.9-2,28-4.8,47.5-11.9c8.3-3,17.7-6.1,21.2-7c1.3-0.3,3.6-0.9,5.1-1.3c3.4-0.9,11.9-2.4,17.3-3.1c9.8-1.3,13.8-1.5,31.1-1.5
        c29.8,0,41.5,1.2,79.5,8c12.9,2.3,23.4,3.4,42.5,4.6c9.3,0.6,35.1,0.3,40.4-0.5c2.1-0.3,3.9-0.5,4.1-0.4c0.2,0.1,0.3,0,0.3-0.2
        c0-0.5-0.3-0.5-5.7,0.2c-3.7,0.5-7.8,0.6-20.3,0.6c-18.9,0-23.6-0.3-37-2.6c-4.7-0.8-17.9-4.3-21-5.5c-0.7-0.3-1.5-0.5-1.6-0.5
        c-0.4,0-5.3-1.7-7.8-2.7c-17.8-6.9-34.7-11.3-48.6-12.8c-2-0.2-5-0.5-6.8-0.7c-1.7-0.2-11.6-0.5-22-0.7c-17.1-0.3-26.5-0.8-38.9-1.9
        c-2.4-0.2-4.7-0.5-5.1-0.5c-0.3-0.1-2.8-0.4-5.5-0.7c-4.1-0.5-16.9-2.6-26.6-4.4C320.4,260.1,288.7,258.1,263.5,259.8"/>
        <g id="path0" transform="matrix(1.13373,0,0,1.48997,-24.0936,-85.498)">
            <path style={st3} d="M385.8,239.5c-10.1,0.6-22.8,2.6-30.1,4.7c-1.4,0.4-3.4,1-4.5,1.3c-3.1,0.9-11.5,4-18.9,7.2
            c-17.3,7.3-27.2,10.1-42.3,12.2c-12.2,1.7-37.3,1.1-42.9-1.1c-0.5-0.2-0.5,45.4-0.5,45.4l300.5-0.2c0,0,0.1-57.3-0.1-57.4
            c-0.2-0.2-1.8,0-3.7,0.3c-4.7,0.8-27.6,1.1-35.9,0.5c-17-1.2-26.4-2.4-37.8-4.8c-24.4-5-32.4-6.3-46.7-7.5
            C416.5,239.7,391.2,239.2,385.8,239.5"/>
        </g>
        <path id="path4_1_" style={st1} d="M1-2.1l0.2,377H257L256.3-2.5C256.2-2.5,1.1-2.3,1-2.1z M119.8,58.4c7.5,2.5,14.5,9.9,16.8,17.8
        c0.1,0.5,0.4,1.4,0.6,2c1,3.1,1.4,6.3,1.6,11.2l0.2,5.2l0.7-0.6c3.5-3.1,9-3.7,12.4-1.4c8,5.3,9,20.2,1.8,27.1
        c-3.1,3-75.1,2.8-75.1,2.8l-2.1-0.9c-3-1.3-7.4-5.5-7.4-6.9c0-0.3-0.1-0.5-0.3-0.5c-0.6,0-2.9-6-2.9-7.8c0-0.4-0.1-0.9-0.2-1
        s-0.3-1.3-0.4-2.6c-0.5-8.5,2.8-16.7,8.6-21.1c3.6-2.8,9.1-3.3,13.3-1.3c1.2,0.6,2.4,1.1,2.6,1.2c0.3,0.1,0.6-0.1,0.7-0.7
        c0.1-0.5,0.6-1.9,0.9-3.1C95.9,63.6,108.7,54.8,119.8,58.4"/>
        <g id="path3_1_" transform="matrix(1.12373,0,0,1.41845,-22.3637,-71.0287)">
            <path style={st2} d="M243.6,232.9c-16.8-2.5-38,0.5-55.4,7.9c-6.5,2.8-15,6-17.8,6.8c-1.8,0.5-3.4,1-3.7,1.1
            c-0.2,0.1-1.8,0.5-3.4,0.8c-8.3,1.7-14.5,2.4-26,2.9c-6.9,0.3-13.5,0.8-14.7,1.1c-1.2,0.3-3.2,0.8-4.5,1.1
            c-9.3,2.2-20.6,9.1-32.5,19.8c-1.7,1.5-5,4.1-5.2,4.1c-0.1,0-0.6,0.4-1.1,0.8c-2,1.9-10.9,7.2-14,8.5c-9,3.5-12.1,4.1-24.7,4
            c-8.3,0-11.1-0.2-13.5-1c-3.6-1.1-3.8-1.1-3.8-0.4c0,0.3,0.1,0.5,0.2,0.3s1.4,0.1,2.8,0.6c3.5,1.2,20.8,1.7,27,0.8
            c12.8-1.8,19.8-3.6,28.4-7.2c25.4-10.5,33.2-12.3,53.1-12.4c11.5,0,14.2,0.3,20.8,2.3c3.6,1.1,9.3,3.5,11.6,4.8
            c1,0.6,2.6,1.5,3.4,2c2.4,1.3,8.6,6.1,14.2,10.8c13,10.9,20.4,15.3,31.7,18.4c9.1,2.6,28,1.6,32.2-1.6c0.3-0.3,0.6-75.1,0.3-75.4
            C248.5,233.8,246.2,233.3,243.6,232.9"/>
        </g>
        <g id="path0_1_" transform="matrix(1.13373,0,0,1.48997,-24.0936,-85.498)">
            <path style={st3} d="M127.6,255.2c-7.6,0.4-17.2,2-22.7,3.6c-1,0.3-2.6,0.8-3.4,1c-2.4,0.7-8.6,3.1-14.2,5.5
            c-13,5.6-20.5,7.8-31.9,9.4c-9.2,1.3-28.1,0.8-32.4-0.8c-0.4-0.1-0.4,35-0.4,35l226.5-0.1c0,0,0-44.2-0.1-44.3
            c-0.1-0.1-1.4,0-2.8,0.2c-3.6,0.6-20.8,0.9-27.1,0.4c-12.8-0.9-19.9-1.9-28.5-3.7c-18.4-3.9-24.4-4.9-35.2-5.8
            C150.7,255.3,131.6,254.9,127.6,255.2"/>
        </g>
        <rect x="112.3" y="55" style={{ ...st0, opacity: .81 }} width="370.7" height="262.5" />
        <text transform="matrix(1 0 0 1 195.3439 285.1641)" style={{ ...st6, opacity: .27 }}>A</text>
    </Svg>
}
export function VentanaH() {
    return <Svg>
        <path id="path4" style={st1} d="M256.8,0.4l0.3,377h338.7L594.8,0C594.9,0,257,0.1,256.8,0.4z M414.1,60.9
        c9.9,2.5,19.2,9.9,22.3,17.8c0.2,0.5,0.6,1.4,0.8,2c1.3,3.1,1.9,6.3,2.1,11.2l0.2,5.2l1-0.6c4.6-3.1,11.9-3.7,16.4-1.4
        c10.6,5.3,11.9,20.2,2.4,27.1c-4,3-99.5,2.8-99.5,2.8l-2.8-0.9c-3.9-1.3-9.9-5.5-9.9-6.9c0-0.3-0.2-0.5-0.3-0.5
        c-0.9,0-3.8-6-3.8-7.8c0-0.4-0.1-0.9-0.3-1c-0.2-0.1-0.4-1.3-0.5-2.6c-0.7-8.5,3.8-16.7,11.4-21.1c4.8-2.8,12.1-3.3,17.7-1.3
        c1.6,0.6,3.1,1.1,3.5,1.2s0.8-0.1,1-0.7c0.2-0.5,0.7-1.9,1.2-3.1C382.5,66.1,399.5,57.2,414.1,60.9"/>
        <path style={st2} d="M263.5,262.9c-3.9,0.3-7.4,0.6-7.7,0.7c-0.6,0.2-0.1,48.6,0.4,48.7c6.3,2.1,34.5,2.7,48.2,1
        c16.9-2,28-4.8,47.5-11.9c8.3-3,17.7-6.1,21.2-7c1.3-0.3,3.6-0.9,5.1-1.3c3.4-0.9,11.9-2.4,17.3-3.1c9.8-1.3,13.8-1.5,31.1-1.5
        c29.8,0,41.5,1.2,79.5,8c12.9,2.3,23.4,3.4,42.5,4.6c9.3,0.6,35.1,0.3,40.4-0.5c2.1-0.3,3.9-0.5,4.1-0.4c0.2,0.1,0.3,0,0.3-0.2
        c0-0.5-0.3-0.5-5.7,0.2c-3.7,0.5-7.8,0.6-20.3,0.6c-18.9,0-23.6-0.3-37-2.6c-4.7-0.8-17.9-4.3-21-5.5c-0.7-0.3-1.5-0.5-1.6-0.5
        c-0.4,0-5.3-1.7-7.8-2.7c-17.8-6.9-34.7-11.3-48.6-12.8c-2-0.2-5-0.5-6.8-0.7c-1.7-0.2-11.6-0.5-22-0.7c-17.1-0.3-26.5-0.8-38.9-1.9
        c-2.4-0.2-4.7-0.5-5.1-0.5c-0.3-0.1-2.8-0.4-5.5-0.7c-4.1-0.5-16.9-2.6-26.6-4.4C320.4,263.2,288.7,261.2,263.5,262.9"/>
        <g id="path0" transform="matrix(1.13373,0,0,1.48997,-24.0936,-85.498)">
            <path style={st3} d="M385.8,241.6c-10.1,0.6-22.8,2.6-30.1,4.7c-1.4,0.4-3.4,1-4.5,1.3c-3.1,0.9-11.5,4-18.9,7.2
            c-17.3,7.3-27.2,10.1-42.3,12.2c-12.2,1.7-37.3,1.1-42.9-1.1c-0.5-0.2-0.5,45.4-0.5,45.4l300.5-0.2c0,0,0.1-57.3-0.1-57.4
            c-0.2-0.2-1.8,0-3.7,0.3c-4.7,0.8-27.6,1.1-35.9,0.5c-17-1.2-26.4-2.4-37.8-4.8c-24.4-5-32.4-6.3-46.7-7.5
            C416.5,241.8,391.2,241.3,385.8,241.6"/>
        </g>
        <path id="path4_1_" style={st1} d="M1,1l0.2,377H257L256.3,0.6C256.2,0.6,1.1,0.8,1,1z M119.8,61.5c7.5,2.5,14.5,9.9,16.8,17.8
        c0.1,0.5,0.4,1.4,0.6,2c1,3.1,1.4,6.3,1.6,11.2l0.2,5.2l0.7-0.6c3.5-3.1,9-3.7,12.4-1.4c8,5.3,9,20.2,1.8,27.1
        c-3.1,3-75.1,2.8-75.1,2.8l-2.1-0.9c-3-1.3-7.4-5.5-7.4-6.9c0-0.3-0.1-0.5-0.3-0.5c-0.6,0-2.9-6-2.9-7.8c0-0.4-0.1-0.9-0.2-1
        s-0.3-1.3-0.4-2.6c-0.5-8.5,2.8-16.7,8.6-21.1c3.6-2.8,9.1-3.3,13.3-1.3c1.2,0.6,2.4,1.1,2.6,1.2c0.3,0.1,0.6-0.1,0.7-0.7
        c0.1-0.5,0.6-1.9,0.9-3.1C95.9,66.7,108.7,57.9,119.8,61.5"/>
        <g id="path3_1_" transform="matrix(1.12373,0,0,1.41845,-22.3637,-71.0287)">
            <path style={st2} d="M243.6,235.1c-16.8-2.5-38,0.5-55.4,7.9c-6.5,2.8-15,6-17.8,6.8c-1.8,0.5-3.4,1-3.7,1.1
            c-0.2,0.1-1.8,0.5-3.4,0.8c-8.3,1.7-14.5,2.4-26,2.9c-6.9,0.3-13.5,0.8-14.7,1.1c-1.2,0.3-3.2,0.8-4.5,1.1
            c-9.3,2.2-20.6,9.1-32.5,19.8c-1.7,1.5-5,4.1-5.2,4.1c-0.1,0-0.6,0.4-1.1,0.8c-2,1.9-10.9,7.2-14,8.5c-9,3.5-12.1,4.1-24.7,4
            c-8.3,0-11.1-0.2-13.5-1c-3.6-1.1-3.8-1.1-3.8-0.4c0,0.3,0.1,0.5,0.2,0.3s1.4,0.1,2.8,0.6c3.5,1.2,20.8,1.7,27,0.8
            c12.8-1.8,19.8-3.6,28.4-7.2c25.4-10.5,33.2-12.3,53.1-12.4c11.5,0,14.2,0.3,20.8,2.3c3.6,1.1,9.3,3.5,11.6,4.8
            c1,0.6,2.6,1.5,3.4,2c2.4,1.3,8.6,6.1,14.2,10.8c13,10.9,20.4,15.3,31.7,18.4c9.1,2.6,28,1.6,32.2-1.6c0.3-0.3,0.6-75.1,0.3-75.4
            C248.5,236,246.2,235.5,243.6,235.1"/>
        </g>
        <g id="path0_1_" transform="matrix(1.13373,0,0,1.48997,-24.0936,-85.498)">
            <path style={st3} d="M126.7,257.3c-7.6,0.4-17.2,2-22.7,3.6c-1,0.3-2.6,0.8-3.4,1c-2.4,0.7-8.6,3.1-14.2,5.5
            c-13,5.6-20.5,7.8-31.9,9.4c-9.2,1.3-28.1,0.8-32.4-0.8c-0.4-0.1-0.4,35-0.4,35l226.5-0.1c0,0,0-44.2-0.1-44.3
            c-0.1-0.1-1.4,0-2.8,0.2c-3.6,0.6-20.8,0.9-27.1,0.4c-12.8-0.9-19.9-1.9-28.5-3.7c-18.4-3.9-24.4-4.9-35.2-5.8
            C149.8,257.4,130.7,257,126.7,257.3"/>
        </g>
        <rect x="107.3" y="90.3" style={st0} width="370.7" height="191.7" />
        <g>
            <g>
                <rect x="345.4" y="38.4" style={st5} width="13.6" height="45.2" />
                <path style={st5} d="M355.2,14.6l16.6,20.2c1.3,1.6-0.3,3.7-3,3.7h-33.3c-2.7,0-4.4-2-3-3.7l16.6-20.2
                C350.5,12.9,353.9,12.9,355.2,14.6z"/>
            </g>
            <g>
                <rect x="236.5" y="38.4" style={st5} width="13.6" height="45.2" />
                <path style={st5} d="M246.3,14.6l16.6,20.2c1.3,1.6-0.3,3.7-3,3.7h-33.3c-2.7,0-4.4-2-3-3.7l16.6-20.2
                C241.6,12.9,244.9,12.9,246.3,14.6z"/>
            </g>
        </g>
        <text transform="matrix(1.369 0 0 1 190.3438 255.2998)" style={st6}>A</text>
        <g>
            <g>
                <rect x="345.4" y="291.1" style={st5} width="13.6" height="45.2" />
                <path style={st5} d="M349.1,360.2L332.5,340c-1.4-1.6,0.3-3.7,3-3.7h33.3c2.7,0,4.3,2,3,3.7l-16.6,20.2
                C353.9,361.9,350.5,361.9,349.1,360.2z"/>
            </g>
            <g>
                <rect x="236.5" y="291.2" style={st5} width="13.6" height="45.2" />
                <path style={st5} d="M240.2,360.2L223.6,340c-1.4-1.6,0.3-3.7,3-3.7h33.3c2.7,0,4.3,2,3,3.7l-16.6,20.2
                C244.9,361.9,241.6,361.9,240.2,360.2z"/>
            </g>
        </g>
    </Svg>
}
export function VentanaV() {
    return <Svg>
        <g transform="matrix(1.5,0,0,1,166.5,-39)">
            <rect x="109.3" y="89.2" style={st0} width="95.2" height="90.8" />
        </g>
        <path id="path4" style={st1} d="M256.8,0.4l0.3,377h338.7L594.8,0C594.9,0,257,0.1,256.8,0.4z M414.1,60.9
        c9.9,2.5,19.2,9.9,22.3,17.8c0.2,0.5,0.6,1.4,0.8,2c1.3,3.1,1.9,6.3,2.1,11.2l0.2,5.2l1-0.6c4.6-3.1,11.9-3.7,16.4-1.4
        c10.6,5.3,11.9,20.2,2.4,27.1c-4,3-99.5,2.8-99.5,2.8l-2.8-0.9c-3.9-1.3-9.9-5.5-9.9-6.9c0-0.3-0.2-0.5-0.3-0.5
        c-0.9,0-3.8-6-3.8-7.8c0-0.4-0.1-0.9-0.3-1c-0.2-0.1-0.4-1.3-0.5-2.6c-0.7-8.5,3.8-16.7,11.4-21.1c4.8-2.8,12.1-3.3,17.7-1.3
        c1.6,0.6,3.1,1.1,3.5,1.2s0.8-0.1,1-0.7c0.2-0.5,0.7-1.9,1.2-3.1C382.5,66.1,399.5,57.2,414.1,60.9"/>
        <path style={st2} d="M263.5,262.9c-3.9,0.3-7.4,0.6-7.7,0.7c-0.6,0.2-0.1,48.6,0.4,48.7c6.3,2.1,34.5,2.7,48.2,1
        c16.9-2,28-4.8,47.5-11.9c8.3-3,17.7-6.1,21.2-7c1.3-0.3,3.6-0.9,5.1-1.3c3.4-0.9,11.9-2.4,17.3-3.1c9.8-1.3,13.8-1.5,31.1-1.5
        c29.8,0,41.5,1.2,79.5,8c12.9,2.3,23.4,3.4,42.5,4.6c9.3,0.6,35.1,0.3,40.4-0.5c2.1-0.3,3.9-0.5,4.1-0.4c0.2,0.1,0.3,0,0.3-0.2
        c0-0.5-0.3-0.5-5.7,0.2c-3.7,0.5-7.8,0.6-20.3,0.6c-18.9,0-23.6-0.3-37-2.6c-4.7-0.8-17.9-4.3-21-5.5c-0.7-0.3-1.5-0.5-1.6-0.5
        c-0.4,0-5.3-1.7-7.8-2.7c-17.8-6.9-34.7-11.3-48.6-12.8c-2-0.2-5-0.5-6.8-0.7c-1.7-0.2-11.6-0.5-22-0.7c-17.1-0.3-26.5-0.8-38.9-1.9
        c-2.4-0.2-4.7-0.5-5.1-0.5c-0.3-0.1-2.8-0.4-5.5-0.7c-4.1-0.5-16.9-2.6-26.6-4.4C320.4,263.2,288.7,261.2,263.5,262.9"/>
        <g id="path0" transform="matrix(1.13373,0,0,1.48997,-24.0936,-85.498)">
            <path style={st3} d="M385.8,241.6c-10.1,0.6-22.8,2.6-30.1,4.7c-1.4,0.4-3.4,1-4.5,1.3c-3.1,0.9-11.5,4-18.9,7.2
            c-17.3,7.3-27.2,10.1-42.3,12.2c-12.2,1.7-37.3,1.1-42.9-1.1c-0.5-0.2-0.5,45.4-0.5,45.4l300.5-0.2c0,0,0.1-57.3-0.1-57.4
            c-0.2-0.2-1.8,0-3.7,0.3c-4.7,0.8-27.6,1.1-35.9,0.5c-17-1.2-26.4-2.4-37.8-4.8c-24.4-5-32.4-6.3-46.7-7.5
            C416.5,241.8,391.2,241.3,385.8,241.6"/>
        </g>
        <path id="path4_1_" style={st1} d="M1,1l0.2,377H257L256.3,0.6C256.2,0.6,1.1,0.8,1,1z M119.8,61.5c7.5,2.5,14.5,9.9,16.8,17.8
        c0.1,0.5,0.4,1.4,0.6,2c1,3.1,1.4,6.3,1.6,11.2l0.2,5.2l0.7-0.6c3.5-3.1,9-3.7,12.4-1.4c8,5.3,9,20.2,1.8,27.1
        c-3.1,3-75.1,2.8-75.1,2.8l-2.1-0.9c-3-1.3-7.4-5.5-7.4-6.9c0-0.3-0.1-0.5-0.3-0.5c-0.6,0-2.9-6-2.9-7.8c0-0.4-0.1-0.9-0.2-1
        s-0.3-1.3-0.4-2.6c-0.5-8.5,2.8-16.7,8.6-21.1c3.6-2.8,9.1-3.3,13.3-1.3c1.2,0.6,2.4,1.1,2.6,1.2c0.3,0.1,0.6-0.1,0.7-0.7
        c0.1-0.5,0.6-1.9,0.9-3.1C95.9,66.7,108.7,57.9,119.8,61.5"/>
        <g id="path3_1_" transform="matrix(1.12373,0,0,1.41845,-22.3637,-71.0287)">
            <path style={st2} d="M243.6,235.1c-16.8-2.5-38,0.5-55.4,7.9c-6.5,2.8-15,6-17.8,6.8c-1.8,0.5-3.4,1-3.7,1.1
            c-0.2,0.1-1.8,0.5-3.4,0.8c-8.3,1.7-14.5,2.4-26,2.9c-6.9,0.3-13.5,0.8-14.7,1.1c-1.2,0.3-3.2,0.8-4.5,1.1
            c-9.3,2.2-20.6,9.1-32.5,19.8c-1.7,1.5-5,4.1-5.2,4.1c-0.1,0-0.6,0.4-1.1,0.8c-2,1.9-10.9,7.2-14,8.5c-9,3.5-12.1,4.1-24.7,4
            c-8.3,0-11.1-0.2-13.5-1c-3.6-1.1-3.8-1.1-3.8-0.4c0,0.3,0.1,0.5,0.2,0.3s1.4,0.1,2.8,0.6c3.5,1.2,20.8,1.7,27,0.8
            c12.8-1.8,19.8-3.6,28.4-7.2c25.4-10.5,33.2-12.3,53.1-12.4c11.5,0,14.2,0.3,20.8,2.3c3.6,1.1,9.3,3.5,11.6,4.8
            c1,0.6,2.6,1.5,3.4,2c2.4,1.3,8.6,6.1,14.2,10.8c13,10.9,20.4,15.3,31.7,18.4c9.1,2.6,28,1.6,32.2-1.6c0.3-0.3,0.6-75.1,0.3-75.4
            C248.5,236,246.2,235.5,243.6,235.1"/>
        </g>
        <g id="path0_1_" transform="matrix(1.13373,0,0,1.48997,-24.0936,-85.498)">
            <path style={st3} d="M127.6,257.3c-7.6,0.4-17.2,2-22.7,3.6c-1,0.3-2.6,0.8-3.4,1c-2.4,0.7-8.6,3.1-14.2,5.5
            c-13,5.6-20.5,7.8-31.9,9.4c-9.2,1.3-28.1,0.8-32.4-0.8c-0.4-0.1-0.4,35-0.4,35l226.5-0.1c0,0,0-44.2-0.1-44.3
            c-0.1-0.1-1.4,0-2.8,0.2c-3.6,0.6-20.8,0.9-27.1,0.4c-12.8-0.9-19.9-1.9-28.5-3.7c-18.4-3.9-24.4-4.9-35.2-5.8
            C150.7,257.4,131.6,257,127.6,257.3"/>
        </g>
        <rect x="107.3" y="62.5" style={st0} width="370.7" height="242.9" />
        <text transform="matrix(1.0806 0 0 1 190.3442 271.5996)" style={st6}>A</text>
        <g>
            <g>
                <rect x="43.6" y="128" style={st5} width="45.2" height="13.6" />
                <path style={st5} d="M19.7,131.8L40,115.2c1.6-1.3,3.7,0.3,3.7,3v33.3c0,2.7-2,4.4-3.7,3l-20.2-16.6C18,136.5,18,133.1,19.7,131.8
                z"/>
            </g>
            <g>
                <rect x="43.5" y="236.9" style={st5} width="45.2" height="13.6" />
                <path style={st5} d="M19.7,240.7L40,224.1c1.6-1.3,3.7,0.3,3.7,3v33.3c0,2.7-2,4.4-3.7,3l-20.2-16.6C18,245.4,18,242.1,19.7,240.7
                z"/>
            </g>
        </g>
        <g>
            <g>
                <rect x="501.5" y="128" style={st5} width="45.2" height="13.6" />
                <path style={st5} d="M570.6,137.9l-20.2,16.6c-1.6,1.4-3.7-0.3-3.7-3v-33.3c0-2.7,2-4.3,3.7-3l20.2,16.6
                C572.3,133.1,572.3,136.5,570.6,137.9z"/>
            </g>
            <g>
                <rect x="501.5" y="236.9" style={st5} width="45.2" height="13.6" />
                <path style={st5} d="M570.6,246.8l-20.3,16.6c-1.6,1.4-3.7-0.3-3.7-3v-33.3c0-2.7,2-4.3,3.7-3l20.2,16.6
                C572.3,242.1,572.3,245.4,570.6,246.8z"/>
            </g>
        </g>
    </Svg>
}
export function Remolino({ invert }: { invert?: boolean }) {
    const style = { transform: invert ? 'scale(-1,1)' : 'scale(1,1)' }
    return <Svg style={style}>
        <g transform="matrix(1.5,0,0,1,166.5,-39)">
            <rect x="109.3" y="89.2" style={st0} width="95.2" height="90.8" />
        </g>
        <path id="path4" style={st1} d="M255.8,0.4l0.3,377h338.7L593.8,0C593.9,0,256,0.1,255.8,0.4z M413.1,60.9
        c9.9,2.5,19.2,9.9,22.3,17.8c0.2,0.5,0.6,1.4,0.8,2c1.3,3.1,1.9,6.3,2.1,11.2l0.2,5.2l1-0.6c4.6-3.1,11.9-3.7,16.4-1.4
        c10.6,5.3,11.9,20.2,2.4,27.1c-4,3-99.5,2.8-99.5,2.8l-2.8-0.9c-3.9-1.3-9.9-5.5-9.9-6.9c0-0.3-0.2-0.5-0.3-0.5
        c-0.9,0-3.8-6-3.8-7.8c0-0.4-0.1-0.9-0.3-1c-0.2-0.1-0.4-1.3-0.5-2.6c-0.7-8.5,3.8-16.7,11.4-21.1c4.8-2.8,12.1-3.3,17.7-1.3
        c1.6,0.6,3.1,1.1,3.5,1.2s0.8-0.1,1-0.7c0.2-0.5,0.7-1.9,1.2-3.1C381.5,66.1,398.5,57.2,413.1,60.9"/>
        <path style={st2} d="M263.5,262.9c-3.9,0.3-7.4,0.6-7.7,0.7c-0.6,0.2-0.1,48.6,0.4,48.7c6.3,2.1,34.5,2.7,48.2,1
        c16.9-2,28-4.8,47.5-11.9c8.3-3,17.7-6.1,21.2-7c1.3-0.3,3.6-0.9,5.1-1.3c3.4-0.9,11.9-2.4,17.3-3.1c9.8-1.3,13.8-1.5,31.1-1.5
        c29.8,0,41.5,1.2,79.5,8c12.9,2.3,23.4,3.4,42.5,4.6c9.3,0.6,35.1,0.3,40.4-0.5c2.1-0.3,3.9-0.5,4.1-0.4c0.2,0.1,0.3,0,0.3-0.2
        c0-0.5-0.3-0.5-5.7,0.2c-3.7,0.5-7.8,0.6-20.3,0.6c-18.9,0-23.6-0.3-37-2.6c-4.7-0.8-17.9-4.3-21-5.5c-0.7-0.3-1.5-0.5-1.6-0.5
        c-0.4,0-5.3-1.7-7.8-2.7c-17.8-6.9-34.7-11.3-48.6-12.8c-2-0.2-5-0.5-6.8-0.7c-1.7-0.2-11.6-0.5-22-0.7c-17.1-0.3-26.5-0.8-38.9-1.9
        c-2.4-0.2-4.7-0.5-5.1-0.5c-0.3-0.1-2.8-0.4-5.5-0.7c-4.1-0.5-16.9-2.6-26.6-4.4C320.4,263.2,288.7,261.2,263.5,262.9"/>
        <g id="path0" transform="matrix(1.13373,0,0,1.48997,-24.0936,-85.498)">
            <path style={st3} d="M385.8,241.6c-10.1,0.6-22.8,2.6-30.1,4.7c-1.4,0.4-3.4,1-4.5,1.3c-3.1,0.9-11.5,4-18.9,7.2
            c-17.3,7.3-27.2,10.1-42.3,12.2c-12.2,1.7-37.3,1.1-42.9-1.1c-0.5-0.2-0.5,45.4-0.5,45.4l300.5-0.2c0,0,0.1-57.3-0.1-57.4
            c-0.2-0.2-1.8,0-3.7,0.3c-4.7,0.8-27.6,1.1-35.9,0.5c-17-1.2-26.4-2.4-37.8-4.8c-24.4-5-32.4-6.3-46.7-7.5
            C416.5,241.8,391.2,241.3,385.8,241.6"/>
        </g>
        <path id="path4_1_" style={st1} d="M1,0l0.2,377H257L256.3-0.4C256.2-0.4,1.1-0.2,1,0z M119.8,60.5c7.5,2.5,14.5,9.9,16.8,17.8
        c0.1,0.5,0.4,1.4,0.6,2c1,3.1,1.4,6.3,1.6,11.2l0.2,5.2l0.7-0.6c3.5-3.1,9-3.7,12.4-1.4c8,5.3,9,20.2,1.8,27.1
        c-3.1,3-75.1,2.8-75.1,2.8l-2.1-0.9c-3-1.3-7.4-5.5-7.4-6.9c0-0.3-0.1-0.5-0.3-0.5c-0.6,0-2.9-6-2.9-7.8c0-0.4-0.1-0.9-0.2-1
        s-0.3-1.3-0.4-2.6c-0.5-8.5,2.8-16.7,8.6-21.1c3.6-2.8,9.1-3.3,13.3-1.3c1.2,0.6,2.4,1.1,2.6,1.2c0.3,0.1,0.6-0.1,0.7-0.7
        c0.1-0.5,0.6-1.9,0.9-3.1C95.9,65.7,108.7,56.9,119.8,60.5"/>
        <g id="path3_1_" transform="matrix(1.12373,0,0,1.41845,-22.3637,-71.0287)">
            <path style={st2} d="M243.6,235.1c-16.8-2.5-38,0.5-55.4,7.9c-6.5,2.8-15,6-17.8,6.8c-1.8,0.5-3.4,1-3.7,1.1
            c-0.2,0.1-1.8,0.5-3.4,0.8c-8.3,1.7-14.5,2.4-26,2.9c-6.9,0.3-13.5,0.8-14.7,1.1c-1.2,0.3-3.2,0.8-4.5,1.1
            c-9.3,2.2-20.6,9.1-32.5,19.8c-1.7,1.5-5,4.1-5.2,4.1c-0.1,0-0.6,0.4-1.1,0.8c-2,1.9-10.9,7.2-14,8.5c-9,3.5-12.1,4.1-24.7,4
            c-8.3,0-11.1-0.2-13.5-1c-3.6-1.1-3.8-1.1-3.8-0.4c0,0.3,0.1,0.5,0.2,0.3s1.4,0.1,2.8,0.6c3.5,1.2,20.8,1.7,27,0.8
            c12.8-1.8,19.8-3.6,28.4-7.2c25.4-10.5,33.2-12.3,53.1-12.4c11.5,0,14.2,0.3,20.8,2.3c3.6,1.1,9.3,3.5,11.6,4.8
            c1,0.6,2.6,1.5,3.4,2c2.4,1.3,8.6,6.1,14.2,10.8c13,10.9,20.4,15.3,31.7,18.4c9.1,2.6,28,1.6,32.2-1.6c0.3-0.3,0.6-75.1,0.3-75.4
            C248.5,236,246.2,235.5,243.6,235.1"/>
        </g>
        <g id="path0_1_" transform="matrix(1.13373,0,0,1.48997,-24.0936,-85.498)">
            <path style={st3} d="M127.6,258.6c-7.6,0.4-17.2,2-22.7,3.6c-1,0.3-2.6,0.8-3.4,1c-2.4,0.7-8.6,3.1-14.2,5.5
            c-13,5.6-20.5,7.8-31.9,9.4c-9.2,1.3-28.1,0.8-32.4-0.8c-0.4-0.1-0.4,35-0.4,35l226.5-0.1c0,0,0-44.2-0.1-44.3
            c-0.1-0.1-1.4,0-2.8,0.2c-3.6,0.6-20.8,0.9-27.1,0.4c-12.8-0.9-19.9-1.9-28.5-3.7c-18.4-3.9-24.4-4.9-35.2-5.8
            C150.7,258.7,131.6,258.3,127.6,258.6"/>
        </g>
        <rect x="144.5" y="70.3" transform="matrix(0.9418 -0.3363 0.3363 0.9418 -42.7821 110.505)" style={st0} width="306.2" height="216.9" />
        <g>
            <path style={{ fill: 'none', stroke: '#1D1D1B', strokeWidth: 20, strokeMiterlimit: 10, }} d="M485.1,81.4c21.6,13.5,32.8,39.2,31.5,64.2c-0.4,7.5-1.9,15-4.4,22.1" />
            <path style={st5} d="M452.3,61.4l20.5,41.3c1.2,2.4,4.5,2.6,6,0.4l25.5-38.4c1.5-2.2,0-5.2-2.7-5.4l-46-2.9
            C452.9,56.2,451.1,59,452.3,61.4z"/>
        </g>
        <g>
            <path style={st5} d="M117.1,279.1c-21.6-13.5-32.8-39.2-31.5-64.2c0.4-7.5,1.9-15,4.4-22.1" />
            <path style={st5} d="M149.9,299.1l-20.5-41.3c-1.2-2.4-4.5-2.6-6-0.4l-25.5,38.4c-1.5,2.2,0,5.2,2.7,5.4l46,2.9
            C149.3,304.3,151.1,301.5,149.9,299.1z"/>
        </g>
        <text transform="matrix(0.9357 -0.3528 0.3528 0.9357 241.4009 292.9787)" style={st6}>A</text>
    </Svg>
}
export function Zoom() {
    return <Svg>
        <g transform="matrix(1.5,0,0,1,166.5,-39)">
            <rect x="109.3" y="89" style={st0} width="95.2" height="90.8" />
        </g>
        <path id="path4" style={st1} d="M256.8,0.2l0.3,377h338.7l-1-377.4C594.9-0.2,257-0.1,256.8,0.2z M414.1,60.7
        c9.9,2.5,19.2,9.9,22.3,17.8c0.2,0.5,0.6,1.4,0.8,2c1.3,3.1,1.9,6.3,2.1,11.2l0.2,5.2l1-0.6c4.6-3.1,11.9-3.7,16.4-1.4
        c10.6,5.3,11.9,20.2,2.4,27.1c-4,3-99.5,2.8-99.5,2.8l-2.8-0.9c-3.9-1.3-9.9-5.5-9.9-6.9c0-0.3-0.2-0.5-0.3-0.5
        c-0.9,0-3.8-6-3.8-7.8c0-0.4-0.1-0.9-0.3-1c-0.2-0.1-0.4-1.3-0.5-2.6c-0.7-8.5,3.8-16.7,11.4-21.1c4.8-2.8,12.1-3.3,17.7-1.3
        c1.6,0.6,3.1,1.1,3.5,1.2s0.8-0.1,1-0.7c0.2-0.5,0.7-1.9,1.2-3.1C382.5,65.9,399.5,57,414.1,60.7"/>
        <path style={st2} d="M263.5,262.7c-3.9,0.3-7.4,0.6-7.7,0.7c-0.6,0.2-0.1,48.6,0.4,48.7c6.3,2.1,34.5,2.7,48.2,1
        c16.9-2,28-4.8,47.5-11.9c8.3-3,17.7-6.1,21.2-7c1.3-0.3,3.6-0.9,5.1-1.3c3.4-0.9,11.9-2.4,17.3-3.1c9.8-1.3,13.8-1.5,31.1-1.5
        c29.8,0,41.5,1.2,79.5,8c12.9,2.3,23.4,3.4,42.5,4.6c9.3,0.6,35.1,0.3,40.4-0.5c2.1-0.3,3.9-0.5,4.1-0.4c0.2,0.1,0.3,0,0.3-0.2
        c0-0.5-0.3-0.5-5.7,0.2c-3.7,0.5-7.8,0.6-20.3,0.6c-18.9,0-23.6-0.3-37-2.6c-4.7-0.8-17.9-4.3-21-5.5c-0.7-0.3-1.5-0.5-1.6-0.5
        c-0.4,0-5.3-1.7-7.8-2.7c-17.8-6.9-34.7-11.3-48.6-12.8c-2-0.2-5-0.5-6.8-0.7c-1.7-0.2-11.6-0.5-22-0.7c-17.1-0.3-26.5-0.8-38.9-1.9
        c-2.4-0.2-4.7-0.5-5.1-0.5c-0.3-0.1-2.8-0.4-5.5-0.7c-4.1-0.5-16.9-2.6-26.6-4.4C320.4,263,288.7,261,263.5,262.7"/>
        <g id="path0" transform="matrix(1.13373,0,0,1.48997,-24.0936,-85.498)">
            <path style={st3} d="M384.9,241.4c-10.1,0.6-22.8,2.6-30.1,4.7c-1.4,0.4-3.4,1-4.5,1.3c-3.1,0.9-11.5,4-18.9,7.2
            c-17.3,7.3-27.2,10.1-42.3,12.2c-12.2,1.7-37.3,1.1-42.9-1.1c-0.5-0.2-0.5,45.4-0.5,45.4l300.5-0.2c0,0,0.1-57.3-0.1-57.4
            c-0.2-0.2-1.8,0-3.7,0.3c-4.7,0.8-27.6,1.1-35.9,0.5c-17-1.2-26.4-2.4-37.8-4.8c-24.4-5-32.4-6.3-46.7-7.5
            C415.6,241.6,390.3,241.1,384.9,241.4"/>
        </g>
        <path id="path4_1_" style={st1} d="M1,0.8l0.2,377H257L256.3,0.4C256.2,0.4,1.1,0.6,1,0.8z M119.8,61.3c7.5,2.5,14.5,9.9,16.8,17.8
        c0.1,0.5,0.4,1.4,0.6,2c1,3.1,1.4,6.3,1.6,11.2l0.2,5.2l0.7-0.6c3.5-3.1,9-3.7,12.4-1.4c8,5.3,9,20.2,1.8,27.1
        c-3.1,3-75.1,2.8-75.1,2.8l-2.1-0.9c-3-1.3-7.4-5.5-7.4-6.9c0-0.3-0.1-0.5-0.3-0.5c-0.6,0-2.9-6-2.9-7.8c0-0.4-0.1-0.9-0.2-1
        s-0.3-1.3-0.4-2.6C65,97.2,68.3,89,74.1,84.6c3.6-2.8,9.1-3.3,13.3-1.3c1.2,0.6,2.4,1.1,2.6,1.2c0.3,0.1,0.6-0.1,0.7-0.7
        c0.1-0.5,0.6-1.9,0.9-3.1C95.9,66.5,108.7,57.7,119.8,61.3"/>
        <g id="path3_1_" transform="matrix(1.12373,0,0,1.41845,-22.3637,-71.0287)">
            <path style={st2} d="M243.6,235c-16.8-2.5-38,0.5-55.4,7.9c-6.5,2.8-15,6-17.8,6.8c-1.8,0.5-3.4,1-3.7,1.1
            c-0.2,0.1-1.8,0.5-3.4,0.8c-8.3,1.7-14.5,2.4-26,2.9c-6.9,0.3-13.5,0.8-14.7,1.1c-1.2,0.3-3.2,0.8-4.5,1.1
            c-9.3,2.2-20.6,9.1-32.5,19.8c-1.7,1.5-5,4.1-5.2,4.1c-0.1,0-0.6,0.4-1.1,0.8c-2,1.9-10.9,7.2-14,8.5c-9,3.5-12.1,4.1-24.7,4
            c-8.3,0-11.1-0.2-13.5-1c-3.6-1.1-3.8-1.1-3.8-0.4c0,0.3,0.1,0.5,0.2,0.3s1.4,0.1,2.8,0.6c3.5,1.2,20.8,1.7,27,0.8
            c12.8-1.8,19.8-3.6,28.4-7.2c25.4-10.5,33.2-12.3,53.1-12.4c11.5,0,14.2,0.3,20.8,2.3c3.6,1.1,9.3,3.5,11.6,4.8
            c1,0.6,2.6,1.5,3.4,2c2.4,1.3,8.6,6.1,14.2,10.8c13,10.9,20.4,15.3,31.7,18.4c9.1,2.6,28,1.6,32.2-1.6c0.3-0.3,0.6-75.1,0.3-75.4
            C248.5,235.9,246.2,235.4,243.6,235"/>
        </g>
        <g id="path0_1_" transform="matrix(1.13373,0,0,1.48997,-24.0936,-85.498)">
            <path style={st3} d="M127.6,257.1c-7.6,0.4-17.2,2-22.7,3.6c-1,0.3-2.6,0.8-3.4,1c-2.4,0.7-8.6,3.1-14.2,5.5
            c-13,5.6-20.5,7.8-31.9,9.4c-9.2,1.3-28.1,0.8-32.4-0.8c-0.4-0.1-0.4,35-0.4,35l226.5-0.1c0,0,0-44.2-0.1-44.3
            c-0.1-0.1-1.4,0-2.8,0.2c-3.6,0.6-20.8,0.9-27.1,0.4c-12.8-0.9-19.9-1.9-28.5-3.7c-18.4-3.9-24.4-4.9-35.2-5.8
            C150.7,257.2,131.6,256.8,127.6,257.1"/>
        </g>
        <rect x="159" y="91" style={st0} width="277.2" height="196.3" />
        <g>
            <rect x="432.8" y="55.3" transform="matrix(0.866 -0.5 0.5 0.866 31.5614 246.2109)" style={st5} width="84.8" height="17.9" />
            <path style={{ ...st5, strokeWidth: 1 }} d="M550.6,17.2H503c-2.7,0-4.4,2.9-3,5.2l23.8,41.3c1.3,2.3,4.7,2.3,6,0l23.8-41.3
            C554.9,20.1,553.3,17.2,550.6,17.2z"/>
        </g>
        <g>
            <rect x="110.9" y="21.2" transform="matrix(0.5 -0.866 0.866 0.5 4.8374 135.6053)" style={st5} width="17.9" height="84.8" />
            <path style={{ ...st5, strokeWidth: 1 }} d="M44.4,16.6H92c2.7,0,4.4,2.9,3,5.2L71.2,63.1c-1.3,2.3-4.7,2.3-6,0L41.4,21.8C40,19.5,41.7,16.6,44.4,16.6z" />
        </g>
        <g>
            <rect x="76.6" y="305.7" transform="matrix(0.866 -0.5 0.5 0.866 -141.3621 101.6569)" style={st5} width="84.8" height="17.9" />
            <path style={{ ...st5, strokeWidth: 1 }} d="M43.6,361.6h47.6c2.7,0,4.4-2.9,3-5.2l-23.8-41.3c-1.3-2.3-4.7-2.3-6,0l-23.8,41.3
            C39.2,358.7,40.9,361.6,43.6,361.6z"/>
        </g>
        <g>
            <rect x="467.3" y="273.3" transform="matrix(0.5 -0.866 0.866 0.5 -35.3307 570.2669)" style={st5} width="17.9" height="84.8" />
            <path style={{ ...st5, strokeWidth: 1 }} d="M551.7,362.8h-47.6c-2.7,0-4.4-2.9-3-5.2l23.8-41.3c1.3-2.3,4.7-2.3,6,0l23.8,41.3
            C556.1,359.9,554.4,362.8,551.7,362.8z"/>
        </g>
        <text transform="matrix(1 0 0 1 224.5436 259.1615)" style={st6}>A</text>
    </Svg>
}