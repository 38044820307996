import React from 'react';
import { Button, Col, InputGroup, Card, Row, FormControl } from 'react-bootstrap';
import { ControlNumber, ControlText } from '../../Utiles/FormControl';
import MonedaSelector from './Moneda/Selector';
import { conConfig } from '@victorequena22/conneccion';
interface State {
    cod_fiscal: string,
    moneda: string
    impuesto: number
    impuesto1: number
    contribuyente?: string
}
export default class Region extends React.Component<any, State> {
    con = conConfig('region');
    state = { cod_fiscal: '', moneda: '', impuesto: 0, impuesto1: 0, contribuyente: 'NORMAL' };
    render() {
        let { cod_fiscal, moneda, impuesto, impuesto1, contribuyente } = this.state;
        let setState = this.setState.bind(this);
        return (<Card>
            <Card.Header className='bg-info'>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> CONFIGURACION DE REGION </h4>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col md={12}>
                        <ControlText after="EJEMPLO: RIF, CUIT, NIT..." before="IDENTIFICACION TRIBUTARIA" value={cod_fiscal} setData={setState} update="cod_fiscal" />
                    </Col>
                    <Col md={4}><br />
                        <InputGroup style={{ width: '100%' }} className="mb-3">
                            <span style={{ width: '100px', position: 'relative', backgroundColor: "#eee" }} className="form-control">MONEDA</span>
                            <MonedaSelector defaultValue={moneda} change={this.setMoneda.bind(this)} />
                        </InputGroup>
                    </Col>
                    <Col md={4}><br />
                        <InputGroup style={{ width: '100%' }} className="mb-3">
                            <span style={{ width: '150px', position: 'relative', backgroundColor: "#eee" }} className="form-control">CONTRIBUYENTE</span>
                            <FormControl value={contribuyente} onChange={this.setContribuyente} as='select' style={{ width: 'calc(100% - 150px' }}>
                                <option>NORMAL</option>
                                <option>ESPECIAL</option>
                            </FormControl>
                        </InputGroup>
                    </Col>
                    <Col md={2}><br />
                        <ControlNumber min={.01} step=".01" before="IMPUESTO" value={impuesto} setData={setState} update="impuesto" />
                    </Col>
                    <Col md={2}><br />
                        <ControlNumber min={.01} step=".01" before="IGTF" value={impuesto1} setData={setState} update="impuesto1" />
                    </Col>
                </Row>
                <Col md={12}><br />
                    <Button className="float-right" onClick={this.guardar.bind(this)} variant="primary">
                        <span className="fas fa-save" /> Guardar
                    </Button>
                </Col>
            </Card.Body>
        </Card>)
    }
    setMoneda = (moneda: string) => this.setState({ moneda })
    componentDidMount = () => this.con.pull(this.setState.bind(this));
    guardar = () => this.con.push(this.state, this.setState.bind(this));
    setContribuyente = (e: React.ChangeEvent<HTMLSelectElement>) => this.setState({ contribuyente: e.currentTarget.value })
}