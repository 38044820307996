import { VentasContext, VentasType } from "@/Context/Ventas";
import { numberFormat } from "@/Utiles/Formatos";
import { useContext } from "react";

export function CalcularImpuesto({ dolar, imponible: i, exento: e, cols = 4, ext }: { dolar?: boolean, ext: boolean, imponible: number, exento: number, cols?: number }) {
    const { impuesto, tasa } = useContext(VentasContext) as VentasType;
    const precio = (p: number) => dolar ? parseFloat((p / tasa).toFixed(2)) : p;
    const imponible = precio(i);
    const exento = precio(e);
    function calImpuesto() {
        if (ext) return numberFormat(0);
        let imp = (impuesto / 100);
        return numberFormat(imponible * imp);
    }
    function calPagar() {
        if (ext) return numberFormat(imponible + exento);
        let imp = impuesto / 100;
        let total = imponible + exento + (imponible * imp);
        return numberFormat(total)
    }
    return <>
        <tr>
            <th colSpan={cols} rowSpan={8}></th>
            <th style={{ textAlign: "right" }} colSpan={2}>SUBTOTAL:</th>
            <th style={{ textAlign: "right" }}>{numberFormat(imponible + exento)}</th>
        </tr>
        {exento > 0 ? <>
            <tr>
                <th style={{ textAlign: "right" }} colSpan={2}>EXENTO:</th>
                <th style={{ textAlign: "right" }}>{numberFormat(exento)}</th>
            </tr>
            <tr>
                <th style={{ textAlign: "right" }} colSpan={2}>BASE IMPONIBLE:</th>
                <th style={{ textAlign: "right" }}>{numberFormat(imponible)}</th>
            </tr>
        </> : <></>}
        <tr>
            <th style={{ textAlign: "right" }} colSpan={2}>IMPUESTO {ext ? 0 : impuesto}%:</th>
            <th style={{ textAlign: "right" }}>{calImpuesto()}</th>
        </tr>
        <tr>
            <th style={{ textAlign: "right" }} colSpan={2}>TOTAL:</th>
            <th style={{ textAlign: "right" }}>{calPagar()}</th>
        </tr>
    </>
}