import { css } from '@emotion/css';
import { Row, Container, Card, Col, Image } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { BlockElement, TextToHTML } from '@victorequena22/slate-bootstrap';
import { getImagenURL } from '../../../Utiles/Formatos';
export function Testimonio({ data: { itens, props } }: any) {
    let settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1
    }
    const style = { with: '100%', margin: '0 0 0 0', padding: '0 0 0 0' };
    const { color, img, inter } = props
    return <div className="m-0 p-0 w-100" style={{ background: `url(${img})`, backgroundSize: 'cover' }}>
        <Row style={{ background: color, ...style, padding: '0 0 2rem 0' }}>
            <Container className={getCSS(inter)} style={{ padding: '60px' }} >
                <Slider {...settings}>
                    {itens.map((i: any) => <Itens key={'itens' + i.id} iten={i} props={props} />)}
                </Slider>
            </Container>
        </Row>
    </div>
}
function Itens({ iten: { props: { marco, borde, width, img, color, back, top, left, right, bottom } } }: any) {
    const style = { background: back, color };
    return <Col xs={12} className='h-100 pl-1 pr-1 d-flex flex-column justify-content-center'>
        <Card style={style} >
            <Card.Body>
                <div className='m-0 p-0 d-flex flex-column'>
                    <IsHTML html={top} />
                    <Row className='d-flex flex-row justify-content-center'>
                        <IsHTML width={getWidth(!!left, !!right, width)} html={left} />
                        <Row className='m-0 p-0'>
                            <Image style={{ maxWidth: width + 'px', background: color, border: '0px' }}
                                className={`${marco} ${borde ? 'img-thumbnail' : ''}`} src={getImagenURL(img)} />
                        </Row>
                        <IsHTML width={getWidth(!!left, !!right, width)} html={right} />
                    </Row>
                    <IsHTML html={bottom} />
                </div>
            </Card.Body>
        </Card>
    </Col >
}
function getCSS(color: string) {
    return css`button: before{color: ${color} !important;}`;
}
const getWidth = (l: boolean, r: boolean, w: number) => {
    if (l && r) return `calc(50% - ${w / 2}px)`;
    return `calc(100% - ${w}px)`;
}
interface IsP {
    html: false | BlockElement[];
    width?: string
}
const IsHTML = ({ html, width }: IsP) => {
    if (!html) return <></>
    const w = { width, maxWidth: width };
    return <Row style={w} className='m-0 p-0'>
        <TextToHTML key={'edit' + Math.random()} text={html} />
    </Row>
}