import { useState, useEffect } from 'react';
import { css } from '@emotion/css';
import { Button, InputGroup, ButtonGroup, FormControl } from "react-bootstrap";
import { CardMain, RowConfig, CardConfig, TitleCard, BoxConfig, ButtonImage, ButtonColor, ButtonConfig } from '../../Component';
import { InputText } from "@victorequena22/component-bootstrap";
import { State } from './index';
import { EditArea, Inserts } from '../../Component/SlateCustom';
import { ColorButton, FontStyle, MarkMenu } from "@victorequena22/slate-bootstrap";
import { IconSelector } from "@victorequena22/icon-selector-bootstrap";
export interface Props { data: State, setData: (d: Partial<State>) => void, save: () => void }
export function Config({ save, data, setData }: Props) {
    const { html, tiempo, estilo, link, linkText, activo, icon, backHeader, colorHeader, backChat, backComment, colorComment, backChatButton, colorChatButton, backBotton, colorBotton } = data;
    const [key, setKey] = useState(Math.random());
    useEffect(() => setKey(Math.random()), [html.length]);
    return <CardMain key={`${estilo}${activo ? 'siBoton' : 'noBoton'}`}>
        <RowConfig style={{ width: '100%' }}>
            <Guardar guardar={save} />
            <CardConfig width='200px'>
                <RowConfig>
                    <InputGroup data-tip='ESTILO DE GLOBO DE CHAT' className='input-group-sm' style={{ width: '100%', height: '24px' }}>
                        <InputGroup.Prepend style={{ height: '24px', width: '55px' }}>ESTILO:</InputGroup.Prepend>
                        <Selector chance={setData} value={estilo} />
                    </InputGroup>
                </RowConfig>
                <RowConfig top={2}>
                    <IconSelector value={icon} onChange={icon => setData({ icon })} />
                    <ButtonImage className='ml-1' setData={img => setData({ img })} />
                    <ButtonConfig tip={`ACTIVAR BOTON FLOTANTE: ${activo ? 'SI' : 'NO'}`} className='ml-1' variant={activo ? 'success' : 'secondary'} action={() => setData({ activo: !activo })} icon={`fas fa-${activo ? 'eye' : 'eye-slash'}`} />
                </RowConfig>
                <TitleCard>OPCIONES</TitleCard>
            </CardConfig>
            <CardConfig width='calc(100% - 735px)'>
                <RowConfig >
                    <span style={{ height: '24px', width: '110px' }}>ENCABEZADO:</span>
                </RowConfig>
                <RowConfig top={2}>
                    <InputText data-tip='TEXTO EN EL ENCABEZADO' value={tiempo} update='tiempo' style={{ height: '24px' }} className='form-control-sm' setData={setData} />
                </RowConfig>
                <TitleCard>ENCABEZADO</TitleCard>
            </CardConfig>
            <CardConfig width='450px'>
                <RowConfig>
                    <InputGroup data-tip='TEXTO EN EL BOTON DEL AREA DE MENSAJES' className='input-group-sm' style={{ width: '100%', height: '24px' }}>
                        <InputGroup.Prepend style={{ height: '24px', width: '65px' }}>TEXTO:</InputGroup.Prepend>
                        <InputText value={linkText} update='linkText' style={{ height: '24px' }} className='form-control-sm' setData={setData} />
                    </InputGroup>
                </RowConfig>
                <RowConfig top={2}>
                    <InputGroup data-tip='LINK DEL BOTON EN AREA DE MENSAJES AL QUE VA HA REDIRECIONAR' className='input-group-sm' style={{ width: '100%', height: '24px' }}>
                        <InputGroup.Prepend style={{ height: '24px', width: '65px' }}>LINK:</InputGroup.Prepend>
                        <InputText value={link} update='link' style={{ height: '24px' }} className='form-control-sm' setData={setData} />
                    </InputGroup>
                </RowConfig>
                <TitleCard>BOTON DEL CHAT</TitleCard>
            </CardConfig>
        </RowConfig>
        {estilo === 'personalizado' ? <CardConfig width='100%' marginNull>
            <RowConfig>
                <BoxConfig className='row' style={{ margin: '0 0 0 0' }} width='50%'>
                    <span>
                        ENCABEZADO:
                    </span>
                    <ButtonGroup className='ml-auto'>
                        <ButtonColor disableAlpha={true} tip='COLOR DE FONDO DE LA ZONA DE ENCABEZADO' color={backHeader} setData={backHeader => setData({ backHeader })}>
                            <i className='fas fa-ad' />
                        </ButtonColor>
                        <ButtonColor disableAlpha={true} tip='COLOR DE FUENTE DE LA ZONA DE ENCABEZADO' color={colorHeader} setData={colorHeader => setData({ colorHeader })}>
                            <i className='fas fa-font' />
                        </ButtonColor>
                    </ButtonGroup>
                </BoxConfig>
                <BoxConfig className='row' style={{ margin: '0 0 0 0' }} width='50%'>
                    <span className='ml-3'>
                        BOTON FLOTANTE:
                    </span>
                    <ButtonGroup className='ml-auto'>
                        <ButtonColor disableAlpha={true} tip='COLOR DE FONDO DEL BOTON FLOTANTE' color={backBotton} setData={backBotton => setData({ backBotton })}>
                            <i className='fas fa-ad' />
                        </ButtonColor>
                        <ButtonColor disableAlpha={true} tip='COLOR DE FUENTE DEL BOTON FLOTANTE' color={colorBotton} setData={colorBotton => setData({ colorBotton })}>
                            <i className='fas fa-font' />
                        </ButtonColor>
                    </ButtonGroup>
                </BoxConfig>
            </RowConfig>
            <RowConfig top={2}>
                <BoxConfig className='row' style={{ margin: '0 0 0 0' }} width='50%'>
                    <span>
                        ZONA DE CHAT:
                    </span>
                    <ButtonColor className='ml-auto' tip='COLOR DE FONDO DEL AREA DE MENSAJES' disableAlpha={true} color={backChat} setData={backChat => setData({ backChat })}>
                        <i className='fas fa-ad' />
                    </ButtonColor>
                    <ButtonGroup className='ml-2'>
                        <ButtonColor disableAlpha={true} tip='COLOR DE FONDO DEL GLOBO DE MENSAJES' color={backComment} setData={backComment => setData({ backComment })}>
                            <i className='fas fa-ad' />
                        </ButtonColor>
                        <ButtonColor disableAlpha={true} tip='COLOR DE FUENTE DEL GLOBO DE MENSAJES' color={colorComment} setData={colorComment => setData({ colorComment })}>
                            <i className='fas fa-font' />
                        </ButtonColor>
                    </ButtonGroup>
                </BoxConfig>
                <BoxConfig className='row' style={{ margin: '0 0 0 0' }} width='50%'>
                    <span className='ml-3'>
                        BOTON DE CHAT:
                    </span>
                    <ButtonGroup className='ml-auto'>
                        <ButtonColor tip='COLOR DE FONDO DEL BOTON DEL AREA DE MENSAJES' disableAlpha={true} color={backChatButton} setData={backChatButton => setData({ backChatButton })}>
                            <i className='fas fa-ad' />
                        </ButtonColor>
                        <ButtonColor tip='COLOR DE FUENTE DEL BOTON DEL AREA DE MENSAJES' disableAlpha={true} color={colorChatButton} setData={colorChatButton => setData({ colorChatButton })}>
                            <i className='fas fa-font' />
                        </ButtonColor>
                    </ButtonGroup>
                </BoxConfig>
            </RowConfig>
            <TitleCard>COLORES</TitleCard>
        </CardConfig> : <></>}
        <CardConfig marginNull width="100%">
            <RowConfig>
                <MarkMenu />
                <ColorButton left={1} />
                <Inserts />
                <BoxConfig width='315px' margin='0 0 0 .25rem'> <FontStyle /></BoxConfig>
            </RowConfig>
            <TitleCard>TEXTO Y FUENTE</TitleCard>
        </CardConfig>
        <CardConfig marginNull width="100%" style={{ backgroundColor: backComment }}>
            <RowConfig style={{ color: colorComment }}>
                <EditArea key={`area${key}`} />
            </RowConfig>
            <TitleCard>AREA DE TEXTO</TitleCard>
        </CardConfig>
    </CardMain>
}
function Guardar({ guardar }: { guardar: () => void }) {
    const CN = css`
    color: #28a745;width: 70px;padding: .3rem .3rem .3rem .3rem;
    border-color: #28a745;height: 76px;
    &:hover{color: white;background-color: #28a745;}`;
    return <Button data-tip='GUARDAR' variant='default' onClick={guardar} className={CN} >
        <i className="fa fa-save fa-3x"></i>
    </Button>
}
function Selector({ chance, value }: { chance: (d: Partial<State>) => void, value: string }) {
    const set = (e: React.ChangeEvent<HTMLSelectElement>) => {
        switch (e.currentTarget.value) {
            case 'whatsapp': chance(styleWhatsapp); break;
            case 'facebook': chance(styleFacebook); break;
            case 'telegram': chance(styleTelegram); break;
            default: chance({ estilo: 'personalizado' });
        }
    };
    return <FormControl as='select' onChange={set} value={value}
        style={{ height: '24px', width: '129px', fontSize: '10' }}
        className='form-control-sm ml-1'>
        <option value='whatsapp'>WHAPSAPP</option>
        <option value='facebook'>FACEBOOK</option>
        <option value='telegram'>TELEGRAM</option>
        <option value='personalizado'>PERSONALIZADO</option>
    </FormControl>
}
const styleWhatsapp = {
    estilo: 'whatsapp',
    backHeader: '#095E54', colorHeader: '#FFFFFF',
    backChat: '#E6DDD4',
    backComment: '#FFFFFF', colorComment: '#111111',
    backChatButton: '#4FCE5D', colorChatButton: '#FFFFFF',
    backBotton: '#FFFFFF', colorBotton: '#4AD504',
    icon: { width: 448, height: 512, path: "M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" }
}
const styleFacebook = {
    estilo: 'facebook',
    backHeader: '#FFFFFF', colorHeader: '#111111',
    backChat: '#FFFFFF',
    backComment: '#77A7FF', colorComment: '#FFFFFF',
    backChatButton: '#3578E5', colorChatButton: '#FFFFFF',
    backBotton: '#FFFFFF', colorBotton: '#3578E5',
    icon: { width: 512, height: 512, path: "M256.55 8C116.52 8 8 110.34 8 248.57c0 72.3 29.71 134.78 78.07 177.94 8.35 7.51 6.63 11.86 8.05 58.23A19.92 19.92 0 0 0 122 502.31c52.91-23.3 53.59-25.14 62.56-22.7C337.85 521.8 504 423.7 504 248.57 504 110.34 396.59 8 256.55 8zm149.24 185.13l-73 115.57a37.37 37.37 0 0 1-53.91 9.93l-58.08-43.47a15 15 0 0 0-18 0l-78.37 59.44c-10.46 7.93-24.16-4.6-17.11-15.67l73-115.57a37.36 37.36 0 0 1 53.91-9.93l58.06 43.46a15 15 0 0 0 18 0l78.41-59.38c10.44-7.98 24.14 4.54 17.09 15.62z" }
}
const styleTelegram = {
    estilo: 'telegram',
    backHeader: '#FFFFFF', colorHeader: '#111111',
    backChat: '#d9ffb5',
    backComment: '#77A7FF', colorComment: '#FFFFFF',
    backChatButton: '#3578E5', colorChatButton: '#FFFFFF',
    backBotton: '#3578E5', colorBotton: '#FFFFFF',
    icon: { width: 448, height: 512, path: "M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z" }
}