import React from 'react';
import { Link } from 'react-router-dom';
import Avanzado, { AvanzadosProps } from './Avanzado';
import CardBuscador from '../../../Utiles/Buscadores/Card';
import { Producto } from '../../../Models/Productos';
import FilaAjuste from './FilaAjuste';
import { conAjuste, AjusteFila } from '../../../Models/Ajuste';
import { EstadoNull } from '../../../Models/General';
import { formatoHora } from '../../../Utiles/Formatos';
import AjustesExcel from './Excel';
import { Col } from 'react-bootstrap';
import { fechaToNumber } from "@victorequena22/utiles";
export default class ListAjustes extends CardBuscador {
    permiso = 'AJUSTE';
    estado = EstadoNull;
    con = conAjuste();
    placeholder = "BUSCAR AJUSTE";
    buscarEn = ["codigo", "nombre", 'fecha'];
    fecha_init = 'dd/mm/aaaa';
    fecha_fin = 'dd/mm/aaaa';
    productos: Producto[] = []
    status = -1;
    user = -1;
    tipo: '' | 'SALIDA' | 'ENTRADA' | 'COSTO' | 'UTILIDAD' = '';
    Avansado() {
        const { tipo, fecha_init, fecha_fin, status, user, productos, setAvanzados } = this;
        return <Avanzado setData={setAvanzados.bind(this)}
            estatus={['AJUSTES REVERTIDOS', 'AJUSTES REALIZADOS']} tipo={tipo}
            fecha_init={fecha_init} fecha_fin={fecha_fin}
            status={status} user={user} productos={productos} />
    }
    setAvanzados({ fecha_init, fecha_fin, status, user, productos, tipo }: AvanzadosProps) {
        if (fecha_init) this.fecha_init = fecha_init;
        if (fecha_fin) this.fecha_fin = fecha_fin;
        if (user) this.user = user;
        if (status) this.status = status;
        if (status === 0) this.status = status;
        if (tipo) this.tipo = tipo;
        if (productos) this.productos = productos;
        this.forceUpdate()
    }
    filter() {
        const { tipo, fecha_init, fecha_fin, status, user, productos } = this;
        let itens = this.ordenar();
        return itens.filter((c: AjusteFila) => {
            if (c.status !== status && status !== -1) return false;
            if (c.usuario !== user && user !== -1) return false;
            if (c.tipo !== tipo && tipo !== '') return false;
            const creacion = fechaToNumber(c.fecha);
            if (fecha_fin !== 'dd/mm/aaaa' && creacion > fechaToNumber(fecha_fin)) return false;
            if (fecha_init !== 'dd/mm/aaaa' && creacion < fechaToNumber(fecha_init)) return false;
            if (!productos.every(p => c.detalles.some(d => p.codigo === d))) return false;
            return true;
        });
    }
    Title() {
        return <>
            <h4 className='mr-auto'><i className="fa fa-search"></i> BUSCAR AJUSTE</h4>
            <Link to="/producto/ajuste/nuevo" className="btn btn-primary"><span className="fa fa-plus"></span> NUEVO AJUSTE</Link>
        </>
    }
    Header() {
        const { Th } = this;
        return <tr className="info">
            <Th label='codigo' width='100px'>N°</Th>
            <Th label='fecha' width='90px'>FECHA</Th>
            <Th label='nombre' width='calc(100% - 470px)'>USUARIO</Th>
            <Th width='100px'>TIPO</Th>
            <Th width='100px'>ESTADO</Th>
            <Th width='70px'>{undefined}</Th>
        </tr>
    }
    Row(p: AjusteFila) {
        return <FilaAjuste update={this.set.bind(this)} key={"cot" + p.codigo + p.status} data={p} />
    }
    getEstado() {
        return this.estado;
    }
    set() {
        this.estado = { status: 2, hora: '...ACTUALIZANDO' };
        this.con.lista((itens) => {
            this.estado = { status: 3, hora: formatoHora(undefined) };
            this.setState({ itens: this.arrayToAjuste(itens) });
        })
    }
    Actualizar() {
        const { hora } = this.getEstado();
        return <Col md={3} className='mr-auto d-flex flex-row-reverse'> 
            <AjustesExcel ajustes={this.filter()} />
            <div style={{ fontSize: '12px', width: "200px", paddingLeft: '15px' }}>
                <strong>ACTUALIZADO:</strong> <br />
                {hora}
            </div>
        </Col>
    }
    arrayToAjuste(a: any[]): AjusteFila[] {
        return a.map(a => {
            return {
                codigo: a[0],
                usuario: a[1],
                nombre: a[2],
                tipo: a[3],
                fecha: a[4],
                nota: a[5],
                status: a[6],
                detalles: a[7],
                monto: 0,
                rif: '',
                tasa: 0
            }
        })
    }
}
