import { useContext, useState, useMemo } from 'react';
import { DolarType, DolarContext } from '../Context/Producto/Dolar';
import { SeccionType, SeccionContext } from '../Context/Seccion';
import { Modal } from 'react-bootstrap';
import Dolar from '../App/Dashboard/Daministrativo/Dolar';
import { numberFormat } from '../Utiles/Formatos';
export default function Tasa() {
    const [open, setOpen] = useState(false);
    const { tasa, banco } = useContext(DolarContext) as DolarType;
    return <li className="nav-item">
        <ModalTasa open={open} close={() => setOpen(false)} />
        {useMemo(() => <span onClick={() => setOpen(true)} className="btn bg-success active">TASA ACTUAL: <b>
            {numberFormat(tasa)} | {numberFormat(banco)}</b></span>, [tasa, banco])}
    </li>
}

function ModalTasa({ open, close }: { open: boolean, close: () => void }) {
    const { data: { Permisos } } = useContext(SeccionContext) as SeccionType;
    return <Modal size='xl' show={open} onHide={close} centered className=' modal-warning' >
        <Modal.Header closeButton>
            <h4 className="m-0 font-weight-bold mr-auto align-self-center">
                <span><b>GRAFICA DEL DOLAR</b></span>
            </h4>
        </Modal.Header>
        <Modal.Body >
            <Dolar p={Permisos} />
        </Modal.Body>
    </Modal>
}