import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Route, Link } from "react-router-dom"
import Ventas from '../App/Reportes/Ventas/reportes';
import Compras from '../App/Reportes/Compras/Reportes';
import Vendedores from '../App/Reportes/Ventas/Vendedores';
import Diferencial from '../App/Reportes/Ventas/Diferencial';
import Inventario from '../App/Reportes/Inventario';
import Historico from '../App/Reportes/Historico';
import Cotizaciones from '../App/Reportes/Cotizacion/reporte';
import Notas from '../App/Reportes/Notas/reporte';
import Ordenes from '../App/Reportes/Orden/reporte';
import HistoricoCompra from '../App/Reportes/Compras/Historico';
import HistoricoFactura from '../App/Reportes/Ventas/Historico';
import HistoricoCotizacion from '../App/Reportes/Cotizacion/Historico';
import HistoricoNota from '../App/Reportes/Notas/Historico';
import HistoricoOrden from '../App/Reportes/Orden/Historico';
import ItensCompra from '../App/Reportes/Compras/Itens';
import ItensCotizacion from '../App/Reportes/Cotizacion/Itens';
import ItensFactura from '../App/Reportes/Ventas/Itens';
import ItensNota from '../App/Reportes/Notas/Itens';
import ItensOrden from '../App/Reportes/Orden/Itens';
import Graficas from '../App/Reportes/Graficas';
import { Menus } from '../Utiles/Componentes/Menus';
import { SeccionContext } from '../Context/Seccion';
export default function MasterReportes() {
    const context = useContext(SeccionContext);
    let p: string[] = []
    if (context !== null)
        p = context.data.Permisos;
    return (<Row style={{ minHeight: "calc(100vh - 145px)" }}>
        <Col md={3} style={{ minHeight: "calc(100vh - 145px)" }}>
            {p.some(d => d === 'FACTURA') ?
                <Menus title='REPORTES DE VENTAS' >
                    <Link className='btn btn-default' to='/reporte/ventas'>
                        VENTAS
                    </Link>
                    <Link className='btn btn-default' to='/reporte/ventas/vendedor'>
                        VENDEDORES
                    </Link>
                    <Link className='btn btn-default' to='/reporte/ventas/diferencial'>
                        DIFERENCIAL CAMBIARIO
                    </Link>
                    <Link className='btn btn-default' to='/reporte/ventas/de'>
                        VENTAS DE UN PORDUCTO
                    </Link>
                    <Link className='btn btn-default' to='/reporte/ventas/productos'>
                        PRODUCTOS POR VENTAS
                    </Link>
                </Menus> : <></>}
            {p.some(d => d === 'NOTA') ?
                <Menus title='REPORTES DE NOTAS DE ENTREGA' >
                    <Link className='btn btn-default' to='/reporte/notas'>
                        NOTAS DE ENTREGA
                    </Link>
                    <Link className='btn btn-default' to='/reporte/notas/de'>
                        N.E DE UN PORDUCTO
                    </Link>
                    <Link className='btn btn-default' to='/reporte/notas/productos'>
                        PRODUCTOS POR N.E
                    </Link>
                </Menus> : <></>}
            {p.some(d => d === 'COTIZACION') ?
                <Menus title='REPORTES DE COTIZACIONES' >
                    <Link className='btn btn-default' to='/reporte/cotizacion'>
                        COTIZACIONES
                    </Link>
                    <Link className='btn btn-default' to='/reporte/cotizacion/de'>
                        COTIZACIONES DE UN PORDUCTO
                    </Link>
                    <Link className='btn btn-default' to='/reporte/cotizacion/productos'>
                        PRODUCTOS POR COTIZACIONES
                    </Link>
                </Menus> : <></>}
            {p.some(d => d === 'FACTURA') ?
                <Menus title='REPORTES DE COMPRAS' >
                    <Link className='btn btn-default' to='/reporte/compras'>
                        COMPRAS
                    </Link>
                    <Link className='btn btn-default' to='/reporte/compras/de'>
                        COMPRAS DE UN PORDUCTO
                    </Link>
                    <Link className='btn btn-default' to='/reporte/compras/productos'>
                        PRODUCTOS POR COMPRAS
                    </Link>
                </Menus> : <></>}
            {p.some(d => d === 'COMPRA') ?
                <Menus title='REPORTES DE ORDENES DE COMPRA' >
                    <Link className='btn btn-default' to='/reporte/ordenes'>
                        ORDENES DE COMPRA
                    </Link>
                    <Link className='btn btn-default' to='/reporte/ordenes/de'>
                        O.C DE UN PORDUCTO
                    </Link>
                    <Link className='btn btn-default' to='/reporte/ordenes/productos'>
                        PRODUCTOS POR O.C
                    </Link>
                </Menus> : <></>}
            {p.some(d => d === 'PRODUCTO') ?
                <Menus title='REPORTES DE INVENTARIO' >
                    <Link className='btn btn-default' to='/reporte/inventario'>
                        INVENTARIO
                    </Link>
                    <Link className='btn btn-default' to='/reporte/inventario/historico'>
                        HISTORICO
                    </Link>
                </Menus> : <></>}
        </Col>
        <Col md={9} style={{ minHeight: "calc(100vh - 145px)" }}>
            <Route exact path="/reporte"><Graficas /></Route>
            {/* reportes de ventas */}
            <Route exact path="/reporte/ventas"><Ventas /></Route>
            <Route path="/reporte/ventas/vendedor"><Vendedores /></Route>
            <Route path="/reporte/ventas/diferencial"><Diferencial /></Route>
            <Route path="/reporte/ventas/de"><HistoricoFactura /></Route>
            <Route path="/reporte/ventas/productos"><ItensFactura /></Route>
            {/* reportes de notas */}
            <Route exact path="/reporte/notas"><Notas /></Route>
            <Route path="/reporte/notas/de"><HistoricoNota /></Route>
            <Route path="/reporte/notas/productos"><ItensNota /></Route>
            {/* reportes de cotizaciones */}
            <Route exact path="/reporte/cotizacion"><Cotizaciones /></Route>
            <Route path="/reporte/cotizacion/de"><HistoricoCotizacion /></Route>
            <Route path="/reporte/cotizacion/productos"><ItensCotizacion /></Route>
            {/* reportes de cotizaciones */}
            <Route exact path="/reporte/compras"><Compras /></Route>
            <Route path="/reporte/compras/de"><HistoricoCompra /></Route>
            <Route path="/reporte/compras/productos"><ItensCompra /></Route>
            {/* reportes de ordenes */}
            <Route exact path="/reporte/ordenes"><Ordenes /></Route>
            <Route path="/reporte/ordenes/de"><HistoricoOrden /></Route>
            <Route path="/reporte/ordenes/productos"><ItensOrden /></Route>
            {/* reportes de ordenes */}
            <Route exact path="/reporte/inventario"><Inventario /></Route>
            <Route path="/reporte/inventario/historico"><Historico /></Route>
        </Col>
    </Row>)
}