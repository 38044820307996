import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { ProductosContext, ProductosType } from '../../Context/Producto';
import { tipoData } from '../../Models/General';
import PrintCompnent from './PrintCompnent';
interface Props {
    open: boolean,
    close: () => void,
    tipo: tipoData
    id: number,
    title: string
}
export default function ModalPrint({ title, close, tipo, id, open }: Props) {
    const { dolar } = useContext(ProductosContext) as ProductosType;
    return <Modal show={open} size='xl' centered onHide={close}>
        <Modal.Header closeButton>
            <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                <span><b>{title}</b></span>
            </h6>
        </Modal.Header>
        <Modal.Body>
            <PrintCompnent tipo={tipo} id={id} dolar={dolar} />
        </Modal.Body>
    </Modal>
}