import { Row } from 'react-bootstrap';
import { State, iten } from './index'
import { Itens, Tap } from './Itens'
import { getCSS } from './props'
export function Panel({ config, setConfig }: { setConfig: (p: Partial<State>) => void, config: State, seled: (i: number) => void }) {
    const { color, itens, tap, tapTitle, tapPosition, font, variant } = config;
    return <div className={`${getCSS(tap, tapTitle, font)} d-flex flex-${tapPosition}`} style={{ background: color, margin: '0 0 0 0', padding: '10px 0 10px 0' }}>
        <Row style={{ margin: '0 0 0 0', padding: '0 0 0 0' }} as='ul' className={variant}>
            {itens.map((iten, i) => <Tap key={i} config={config} setConfig={setConfig} iten={iten} />)}
        </Row>
        <Itens config={config} setConfig={setConfig} iten={itens.find(i => i.id === config.seleted) as iten} />
    </div>
}