import { ConexionCompleta, setData } from "@victorequena22/conneccion"

export default interface Usuario {
    id_usuario: number,
    usuario: string,
    nivel: number,
    fecha: string,
    rol: string,
    permisos: string[]
}
export const userNull: Usuario = {
    id_usuario: -1,
    usuario: '',
    nivel: 0,
    fecha: '',
    rol: '',
    permisos: []
}
export interface UsuarioFila {
    codigo: number,
    nombre: string,
    correo: string,
    clave?: string
    rol: string
    nivel: number
    estado: number
}

export interface Rol {
    id: number,
    nombre: string
    permisos: Permisos[]
}
export interface Permisos {
    id: number,
    nombre: string
}
class UsuarioConexion extends ConexionCompleta {
    clase = 'Usuario';
    persistencia = 1000000;
    listaRoles = async (setData: setData) => {
        try {
            const data = await this.getProxi('/listar_roles');
            setData(data);
        } catch (e) {
            console.log(e)
        }
    }
    listaPermisos = async (setData: setData) => {
        try {
            const data = await this.getProxi('/listar_permisos');
            setData(data);
        } catch (e) {
            console.log(e)
        }
    }
    PermisosRol = async (cod: number, setData: setData) => {
        try {
            const data = await this.getProxi('/detalles_rol/' + cod);
            setData(data);
        } catch (e) {
            console.log(e)
        }
    }
    nuevoRol = (data: any, setData?: setData) => {
        this.postProxi('/nuevo_rol', data, setData)
    }
    actualizarRol = (codigo: string | number, data: any, setData?: setData) => {
        this.postProxi('/actualizar_rol/' + codigo, data, setData);
    }
    clave = (codigo: string | number, data: any, setData?: setData, setError?: setData) => {
        this.postProxi('/clave/' + codigo, data, setData, setError);
    }

}
export function conUsuario() {
    return new UsuarioConexion();
}