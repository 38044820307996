import { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Arriba, Abajo, Izquierda, Derecha, VentanaH, VentanaV, Remolino, Zoom } from './variants'
import { animatedList } from './props';

export function Selector({ set, ani }: { set: (s: string) => void, ani: animatedList }) {
    const [page, setPage] = useState(getDefault(ani))
    return <ButtonGroup size="sm">
        <Page page={page} set={setPage} />
        {page === 1 ? <>
            <Button data-tip='ANIMACION DE MOVER VERTICAL' onClick={() => set('translateYI')} active={(ani === 'translateYI')} variant='outline-info'><Arriba /></Button>
            <Button data-tip='ANIMACION DE MOVER VERTICAL INVERTIDO' onClick={() => set('translateY')} active={(ani === 'translateY')} variant='outline-info'><Abajo /></Button>
            <Button data-tip='ANIMACION DE MOVER HORIZONTAL' onClick={() => set('translateX')} active={(ani === 'translateX')} variant='outline-info'><Derecha /></Button>
        </> : page === 2 ? <>
            <Button data-tip='ANIMACION DE MOVER HORIZONTAL INVERTIDO' onClick={() => set('translateXI')} active={(ani === 'translateXI')} variant='outline-info'><Izquierda /></Button>
            <Button data-tip='ANIMACION DE ROTACION' onClick={() => set('rotate')} active={(ani === 'rotate')} variant='outline-info'><Remolino /></Button>
            <Button data-tip='ANIMACION DE ROTACION INVERTIDO' onClick={() => set('rotateI')} active={(ani === 'rotateI')} variant='outline-info'><Remolino invert /></Button>
        </> : <>
            <Button data-tip='ANIMACION DE ZOOM' onClick={() => set('scaled')} active={(ani === 'scaled')} variant='outline-info'><Zoom /></Button>
            <Button data-tip='ANIMACION DE VENTANA HORIZONTAL' onClick={() => set('scaledY')} active={(ani === 'scaledY')} variant='outline-info'><VentanaH /></Button>
            <Button data-tip='ANIMACION DE VENTANA VERTICAL' onClick={() => set('scaledX')} active={(ani === 'scaledX')} variant='outline-info'><VentanaV /></Button>
        </>}
    </ButtonGroup >
}
function getDefault(ani: animatedList) {
    switch (ani) {
        case 'translateY':
        case 'translateYI':
        case 'translateX':
            return 1;
        case 'translateXI':
        case 'rotate':
        case 'rotateI':
            return 2;
        default:
            return 3;
    }
}
function Page({ set, page }: { set: (s: number) => void, page: number }) {
    return <ButtonGroup vertical size="sm">
        <Button data-tip='SUBIR' style={{ padding: '0 0 0 0' }} onClick={() => set(page === 1 ? 3 : page - 1)} variant='outline-dark'>
            <i className="fas fa-arrow-up" />
        </Button>
        <Button data-tip='BAJAR' style={{ padding: '0 0 0 0' }} onClick={() => set(page === 3 ? 1 : page + 1)} variant='outline-dark'>
            <i className="fas fa-arrow-down" />
        </Button>
    </ButtonGroup>

}