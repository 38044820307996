import { css } from '@emotion/css';
import { area } from './index';
export function getCSS(size: string) {
    switch (size) {
        case '570px':
        case '780px':
        case '990px':
            return css`
                display: block;
                position: relative !important;
                width: 100%;
                height: 300px !important;`;
        default:
            return css`
                display: block;
                top:0;
                bottom:0;
                left:0;
                right:0;
                position: absolute !important;`;
    }
}
export function getWidth(xs: area, sm: area, md: area, lg: area, xl: area, xxl: area, size: string) {
    const l = (size === '570px') ? xs : (size === '780px') ? sm : (size === '990px') ? md : (size === '1080px') ? lg : (size === '1320px') ? xl : xxl;
    return css`
        ${aspect(size)}
        &> .col{
            margin: 1.5rem  0  1.5rem  0;
            padding: 1.5rem  1.5rem  1.5rem  1.5rem;
            margin-left: ${l.offset}%;
            -webkit-flex: 0 0 ${l.span - l.offset}%;
            -ms-flex: 0 0 ${l.span - l.offset}%;
            flex: 0 0 ${l.span - l.offset}%;
            max-width: ${l.span - l.offset}%;
        }`;
}
export function aspect(size: string) {
    switch (size) {
        case '1080px': return `aspect-ratio: 16/4;`;
        case '1320px': return `aspect-ratio: 16/5;`;
        case '1440px': return `aspect-ratio: 16/6;`;
        default: return ``;
    }
}
export function getWidth2(xs: area, sm: area, md: area, lg: area, xl: area, xxl: area,) {
    return css`
        &> .col{
            margin-left: ${xs.offset}%;
            -webkit-flex: 0 0 ${xs.span}%;
            -ms-flex: 0 0 ${xs.span}%;
            flex: 0 0 ${xs.span}%;
            max-width: ${xs.span}%;
        }
        @media (min-width: 576px) {
            &> .col{
                margin-left: ${sm.offset}%;
                -webkit-flex: 0 0 ${sm.span}%;
                -ms-flex: 0 0 ${sm.span}%;
                flex: 0 0 ${sm.span}%;
                max-width: ${sm.span}%;
            }
        }
        @media (min-width: 768px) {
            &> .col{
                margin-left: ${md.offset}%;
                -webkit-flex: 0 0 ${md.span}%;
                -ms-flex: 0 0 ${md.span}%;
                flex: 0 0 ${md.span}%;
                max-width: ${md.span}%;
            }
        }
        @media (min-width: 992px) {
            &> .col{
                margin-left: ${lg.offset}%;
                -webkit-flex: 0 0 ${lg.span}%;
                -ms-flex: 0 0 ${lg.span}%;
                flex: 0 0 ${lg.span}%;
                max-width: ${lg.span}%;
                aspect-ratio: 16/4;
            }
        }
        @media (min-width: 1200px) {
            &> .col{
                margin-left: ${xl.offset}%;
                -webkit-flex: 0 0 ${xl.span}%;
                -ms-flex: 0 0 ${xl.span}%;
                flex: 0 0 ${xl.span}%;
                max-width: ${xl.span}%;
                aspect-ratio: 16/5;
            }
        }
        @media (min-width: 1400px) {
            &> .col{
                margin-left: ${xxl.offset}%;
                -webkit-flex: 0 0 ${xxl.span}%;
                -ms-flex: 0 0 ${xxl.span}%;
                flex: 0 0 ${xxl.span}%;
                max-width: ${xxl.span}%;
                aspect-ratio: 16/6;
            }
        }`;
}