import { css } from '@emotion/css';
export function getCSS(width: number, gap: number) {
    return css`
        display: grid;
        gap: ${gap}px;
        grid-auto-flow: dense;
        grid-auto-rows:repeat(auto-fill, ${width}px);
        grid-template-columns: repeat(auto-fill, minmax(${width}px, 1fr));
    `;
}

