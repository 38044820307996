import { Button, Modal, Row, Col, FormGroup, FormLabel } from 'react-bootstrap';
import { Proveedor, conProveedor, ProveedorNull } from '../../../Models/Compra';
import { ComprasContext, ComprasType } from '../../../Context/Compras';
import { ControlArea, ControlText } from '../../../Utiles/FormControl';
import { toast } from 'react-toastify';
import React from 'react';
interface Props {
    open: boolean
    Proveedor: Proveedor | null
    title: string
    close: () => void
    add?: (c: Proveedor) => void
}
interface State extends Proveedor {
    activeError: boolean;
}
class FormProveedor extends React.Component<Props, State> {
    error: any = [];
    con = conProveedor();
    constructor(p: Props) {
        super(p)
        this.setState = this.setState.bind(this);
        let state = p.Proveedor;
        if (state === null) state = ProveedorNull;
        this.state = { ...state, activeError: false };
    }
    render() {
        let { setData, state: s } = this
        return <Modal animation show={this.props.open} size='lg' centered onHide={this.props.close}>
            <Modal.Header className='bg-primary' closeButton>
                <h4 className='mr-auto'>{this.props.title}</h4>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col as={FormGroup} xs={12}>
                        <ControlText error={this.error} upper={true} mask='A-99999999-9' maxLength={20}
                            before="RIF" value={s.codigo} setData={setData} update="codigo"
                            disabled={(this.props.Proveedor !== null)} />
                    </Col>
                    <Col as={FormGroup} xs={12}>
                        <ControlText error={this.error} upper={true} before="RAZON SOCIAL" maxLength={150}
                            value={s.nombre} setData={setData} update="nombre" />
                    </Col>
                    <Col as={FormGroup} xs={12}>
                        <FormLabel>DIRECCION</FormLabel>
                        <ControlArea error={this.error} value={s.direccion}
                            className='mt-1' placeholder='DIRECCION' upper={true}
                            setData={setData} update="direccion" maxLength={300} />
                    </Col>
                    <Col as={FormGroup} xs={12}>
                        <ControlText error={this.error} upper={true} maxLength={120}
                            before="TELÉFONO" value={s.telefono} setData={setData} update="telefono" />
                    </Col>
                    <Col as={FormGroup} xs={12}>
                        <ControlText error={this.error} upper={true} before="PERSONA DE CONTACTO"
                            maxLength={50} value={s.contacto} setData={setData} update="contacto" />
                    </Col>
                    <Col as={FormGroup} xs={12}>
                        <ControlText upper={true} before="CORREO" maxLength={150}
                            value={s.correo} setData={setData} update="correo" />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button className="float-right" onClick={this.get.bind(this)} variant="primary">
                    <span className="fa fa-floppy-disk" /> GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    }
    isInvalid(s: string) {
        if (s === "")
            return true
        return false
    }
    action(data: any) {
        const context = this.context as ComprasType;
        if (this.props.add) this.props.add(data);
        context.actProveedor();
        if (this.props.Proveedor === null) this.setState(ProveedorNull);
        this.props.close();

    }
    get() {
        let error: Partial<State> = {};
        let { state: s } = this
        let procesar = true;
        if (s.codigo === "") error.codigo = 'DEBE COLOCAR EL DOCUMENTO LEGAL DEL CLIENTE';
        if (s.nombre === "") error.nombre = 'DEBE COLOCAR LA RAZON SOCIAL';
        if (s.telefono === "") error.telefono = 'DEBE COLOCAR NUMERO DE TELEFONO';
        if (s.direccion === "") error.direccion = 'DEBE COLOCAR UNA DIRECCION';
        if (s.contacto === "") error.contacto = 'DEBE COLOCAR LA PERSONA DE CONTACTO';
        if (Object.keys(error).length > 0) { this.setError([error]); procesar = false; }
        if (procesar) {
            if (this.props.Proveedor === null) this.con.nuevo(s, this.action, this.setError)
            else this.con.actualizar(this.props.Proveedor.codigo, s, this.action, this.setError)
        }
    }
    setError = (err: any) => {
        err.forEach((err: any) => {
            if (err.sql) err.sql.forEach((er: string) => toast(er, { type: toast.TYPE.ERROR }));
            else Object.keys(err).forEach(t => this.error[t] = err[t])
        })
        this.forceUpdate();
    }
    setData = (data: any) => {
        Object.keys(data).forEach(t => this.error[t] = '');
        this.setState(data);
    }
}

FormProveedor.contextType = ComprasContext;
export default FormProveedor;