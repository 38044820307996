import { Button, Col, InputGroup, Row } from 'react-bootstrap';
import { InputText } from '../../../Utiles/Inputs';
import { EstadoNull, GeneralFila } from '../../../Models/General';
import ModalBusqueda from '../../../Utiles/Buscadores/Modal';
import FilaGeneraModal from '../../../Utiles/Prototipos/FilaGeneraModal';
import { formatoHora } from '../../../Utiles/Formatos';
import { conConfig, don } from '@victorequena22/conneccion';
export default class BuscarGuardado extends ModalBusqueda {
    buscarEn = ['codigo', 'nombre', 'fecha',];
    estado = EstadoNull;
    Buscador() {
        return <Row style={{ paddingBottom: '15px' }}>
            <Col className="col-md-9 mr-auto">
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            {this.buscarLabel}
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <InputText upper setData={this.setState} update='buscar' value={this.state.buscar} />
                    <InputGroup.Append>
                        <Button variant="primary">
                            <i className="fa fa-search" /> BUSCAR
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </Col>
            {this.Actualizar()}
        </Row>
    }
    Title() {
        return <h4 className='mr-auto'>BUSCAR GUARDADOS</h4>
    }
    Header() {
        const { Th } = this;
        return <tr className="info">
            <Th label='codigo' width='100px'>N°</Th>
            <Th label='fecha' width='90px'>FECHA</Th>
            <Th label='nombre' width='auto'>USUARIO</Th>
            <Th width='100px'>ESTATUS</Th>
            <Th width='135px'>ACCIONES</Th>
        </tr>
    }
    Row(c: GeneralFila) {
        return <FilaGeneraModal key={c.codigo + c.status} data={c} type='Cotizacion' add={this.add.bind(this)} />
    }
    getEstado() { return this.estado; }
    add(c: GeneralFila) { this.props.add(c); this.props.close(); }
    async set() {
        this.estado = { status: 2, hora: '...ACTUALIZANDO' };
        this.forceUpdate();
        try {
            await conConfig('').get(don + '/api/Cotizacion/plantillas', itens => {
                this.estado = { status: 3, hora: formatoHora(undefined) };
                this.setState({ itens })
            })
        } catch (error) {
            console.log(error)
        }
    }
}