import { useState } from "react";
import { ButtonImage, ButtonConfig } from '../../Panel/Component';
import { Modal, ButtonGroup } from "react-bootstrap";
import { Main } from "./Editor";
import './Modal.css';
interface Props {
    img: string
    setImg: (img: string) => void,
    aspect: number
}
export function CropModal({ img, setImg, aspect }: Props) {
    return <ButtonGroup>
        <ButtonImage setData={setImg} />
        <EditModal setImg={setImg} img={img} aspect={aspect} />
    </ButtonGroup>
}
export function EditModal({ img, setImg, aspect }: Props) {
    const [open, setOpen] = useState(false);
    return <>
        <Modal Modal className='super-modal' style={{ overflow: 'hidden' }} animation show={open} onHide={() => setOpen(false)}>
            <Modal.Body>
                <Main close={() => setOpen(false)} img={img} setImg={(s) => { setImg(s); setOpen(false) }} aspect={aspect} />
            </Modal.Body>
        </Modal>
        <ButtonConfig tip='EDITAR IMAGEN' action={() => setOpen(true)} icon='fas fa-edit' />
    </>
}