
import React from 'react';
import { conAjuste, AjusteFila } from '../../../Models/Ajuste';
import { Button, ButtonGroup } from 'react-bootstrap';
import { formatoFecha, zfill } from '../../../Utiles/Formatos';
import ModalAlert from '../../../Utiles/Componentes/ModalAlert';
import Detalles from './Detalles';
import THFila from '../../../Utiles/Componentes/THFila';
import ModalReport from '../../../Utiles/Componentes/ModalReport';



interface Props {
    data: AjusteFila
    update?: () => void
}
interface State {
    print: boolean
    detalles: boolean
    aux: boolean
    eliminar: boolean
}
export default class FilaAjuste extends React.Component<Props, State>{
    con = conAjuste();
    state = { print: false, detalles: false, aux: false, eliminar: false };
    render() {
        let cot = this.props.data;
        return <tr >
            <th style={{ width: '100px' }}>
                <Button data-tip="VER DETALLEA" onClick={() => { this.setState({ detalles: true }) }} variant="link" >
                    {zfill(cot.codigo)}
                </Button>
            </th>
            <THFila width="120px" align='center'>{formatoFecha(cot.fecha)}</THFila>
            <THFila width="auto" align='left'>{cot.nombre}</THFila>
            <THFila width="100px" align='center'>{cot.tipo}</THFila>
            <THFila width="100px" align='center'>{(cot.status === 1) ?
                <span className="badge badge-success">REALIZADO</span>
                : <span className="badge badge-danger">REVERTIDO</span>}</THFila>
            <th style={{ width: '70px' }}>
                {this.getModal()}
                <ButtonGroup className="float-right">
                    <Button data-tip="VER IMPRESION" variant="primary"
                        onClick={() => { this.setState({ print: true }) }}>
                        <i className="fa fa-download"></i>
                    </Button>
                    {(cot.status === 1) ?
                        <Button data-tip="REVERTIR" variant="danger"
                            onClick={() => { this.setState({ eliminar: true }) }} >
                            <i className="fa fa fa-ban"></i>
                        </Button> : <></>}
                </ButtonGroup>
            </th>
        </tr > 
    }
    getModal() {
        let cot = this.props.data;
        return <>
            <ModalAlert title={"REVERTIR EL AJUSTE N° " + zfill(cot.codigo)}
                key={'ModalAlert' + cot.tipo + zfill(cot.codigo)}
                msn={"ESTA SEGURO QUE REVERITRA LA EL AJUSTE N°" + zfill(cot.codigo) + "?"}
                open={this.state.eliminar} close={this.close.bind(this)} accion={this.cancelar.bind(this)} />
            <Detalles titulo={"DETALLES EL AJUSTE N° " + zfill(cot.codigo)}
                key={'ModalDetalles' + cot.tipo + zfill(cot.codigo)} id={cot.codigo}
                open={this.state.detalles} close={this.close.bind(this)} />
            <ModalReport title={"IMPRIMIR AJUSTE N° " + zfill(cot.codigo)}
                url={'/api/Ajuste/PDF/' + cot.codigo}
                key={'ModalPrint' + cot.tipo + cot.codigo}
                open={this.state.print} close={this.close.bind(this)} />
        </>;
    }
    getStatus() {
        if (this.props.data.status === 1)
            return <span className="badge badge-success">ACTIVA</span>
        return <span className="badge badge-danger">CANCELADA</span>
    }
    getButtons() {
        return
    }
    close() {
        this.setState({ print: false, detalles: false, aux: false, eliminar: false });
    }
    cancelar() {
        this.con.cancelar(this.props.data.codigo, this.props.update)
        this.close();
    }
}