import { ButtonGroup, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { conCompra } from '../../../Models/Compra';
import FilaCompras from '../../../Utiles/Compra/FilaCompras';
import BTNCancelar from '../../../Utiles/Prototipos/Fila/Botones/BTNCancelar';
import BTNImprimir from '../../../Utiles/Prototipos/Fila/Botones/BTNImprimirDolar';
import BTNFacturar from './RegistrarFactura';

export default class FilaCompra extends FilaCompras {
    con = conCompra();
    constructor(p: any) {
        super(p);
        this.label = 'COMPRA';
        this.type = 'Compra';
    }
    Botones() {
        const { type, con, label, cancelado, data: { status, codigo } } = this;
        return <th style={{ minWidth: "135px", maxWidth: "135px" }}>
            <ButtonGroup className="float-right">
                {(status !== 2) ? <></> : <Button as={Link}data-tip="GENERAR NOTA DE CREDITO O DEBITO"  variant='warning' to={`compra/${codigo}/Nota`}><i className='fa fa-file' /></Button>}
                {(status !== 1) ? <></> : <BTNFacturar id={codigo} />}
                <BTNImprimir label={label} id={codigo} tipo={type} />
                {(status === 0) ? <></> :
                    <BTNCancelar con={con} label={label} id={codigo} msn={cancelado()} />}
            </ButtonGroup>
        </th>
    }
    cancelado() {
        return 'COMPRA N° ' + this.data.codigo + ' CANCELADA ESXITOSAMENTE';
    }
}