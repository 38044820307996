import { Component } from 'react'
import { toast } from 'react-toastify'
import { busqueda, ordenNumero } from '@victorequena22/utiles'
import { Detalles, GeneralData } from '@/Models/General'
import { Producto } from '@/Models/Productos'
interface State {
    pagina: number
    buscar: string
    aux: boolean
    aux2: boolean
    aux3: boolean
    aux4: boolean
    aux5: boolean
    nota: boolean
}
interface Props {
    data: GeneralData
    setData: (data: any) => void
}
export type ProductosModal = 'Cotizacion' | 'Compra' | 'Factura' | 'Nota' | 'Orden' | 'SALIDA' | 'ENTRADA' | 'COSTO' | 'UTILIDAD' | 'Seleccion';
export default class InterfaceNuevo<p = {}> extends Component<Props & p, State> {
    setData: (data: any) => void;
    context: any = {};
    nuevo = false;
    productos: ProductosModal = 'Compra';
    constructor(p: Props & p) {
        super(p);
        this.setData = p.setData;
        this.state = { pagina: 0, nota: false, buscar: '', aux: false, aux2: false, aux3: false, aux4: false, aux5: false };
    }
    add = (pr: Detalles) => {
        const { props: { data: { detalles } } } = this;
        pr.precio = parseFloat(pr.precio.toFixed(2));
        if (detalles.some(p => p.codigo === pr.codigo))
            this.setDetalles(detalles.map(p => p.codigo === pr.codigo ? ({ ...pr, index: p.index }) : p))
        else this.setDetalles([...detalles, pr]);
    }
    remove = (pr: Detalles) => {
        const { props: { data: { detalles } } } = this;
        this.setDetalles(detalles.filter(p => p.codigo !== pr.codigo))
        toast('PRODUCTO ' + pr.descripcion + " QUITADO", { type: toast.TYPE.SUCCESS });
    }
    ord = 0;
    orden = (detalles: Detalles[]) => {
        const i = this.nIndex(detalles);
        if (this.ord !== detalles.length || i > 99900) {
            this.ord = detalles.length;
            return detalles
                .sort((a, b) => ordenNumero(a.index, b.index, 'asc'))
                .map((p, i) => ({ ...p, index: i + 1 }))
        }
        return detalles;
    }
    getBusqueda = () => {
        const { props: { data: { detalles } }, state: { buscar } } = this;
        const det = busqueda(detalles, buscar, ['codigo', 'descripcion']);
        return det;
    }
    getPagina = (detalles: Detalles[]) => {
        if (detalles.length < 60) return detalles;
        const { state: { pagina } } = this;
        const min = pagina * 40, max = (pagina + 1) * 40;
        return detalles.filter((_p, i) => i >= min && i < max);
    }
    close = () => this.setState({ aux: false });
    addProductos = (pr: Detalles) => this.add(pr);
    removeProductos = (pr: Detalles) => this.remove(pr);
    nextIndex = () => this.nIndex(this.props.data.detalles);
    nIndex = (detalles: Detalles[]) => Math.max(...detalles.map(({ index }) => index)) + 1;
    setDetalles = (detalles: Detalles[]) => this.setData({ detalles: this.orden(detalles) });
    imponible = () => this.props.data.detalles.reduce((a, p) => (!p.exento) ? a + (p.precio * p.unidades) : a, 0)
    exento = () => this.props.data.detalles.reduce((a, p) => (p.exento) ? a + (p.precio * p.unidades) : a, 0)
    nuevoProductos = (pr: Producto) => this.add({ ...pr, index: this.nextIndex(), unidades: 1, precio: pr.costo, comentario: "" });
}