import React, { useContext } from 'react';
import { FormControl } from 'react-bootstrap';
import { UTDContext, UTDType } from '../../../Context/Producto/UTD';
interface Props {
    change: (r: string) => void,
    defaultValue: string,
    width?: string
    tipo?: 'TODOS LOS ' | 'SELECCIONAR'
    isInvalid?: boolean
}
export default function DepartamentoSelector({ change, defaultValue, width, tipo = 'TODOS LOS ', isInvalid }: Props) {
    const context = useContext(UTDContext) as UTDType;
    const setRol = (Rol: React.ChangeEvent<HTMLSelectElement>) => { change(Rol.currentTarget.value) }
    return <FormControl as='select' style={{ width }} onChange={setRol} value={defaultValue} isInvalid={isInvalid}>
        <option value='TODO'>{tipo} DEPARTAMENTO</option>
        {context.Departamentos.filter(d => d.estatus === 1).map(c => <option key={"user" + c.codigo} value={c.codigo}>{c.descripcion}</option>)}
    </FormControl>
}