import { ConexionCompleta } from "@victorequena22/conneccion";

export interface Sub {
    id: number
    nombre: string
    correo: string
}
export interface Contact {
    id: number
    nombre: string
    correo: string
    asunto: string
    telefono: string
    mensaje: string
}

export function arrayToSub(f: any[]): Sub[] {
    return f.map(([a, b, c]) => { return { id: a, nombre: b, correo: c } });
}
export function arrayToContact(f: any[]): Contact[] {
    return f.map(([a, b, c, e, f, g]) => { return { id: a, nombre: b, correo: c, asunto: e, telefono: f, mensaje: g } });
}
class SubConeccion extends ConexionCompleta {
    clase = 'Web/Suscribirse';
    persistencia = 1000;
    delete = (id: number, setData: () => void, setError: () => void) => {
        this.get(`/api/${this.clase}/delete/${id}`, setData, setError)
    }
}
export function conSub() {
    return new SubConeccion();
}
class ContactConeccion extends ConexionCompleta {
    clase = 'Web/Contacto';
    persistencia = 1000;
    delete = (id: number, setData: () => void, setError: () => void) => {
        this.get(`/api/${this.clase}/delete/${id}`, setData, setError)
    }
}
export function conContac() {
    return new ContactConeccion();
}