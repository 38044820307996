import { TextToHTML } from "@victorequena22/slate-bootstrap";
import { UseIcon } from "@victorequena22/icon-selector-bootstrap";
import { State } from './index';  

export function Chat({ company, data: { tiempo, icon, colorBotton, colorChatButton, linkText, link, html } }: { company?: any, data: State }) {
    const { nombre } = company ? company : { nombre: '' };
    return <>
        <div style={{ width: "360px", borderRadius: '10px', overflow: 'hidden', border: '#11111144 solid 2px' }}>
            <div color="rgb(255, 255, 255)" role="button" className="whatsapp-cerrar" />
            <div className="whatsapp-header">
                <div className="whatsapp-img-conten">
                    <div className="whatsapp-img-circle">
                        <div className="whatsapp-img"></div>
                    </div>
                </div>
                <div className="whatsapp-header-title">
                    <div className="whatsapp-header-nombre">{nombre}</div>
                    <div className="whatsapp-header-msn">{tiempo}</div>
                </div>
            </div>
            <div className="whatsapp-chat-conten">
                <div className="whatsapp-chat-dialog">
                    <div className="whatsapp-chat-aux" style={{ opacity: 0 }}>
                        <div className="whatsapp-chat-space"></div>
                    </div>
                    <div className="whatsapp-chat-pop" style={{ opacity: 1 }}>
                        <div className="whatsapp-chat-pop-header">
                            {nombre}
                        </div>
                        <div className="whatsapp-chat-pop-msn"><TextToHTML text={html} /></div>
                    </div>
                </div>
                <a target="_blank" role="button" className="whatsapp-chat-button"
                    href={link} title="WhatsApp" rel="noreferrer">
                    <UseIcon icon={icon} width={20} height={20} fill={colorChatButton} />
                    <span className="whatsapp-chat-button-msn">{linkText}</span>
                </a>
            </div>
        </div>
        <div role="button" style={{ marginTop: 35 }} className="whatsapp-button">
            <div className="Icon__Component-sc-6s5exc-0 eaUCvE Bubble__StyledIcon-sc-83hmjh-1 whatsapp-button-svg">
                <UseIcon icon={icon} width={32} height={32} fill={colorBotton} />
            </div>
        </div>
    </>
}