import React from "react";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Button } from "react-bootstrap";

export default class ProductosExcel extends React.Component<{ productos: any[] }, {}>{
    _export = React.createRef<any>();
    excelExport() {
        if (this._export.current)
            this._export.current.save();
    };
    render() {
        const { productos } = this.props;
        if (productos.length === 0) return <></>
        return <> 
        <Button variant='success' onClick={this.excelExport.bind(this)} style={{marginRight:'15px'}}>
        <i className="fa fa-file-excel"/>
        </Button>
        <ExcelExport data={productos} ref={this._export} fileName="Productos">
        <ExcelExportColumn field="codigo" title="N°" width={120} />
        <ExcelExportColumn field="descripcion" title="DESCRIPCION" width={2100} />
        <ExcelExportColumn field="fecha" title="FECHA" width={200} />
        <ExcelExportColumn field="cantidad" title="CANTIDAD" width={120} />
        <ExcelExportColumn field="costo" title="COSTO" width={200} />
        

        </ExcelExport>
        </>
    }

}