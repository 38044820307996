import { css } from '@emotion/css';
export function getCSS(a:animatedList) {
    return css`
    width: 100%;
    overflow: hidden;
    &>.carousel-inner{
        ${aux[a].inert}
    }
    &> .carousel-inner.prev> *,
    &> .carousel-inner.next> *,{
        transition: all 0.25s;
    }
    &> .carousel-inner> .iten{
        ${aux[a].stop}
    }
    ${aux[a].prev}
    ${aux[a].next}`;
}
export type animatedList = 'translateX' | 'translateY' | 'scaled' | 'scaledY' | 'scaledX' | 'rotate' | 'translateXI' | 'translateYI' | 'scaledI' | 'scaledYI' | 'scaledXI' | 'rotateI';
const iner = '&> .carousel-inner';
const prev = `${iner}.prev> .iten`;
const next = `${iner}.next> .iten`;
const aux = {
    translateX: {
        type: 'inner',
        inert: 'width: 300%;margin-left: -100%;',
        prev: `${prev}{transform: translateX(100%);}`,
        next: `${next}{transform: translateX(-100%);}`,
        stop: `position: relative;
            display: inline-block;
            width: 33.333333%;
            height: 100%;
            overflow: hidden;`
    },
    translateY: {
        type: 'inner',
        inert: 'height: 300%;margin-top: -43.7%;',
        prev: `${prev}{transform: translateY(100%);}`,
        next: `${next}{transform: translateY(-100%);}`,
        stop: `
            position: relative;
            display: inline-block;
            width: 100%;
            height: 33.333333%;
            overflow: hidden;`
    },
    scaled: {
        type: 'iten',
        inert: 'height: 100%;width: 100%;',
        prev: `
            ${iner}> .iten.prev{z-index:1;}
            ${prev}{transform: scale(0, 0);}
            ${iner}> .iten.prev{transform: scale(1, 1);}`,
        next: `
            ${iner}> .iten.next{transform: scale(0, 0);z-index:3;}
            ${next}.next{transform: scale(1, 1);}`,
        stop: `
            z-index:2;
            position: absolute;
            top:0;left:0;right:0;bottom:0;
            display: inline-block;
            width: 100%;
            height: 100%;            
            overflow: hidden;`
    },
    scaledY: {
        type: 'iten',
        inert: 'height: 100%;width: 100%;',
        prev: `
            ${iner}> .iten.prev{z-index:1;}
            ${prev}{transform: scaleY(0);}
            ${iner}> .iten.prev{transform: scaleY(1);}`,
        next: `
            ${iner}> .iten.next{transform: scaleY(0);z-index:3;}
            ${next}.next{transform: scaleY(1);}`,
        stop: `
            z-index:2;
            position: absolute;
            top:0;left:0;right:0;bottom:0;
            display: inline-block;
            width: 100%;
            height: 100%;            
            overflow: hidden;`
    },
    scaledX: {
        type: 'iten',
        inert: 'height: 100%;width: 100%;',
        prev: `
            ${iner}> .iten.prev{z-index:1;}
            ${prev}{transform: scaleX(0);}
            ${iner}> .iten.prev{transform: scaleX(1);}`,
        next: `
            ${iner}> .iten.next{transform: scaleX(0);z-index:3;}
            ${next}.next{transform: scaleX(1);}`,
        stop: `
            z-index:2;
            position: absolute;
            top:0;left:0;right:0;bottom:0;
            display: inline-block;
            width: 100%;
            height: 100%;            
            overflow: hidden;`
    },
    rotate: {
        type: 'iten',
        inert: 'height: 100%;width: 100%;',
        prev: `
            ${iner}> .iten.prev{z-index:1;}
            ${prev}{transform: scale(0, 0) rotate(-240deg);}
            ${iner}> .iten.prev{transform: scale(1, 1) rotate(0deg);}`,
        next: `
            ${iner}> .iten.next{transform: scale(0, 0) rotate(-240deg);z-index:3;}
            ${next}.next{transform: scale(1, 1) rotate(0deg);}`,
        stop: `
            z-index:2;
            position: absolute;
            top:0;left:0;right:0;bottom:0;
            display: inline-block;
            width: 100%;
            height: 100%;            
            overflow: hidden;`
    },
    translateXI: {
        type: 'inner',
        inert: 'width: 300%;margin-left: -100%;',
        prev: `${prev}{transform: translateX(-100%);}`,
        next: `${next}{transform: translateX(100%);}`,
        stop: `position: relative;
            display: inline-block;
            width: 33.333333%;
            height: 100%;
            overflow: hidden;`
    },
    translateYI: {
        type: 'inner',
        inert: 'height: 300%;margin-top: -43.7%;',
        prev: `${prev}{transform: translateY(-100%);}`,
        next: `${next}{transform: translateY(100%);}`,
        stop: `position: relative;
            display: inline-block;
            width: 100%;
            height: 33.333333%;
            overflow: hidden;`
    },
    scaledI: {
        type: 'iten',
        inert: 'height: 100%;width: 100%;',
        prev: `
            ${iner}> .iten.prev{transform: scale(0, 0);z-index:3;}
            ${prev}.prev{transform: scale(1, 1);}`,
        next: `
            ${iner}> .iten.next{z-index:1;}
            ${next}{transform: scale(0, 0);}
            ${iner}> .iten.next{transform: scale(1, 1);}`,
        stop: `
            z-index:2;
            position: absolute;
            top:0;left:0;right:0;bottom:0;
            display: inline-block;
            width: 100%;
            height: 100%;            
            overflow: hidden;`
    },
    rotateI: {
        type: 'iten',
        inert: 'height: 100%;width: 100%;',
        prev: `
            ${iner}> .iten.prev{transform: scale(0, 0) rotate(-240deg);z-index:3;}
            ${prev}.prev{transform: scale(1, 1) rotate(0deg);}`,
        next: `
            ${iner}> .iten.next{z-index:1;}
            ${next}{transform: scale(0, 0) rotate(-240deg);}
            ${iner}> .iten.next{transform: scale(1, 1);}`,
        stop: `
            z-index:2;
            position: absolute;
            top:0;left:0;right:0;bottom:0;
            display: inline-block;
            width: 100%;
            height: 100%;            
            overflow: hidden;`
    },
    scaledYI: {
        type: 'iten',
        inert: 'height: 100%;width: 100%;',
        prev: `
            ${iner}> .iten.prev{transform: scaleY(0);z-index:3;}
            ${prev}.prev{transform: scaleY(1);}`,
        next: `
            ${iner}> .iten.next{z-index:1;}
            ${next}{transform: scaleY(0);}
            ${iner}> .iten.next{transform: scaleY(1);}`,
        stop: `
            z-index:2;
            position: absolute;
            top:0;left:0;right:0;bottom:0;
            display: inline-block;
            width: 100%;
            height: 100%;            
            overflow: hidden;`
    },
    scaledXI: {
        type: 'iten',
        inert: 'height: 100%;width: 100%;',
        prev: `
            ${iner}> .iten.prev{transform: scaleX(0);z-index:3;}
            ${prev}.prev{transform: scaleX(1);}`,
        next: `
            ${iner}> .iten.next{z-index:1;}
            ${next}{transform: scaleX(0);}
            ${iner}> .iten.next{transform: scaleX(1);}`,
        stop: `
            z-index:2;
            position: absolute;
            top:0;left:0;right:0;bottom:0;
            display: inline-block;
            width: 100%;
            height: 100%;            
            overflow: hidden;`
    },
} 