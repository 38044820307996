import { semaforo } from "@victorequena22/utiles"
import { ConexionCompleta, setData } from "@victorequena22/conneccion"
export interface Panel {
    id: number
    indice: number
    pagina: number
    title: string
    type: string
    data: any
    text: string
    estado: number
    items: Items[]
}
export interface Items {
    id: number
    panel_id: number
    indice: number
    type: string
    data: any
    text: string
}
export const panelNull: Panel = {
    id: 0,
    indice: 0,
    pagina: 1,
    type: '',
    title: '',
    data: {},
    text: '',
    estado: 0,
    items: []
}
export const itemNull: Items = {
    id: 0,
    panel_id: 0,
    indice: 0,
    type: '',
    data: {},
    text: ''
}
export interface PanelProps {
    data: any
    text: string
    setText: (s: string) => void,
    removeItems?: (n: number) => void,
    items?: Items[]
    Items: JSX.Element
}
class PanelConeccion extends ConexionCompleta {
    clase = 'Panel';
    page = async (id: number, page: any, setData: setData, setError?: setData) => {
        try {
            const data = await this.getProxi(`/page/${id}/${page}`)
            if (data.errores && setError) setError(data.errores)
            else setData(data)
        } catch (error) {
            if (setError) setError(error)
        }
    }
    up = async (indice: number, setData: setData, setError?: setData) => {
        try {
            const data = await this.getProxi(`/up/${indice}`)
            if (data.errores && setError) setError(data.errores)
            else setData(data)
        } catch (error) {
            if (setError) setError(error)
        }
    }
    down = async (indice: number, setData: setData, setError?: setData) => {
        try {
            const data = await this.getProxi(`/down/${indice}`)
            if (data.errores && setError) setError(data.errores)
            else setData(data)
        } catch (error) {
            if (setError) setError(error)
        }
    }
    delete = async (id: number, setData: setData, setError?: setData) => {
        try {
            const data = await this.getProxi(`/delete/${id}`)
            if (data.errores && setError) setError(data.errores)
            else setData(data)
        } catch (error) {
            if (setError) setError(error)
        }
    }
}
export const PanelUpdate = (con: PanelConeccion, _u: Panel[], setData: (c: Panel[]) => void) => {
    const s = semaforo()
    if (s.isTurn('Panel')) con.lista(data => {
        if (data.length) setData(data);
        s.next();
    });
    setTimeout(() => {
        s.next();
    }, 1000);
}
// function setCambioPanel(data: Panel[], c: Panel[]) { return setCambio(data, c); }
export function conPanel() { return new PanelConeccion(); }