import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { tipoData } from "../../../../Models/General";
import ModalPrint from "../../../Componentes/ModalReport";
import { zfill } from "../../../Formatos";
interface Props {
    id: number
    label: string
    tipo: tipoData
}
export default function BTNPrint({ id, label, tipo }: Props) {
    const [open, setOpen] = useState(false);
    return <>
        <Button data-tip="VER IMPRESION" variant="primary"
            onClick={() => setOpen(true)}>
            <i className="fa fa-download"></i>
        </Button>
        <ModalPrint title={`IMPRIMIR ${label} N° ${zfill(id)}`}
            url={`/api/${tipo}/PDF/${id}`} key={'ModalPrint' + label + id}
            open={open} close={() => setOpen(false)} />
    </>
}