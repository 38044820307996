import { useState } from "react";
import { Button } from "react-bootstrap";
import CategoriaBuscar, { PropsCategoriaBuscar } from "./CategoriaBuscar";




export default function CategoriaBuscarBoton(p: PropsCategoriaBuscar) {
    const [o, s] = useState(false);
    return <>
        <CategoriaBuscar  {...p} open={o} close={() => s(false)} />
        <Button data-tip="APLICAR A DEPARTAMENTO" onClick={() => s(true)} variant="warning">
            <span className="fa fa-search"></span>
        </Button>
    </>
}