import React, { useState } from 'react';
import { Button, ButtonGroup, Col, FormGroup, Modal, Row } from 'react-bootstrap';
import { conCompra } from '../../../Models/Compra';
import { zfill } from '../../../Utiles/Formatos';
import { ControlDate, ControlText } from '../../../Utiles/FormControl';
interface Props {
    open: boolean,
    close: () => void
    id: number
    update?: () => void
}
interface State {
    cod_doc: string
    num_con: string
    fecha_doc: string
}
class RegistrarFactura extends React.Component<Props, State> {
    con = conCompra();
    constructor(p: Props) {
        super(p);
        this.state = { cod_doc: "", num_con: "", fecha_doc: "" };
        this.setState = this.setState.bind(this);
        this.actualizar = this.actualizar.bind(this);
    }
    render() {
        let { props: { close, open, id }, state: { cod_doc, num_con, fecha_doc }, setState, actualizar } = this;
        return <Modal bsSize="lg" show={open} onHide={close}>
            <Modal.Header>
                <h4 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    <span><b>REGISTRAR FACTURA DE COMPRA N°: {zfill(id)}</b></span>
                </h4>
            </Modal.Header>
            <Modal.Body>
                <Row style={{ padding: "10px 10px 10px 10px" }}>
                    <Row>
                        <FormGroup as={Col} xs='12'>
                            <ControlText maxLength={50} upper before="NUMERO DE DOCUMENTO" setData={setState} update="cod_doc" value={cod_doc} />
                        </FormGroup>
                        <FormGroup as={Col} xs='12'>
                            <ControlText maxLength={50} upper before="NUMERO DE CONTROL" setData={setState} update="num_con" value={num_con} />
                        </FormGroup>
                        <FormGroup as={Col} xs='12'>
                            <ControlDate before="FECHA DE DOCUMENTO" setData={setState} update="fecha_doc" value={fecha_doc} />
                        </FormGroup>
                    </Row>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup className="float-right">
                    <Button onClick={actualizar} variant="success">
                        <span className="fa fa-check-circle-o"></span> Aceptar
                    </Button>
                    <Button onClick={close} variant="danger">
                        <span className="fa fa-remove-circle"></span> Cancelar
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal >
    }
    actualizar() {
        this.con.facturar(this.props.id, this.state, this.props.update);
    }
}
export default function BTNFacturar({ id }: { id: number }) {
    const [open, setOpen] = useState(false);
    return <>
        <Button data-tip="VER IMPRESION" variant="outline-dark"
            onClick={() => setOpen(true)}>
            <i className="fa fa-edit"></i>
        </Button>
        <RegistrarFactura id={id} open={open}
            key={'RegistrarFacturaCompra' + id}
            close={() => setOpen(false)} />
    </>
}