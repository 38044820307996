import React from 'react';
import { Accordion } from 'react-bootstrap';
import { AuthSome } from '../Config/Permisos';
interface props {
    title: any
    children: any
    permisos: string[]
    event: number
}
export default function Treeview({ title, children, permisos, event }: props) {
    return (AuthSome(permisos)) ? <>
        <Accordion.Toggle eventKey={event + 'Number'} className="nav-item" as='li'>
            {title}
        </Accordion.Toggle>
        <Accordion.Collapse style={{ fontSize: '0.8rem' }} eventKey={event + 'Number'} className='row bg-navy pl-3'>
            <>{children}</>
        </Accordion.Collapse>
    </> : <></>
}
