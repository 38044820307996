import { Button } from 'react-bootstrap';
import { TitleCard, CardConfig, RowConfig } from '../../Panel/Component';
import { Link } from "react-router-dom";
export default function MenuTemplate() {
    return <CardConfig width='170px'>
        <RowConfig>
            <Button data-place='bottom' data-tip='CREAR UN NUEVO PANEL' as={Link} to='/panel/crear' size='sm' variant='success'>
                <i className='fas fa-plus' />
            </Button>
            <Button data-place='bottom' data-tip='VER ATAJOS' className='ml-1' as={Link} to='/panel/short' size='sm' variant='info'>
                <i className='fas fa-hashtag' />
            </Button>
            <Button data-place='bottom' data-tip='CONFIGURAR MENU' className='ml-1' as={Link} to='/panel/menu' size='sm' variant='warning'>
                <i style={{ borderTop: '4px solid currentColor', fontSize: '.6rem' }} className='fas fa-arrow-up' />
            </Button>
            <Button data-place='bottom' data-tip='CONFIGURAR PIE DE PAGINA' className='ml-1' as={Link} to='/panel/pie' size='sm' variant='warning'>
                <i style={{ borderBottom: '4px solid currentColor', fontSize: '.6rem' }} className='fas fa-arrow-down' />
            </Button>
            <Button data-place='bottom' data-tip='CONFIGURAR EL BOTON PARA EL CHAT' className='ml-1' as={Link} to='/panel/chats' size='sm' variant='primary'>
                <i className='fas fa-comment' />
            </Button>
        </RowConfig>
        <TitleCard>OPCIONES</TitleCard>
    </CardConfig>
}