import { conProducto, Producto, ProductoNull } from '../../../../Models/Productos';
import { ProductosContext, ProductosType } from '../../../../Context/Producto';
import { ControlNumber, ControlArea } from '../../../../Utiles/FormControl';
import { ButtonChance } from '../../../../Utiles/Componentes/ButtonChance';
import { InputBS, InputDolar, UtilBS, UtilDolar } from './Inputs';
import DepartamentoSelector from '../../Departamento/Selector';
import { Button, Row, Col, Card } from 'react-bootstrap';
import { ImageSelector } from '../../../Media/Selector';
import UnidadesSelector from '../../Unidad/Selector';
import TipoSelector from '../../Tipos/Selector';
import { ColFeedSelector } from '../../../../Utiles/Componentes/ColFeed';
import { toast } from 'react-toastify';
import React from 'react';

class ProductoNuevo extends React.Component<any, Producto> {
    error: any = [];
    procesar = true;
    con = conProducto();
    constructor(p: any) {
        super(p)
        this.state = ProductoNull;
        this.setState = this.setState.bind(this);
    }
    render = () => {
        let { setState, error, create,
            state: { exento, descripcion, precio1, precio2, precio3, costo,
                tipo, unidad, departamento: d, enser, imagen },
        } = this;
        return (<Card>
            <Card.Header className='bg-info'>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> NUEVO PRODUCTO </h4>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col style={{ minWidth: '17%' }} as={Row}>
                        <ImageSelector img={imagen === '' ? '/public/Productos/Add.svg' : imagen} setImg={imagen => setState({ imagen })} />
                    </Col>
                    <Col style={{ minWidth: '83%' }} as={Row}>
                        <Col md={4} className="mt-2">
                            <ButtonChance block value={enser as any} change={(enser: any) => setState({ enser })}
                                states={['danger', 'success']} message={['NO (ENSER)', 'SI']}>
                                PARA LA VENTA:
                            </ButtonChance>
                        </Col>
                        <ColFeedSelector setData={d => setState({ departamento: d })} valor={d}
                            col={8} Selector={DepartamentoSelector} error={error['departamento']} />
                        <Col md={4} className="mt-2">
                            <ButtonChance block value={exento} change={exento => setState({ exento })}
                                states={['success', 'primary']} message={['SI', 'NO']}>
                                EXENTO:
                            </ButtonChance>
                        </Col>
                        <ColFeedSelector setData={unidad => setState({ unidad })} valor={unidad} col={4} Selector={UnidadesSelector} error={error['unidad']} />
                        <ColFeedSelector setData={tipo => setState({ tipo })} valor={tipo} col={4} Selector={TipoSelector} error={error['tipo']} />
                        <Col xs={12} className="mt-2">
                            <b>DESCRIPCIÓN</b>
                            <ControlArea error={error} rows={3} maxLength={300} value={descripcion} setData={setState} update="descripcion" />
                        </Col>
                        <Col md={2} className='mt-3'>COSTO:</Col>
                        <InputBS error={error} col={5} valor={costo} setData={setState} />
                        <InputDolar error={error} col={5} valor={costo} setData={setState} />
                    </Col><br />
                    <Col style={{ minWidth: '17%' }} as={Row}>
                        <ControlNumber classGroup='mt-2' min={.01} step=".01" after="%" before="UTILIDAD 1" value={precio1} setData={setState} update="precio1" />
                        <ControlNumber classGroup='mt-2' min={.01} step=".01" after="%" before="UTILIDAD 2" value={precio2} setData={setState} update="precio2" />
                        <ControlNumber classGroup='mt-2' min={.01} step=".01" after="%" before="UTILIDAD 3" value={precio3} setData={setState} update="precio3" />
                    </Col>
                    <Col style={{ minWidth: '83%' }} as={Row}>
                        <Col md={2} className='mt-3'>PRECIO 1:</Col>
                        <UtilBS update='precio1' col={5} valor={precio1} costo={costo} setData={setState} />
                        <UtilDolar update='precio1' col={5} valor={precio1} costo={costo} setData={setState} />
                        <Col md={2} className='mt-3'>PRECIO 2:</Col>
                        <UtilBS update='precio2' col={5} valor={precio2} costo={costo} setData={setState} />
                        <UtilDolar update='precio2' col={5} valor={precio2} costo={costo} setData={setState} />
                        <Col md={2} className='mt-3'>PRECIO 3:</Col>
                        <UtilBS update='precio3' col={5} valor={precio3} costo={costo} setData={setState} />
                        <UtilDolar update='precio3' col={5} valor={precio3} costo={costo} setData={setState} />
                    </Col><br />
                    <Col as={Row}>
                        <b></b>
                        <Button className='ml-auto mt-2' variant="success" onClick={create}>
                            <i className="fa fa-save"></i> Guardar
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card >)
    }
    create = () => {
        this.error = [];
        let procesar = true;
        let { descripcion, tipo, unidad, departamento, costo } = this.state;
        if (descripcion === '') {
            this.error['descripcion'] = 'DEBE COLOCAR UNA DESCRIPCION';
            procesar = false;
        } if (departamento === 'TODO') {
            this.error['departamento'] = 'DEBE SELECCIONAR UN DEPARTAMENTO';
            procesar = false;
        } if (tipo === -1) {
            this.error['tipo'] = 'DEBE SELECCIONAR UN TIPO DE PRODUCTO';
            procesar = false;
        } if (unidad === -1) {
            this.error['unidad'] = 'DEBE SELECCIONAR UNA UNIDAD DE MEDIDA';
            procesar = false;
        } if (costo === 0) {
            this.error['costo'] = "DEBE COLOCAR EL COSTO";
            procesar = false;
        }
        if (procesar) {
            if (this.procesar) {
                this.procesar = false;
                this.con.nuevo(this.state, data => {
                    this.props.add(data);
                    this.procesar = true;
                    let { precio1, precio2, precio3 } = this.context as ProductosType;
                    this.setState({ ...ProductoNull, precio1, precio2, precio3 })
                    this.props.close();
                }, this.setError)
            }
        } else
            this.forceUpdate();
    }
    setContextUtilidades = () => {
        let { precio1, precio2, precio3 } = this.context as ProductosType;
        if (precio1 > 1) this.setState({ precio1, precio2, precio3 })
        else setTimeout(this.setContextUtilidades, 100);
    }
    setError = (err: any) => {
        if (err.response) {
            let { errores } = err.response;
            errores.forEach((err: any) => {
                if (err.sql) {
                    err.sql.forEach((er: string) => toast(er, { type: toast.TYPE.ERROR }));
                } else {
                    Object.keys(err).forEach(t => this.error[t] = err[t])
                }
            })
            this.forceUpdate();
        }
    }
    componentDidMount = () => {
        this.setContextUtilidades();
    }
    componentDidUpdate = (p: any, s: Producto) => {
        
    }
}
ProductoNuevo.contextType = ProductosContext;
export default ProductoNuevo;