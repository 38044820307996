import React from "react"
import { Button, InputGroup, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { don } from '@victorequena22/conneccion'
import Area from "../../../Utiles/Charts/Area";
import { formatoFecha, formatoHora, fechaToNumber } from "../../../Utiles/Formatos";
import { InputNumber } from "../../../Utiles/Inputs";
import { conConfig } from "@victorequena22/conneccion";
type dolar = { fecha: string, valor: number, banco: number };
export default class Dolar extends React.Component<{ p: string[] }, { dolares: dolar[] } & dolar> {
    random = Math.random();
    constructor(p: any) {
        super(p);
        this.state = { dolares: [], valor: 0, banco: 0, fecha: '' };
    }
    render() {
        const { state: { valor, fecha, banco }, props: { p } } = this;
        return <div className="Dolar">
            <div style={{ display: "flex" }}>
                <InputGroup style={{ width: "calc(100% - 250px)" }}>
                    <InputGroup.Prepend>
                        <InputGroup.Text>TASA DE CAMBIO</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Row style={{ width: "calc(100% - 272px)" }}>
                        <InputGroup style={{ width: "100%" }}>
                            <InputGroup.Prepend style={{ width: "60px" }}>
                                <InputGroup.Text className='text-center' style={{ borderRadius: 0, width: "60px" }}><i className="fas fa-dollar-sign"></i></InputGroup.Text>
                            </InputGroup.Prepend>
                            <InputNumber min={0} value={valor} setData={this.setState.bind(this)} update='valor' disabled={!p.some(d => d === 'DOLARES')} />
                        </InputGroup>
                        <InputGroup style={{ width: "100%" }}>
                            <InputGroup.Prepend style={{ width: "60px" }}>
                                <InputGroup.Text className='text-center' style={{ borderRadius: 0, width: "60px" }}>BCV</InputGroup.Text>
                            </InputGroup.Prepend>
                            <InputNumber min={0} value={banco} setData={this.setState.bind(this)} update='banco' disabled={!p.some(d => d === 'DOLARES')} />
                        </InputGroup>
                    </Row>
                    {p.some(d => d === 'DOLARES') ?
                        <InputGroup.Append>
                            <Button variant='primary' onClick={this.actualizar}>
                                <i className="fa fa-refresh"></i> ACTUALIZAR
                            </Button>
                        </InputGroup.Append> : <></>}
                </InputGroup>
                <div style={{ fontSize: '12px', width: "250px", paddingLeft: '15px' }}>
                    <strong>ACTUALIZADO:</strong> {formatoFecha(fecha)}<br />
                    <strong>HORA:</strong> {formatoHora(fecha)}
                </div>
            </div>
            <Area key={`dolar${this.random}`} values={this.get()}
                label={['FECHA Y HORA', 'PRECIO', 'BCV']}
                title="FLUCTUACIONES DEL DOLAR" hAxisTitle="FECHA Y HORA" />
        </div>
    }
    actualizar = () => {
        const { valor, banco } = this.state;
        if (valor !== 0) conConfig('').post(don + '/api/Dolares/set', { valor, banco }, this.send);
        else toast('LA TASA DE CAMBIO NO PUEDE SER 0', { type: toast.TYPE.ERROR });
        this.send();
    }
    get = () => this.state.dolares.map(d => [formatoFecha(d.fecha) + ' ' + formatoHora(d.fecha), d.valor, d.banco])
    send = async () => {
        try {
            let token = localStorage.getItem('Authorization');
            let response = await fetch(don + '/api/Dolares/formatter/last', { headers: { 'Authorization': 'Bearer ' + token } });
            let dolares: dolar[] = await response.json();
            let valor = 0, banco = 0, fecha = '1999-01-01';
            dolares.forEach(d => {
                if (fechaToNumber(d.fecha) > fechaToNumber(fecha)) {
                    valor = d.valor; fecha = d.fecha; banco = d.banco;
                }
            })
            this.random = Math.random();
            this.setState({ dolares, valor, fecha, banco });
        } catch (error) {
            console.log(error);
            this.setState({ dolares: [] });
        }
    }
    componentDidMount() { this.send() }
}