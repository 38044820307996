import { Row, Container, Card, Image } from 'react-bootstrap';
import { State } from './index'
import { Itens } from './Itens'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { css } from '@emotion/css';

export function Panel({ config, seled }: { config: State, seled: (i: number) => void }) {
    const { color, itens, size, inter, up, down } = config;
    const { length: l } = itens;
    const s1 = ['990px', '1080px', '780px'];
    const s2 = ['1440px', '1320px'];
    let settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 1000,
        slidesToShow: s2.some(s => size === s) ? 5 > l ? l : 5
            : s1.some(s => size === s) ? 4 > l ? l : 4
                : 3 > l ? l : 3,
        slidesToScroll: 1
    }
    return <Row className='pt-3'>
        <Container>
            <Card style={{ background: color, padding: '0 0 2rem 0' }}>
                <Row className='m-0 p-1'>
                    {itens.map((i: any) => <Itens key={`iten${i.img}${i.id}`} iten={i} config={config} seled={seled} />)}
                </Row>
            </Card>
        </Container>
        <div className='w-100 p-0' style={{ height: '3rem', background: up, marginBottom: '-2.5rem' }} />
        <Container className='p-0' >
            <Card className={getCSS(inter)} style={{ background: color, padding: '.5rem 2rem 1.5rem 2rem' }}>
                <Slider {...settings}>
                    {itens.map((i: any) => <div>
                        <Image style={{ height: '3rem' }} className={`m-auto ${img}`} src={i.img} alt='banner' />
                    </div>)}
                </Slider>
            </Card>
        </Container>
        <div className='w-100 p-0' style={{ height: '3rem', background: down, marginTop: '-2.5rem' }} />
    </Row>
}
function getCSS(color: string) {
    return css`
    margin: 0 0 0 0;
    button: before{color: ${color} !important;}
    `;
}

const img = css`
    &{
        filter: grayscale(1);
        &:hover{ 
            filter: grayscale(0);
        }
    } 
`;