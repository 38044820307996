import React, { useState, useEffect, useContext } from "react";
import { Button, Modal, Table, Row, FormControl, ButtonGroup } from "react-bootstrap";
import { getExentoEImpuesto } from "../../../../Models/General";
import { conFactura, Factura } from "../../../../Models/Ventas";
import { numberFormat } from "../../../../Utiles/Formatos";
import { InputNumber } from "../../../../Utiles/Inputs";
import { DolarContext, DolarType } from "../../../../Context/Producto/Dolar";
import { ProductosType, ProductosContext } from "../../../../Context/Producto";
interface Metodos { id: number, descripcion: string, moneda: number }
interface PropsModal extends Props { open: boolean, close: () => void, }
function ModalDetalle({ open, close, data: { detalles, pagos }, iva, setData, accion }: PropsModal) {
    const { impuesto1, contribuyente, findProVen } = useContext(ProductosContext) as ProductosType;
    const [imp, ext] = getExentoEImpuesto(detalles.map(p => { return { ...p, exento: findProVen(p, false).exento } }));
    const total = imp * (1 + (iva / 100));
    const [metodos, setMetodos] = useState([{ id: 2, descripcion: 'PAGO EFECTIVO EN DIVISAS', moneda: 2 }]);
    useEffect(() => { conFactura().get('/api/Factura/metodos', (d: Metodos[]) => setMetodos(d.filter(m => m.id !== 1))); }, []);
    const { banco } = useContext(DolarContext) as DolarType;
    const [monto, setMonto] = useState(0);
    const [pago, setPago] = useState(2);
    const addPago = () => setData({ pagos: (pagos.some(p => p.metodo === pago)) ? pagos.map(p => (p.metodo === pago) ? { monto, metodo: pago } : p) : [...pagos, { monto, metodo: pago }] });
    const quitPago = (i: number) => setData({ pagos: pagos.filter(p => p.metodo !== i) });
    const getMetodo = (i: number) => { let m = metodos.find(m => m.id = i); return m ? m : { id: 0, descripcion: '0', moneda: 0 } }
    let suma = total + ext;
    const met = getMetodo(pago)
    const setPagoTasa = (m: number) => setMonto((met.moneda === 1) ? m : m * banco);
    let igtf = 0;
    return <Modal size='xl' centered animation show={open} onHide={close}>
        <Modal.Header closeButton>
            <h4 className="d-flex justify-content-end m-0 font-weight-bold text-primary mr-auto w-100">
                <b>MONTO A PAGAR{iva === 0 ? '' : ' + IVA'}:</b> <b className='ml-3 mr-5'>{numberFormat(suma)}</b>
            </h4>
        </Modal.Header>
        <Modal.Body>
            <Row style={{ padding: '0px 30px 0px 30px' }}>
                <Table>
                    <thead>
                        <tr>
                            <th>DESCRIPCION</th>
                            <th style={{ width: "200px", textAlign: "right" }}>MONTO</th>
                            <th style={{ width: "40px", textAlign: "right" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><Selector metodos={metodos} change={setPago} defaultValue={met.id} /></td>
                            <td><InputNumber value={(met.moneda === 1) ? monto : monto / banco} setData={setPagoTasa} /></td>
                            <td><Button onClick={addPago} variant="success"><i className="fas fa-plus"></i></Button></td>
                        </tr>
                    </tbody>
                </Table>
                <Table>
                    <thead>
                        <tr>
                            <th>DESCRIPCION</th>
                            <th style={{ width: "150px", textAlign: "right" }}>USD</th>
                            <th style={{ width: "150px", textAlign: "right" }}>BSD</th>
                            <th style={{ width: "40px", textAlign: "right" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {pagos.map((s) => {
                            suma -= s.monto;
                            const m = getMetodo(s.metodo)
                            if (m.moneda === 2 && (contribuyente === 'ESPECIAL')) igtf += s.monto;
                            return <tr>
                                <td>{m.descripcion}</td>
                                <td style={{ textAlign: "right" }}>{(m.moneda === 2) ? numberFormat(s.monto / banco) : ''}</td>
                                <td style={{ textAlign: "right" }}>{numberFormat(s.monto)}</td>
                                <td><Button onClick={() => quitPago(s.metodo)} variant="danger"><i className="fas fa-trash"></i></Button></td>
                            </tr>
                        })}
                        {(igtf && impuesto1 && (contribuyente === 'ESPECIAL')) ? <tr>
                            <td style={{ textAlign: "right" }}><b>IGTF</b></td>
                            <td style={{ textAlign: "right" }}>{numberFormat((igtf / banco) * (impuesto1 / 100))}</td>
                            <td style={{ textAlign: "right" }}>{numberFormat(igtf * (impuesto1 / 100))}</td>
                            <td></td>
                        </tr> : <></>}
                        <tr>
                            <td>PAGO EFECTIVO EN BOLIVARES</td>
                            <td style={{ textAlign: "right" }}></td>
                            <td style={{ textAlign: "right" }}>{numberFormat(suma + (igtf * (impuesto1 / 100)))}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "right" }}><b>TOTAL PAGADO</b></td>
                            <td style={{ textAlign: "right" }}></td>
                            <td style={{ textAlign: "right" }}>{numberFormat(total + (igtf * (impuesto1 / 100)))}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
                <ButtonGroup className="ml-auto">
                    <Button onClick={() => { accion(); close() }} variant="success">
                        <span className="fa fa-check-circle-o"></span> Aceptar
                    </Button>
                    <Button onClick={close} variant="danger">
                        <span className="fa fa-remove-circle"></span> Cancelar
                    </Button>
                </ButtonGroup>
            </Row>
        </Modal.Body>
    </Modal >
}
interface PropsSelector { change: (r: any) => void, defaultValue: number, width?: string, metodos: Metodos[] }
function Selector({ change, defaultValue, width, metodos }: PropsSelector) {
    const setRol = (Rol: React.ChangeEvent<HTMLSelectElement>) => change(parseInt(Rol.currentTarget.value))
    return <FormControl as='select' style={{ width }} onChange={setRol} value={defaultValue}>
        {metodos.map(c => <option key={"id" + c.id} value={c.id}>{c.descripcion}</option>)}
    </FormControl>
}
interface Props { data: Factura, setData: (d: Partial<Factura>) => void, iva: number, accion: () => void }
export function Procesar(p: Props) {
    const [open, setOpen] = useState(false);
    return <>
        <ModalDetalle {...p} open={open} close={() => setOpen(false)} />
        <Button data-tip="PROCESAR" style={{ marginRight: "15px" }} variant="success"
            className="float-right" onClick={() => setOpen(true)} >
            <span className="fa fa-print" />
        </Button>
    </>
}
