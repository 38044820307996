import React from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { ClientesContext } from '../../../Context/Ventas/Cliente';
import { Cliente, conCliente } from '../../../Models/Ventas';
import CardBuscador from '../../../Utiles/Buscadores/Card';
import ClientesExcel from './Excel';
import FilaCliente from './Fila';
import FormCliente from './Form';

class ListaClientes extends CardBuscador {
    context: any = {};
    con = conCliente();
    placeholder = "BUSCAR CLIENTE";
    buscarEn = ['codigo', 'nombre', 'contacto', 'correo', 'telefono', 'direccion'];
    status = -1;
    Avansado() {
        return <Row>
            <Col style={{ minWidth: '230px' }} md={12}>
                <select data-tip="FILTRAR POR EL ESTADO" onChange={this.setStatus.bind(this)} className="form-control" value={this.status}>
                    <option value={-1}>TODOS LOS ESTADOS</option>
                    <option value={0}>INACTIVO</option>
                    <option value={1}>ACTIVO</option>
                </select>
            </Col>
        </Row>;
    }
    Title() {
        return <>
            <FormCliente open={this.state.modal} title='NUEVO CLIENTE' cliente={null}
                close={() => { this.setState({ modal: false }) }} add={this.set.bind(this)} />
            <h4 className='mr-auto'><i className="fa fa-search"></i> BUSCAR CLIENTE</h4>
            <Button onClick={() => { this.setState({ modal: true }) }}><i className="fa fa-edit"></i> NUEVO CLIENTE</Button>
        </>
    }
    Header() {
        const { Th } = this;
        return <tr className="info">
            <Th width='150px' label='codigo'>CI/RIF</Th>
            <Th width='calc(100% - 385px)' label='nombre'>NOMBRE</Th>
            <Th width='120px'>ESTATUS</Th>
            <Th width='135px'>ACCIONES</Th>
        </tr>
    }
    Row(d: Cliente) {
        return <FilaCliente key={d.codigo + d.estatus} update={this.set.bind(this)} data={d} />
    }
    filter() {
        let itens = this.ordenar();
        if (this.status !== -1) itens = itens.filter(i => { return (i.estatus === this.status); })
        return itens;
    }
    setStatus(e: React.ChangeEvent<HTMLSelectElement>) {
        let value = e.currentTarget.value as string;
        this.status = parseInt(value);
        this.forceUpdate();
    }
    getItens() { return this.context.Clientes; }
    set() { this.context.actCliente(); }
    getEstado() { return this.context.estCliente; }
    Actualizar() {
        const { hora } = this.getEstado();
        return <Col md={3} className='mr-auto d-flex flex-row-reverse'> 
            <ClientesExcel clientes={this.filter()} />
            <div style={{ fontSize: '12px', width: "200px", paddingLeft: '15px' }}>
                <strong>ACTUALIZADO:</strong> <br />
                {hora}
            </div>
        </Col>
    }
}
ListaClientes.contextType = ClientesContext;
export default ListaClientes
