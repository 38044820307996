import React from "react"
import { Button, Table } from "react-bootstrap"
import { toast } from "react-toastify"
import ReactTooltip from "react-tooltip"
import { conDepartamento, Departamento } from "../../../Models/Productos"
import { InputText } from "../../../Utiles/Inputs"
interface Props {
    update?: () => void
}
export default class NuevoDepartamento extends React.Component<Props, Departamento> {
    con = conDepartamento()
    state = { codigo: '', descripcion: '', estatus: 1 };
    render() {
        let { codigo, descripcion } = this.state
        return <>
            <br />
            <b>NUEVO DEPARTAMENTO</b>
            <Table>
                <tr>
                    <th style={{ width: "120px" }}>
                        <InputText maxLength={4} upper only='letter' setData={this.setState.bind(this)} value={codigo} update="codigo" />
                    </th>
                    <th style={{ width: "calc(100% - 190px)" }}>
                        <ReactTooltip />
                        <InputText placeholder='DESCRIPCION' upper setData={this.setState.bind(this)} value={descripcion} update="descripcion" />
                    </th>
                    <th style={{ width: "70px" }}>
                        <Button variant="primary" onClick={this.nuevo.bind(this)}>
                            <i className="fa fa-edit"></i> GUARDAR
                        </Button>
                    </th>
                </tr>
            </Table>
        </>
    }
    nuevo() {
        let { descripcion, codigo } = this.state;
        if (descripcion === '') {
            toast('DEBE COLOCAR UNA DESCRIPCION', { type: toast.TYPE.ERROR })
        } else if (codigo === '') {
            toast('DEBE COLOCAR UN CODIGO', { type: toast.TYPE.ERROR })
        } else if (codigo === 'TODO') {
            toast('"TODO" ES UN CODIGO RESERVADO PARA USO INTERNO DEL SISTEMA', { type: toast.TYPE.ERROR })
        } else if (codigo === 'AAAA') {
            toast('"AAAA" ES UN CODIGO RESERVADO PARA USO INTERNO DEL SISTEMA', { type: toast.TYPE.ERROR })
        } else {
            let { update } = this.props;
            this.con.nuevo(this.state, () => {
                if (update) update();
                this.setState({ codigo: '', descripcion: '', estatus: 1 });
                toast('CREADO EXITOSAMENTE', { type: toast.TYPE.SUCCESS })
            });
        }
    }
}