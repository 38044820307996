import React from 'react';
import { Button, Col, InputGroup, Row } from 'react-bootstrap';
import { Detalles } from '../../../../Models/General';
import { conProducto, Producto } from '../../../../Models/Productos';
import FilaCompra from './FilaCompra';
import FilaSeleccion from './FilaSeleccion';
import FilaEntrada from './FilaEntrada';
import FilaPrecio from './FilaPrecio';
import FilaSalida from './FilaSalida';
import FilaUtilidad from './FilaUtilidad';
import FilaVentas from './FilaVenta';
import DepartamentoSelector from '../../Departamento/Selector';
import ModalBusqueda from '../../../../Utiles/Buscadores/Modal';
import { InputText } from '../../../../Utiles/Inputs';
import { ProductosContext } from '../../../../Context/Producto';
export interface PropsBuscarProducto {
    type: 'Cotizacion' | 'Compra' | 'Factura' | 'Nota' | 'Orden' | 'SALIDA' | 'ENTRADA' | 'COSTO' | 'UTILIDAD' | 'Seleccion';
    add: (p: Detalles) => void
    dolar?: boolean
}
class BuscarProducto extends ModalBusqueda<PropsBuscarProducto> {
    con = conProducto();
    buscarEn = ['codigo', 'descripcion'];
    departamento: string = 'TODO';
    Title() { return <h4 className='mr-auto'>BUSCAR PRODUCTOS</h4> }
    setDepartamento(departamento: string) {
        this.departamento = departamento;
        this.forceUpdate();
    }
    Buscador() {
        return <Row style={{ marginBottom: '15px' }}>
            <Col md={9}>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            {this.buscarLabel}
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <InputText className='form-control' upper setData={this.setState} update='buscar' value={this.state.buscar} />
                    <DepartamentoSelector width='300px' defaultValue={this.departamento} change={this.setDepartamento.bind(this)} />
                    <InputGroup.Append>
                        <Button variant="primary">
                            <span className="fa fa-search"></span> BUSCAR
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </Col>
            {this.Actualizar()}
        </Row>
    }
    Header() {
        const { Th } = this;
        return <tr className="info">
            <Th label='codigo' width='90px'>COD</Th>
            <Th label='descripcion' width='auto'>DESCRIPCION</Th>
            {this.head()}
            <Th width='40px'> </Th>
        </tr>
    }
    Row(c: Producto) {
        const { type, add, dolar } = this.props;
        switch (type) {
            case 'Seleccion':
                return <FilaSeleccion key={c.codigo + 'Seleccion'} producto={c} add={add} />
            case 'ENTRADA':
                return <FilaEntrada key={c.codigo + 'ENTRADA'} producto={c} add={add} />;
            case 'SALIDA':
                return <FilaSalida key={c.codigo + 'SALIDA'} producto={c} add={add} />;
            case 'COSTO':
                return <FilaPrecio dolar={dolar} key={c.codigo + 'COSTO'} producto={c} add={add} />;
            case 'UTILIDAD':
                return <FilaUtilidad key={c.codigo + 'UTILIDAD'} producto={c} add={add} />;
            case 'Compra': case 'Orden':
                return <FilaCompra dolar={dolar} key={c.codigo + 'Compra'} producto={c} add={add} />
            case 'Nota': case 'Factura': case 'Cotizacion':
                return <FilaVentas dolar={dolar} venta={(type === 'Nota' || type === 'Factura')}
                    key={c.codigo + 'Factura'} producto={c} add={add} />
        }
    }
    filter() {
        const { departamento, props: { type } } = this;
        let itens = this.ordenar();
        return itens.filter((c: Producto) => {
            if (c.estatus === 0) return false;
            if (departamento !== 'TODO' && c.departamento !== departamento) return false;
            if ((type === 'ENTRADA' || type === 'SALIDA') && c.inventario === 1) return false;
            return true;
        });
    }
    componentDidMount() { this.set(); }
    head() {
        const { Th } = this;
        switch (this.props.type) {
            case 'ENTRADA': case 'SALIDA': return <>
                <Th width='120px'>CANTIDAD</Th>
                <Th width='120px'>DISPONIBLE</Th>
            </>;
            case 'COSTO': return <>
                <Th width='150px'>COSTO</Th>
            </>;
            case 'UTILIDAD': return <>
                <Th width='150px'>% UTILIDAD 1</Th>
                <Th width='150px'>% UTILIDAD 2</Th>
                <Th width='150px'>% UTILIDAD 3</Th>
            </>;
            case 'Compra': case 'Orden': return <>
                <Th width='120px'>CANTIDAD</Th>
                <Th width='150px'>COSTO</Th>
                <Th width='200px'>TOTAL</Th>
            </>
            case 'Seleccion': return <></>;
        }
        return <>
            <Th width='120px'>CANTIDAD</Th>
            <Th width='150px'>P.U.</Th>
            <Th width='200px'>TOTAL</Th>
        </>
    }
    context: any = {};
    reset() { this.context.resetPro() }
    getItens() { return this.context.Productos; }
    set() { this.context.actProducto(); }
    getEstado() { return this.context.estProducto }
}
BuscarProducto.contextType = ProductosContext;
export default BuscarProducto;


