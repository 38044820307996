import React from 'react'
import { Col, Modal, Row, Table } from 'react-bootstrap';
import Ajuste, { AjusteNull, conAjuste, DetallesAjuste, Costo, Utilidad } from '../../../Models/Ajuste';
import { numberFormat } from '../../../Utiles/Formatos';
interface Props {
    id: number
    open: boolean,
    close: () => void,
    titulo: string
}
export default class ModalDetalle extends React.Component<Props, Ajuste> {
    con = conAjuste();
    state = { ...AjusteNull };
    render() {
        let { detalles } = this.state;
        if (detalles === undefined)
            return <></>
        return <Modal size='xl' centered dialogClassName="modal-detalle" animation show={this.props.open} onHide={this.props.close}>
            <Modal.Header closeButton>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    <span><b>{this.props.titulo}</b></span>
                </h6>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs='12'>
                        <b>TIPO DE AJUSTE:</b> {this.state.tipo}
                    </Col>
                    <Col xs='12'>
                        <b>NOTA:</b> {this.state.nota}
                    </Col>
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th style={{ width: "100px" }}>CODE</th>
                                <th>DESCRIPCION</th>
                                {this.th()}
                                <th>NOTA</th>
                            </tr>
                        </thead>
                        <tbody>
                            {detalles.map((s) => {
                                switch (this.state.tipo) {
                                    case 'ENTRADA':
                                    case 'SALIDA':
                                        return <RowES d={s} />
                                    case 'COSTO':
                                        return <RowCosto d={s} />
                                    case 'UTILIDAD':
                                        return <RowUtilidad d={s} />
                                }
                                return <></>
                            })}
                        </tbody>
                    </Table>
                </Row>
            </Modal.Body>
        </Modal>
    }
    th() {
        switch (this.state.tipo) {
            case 'ENTRADA':
            case 'SALIDA':
                return <th style={{ width: "60px", textAlign: "right" }}>CANT</th>
            case 'COSTO':
            case 'UTILIDAD':
                return <>
                    <th style={{ width: "100px", textAlign: "right" }}>ANTERIOR</th>
                    <th style={{ width: "100px", textAlign: "right" }}>ACTUAL</th>
                    <th style={{ width: "100px", textAlign: "right" }}>VIGENTE</th>
                </>
        }
        return <></>
    }
    dialog() {
        return <Modal.Dialog style={{ width: "90%" }} />
    }
    componentDidMount() {
        this.set();
    }
    set() {
        this.con.detalle(this.props.id, this.setState.bind(this));
    }
}

function RowES({ d: { codigo, descripcion, unidades, comentario } }: { d: DetallesAjuste }) {
    return <tr>
        <td style={{ width: "100px" }}>{codigo}</td>
        <td>{descripcion}</td>
        <td style={{ width: "60px", textAlign: "right" }}>{numberFormat(unidades)}</td>
        <td>{comentario}</td>
    </tr>
}
function RowCosto({ d: { codigo, descripcion, data, unidades, costo, tasa, comentario } }: { d: DetallesAjuste }) {
    let d = data as Costo;
    const vig = () => {
        if (unidades !== costo) return false;
        return true;
    }
    return <tr>
        <td style={{ width: "100px" }}>{codigo}</td>
        <td>{descripcion}</td>
        <td style={{ width: "100px", textAlign: "right" }}>{numberFormat(d.costo)}</td>
        <td style={{ width: "100px", textAlign: "right" }}>{numberFormat(unidades)}</td>
        <td style={{ width: "100px", textAlign: "right" }}>{vig() ? 'SI' : 'NO'}</td>
        <td>{comentario}</td>
    </tr>
}
function RowUtilidad({ d: { codigo, descripcion, data, comentario } }: { d: DetallesAjuste }) {
    let { actual, previo } = data as Utilidad;
    const vig = () => {
        if (previo.precio1 !== actual.precio1) return false;
        if (previo.precio2 !== actual.precio2) return false;
        if (previo.precio3 !== actual.precio3) return false;
        return true;
    }
    return <tr>
        <td style={{ width: "100px" }}>{codigo}</td>
        <td>{descripcion}</td>
        <td style={{ width: "100px", textAlign: "right" }}>
            {numberFormat(previo.precio1)}<br />
            {numberFormat(previo.precio2)}<br />
            {numberFormat(previo.precio3)}<br />
        </td>
        <td style={{ width: "100px", textAlign: "right" }}>
            {numberFormat(actual.precio1)}<br />
            {numberFormat(actual.precio2)}<br />
            {numberFormat(actual.precio3)}<br />
        </td>
        <td style={{ width: "100px", textAlign: "right" }}>{vig() ? 'SI' : 'NO'}</td>
        <td>{comentario}</td>
    </tr>
}