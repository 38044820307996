import { css } from '@emotion/css';
import { area } from './index';
export function getCSS(image: string) {
    return css`
        overflow: hidden;
        background-image: url(${image});
        background-attachment: fixed;
        background-position: center center;
        background-size: cover;
        background-color: transparent;`;
}
export function getWidth(xs: area, sm: area, md: area, lg: area, xl: area, xxl: area, size: string) {
    const l = (size === '570px') ? xs : (size === '780px') ? sm : (size === '990px') ? md : (size === '1080px') ? lg : (size === '1320px') ? xl : xxl;
    return css`
        &> .col{
            padding: 1.5rem  1.5rem  1.5rem  1.5rem;
            margin-left: ${l.offset}%;
            -webkit-flex: 0 0 ${l.span - l.offset}%;
            -ms-flex: 0 0 ${l.span - l.offset}%;
            flex: 0 0 ${l.span - l.offset}%;
            max-width: ${l.span - l.offset}%;
        }`;

}
export function getWidth2(xs: area, sm: area, md: area, lg: area, xl: area, _size: string) {
    return css`
        margin-left: ${xs.offset}%;
        -webkit-flex: 0 0 ${xs.span}%;
        -ms-flex: 0 0 ${xs.span}%;
        flex: 0 0 ${xs.span}%;
        max-width: ${xs.span}%;
        @media (min-width: 576px) {
            margin-left: ${sm.offset}%;
            -webkit-flex: 0 0 ${sm.span}%;
            -ms-flex: 0 0 ${sm.span}%;
            flex: 0 0 ${sm.span}%;
            max-width: ${sm.span}%;
        }
        @media (min-width: 768px) {
            margin-left: ${md.offset}%;
            -webkit-flex: 0 0 ${md.span}%;
            -ms-flex: 0 0 ${md.span}%;
            flex: 0 0 ${md.span}%;
            max-width: ${md.span}%;
        }
        @media (min-width: 992px) {
            margin-left: ${lg.offset}%;
            -webkit-flex: 0 0 ${lg.span}%;
            -ms-flex: 0 0 ${lg.span}%;
            flex: 0 0 ${lg.span}%;
            max-width: ${lg.span}%;
        }
        @media (min-width: 1200px) {
            margin-left: ${xl.offset}%;
            -webkit-flex: 0 0 ${xl.span}%;
            -ms-flex: 0 0 ${xl.span}%;
            flex: 0 0 ${xl.span}%;
            max-width: ${xl.span}%;
        }`;
}