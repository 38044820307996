import { useState, useEffect } from 'react';
import { RowConfig, CardConfig, TitleCard, ButtonConfig } from '../../../Component';
import { Props } from './index';
import { InputText } from "@victorequena22/component-bootstrap";
import { InputGroup } from "react-bootstrap";
import { SelecPanelModal } from '../../../../PanelProxi/Ver/Selector';
export function Link({ data: { data }, setData }: Props) {
    const [text, setText] = useState(data.text ? data.text : '');
    const [link, setLink] = useState(data.link ? data.link : '');
    const [target, setTarget] = useState(data.target ? data.target : '');
    useEffect(() => setData({
        data: { text, link, target },
        html: `<a href="${link}" target="${target}">${text}</a>`
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [text, link, target]);
    return <CardConfig marginNull width="100%">
        <RowConfig>
            <InputGroup data-tip='TEXTO QUE SE MOSTRARA EN EL VINCULO' className='input-group-sm' style={{ height: '24px' }}>
                <InputGroup.Prepend style={{ height: '24px', width: '65px' }}>
                    TEXTO:
                </InputGroup.Prepend>
                <InputText setData={setText} value={text} style={{ height: '24px' }} className='form-control-sm' />
            </InputGroup>
        </RowConfig>
        <SetLink data={link} setData={(a, b) => { setLink(a); setTarget(b) }} />
        <TitleCard>TEXTO Y LINK</TitleCard>
    </CardConfig>
}
interface PropsLink {
    data: string,
    setData: (d: string, t: string) => void
}
export function SetLink({ data, setData }: PropsLink) {
    const [panel, setPanel] = useState(false);
    return <RowConfig top={2}>
        <SelecPanelModal open={panel} close={() => setPanel(false)}
            set={({ id }: any) => { setData(`#panel${id}`, ''); setPanel(false) }} />
        <InputGroup data-tip='LINK O HIPERVINCULO' className='input-group-sm' style={{ width: 'calc(100% - 32px - .25rem)', height: '24px' }}>
            <InputGroup.Prepend style={{ height: '24px', width: '65px' }}>
                LINK:
            </InputGroup.Prepend>
            <InputText style={{ height: '24px' }} className='form-control-sm'
                setData={d => setData(d, '_blank')} value={data} />
        </InputGroup>
        <ButtonConfig tip='VINCULAR A UN PANEL DE LA PAGINA' className='ml-1' action={() => setPanel(true)} icon='fas fa-ad' />
    </RowConfig>
}