import  { useContext, useState, useEffect } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap'
import { Detalles } from '../../../../Models/General'
import { InputNumber } from '../../../../Utiles/Inputs'
import { ControlText } from '../../../../Utiles/FormControl'
import THFila, { THInput } from '../../../../Utiles/Componentes/THFila'
import { ProductosContext, ProductosType } from '../../../../Context/Producto';

interface Props {
    producto: Detalles,
    edit: (iten: Detalles) => void,
    remove: (iten: Detalles) => void
    dolar?: boolean
}
export default function FilaPrecio({ producto, edit, remove, dolar = false }: Props) {
    const { findProVen, tasa } = useContext(ProductosContext) as ProductosType;
    const { codigo, descripcion } = findProVen(producto, dolar);
    const [precio, setPrecio] = useState(producto.precio);
    const [comentario, setComentario] = useState(producto.comentario);
    const [open, setOpen] = useState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => edit({ ...producto, unidades: 0, precio, comentario }), [precio, comentario]);
    const pre = (dolar) ? precio / tasa : precio;
    return <>
        <tr>
            <THFila width='90px'>{codigo}</THFila>
            <THFila width='auto'>{descripcion}</THFila>
            <THInput width='180px'>
                <InputNumber value={pre} step='0.01' setData={(p: number) => setPrecio((dolar) ? p * tasa : p)} />
            </THInput>
            <THInput width='110px'>
                <ButtonGroup>
                    <Button variant="info" onClick={() => setOpen(!open)} data-tip="DEJAR NOTA">
                        <span className="fa fa-edit"></span>
                    </Button>
                    <Button variant="danger" onClick={() => remove(producto)} data-tip="QUITAR PRODUCTO">
                        <i className="fa fa-ban"></i>
                    </Button>
                </ButtonGroup>
            </THInput>
        </tr><tr></tr>
        {open ? <tr>
            <td colSpan={7}>
                <ControlText maxLength={250} before="NOTA INTERNA" setData={setComentario} value={comentario} />
            </td>
        </tr> : <tr></tr>}
    </>
}