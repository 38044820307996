import React from "react"
import Area from "../../../Utiles/Charts/Area";
import { don } from "@victorequena22/conneccion";
export default class VentasMes extends React.Component<{ rango: string }, { dias: number[][] }>{
    constructor(p: any) {
        super(p);
        this.state = { dias: [] };
    }
    render() {
        return <div className="ventas" style={{ width: '100%' }}>
            <Area label={['DIA', 'VENTAS']} values={this.get()} title="VENTAS DEL MES" hAxisTitle="DIA DEL MES" />
        </div>
    }
    get() {
        return this.state.dias.sort((a, b) => a[0] - b[0]);
    }
    async send() {
        try {
            let token = localStorage.getItem('Authorization');
            let response = await fetch(don + '/api/Factura/linea/' + this.props.rango, { headers: { 'Authorization': 'Bearer ' + token } });
            let dias = await response.json();
            this.setState({ dias });
        } catch (error) {
            console.log(error);
            this.setState({ dias: [] });
        }
    }
    componentDidMount() { this.send() }
}