
import { CondicionesData, condicionesNull, getSubtotal, sentDetalles } from "../../General";
export interface Cotizacion extends CondicionesData {
    cod_cliente: string
    plantilla: number
    dolar: boolean
}
export const CotizacionNull: Cotizacion = {
    cod_cliente: '',
    plantilla: 0,
    dolar: false,
    ...condicionesNull,
}
export function sentCotizacion(e: Cotizacion, iva: number) {
    let subtotal = getSubtotal(e.detalles),
        impuesto = subtotal * (iva / 100),
        total = subtotal + impuesto;
    let { cod_cliente, validez, tiempo_entrega, forma_pago, nota, plantilla } = e;
    return {
        cod_cliente,
        plantilla,
        validez,
        tiempo_entrega,
        forma_pago,
        nota,
        total,
        impuesto,
        subtotal,
        detalles: sentDetalles(e.detalles)
    }
}