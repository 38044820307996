import React, { useContext, useState, useEffect } from 'react';
import { Button, ButtonGroup, FormControl } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip';
import { ProductosContext, ProductosType } from '../../Context/Producto';
import THFila, { THInput } from '../Componentes/THFila';
import { Detalles } from '../../Models/General';
import { numberFormat } from '../Formatos';
import { InputNumber } from '../Inputs';
interface Props {
    data: Detalles
    edit: (d: Detalles) => void
    remove: (iten: Detalles) => void
    dolar?: boolean
}

export default function ProductosCompras({ data, edit, remove, dolar = false }: Props) {
    const { findProVen, tasa } = useContext(ProductosContext) as ProductosType;
    const { codigo, descripcion, costo, cantidad, inventario } = findProVen(data, dolar);
    const [unidades, setUnidades] = useState(data.unidades);
    const [precio, setPrecio] = useState(data.precio);
    // eslint-disable-next-line
    useEffect(() => edit({ ...data, unidades, precio, comentario: '' }), [unidades, precio]);
    const pre = (dolar) ? precio / tasa : precio;
    return <tr>
        <THFila width='50px' align='center'>{data.index}</THFila>
        <THFila width='120px' align='center'>{codigo}</THFila>
        <THFila width='auto'>{descripcion}</THFila>
        <THInput width='120px'><InputNumber min={0.01} setData={setUnidades} value={unidades} /></THInput>
        <THInput width='120px'><FormControl style={{ textAlign: 'right' }} disabled value={(inventario === 1) ? 'NO APLICA' : numberFormat(cantidad)} /></THInput>
        <THInput width='180px'>
            <InputNumber list={costo + 'list'} value={pre} step='0.01'
                setData={(p: number) => setPrecio((dolar) ? p * tasa : p)} />
        </THInput>
        <THInput width='230px'><InputNumber disabled setData={() => { }} value={parseFloat(pre.toFixed(2)) * unidades} update="p" /></THInput>
        <THInput width='110px'>
            <ReactTooltip />
            <ButtonGroup>
                <Button variant="danger" onClick={() => remove(data)} data-tip="QUITAR PRODUCTO">
                    <i className="fa fa-ban"></i>
                </Button>
            </ButtonGroup>
        </THInput>
    </tr>
}