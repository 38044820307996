import React from 'react';
import { Button, Col, InputGroup } from 'react-bootstrap';
import { InputDate, InputNumber } from '../../../Utiles/Inputs';
import DepartamentoSelector from '../Departamento/Selector';
export interface AvanzadosProps {
    setData: (d: any) => void
    fecha_init: string
    fecha_fin: string
    min: number
    max: number
    departamento: string
    status: number
}
export default function Avanzados({ fecha_init, fecha_fin, min, max, departamento,status, setData }: AvanzadosProps) {
    const limpiarFechas = () => { setData({ fecha_ini: 'dd/mm/aaaa', fecha_fin: 'dd/mm/aaaa' }) };
    const limpiarstock = () => { setData({ min: 0, max: 0 }) };
    const setDepartamento = (departamento: string) => { setData({ departamento }) };
    const setStatus = (e: React.ChangeEvent<HTMLSelectElement>) => { setData({ status: parseInt(e.currentTarget.value) }) };
    return <div className='row'>
        <Col xs={12} className='RangoFechas' >
            <InputGroup>
                <InputGroup.Prepend><InputGroup.Text>TIEMPO DE CREACION</InputGroup.Text></InputGroup.Prepend>
                <InputDate value={fecha_init} setData={setData} update='fecha_init' />
                <InputDate value={fecha_fin} setData={setData} update='fecha_fin' />
                <Button onClick={limpiarFechas} data-tip="LIMPIAR FECHAS" variant="danger"><i className="fa fa-ban"></i></Button>
            </InputGroup>
        </Col>
        <Col xs={12} className='productoStock'><br />
            <InputGroup>
                <InputGroup.Prepend><InputGroup.Text>INVENTARIO DISPONIBLE DESDE</InputGroup.Text></InputGroup.Prepend>
                <InputNumber value={min} setData={setData} update='min' />
                <InputGroup.Prepend><InputGroup.Text>HASTA</InputGroup.Text></InputGroup.Prepend>
                <InputNumber value={max} setData={setData} update='max' />
                <Button onClick={limpiarstock} data-tip="LIMPIAR STOCK" variant="danger"><i className="fa fa-ban"></i></Button>
            </InputGroup>
        </Col>
        <Col data-tip="FILTRAR POR DEPARTAMENTO" xs={12} style={{ minWidth: '320px' }} className='RangoFechas'><br />
            <DepartamentoSelector change={setDepartamento} defaultValue={departamento} />
        </Col>
        <Col style={{ minWidth: '230px' }} xs={12} className='productoStock'><br />
            <select data-tip="FILTRAR POR EL ESTADO" onChange={setStatus} className="form-control" value={status}>
                <option value={-1}>TODOS LOS ESTADOS</option>
                <option value={0}>INACTIVO</option>
                <option value={1}>ACTIVO</option>
            </select>
        </Col>
    </div >
}