
import { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { conNota } from '../../../Models/Ventas';
import ModalPrint from './ModalPrint';
import FilaVentas from '../../../Utiles/Ventas/FilaVentas';
import { zfill } from '../../../Utiles/Formatos';
import BTNCancelar from '../../../Utiles/Prototipos/Fila/Botones/BTNCancelar';
interface Props {
    id: number
    label: string
}
function BTNPrint({ id, label }: Props) {
    const [open, setOpen] = useState(false);
    return <>
        <Button data-tip="VER IMPRESION" variant="primary"
            onClick={() => setOpen(true)}>
            <i className="fa fa-download"></i>
        </Button>
        <ModalPrint title={`IMPRIMIR ${label} N° ${zfill(id)}`} open={open}
            key={'ModalPrint' + label + id} codigo={zfill(id)} close={() => setOpen(false)} />
    </>
}
export default class FilaNota extends FilaVentas {
    con = conNota();
    constructor(p: any) {
        super(p);
        this.label = 'NOTA DE ENTREGA';
        this.type = 'Nota';
    }
    Botones() {
        const { con, label, cancelado, data: { status, codigo } } = this;
        return <th style={{ minWidth: "135px", maxWidth: "135px" }}>
            <ButtonGroup className="float-right">
                <BTNPrint label={label} id={codigo} />
                {(status !== 1) ? <></> :
                    <BTNCancelar con={con} label={label} id={codigo} msn={cancelado()} />}
            </ButtonGroup>
        </th>
    }
    cancelado() {
        return 'NOTA DE ENTREGA N° ' + this.data.codigo + ' CANCELADA ESXITOSAMENTE';
    }
}