import { ConexionCompleta } from "@victorequena22/conneccion"


export default interface Proveedor {
    codigo: string
    nombre: string
    telefono: string
    correo: string
    contacto: string
    direccion: string
    pais: string
    ciudad: string
    estado: string
    estatus: number
}
export var ProveedorNull: Proveedor = {
    codigo: "",
    nombre: "",
    telefono: "",
    correo: "",
    contacto: "",
    direccion: "",
    pais: "",
    ciudad: "",
    estado: "",
    estatus: 1
}
class ProveedorConexion extends ConexionCompleta {
    clase = 'Proveedor';
    persistencia = 1000;
}
export function conProveedor() {
    return new ProveedorConexion();
}