/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useContext, useCallback } from 'react'
import { conFactura } from '../../Models/Ventas'
import { AuthSome } from '../../Config/Permisos';
import { NotasContext, NotasType } from './Notas';
import { updateOrPush, Estado, EstadoNull, GeneralFila } from '../../Models/General'
import { formatoHora } from '../../Utiles/Formatos';
import { sleep, useSemaforo } from '../../Utiles/ReactUtils';
export interface VentasType extends NotasType {
    estFactura: Estado
    actFactura: () => void
    Facturas: GeneralFila[]
}
export const VentasContext = createContext<VentasType | null>(null)
export default function VentasProvider({ children }: { children: any }) {
    const [Facturas, setFacturas] = useState<GeneralFila[]>([]);
    const notas = useContext(NotasContext) as NotasType;
    const [est, setest] = useState(EstadoNull);
    const [con] = useState(conFactura());
    const update = useCallback(async () => {
        if (AuthSome(['FACTURA'])) con.cambios((data: any[]) => {
            setest({ status: 3, hora: formatoHora(con.state) })
            setFacturas(updateOrPush(data, Facturas))
        })
        await sleep(50);
    }, [Facturas])
    useSemaforo('Facturas', async () => {
        await update()
    })
    return <VentasContext.Provider value={{
        ...notas,
        estFactura: est, Facturas,
        actFactura: update
    }}>
        {children}
    </VentasContext.Provider>
}
export const SeccionConsumer = VentasContext.Consumer;