import React, { useState } from 'react';
import { Button, Row, ButtonGroup } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { tipoData } from '../../Models/General';
interface Props {
    dolar: boolean,
    tipo: tipoData | 'Ajuste',
    id: number,
}
export default function PrintCompnent({ tipo, id, dolar }: Props) {
    const [usd, setUSD] = useState(dolar);
    return <Row>
        <ReactTooltip />
        <Row className='col-12'>
            <h4 className='mr-auto'>
                <span className="fa fa-print" />
                MOSTRAR VISTA
            </h4>
            <ButtonGroup>
                <Button onClick={() => setUSD(false)} data-tip="CAMBIAR A PRECIO EN BOLIVARES" variant={(usd ? 'outline-' : '') + "primary"}>
                    PRECIO EN BOLIVARES
                </Button>
                <Button onClick={() => setUSD(true)} data-tip="CAMBIAR A PRECIO EN DOLARES" variant={(!usd ? 'outline-' : '') + "warning"}>
                    PRECIO EN DOLARES
                </Button>
            </ButtonGroup>
        </Row>
        <iframe src={`/api/${tipo}/${usd ? 'PDFD' : 'PDF'}/${id}`} title="print" frameBorder='0' width='100%' height='700wh' scrolling='no' />
    </Row>
}