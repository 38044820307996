import { Panel } from './Panel';
import { Config } from './Config';
import { GalIten, StateS, Iten, defaultIten } from '../../ProtoPanes/GalIten';
import { BlockElement, getText } from "@victorequena22/slate-bootstrap";
import { defaultText } from '../../ProtoPanes/DefaultData';
export interface I {
    img: string;
    top: false | BlockElement[]
    bottom: false | BlockElement[]
    left: false | BlockElement[]
    right: false | BlockElement[]
    marco: 'rounded-circle' | ''
    borde: boolean
    width: number
}
export interface S {
    color: string;
    inter: string;
    img: string;
    edit: 'top' | 'bottom' | 'left' | 'right';
}
export type State = StateS<I> & S;
export type iten = Iten & I;
const aux: I = {
    img: '/public/panels/iten.jpg',
    top: defaultText,
    bottom: defaultText,
    left: defaultText,
    right: defaultText,
    marco: 'rounded-circle',
    borde: false,
    width: 150
}
export const dIten: Iten & I = { ...aux, ...defaultIten, back: '#666666', color: 'rgba(255,255,255,.4)' }
export class Testimonio extends GalIten<S, I> {
    constructor(p: any) {
        super(p);
        this.initGal({ color: 'rgba(100,100,100,.4)', inter: "rgba(255,255,255,.4)", img: '/api/Media/ver/68', edit: 'top' }, [
            { ...dIten, id: 1 },
            //  { ...dIten, id: 2 }, 
            //  { ...dIten, id: 3 }
        ]);
    }
    Config = () => <Config
        copy={this.copy}
        save={this.save}
        key='config'
        iten={this.getIten(aux)}
        setIten={this.setIten}
        setConfig={this.setData}
        title={this.state.title}
        setTitle={this.setTitle}
        removeItem={this.removeItem}
        itenUp={this.itenUp}
        itenDown={this.itenDown}
        addIten={() => this.addIten(aux)}
        config={this.state} />
    Panel = () => <Panel setConfig={this.setData} key='panel' config={this.state} seled={this.seleted} />
    saveItens = () => this.state.itens.map(({ id, top, left, right, bottom, img, color, back, marco, borde, width }) => {
        const data = JSON.stringify({});
        const props = JSON.stringify({ id, top, left, right, bottom, img, color, back, marco, borde, width });
        return { data, props, html: '', id };
    })
    getDataSave = () => {
        const { title, color, img, inter } = this.state;
        const data = JSON.stringify({});
        const props = JSON.stringify({ color, img, inter });
        return { title, html: '', data, props, itens: this.saveItens(), type: 'testimonio' };
    }
    getText = () => {
        const { edit } = this.state;
        const get = (id: string, html: false | BlockElement[]): any => ({ id, html: html ? html : defaultText });
        const i = this.getIten(aux);
        switch (edit) {
            case 'top': return get('top' + i.id, i.top);
            case 'bottom': return get('bottom' + i.id, i.bottom);
            case 'left': return get('left' + i.id, i.left);
            case 'right': return get('right' + i.id, i.right);
        }
        return get('default', defaultText);
    }
    setText = (p: { text: any[] }) => {
        switch (this.state.edit) {
            case 'top': this.setIten({ top: getText(p.text) }); break;
            case 'bottom': this.setIten({ bottom: getText(p.text) }); break;
            case 'left': this.setIten({ left: getText(p.text) }); break;
            case 'right': this.setIten({ right: getText(p.text) }); break;
        }
    }

}
