import React from 'react';
import { Col } from 'react-bootstrap';
import { ControlText } from '../FormControl';
import { InputArea } from '../Inputs';
interface Props {
    data: {
        validez: string,
        tiempo_entrega: string,
        forma_pago: string,
        nota: string
    }
    setData: (d: any) => void
}
export default class Condiciones extends React.Component<Props> {
    render() {
        let { data: { validez, tiempo_entrega, forma_pago, nota }, setData } = this.props;
        return (<>
            <Col xs={12}>
                <ControlText upper name='validez' maxLength={120} before="VALIDES DE LA OFERTA" value={validez} setData={setData} update="validez" />
            </Col>
            <Col xs={12}><br />
                <ControlText upper name='tiempo_entrega' maxLength={120} before="TIEMPO DE ENTREGA" value={tiempo_entrega} setData={setData} update="tiempo_entrega" />
            </Col>
            <Col xs={12}><br />
                <ControlText upper name='forma_pago' maxLength={120} before="FORMA DE PAGO" value={forma_pago} setData={setData} update="forma_pago" />
            </Col>
            <Col xs={12}><br />
                <b>NOTA</b>
                <InputArea upper autoComplete='on' name='nota' rows={3} maxLength={600} value={nota} setData={setData} update="nota" />
            </Col>
        </>)
    }
}