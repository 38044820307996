import React from "react"
import Area from "../../../Utiles/Charts/Barras";
import { getMes } from "../../../Utiles/Formatos";
import { don } from "@victorequena22/conneccion";
export default class VentasAno extends React.Component<{ rango: string }, { dias: number[][] }>{
    constructor(p: any) {
        super(p);
        this.state = { dias: [] };
    }
    render() {
        return <div className="ventas" style={{ width: '100%' }}>
            <Area label={['MES', 'VENTAS', 'EQUILIBRIO']} values={this.get()} title="VENTAS DEL AÑO" />
        </div>
    }
    get() {
        return this.state.dias.sort((a, b) => a[0] - b[0]).map(d => [getMes(d[0]), d[1], d[2]]);
    }
    async send() {
        try {
            let token = localStorage.getItem('Authorization');
            let response = await fetch(don + '/api/Factura/linea/' + this.props.rango, { headers: { 'Authorization': 'Bearer ' + token } });
            let dias = await response.json();
            this.setState({ dias });
        } catch (error) {
            console.log(error);
            this.setState({ dias: [] });
        }
    }
    componentDidMount() { this.send() }
}