import { Card, Col, Button, InputGroup } from 'react-bootstrap';
import { EditModal as EditVideo } from '../VideoModal';
import { EditModal as EditImage } from '../CropModal';
import ReactTooltip from 'react-tooltip';
import { useState, useRef } from 'react';
import { Nombre } from './Nombre';
import { conMedia, getSize } from '../Models';
import { ModalAlert } from '../../../Components/Modals';
import { getImagenURL } from '../../../Utiles/Formatos';
export function GetImagen({ img, actualizar, cargarTags, tagsList }: any) {
    const video = useRef<HTMLVideoElement>(null);
    const hoverIn = () => { const v = video.current; if (v !== null) v.play(); }
    const hoverOut = () => { const v = video.current; if (v !== null) { v.pause(); v.currentTime = 0; } }
    return <Col xs={2} key={`img${img.id}`}
        style={{ padding: '0 .5rem .5rem .5rem', margin: '0 0 0 0', borderRadius: '.5rem' }}>
        <Card style={{ overflow: 'hidden' }}>
            <Opciones size={img.size} id={img.id} tags={img.tags} type={img.type} actualizar={actualizar} />
            <InputGroup style={{ height: '24px', width: '100%', margin: '0 0 0 0' }}>
                <b style={{ height: '24px', width: 'calc(100% - 33px)', fontSize: '9px', paddingLeft: '.5rem', border: '3px solid #f7f7f7' }}>{img.nombre}</b>
                <Nombre updateTags={cargarTags} tagsList={tagsList} img={img} update={actualizar} />
            </InputGroup>
            <div onMouseEnter={hoverIn} onMouseOut={hoverOut}
                className='p-0 m-0 d-flex justify-content-center align-items-center'
                style={{ width: '100%', aspectRatio: '1/1' }}>
                {img.type.indexOf('image') > -1 ?
                    <img width="100%" src={getImagenURL(`/api/Media/short/${img.id}`)} alt='pp' /> :
                    <video ref={video} width="100%" loop muted
                        src={getImagenURL(`/api/Media/short/${img.id}`)} />
                }
            </div>
        </Card>
    </Col>
}
interface Props {
    id: number,
    tags: number[],
    type: string,
    actualizar: () => void,
    size: number,
}
function Opciones({ id, actualizar, type, tags, size }: Props) {
    const [open, setOpen] = useState(false);
    const upload = (data: string) => conMedia().nuevo({ data }, actualizar, () => { });
    const productos = tags.some(t => t === -5);
    const haztulandig = tags.some(t => t === -6);
    const typeString = type.indexOf('image') > -1 ? 'LA IMAGEN' : 'EL VIDEO';
    return <InputGroup style={{ height: '24px', width: '100%', margin: '0 0 0 0', padding: '0 0 0 0' }}>
        <InputGroup.Prepend style={{ width: 'calc(100% - 64px)' }}>
            <InputGroup.Text style={{ height: '24px', width: '100%', borderRadius: '.25rem 0 0 0' }}>
                <b>{id}</b>
                <small className='ml-auto'>{getSize(size)}</small>
            </InputGroup.Text>
        </InputGroup.Prepend>
        <InputGroup.Append className='ml-auto' style={{ height: '24px', padding: '0 0 0 0', margin: '0 0 0 0' }}>
            {type.indexOf('image') > -1 ?
                <EditImage aspect={1} setImg={upload} img={`/api/Media/ver/${id}`} /> :
                <EditVideo type={type} setVideo={actualizar} video={`${id}`} />
            }
            <ModalAlert close={() => setOpen(false)} open={open} isValid={!(productos || haztulandig)}
                accion={() => conMedia().delete(id, () => { setOpen(false); actualizar(); }, () => { setOpen(false); actualizar(); })}
                msn={(productos || haztulandig) ? `
                <p>NO SE PUEDE ELIMINAR ${typeString} POR QUE ESTA SIENDO USADA EN LOS SIGUIENTES MODULOS:</p>
                <ul>
                    ${productos ? '<li>PRODUCTOS</li>' : ''}
                    ${haztulandig ? '<li>HAZTULANDIG</li>' : ''}
                </ul>`: `DESEA ELIMINAR ${typeString} ${id}`} title={`ELIMINAR ${typeString} ${id}`} />
            <ReactTooltip />
        </InputGroup.Append>
        <Button data-tip='ELIMINAR RECURSO' size='sm' variant='danger'
            style={{ borderRadius: 0, width: '32px' }} onClick={() => setOpen(true)}>
            <i className='fas fa-trash' />
        </Button>
    </InputGroup>


}