import { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Arrow1, Arrow2, Circle, Dia1, Dia2, Ori, Onda1, Onda2, D } from './variants';

export function Selector({ set, variant, ori }: { set: (s: string) => void, variant: string, ori: boolean }) {
    const [page, setPage] = useState(getDefault(variant))
    return <ButtonGroup size="sm">
        <Page page={page} set={setPage} />
        {page === 1 ? <>
            <BTN ori={ori} select={variant} set={set} variant='' tip='BORDE PLANO' ><Ori /></BTN>
            <BTN ori={ori} select={variant} set={set} variant='diagonal' tip='BORDE DIAGONAL'><Dia2 /></BTN>
            <BTN ori={ori} select={variant} set={set} variant='diagonal-i' tip='BORDE DIAGONAL'><Dia1 /></BTN>
        </> : page === 2 ? <>
            <BTN ori={ori} select={variant} set={set} variant='arrow' tip='BORDE FLECHA FUERA'><Arrow1 /></BTN>
            <BTN ori={ori} select={variant} set={set} variant='arrow-i' tip='BORDE FLECHA DENTRO'><Arrow2 /></BTN>
            <BTN ori={ori} select={variant} set={set} variant='semi-circle' tip='BORDE SEMI CIRCULO'><Circle /></BTN>
        </> : <>
            <BTN ori={ori} select={variant} set={set} variant='onda' tip='BORDE ONDA ARRIBA'><Onda1 /></BTN>
            <BTN ori={ori} select={variant} set={set} variant='onda-i' tip='BORDE ONDA ABAJO'><Onda2 /></BTN>
            <BTN ori={ori} select={variant} set={set} variant='D' tip='BORDE SEMI CIRCULO'><D /></BTN>
        </>}
    </ButtonGroup >
}

function getDefault(ani: string) {
    switch (ani) {
        case '': case 'diagonal': case 'diagonal-i': return 1;
        case 'semi-circle': case 'arrow': case 'arrow-i': return 2;
        default: return 3;
    }
}
function Page({ set, page }: { set: (s: number) => void, page: number }) {
    return <ButtonGroup vertical size="sm">
        <Button data-tip='ARRIBA' style={{ padding: '0 0 0 0' }} onClick={() => set(page === 1 ? 3 : page - 1)} variant='outline-dark'>
            <i className="fas fa-arrow-up" />
        </Button>
        <Button data-tip='ABAJO' style={{ padding: '0 0 0 0' }} onClick={() => set(page === 3 ? 1 : page + 1)} variant='outline-dark'>
            <i className="fas fa-arrow-down" />
        </Button>
    </ButtonGroup>

}
interface Props {
    tip: string,
    variant: string,
    set: (d: string) => void,
    select: string,
    ori: boolean,
    children: any
}
const BTN = ({ tip, variant, children, set, select, ori }: Props) => <Button variant='outline-info'
    style={{ transform: ori ? undefined : 'scaleX(-1)' }}
    data-tip={tip} onClick={() => set(variant)} active={variant === select}>{children}</Button>