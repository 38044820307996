import TortasMaster from './Master';

export default class CotizacionPie extends TortasMaster {
    state = { title: '', values: [] };
    estados = ['CANCELADO', 'PENDIENTE', 'PROCESADA'];
    api = 'Cotizacion';
    title='COTIZACIONES'
}


