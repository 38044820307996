import React from 'react'
import { Modal, Row, Button, Col } from 'react-bootstrap'
import { conUsuario, Permisos } from '../../../Models/Usuario';
import { InputText } from '../../../Utiles/Inputs';
interface Props {
    open: boolean
    close: () => void
    actualizar: () => void
}
interface State {
    nombre: string
    permisos: number[],
    Permisos: Permisos[]
}
export default class NewRol extends React.Component<Props, State> {
    con = conUsuario();
    state = { permisos: [], Permisos: [], nombre: '' }
    componentDidMount() {
        this.con.listaPermisos((Permisos: Permisos[]) => {
            this.setState({ Permisos })
        });
    }
    render() {
        let { close, open } = this.props;
        return <Modal animation centered show={open} onHide={close}>
            <Modal.Header closeButton>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center" >
                    NUEVO ROL
                </h6>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12}>
                        <InputText label="MONBRE DEL ROL" update="nombre" setData={this.setState.bind(this)} value={this.state.nombre} /><br />
                        {this.state.Permisos.map((p: Permisos) => {
                            return <Button onClick={this.click.bind(this, p.id)}
                                variant={this.selected(p.id)}>{p.nombre}</Button>
                        })}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button className="float-right" onClick={this.save.bind(this)} variant="primary">
                    <span className="fa fa-floppy-o" /> GUARDAR
                </Button>
            </Modal.Footer>
        </Modal >
    }
    selected(id: number) {
        if (this.state.permisos.some(p => p === id)) return "success"
        return "default";
    }
    click(id: number) {
        const { permisos } = this.state;
        if (permisos.every(p => p !== id)) this.setState({ permisos: [...permisos, id] });
        else this.setState({ permisos: permisos.filter(p => p !== id) });
    }
    save() {
        let { nombre, permisos } = this.state;
        this.con.nuevoRol({ nombre, permisos }, (data) => {
            this.props.actualizar()
            this.props.close()
        });
    }
}