
import { useContext, useState, useEffect } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap'
import { Detalles } from '../../../../Models/General'
import { InputNumber } from '../../../../Utiles/Inputs'
import { ControlText } from '../../../../Utiles/FormControl'
import { numberFormat } from '../../../../Utiles/Formatos'
import THFila, { THInput } from '../../../../Utiles/Componentes/THFila'
import { ProductosContext, ProductosType } from '../../../../Context/Producto';

interface Props {
    producto: Detalles,
    edit: (iten: Detalles) => void,
    remove: (iten: Detalles) => void
    dolar?: boolean
}

export default function FilaUtilidad({ producto, edit, remove, dolar = false }: Props) {
    const { findProVen } = useContext(ProductosContext) as ProductosType;
    const { codigo, descripcion, costo } = findProVen(producto, dolar);
    const [comentario, setComentario] = useState(producto.comentario);
    const [precio1, setPrecio1] = useState(producto.precio1);
    const [precio2, setPrecio2] = useState(producto.precio2);
    const [precio3, setPrecio3] = useState(producto.precio3);
    const [open, setOpen] = useState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => edit({ ...producto, precio1, precio2, precio3, unidades: 0, precio: 0, comentario }), [precio1, precio2, precio3, comentario]);
    return <>
        <tr>
            <THFila width='90px'>{codigo}</THFila>
            <THFila width='auto'>{descripcion}</THFila>
            <THInput width='180px'>
                <InputNumber min={.01} step='0.01' setData={setPrecio1} value={precio1} />
            </THInput>
            <THInput width='180px'>
                <InputNumber min={.01} step='0.01' setData={setPrecio2} value={precio2} />
            </THInput>
            <THInput width='180px'>
                <InputNumber min={.01} step='0.01' setData={setPrecio3} value={precio3} />
            </THInput>
            <THInput width='110px'>
                <ButtonGroup>
                    <Button variant="info" onClick={() => setOpen(!open)} data-tip="DEJAR NOTA">
                        <span className="fa fa-edit"></span>
                    </Button>
                    <Button variant="danger" onClick={() => remove(producto)} data-tip="QUITAR PRODUCTO">
                        <i className="fa fa-ban"></i>
                    </Button>
                </ButtonGroup>
            </THInput>
        </tr><tr></tr>
        {open ? <>
            <tr style={{ display: open ? '' : 'none' }}>
                <td colSpan={2}>
                    <ControlText setData={() => { }} before='COSTO' style={{ textAlign: 'right' }} disabled value={numberFormat(costo)} />
                </td>
                <THInput width='180px'>
                    <InputNumber min={.01} step='0.01' value={costo * ((precio1 / 100) + 1)}
                        setData={(p: number) => setPrecio1(((p / costo) - 1) * 100)} />
                </THInput>
                <THInput width='180px'>
                    <InputNumber min={.01} step='0.01' value={costo * ((precio2 / 100) + 1)}
                        setData={(p: number) => setPrecio2(((p / costo) - 1) * 100)} />
                </THInput>
                <THInput width='180px'>
                    <InputNumber min={.01} step='0.01' value={costo * ((precio3 / 100) + 1)}
                        setData={(p: number) => setPrecio3(((p / costo) - 1) * 100)} />
                </THInput>
            </tr>
            <tr>
                <td colSpan={7}>
                    <ControlText maxLength={250} before="NOTA INTERNA" setData={setComentario} value={comentario} />
                </td>
            </tr>
        </> : <></>}
    </>
}