import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { SeccionContext, SeccionType } from "../../../Context/Seccion";
import SetMes from "./SetMes";
import CompraPie from "./tortas/Compra";
import CotizacionPie from "./tortas/Cotizacion";
import NotaPie from "./tortas/Nota";
import FacturaPie from "./tortas/Factura";
import VentasMes from "./ventasMes";
import VentasAno from "./ventasAno";
import Pais from "./Pais";
import Dipositivos from "./Dispositivos";
import Visitas from "./Visitas";
interface State {
    mont: number
    year: number
}
const admin = ['COMPRA', 'ORDEN', 'PROVEEDOR', 'FACTURA', 'NOTA', 'COTIZACION', 'CLIENTE', 'AJUSTE']
class Graficas extends React.Component<any, State> {
    constructor(p: any) {
        super(p);
        let date = new Date();
        let año = date.getFullYear(),
            mes = date.getMonth() + 1;
        this.state = { year: año, mont: mes };
    }
    render() {
        const { data: { Permisos } } = this.context as SeccionType;
        let { year, mont } = this.state;
        let url = '/ano/' + year, no = 'AÑO'
        if (mont > 0) {
            no = 'MES'; url = '/mes/' + year + '/' + mont;
        }
        return <Card>
            <Card.Header>
                <SetMes mont={mont} year={year} setData={this.setState.bind(this)} />
            </Card.Header>
            <Card.Body>
                {Permisos.some(p => admin.includes(p)) ? <>
                    <Row>
                        {Permisos.some(d => d !== 'ORDEN') ? <>
                            <CompraPie ref={'Compra' + url} lugar='col-3' rango={url} no={no} />
                        </> : <></>}
                        {Permisos.some(d => d !== 'COTIZACION') ? <>
                            <CotizacionPie ref={'Cotizacion' + url} lugar='col-3' rango={url} no={no} />
                        </> : <></>}
                        {Permisos.some(d => d !== 'NOTA') ? <>
                            <NotaPie ref={'Nota' + url} lugar='col-3' rango={url} no={no} />
                        </> : <></>}
                        {Permisos.some(d => d !== 'FACTURA') ? <>
                            <FacturaPie ref={'Factura' + url} lugar='col-3' rango={url} no={no} />
                        </> : <></>}
                    </Row>
                    <Row>
                        {no === 'MES' ? <VentasMes key={'ventas' + url} rango={url} /> : <VentasAno key={'ventas' + url} rango={url} />}
                    </Row>
                </> : <></>}
                {Permisos.some(d => d === 'PANEL') ? <Row>
                    <Col md={4}>
                        <Pais ref={'Pais' + url} rango={url} no={no} />
                        <Dipositivos ref={'Dipositivos' + url} rango={url} no={no} />
                    </Col>
                    <Col md={8}>
                        <Visitas ref={'Visitas' + url} rango={url} no={no} />
                    </Col>
                </Row> : <></>}
            </Card.Body>
        </Card >
    }
}
Graficas.contextType = SeccionContext;
export default Graficas;