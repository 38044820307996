import React from 'react';
import { Button,  Card } from 'react-bootstrap';
import Formulario from './Formulario';
import { Impreciones, imprecionNull } from '../../../Models/General';
import { ControlNumber } from '../../../Utiles/FormControl';
import { InputArea } from '../../../Utiles/Inputs';
import { conConfig } from '@victorequena22/conneccion';
interface State extends Impreciones {
    observacion: string,
    num_factura: number,
}
export default class Factura extends React.Component<any, State> {
    con = conConfig('factura');
    state = { ...imprecionNull, num_factura: 0, observacion: '', };
    render() {
        let { num_factura, observacion } = this.state;
        let setState = this.setState.bind(this);
        return (<Card>
            <Card.Header className='bg-info'>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> CONFIGURACION DE FACTURA </h4>
            </Card.Header>
            <Card.Body>
                <Card>
                    <Card.Header className='bg-info'>
                        <h5>CONFIGURACION DE DATOS POR DEFECTO </h5>
                    </Card.Header>
                    <Card.Body>
                        <ControlNumber value={num_factura} setData={setState} update="num_factura" min={0} step="1"
                            disabled={num_factura > 0} before="NUMERO INICIAL DE FACTURA" />
                        <b style={{ color: "red" }}>UNA VEZ INICIALIZADO YA NO PUEDE SER CAMBIADO</b>
                        <br /><b>OBSERVACIÓN</b>
                        <InputArea rows={6} maxLength={600} value={observacion} setData={setState} update="observacion" />
                    </Card.Body>
                </Card>
                <Formulario version='factura' data={this.state} setData={setState} />
                <Button className="float-right" onClick={this.guardar.bind(this)} variant="primary">
                    <span className="fa fa-floppy-o" /> Guardar
                </Button>
            </Card.Body>
        </Card >)
    }
    componentDidMount() {
        this.con.pull(this.setState.bind(this));
    }
    guardar() {
        this.con.push(this.state, this.setState.bind(this));
    }
}