import React from 'react';
import { GeneralFila } from '../../../Models/General';
import FilaFactura from './FilaFactura';
import { Link } from 'react-router-dom';
import { conFactura } from '../../../Models/Ventas';
import CardBuscador from '../../../Utiles/Buscadores/Card';
import { Producto } from '../../../Models/Productos';
import Avanzado, { AvanzadosProps } from '../../../Utiles/Componentes/Avanzado';
import { VentasContext } from '../../../Context/Ventas';
import FacturaExcel from './Excel';
import { Col } from 'react-bootstrap';
import { fechaToNumber } from "@victorequena22/utiles";
class ListFactura extends CardBuscador {
    con = conFactura();
    placeholder = "BUSCAR FACTURA";
    buscarEn = ["codigo", "nombre", 'fecha'];
    fecha_init = 'dd/mm/aaaa';
    fecha_fin = 'dd/mm/aaaa';
    productos: Producto[] = []
    status = -1;
    user = -1;
    Avansado() {
        const { fecha_init, fecha_fin, status, user, productos, setAvanzados } = this;
        return <Avanzado setData={setAvanzados.bind(this)}
            estatus={['CANCELADO', undefined, 'FACTURADA']}
            fecha_init={fecha_init} fecha_fin={fecha_fin}
            status={status} user={user} productos={productos} />
    }
    setAvanzados({ fecha_init, fecha_fin, status, user, productos }: AvanzadosProps) {
        if (fecha_init) this.fecha_init = fecha_init;
        if (fecha_fin) this.fecha_fin = fecha_fin;
        if (user) this.user = user;
        if (status) this.status = status;
        if (status === 0) this.status = status;
        if (productos) this.productos = productos;
        this.forceUpdate()
    }
    filter() {
        const { fecha_init, fecha_fin, status, user, productos } = this;
        let itens = this.ordenar();
        return itens.filter((c: GeneralFila) => {
            if (c.status !== status && status !== -1) return false;
            if (c.usuario !== user && user !== -1) return false;
            const creacion = fechaToNumber(c.fecha);
            if (fecha_fin !== 'dd/mm/aaaa' && creacion > fechaToNumber(fecha_fin)) return false;
            if (fecha_init !== 'dd/mm/aaaa' && creacion < fechaToNumber(fecha_init)) return false;
            if (!productos.every(p => c.detalles.some(d => p.codigo === d))) return false;
            return true;
        });
    }
    Title() {
        return <>
            <h4 className='mr-auto'><i className="fa fa-search"></i> BUSCAR FACTURA</h4>
            <Link to="/factura/nueva" className="btn btn-primary"><span className="fa fa-plus"></span> NUEVA FACTURA</Link>
        </>
    }
    Header() {
        const { Th } = this;
        return <tr className="info">
            <Th label='codigo' width='100px'>N°</Th>
            <Th label='fecha' width='120px'>FECHA</Th>
            <Th label='nombre' width='calc(100% - 585px)'>CLIENTE</Th>
            <Th width='160px'>MONTO</Th>
            <Th width='100px'>ESTATUS</Th>
            <Th width='135px'>ACCIONES</Th>
        </tr>
    }
    Row(p: GeneralFila) {
        return <FilaFactura con={this.con} key={"cot" + p.codigo + p.status} data={p} />
    }
    context: any = {};
    getItens() { return this.context.Facturas; }
    set() { this.context.actFactura(); }
    getEstado() { return this.context.estFactura; }
    Actualizar() {
        const { hora } = this.getEstado();
        return <Col md={3} className='mr-auto d-flex flex-row-reverse'>
            <FacturaExcel factura={this.filter()} />
            <div style={{ fontSize: '12px', width: "200px", paddingLeft: '15px' }}>
                <strong>ACTUALIZADO:</strong> <br />
                {hora}
            </div>
        </Col>
    }
}
ListFactura.contextType = VentasContext;
export default ListFactura
