import { useState } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { TitleCard, CardConfig, RowConfig, ButtonColor, ButtonConfig, AddItens } from '../../../Component';
import { IconSelector, UseIcon } from "@victorequena22/icon-selector-bootstrap";
import { SelecPanelModal } from '../../../../PanelProxi/Ver/Selector';
import { iten, State } from '../index';
interface Props {
    iten: iten,
    config: State,
    setConfig: (d: Partial<iten>) => void,
    itenUp: (d: number) => void,
    itenDown: (d: number) => void,
    removeItem: (d: number) => void
    addItem: () => void,
    cont: number
}
export function ConfigIten(p: Props) {
    const [panel, setPanel] = useState(false);
    const { config: { back: iBack }, iten: { id, icon, fill, aux }, cont, setConfig, itenUp, itenDown, removeItem, addItem } = p;
    return <CardConfig width='151px'>
        <SelecPanelModal open={panel} close={() => setPanel(false)} set={p => setConfig({ panel: p.id, id })} />
        <ButtonGroup>
            <ButtonConfig tip='MOVER A LA IZQUIERDA' icon='fas fa-arrow-left' action={() => itenUp(id)} />
            <ButtonConfig tip='MOVER A LA DERECHA' icon='fas fa-arrow-right' action={() => itenDown(id)} />
        </ButtonGroup>
        <ButtonConfig className='ml-2 mr-1' tip='QUITAR' icon='fas fa-trash' action={() => removeItem(id)} />
        <AddItens tip='AGREGAR UNA IMAGEN' add={addItem} />
        <RowConfig top={2}>
            <IconSelector value={icon} onChange={icon => setConfig({ icon, id })} />
            <ButtonColor tip='COLOR DEL ICONO' className='ml-1' invert={true} style={{ width: 32, backgroundColor: iBack !== 'none' ? aux : undefined }} color={fill} setData={(fill) => setConfig({ fill, id })} >
                <UseIcon width={15} height={15} icon={icon} fill={fill} />
            </ButtonColor>
            <ButtonColor gradiend tip='COLOR DE FONDO DEL ICONO' disable={iBack === 'none'} className='ml-1' style={{ width: 32 }} color={aux} setData={(aux) => setConfig({ aux, id })} >
                <UseIcon width={15} height={15} icon={icon} fill={fill} />
            </ButtonColor>
            <ButtonConfig className='ml-1' tip='SELECCIONAR PANEL A INSERTAR' icon='fas fa-browser' action={() => setPanel(true)} />
        </RowConfig>
        <RowConfig top={2}>
            <span className='ml-auto mr-auto'>{id} DE {cont}</span>
        </RowConfig>
        <TitleCard>OPCIONES ITEN {id}</TitleCard>
    </CardConfig>
}