import { SeccionContext, SeccionType } from '../../Context/Seccion';
import { Menus } from '../../Utiles/Componentes/Menus';
import Colores from '../../App/Config/Web/Colores';
import Social from '../../App/Config/Web/Social';
import { Route, Link } from "react-router-dom";
import { useContext } from 'react';
const PP = ['PANEL', 'PROGRAMA', 'EVENTO'];
export function MenuWeb() {
    const { data: { Permisos } } = useContext(SeccionContext) as SeccionType;
    if (Permisos.some(p => PP.some(p2 => p2 === p)))
        return <Menus title='CONFIGURACIONES WEB'>
            <Link className='btn btn-default' to='/config/social'>REDES SOCIALES</Link>
            <Link className='btn btn-default' to='/config/colores'>COLORES</Link>
        </Menus>
    return <></>;
}
export function RutasWeb() {
    return <>
        <Route path="/config/social"><Social /></Route>
        <Route path="/config/colores"><Colores /></Route>
    </>
}