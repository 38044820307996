import { Col, Button, Table } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'
import { Detalles } from '@/Models/General'
import CalcularImpuesto from '../CalcularImpuesto'
import InterfaceNuevo from './Interface'
import { THead as TH } from './Thead'
import { Dolar, Productos } from './Botones'
import { Buscar } from './Buscar'
export default class AuxiliarNuevo<p = {}> extends InterfaceNuevo<p> {
    Row = (_p: { data: Detalles, add: (pr: Detalles) => void, remove: (pr: Detalles) => void }) => {
        return <></>;
    }
    BotonesRight = () => {
        const { BTNProcesar, props: { setData, data: { dolar } } } = this;
        return <Col xs={6} className="float-right text-right">
            <Dolar dolar={!!dolar} setData={dolar => setData({ dolar })} />
            <BTNProcesar />
            <Productos nuevo={this.nuevoProductos} add={this.addProductos} productos={this.productos} dolar={dolar} />
        </Col>
    }
    BTNProcesar = () => <Button data-tip="PROCESAR" variant="success" className="float-right mr-2" onClick={() => this.setData({ procesar: true })} >
        <span className="fa fa-print" />
    </Button>
    BotonesLeft = () => <Col xs={6} />
    Top = (p: { max: number, row: number }) => {
        const { BotonesRight, BotonesLeft, state: { buscar, pagina } } = this;
        return <Buscar {...p} pagina={pagina} buscar={buscar} setData={this.setState.bind(this)} >
            <BotonesLeft /><BotonesRight />
        </Buscar>
    }
    Down = ({ detalles }: { detalles: Detalles[] }) => {
        const { THead, Row, Calcular, addProductos, removeProductos } = this;
        return <Table className="accordion" striped hover>
            <THead /><tbody>
                {detalles.map(p => <Row key={p.codigo + p.index} data={p} add={addProductos} remove={removeProductos} />)}
                <Calcular />
            </tbody>
        </Table>
    }
    render = () => {
        const { Down, Top, props: { data: { detalles } } } = this;
        const det = this.getBusqueda();
        return <>
            <ReactTooltip />
            <Top row={det.length} max={detalles.length} />
            <Down detalles={this.getPagina(det)} />
        </>
    }
    THead = () => <TH />
    Calcular = () => <CalcularImpuesto dolar={!!this.props.data.dolar} exento={this.exento()} imponible={this.imponible()} />;
}