import { ConexionCompleta } from "@victorequena22/conneccion";

class NotasCon extends ConexionCompleta {
    constructor(clase: string) {
        super();
        this.clase = clase;
    }
}
export default function conNotasCD(clase: string) {
    return new NotasCon(clase);
}