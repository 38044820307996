/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useCallback } from 'react'
import { AuthSome } from '../../Config/Permisos';
import { conProveedor, Proveedor, ProveedorNull } from '../../Models/Compra';
import { Estado, EstadoNull } from '../../Models/General';
import { formatoHora } from '../../Utiles/Formatos';
import { sleep, useSemaforo } from '../../Utiles/ReactUtils';
export interface ProveedoresType {
    estProveedores: Estado
    Proveedores: Proveedor[]
    actProveedor: () => void
    findProveedor: (p: string) => Proveedor
}
function arrayToProveedor(p: any[]): Proveedor[] {
    return p.map(a => {
        return {
            codigo: a[0],
            nombre: a[1],
            telefono: a[2],
            correo: a[3],
            contacto: a[4],
            direccion: a[5],
            estatus: a[6],
            pais: a[7],
            ciudad: a[8],
            estado: a[9]
        }
    })
}
function setCambioProveedores(data: Proveedor[], p: Proveedor[]) {
    const push = arrayToProveedor(data).filter((d => {
        const i = p.findIndex(p => p.codigo === d.codigo);
        if (i > -1) {
            p[i] = d;
            return false;
        }
        return true;
    }));
    const Proveedores = [...p, ...push];
    return Proveedores;
}
export const ProveedoresContext = createContext<ProveedoresType | null>(null)
export default function ProveedoresProvider({ children }: { children: any }) {
    const [Proveedores, setProveedores] = useState<Proveedor[]>([]);
    const [est, setest] = useState(EstadoNull);
    const [con] = useState(conProveedor());
    const update = useCallback(async () => {
        if (AuthSome(['COMPRA', 'ORDEN', 'PROVEEDOR'])) con.cambios((data: any[]) => {
            let pro = setCambioProveedores(data, Proveedores);
            setProveedores(pro);
            setest({ status: 3, hora: formatoHora(con.state) });
        })
        await sleep(50);
    }, [Proveedores])
    useSemaforo('Cotizacion', async () => {
        await update()
    })
    const findProveedor = useCallback((p: string) => {
        const pro = Proveedores.find(Pro => Pro.codigo === p);
        if (pro !== undefined) return pro;
        return ProveedorNull;
    }, [Proveedores])
    return <ProveedoresContext.Provider value={{
        estProveedores: est,
        Proveedores: Proveedores,
        actProveedor: update,
        findProveedor: findProveedor
    }}>
        {children}
    </ProveedoresContext.Provider>
}
export const SeccionConsumer = ProveedoresContext.Consumer;