import { useContext } from 'react';
import { Route } from "react-router-dom"
import ListAjustes from '../App/Inventario/Ajustes';
import NuevaAjuste from '../App/Inventario/Ajustes/Nuevo';
import ListaProductos from '../App/Inventario/Productos';
import ProductoNuevo from '../App/Inventario/Productos/Form/ProductoNuevo'; 
import { SeccionContext } from '../Context/Seccion';
import UnAutorization from '../Utiles/Componentes/UnAutorization';
export default function MasterProductos() {
    return (<>
        <Route path="/producto" component={Productos} />
        <Route path="/producto/ajuste" component={Ajuste} />
    </>);
}
function Productos() {
    const contex = useContext(SeccionContext);
    if (contex?.data.Permisos.some(p => p === 'PRODUCTO'))
        return <>
            <Route exact path="/producto"><ListaProductos /></Route>
            <Route exact path="/producto/nuevo"><ProductoNuevo /></Route>
        </>
    return <UnAutorization />
}
function Ajuste() {
    const contex = useContext(SeccionContext);
    if (contex?.data.Permisos.some(p => p === 'AJUSTE'))
        return <>
            <Route path="/producto/ajuste/lista"><ListAjustes /></Route>
            <Route path="/producto/ajuste/nuevo"><NuevaAjuste /></Route>
        </>
    return <UnAutorization />
}