import  {  CSSProperties } from 'react';
import { Card, Col } from 'react-bootstrap';
import { TextToHTML } from "@victorequena22/slate-bootstrap";
import { EditArea } from '../../../Component/SlateCustom';
import { iten, State } from '../index';
interface Props {
    iten: iten,
    config: State,
    seled: (i: number) => void
}
export function Itens({ config: { seleted, gap, size },
    iten: { id, html, img, color, back }, seled }: Props) { 
    const s1 = ['990px', '1080px', '780px'];
    const s2 = ['1440px', '1320px'];
    const style: CSSProperties = { background: back, color };
    return <Col style={{ margin: '0 0 0 0', padding: '0 0 0 0' }} xs={s2.some(s => size === s) ? 3 : s1.some(s => size === s) ? 4 : 6}>
        <Card onClick={() => seled(id)} style={{ border: seleted === id ? '5px solid #FF0000' : undefined, margin: `0 ${gap}px 0 ${gap}px` }} className="c-content-person-1 c-option-2 mt-2">
            <Card.Img variant="top" src={img} />
            {seleted === id ?
                <Card.Body style={style}> <EditArea key={'edit' + id} /></Card.Body> :
                <Card.Body style={style}  key={'html' + id}><TextToHTML text={html} /></Card.Body>
            }
        </Card>
    </Col >
}
