import React from "react";
import { Accordion, Button, Card, Col, InputGroup, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { conFactura, Factura, sentFactura, FacturaNull } from "../../../../Models/Ventas";
import ClienteSelector from "../../Clientes/Selector";
import AuxiliarFactura from "./AuxiliarFactura";
import { InputArea } from "../../../../Utiles/Inputs";
import { VentasContext } from "../../../../Context/Ventas";
import CreateOperacion from "../../../../Utiles/Componentes/CreateOperacion";
import { conConfig } from "@victorequena22/conneccion";
class NuevaFactura extends CreateOperacion {
    con = conFactura();
    title = 'FACTURA'
    msn = 'LA FACTURA';
    constructor(p: any) {
        super(p);
        this.tipo = 'Factura';
        this.state = { ...FacturaNull, procesar: false };
    }
    setCondicion = (v: React.ChangeEvent<HTMLSelectElement>) => this.props.change(v.currentTarget.value as string)
    Productos = () => <AuxiliarFactura procesar={this.Procesar} key="auxFactura" setData={this.setState} data={this.state} />
    setConfig = () => conConfig('factura').pull(({ observacion }) => this.setState({ nota: observacion }))
    setCliente = (p: string) => this.setState({ cod_cliente: p });
    PDF = () => <Row>
        <embed src={`/api/Factura/PDF/${this.state.codigo}`} title="print" width='50%' height='700wh' />
        <embed src={`/api/Factura/bauche/${this.state.codigo}`} title="print" width='50%' height='700wh' />
    </Row>
    Top() {
        const data = this.getData() as Factura;
        return <>
            <ClienteSelector Cliente={data.cod_cliente} add={this.setCliente.bind(this)} />
            <Accordion>
                <Col xs={5}>
                    <InputGroup style={{ width: '100%' }} className="mb-3">
                        <InputGroup.Prepend><InputGroup.Text>CONDICION</InputGroup.Text></InputGroup.Prepend>
                        <select onChange={this.setCondicion.bind(this)}
                            className="form-control"
                            defaultValue={data.condicion}>
                            <option value="CONTADO">CONTADO</option>
                            <option value="CREDITO">CREDITO</option>
                        </select>
                    </InputGroup>
                </Col>
                <Accordion.Toggle eventKey='0' as={Button} variant='primary'>OBSERVACIÓN</Accordion.Toggle>
                <Accordion.Collapse eventKey='0'><Card>
                    <Card.Body>
                        <InputArea rows={4} maxLength={600} value={data.nota} setData={this.setState} update="nota" />
                    </Card.Body>
                </Card>
                </Accordion.Collapse>
            </Accordion>
        </>
    }
    Procesar = () => {
        let procesar = true;
        if (this.state.cod_cliente === "") {
            toast('DEBE SELECCIONAR UN CLIENTE', { type: toast.TYPE.ERROR });
            procesar = false;
        }
        procesar = this.validacionExixtencia(procesar);
        procesar = this.valdacionPrecio(procesar);
        procesar = this.validacionGenerica(procesar);
        if (procesar) {
            const { exento } = this.state as any;
            this.con.nuevo(sentFactura(this.getData(), exento ? 0 : this.context.impuesto),
                data => this.setState({ codigo: data }), () => this.procesar = true);
        }
    }
    context: any = {};

}
NuevaFactura.contextType = VentasContext;
export default NuevaFactura;