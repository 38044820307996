import React from 'react'
import { Button } from 'react-bootstrap'
import { InputNumber } from '../../../../Utiles/Inputs'
import { Departamento } from '../../../../Models/Productos'
import { toast } from 'react-toastify'
import THFila, { THInput } from '../../../../Utiles/Componentes/THFila'

interface Props {
    departamento: Departamento,
    add: (iten: { codigo: string, precio: number, operacion: string }) => void
}
export default class FilaPrecio extends React.Component<Props, { precio: number, operacion: string }>{
    constructor(p: Props) {
        super(p)
        this.state = { precio: 1, operacion: 'mas' };
        this.setState = this.setState.bind(this);
        this.Estados = this.Estados.bind(this);
    }
    render() {
        let { state: { precio, operacion }, props: { departamento: { codigo, descripcion } }, Estados } = this;
        return <tr className={codigo === 'AAAA' ? 'bg-warning' : ''}>
            <THFila width='90px'>{codigo}</THFila>
            <THFila width='auto'>{descripcion}</THFila>
            <THInput width='150px'><Estados /></THInput>
            <THInput width='180px'><InputNumber min={.01} step='0.01' setData={this.setState} value={precio} update="precio" /></THInput>
            <THInput width='70px'>
                <Button data-tip="PLICAR A LA CATEGORIA" onClick={() => this.add(codigo, precio, operacion)} variant="primary">
                    <i className="fa fa-plus" />
                </Button>
            </THInput>
        </tr>
    }
    Estados() {
        const setStatus = (e: React.ChangeEvent<HTMLSelectElement>) => this.setState({ operacion: e.currentTarget.value })
        return <select onChange={setStatus} className="form-control" value={this.state.operacion} >
            <option value='mas'>SUMAR</option>
            <option value='menos'>RESTAR</option>
            <option value='por'>MULTIPLICAR</option>
            <option value='entre'>DIVIDIR</option>
        </select >
    }
    add(codigo: string, precio: number, operacion: string) {
        if (precio !== 0) this.props.add({ codigo, precio, operacion })
        else toast('DEBE UNGRESAR UN VALOR A LA OPERACION', { type: toast.TYPE.ERROR });
    }

}