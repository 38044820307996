import React from 'react';
import { Button, Col, Card } from 'react-bootstrap';
import { ControlNumber } from '../../Utiles/FormControl';
import { conConfig } from '@victorequena22/conneccion';
interface State {
    precio1: number
    precio2: number
    precio3: number
}
export default class Utilidades extends React.Component<any, State> {
    con = conConfig('utilidades');
    state = { precio1: 1, precio2: 1, precio3: 1, };
    render() {
        let { precio1, precio2, precio3 } = this.state;
        let setState = this.setState.bind(this);
        return (<Card>
            <Card.Header className='bg-info'>
                <h4 className='mr-auto'><i className="fa fa-edit"></i> CONFIGURACION DE UTILIDADES POR DEFECTO</h4>
            </Card.Header>
            <Card.Body>
                <Col xs={12}><br />
                    <ControlNumber min={1} step="1" after="%" before="UTILIDAD 1" value={precio1} setData={setState} update="precio1" />
                    <p style={{color:'red'}}>*LOS USUARIOS NO PUEDEN VENDER POR DEBAJO DE LA UTILIDAD 1, LOS ADMINISTRADORES NO PUEDEN VENDER POR DEBAJO DEL COSTO</p>
                </Col>
                <Col xs={12}>
                    <ControlNumber min={1} step="1" after="%" before="UTILIDAD 2" value={precio2} setData={setState} update="precio2" />
                </Col>
                <Col xs={12}><br />
                    <ControlNumber min={1} step="1" after="%" before="UTILIDAD 3" value={precio3} setData={setState} update="precio3" />
                </Col>
                <Col md={12}><br />
                    <Button className="float-right" onClick={this.guardar.bind(this)} variant="primary">
                        <span className="fa fa-floppy-o" /> Guardar
                    </Button>
                </Col>
            </Card.Body>
        </Card>)
    }
    componentDidMount() {
        this.con.pull(this.setState.bind(this));
    }
    guardar() {
        this.con.push(this.state, this.setState.bind(this));
    }
}