import React from "react";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Button } from "react-bootstrap";

export default class NotaExcel extends React.Component<{ nota: any[] }, {}>{
    _export = React.createRef<any>();
    excelExport() {
        if (this._export.current)
            this._export.current.save();
    };
    render() {
        const { nota } = this.props;
        if (nota.length === 0) return <></>
        return <>
        <Button variant='success' onClick={this.excelExport.bind(this)} style={{marginRight:'15px'}}>
        <i className="fa fa-file-excel"/>
        </Button>
        <ExcelExport data={nota} ref={this._export} fileName="Nota">
        <ExcelExportColumn field="codigo" title="N°" width={120} />
        <ExcelExportColumn field="fecha" title="FECHA" width={150} />
        <ExcelExportColumn field="nombre" title="CLIENTE" width={500} />
        <ExcelExportColumn field="monto" title="MONTO" width={120} />

        </ExcelExport></>
    }

}