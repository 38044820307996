import React, { useMemo } from 'react';
import { Modal, Row } from 'react-bootstrap';

interface Props {
    open: boolean,
    close: () => void,
    url: string,
    title: string
}
export default function ModalReport({ title, close, url, open }: Props) {
    return <Modal show={open} size='xl' centered onHide={close}>
        {useMemo(() => <>
            <Modal.Header closeButton>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    <span><b>{title}</b></span>
                </h6>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <iframe src={url} title="print" frameBorder='0' width='100%' height='700wh' scrolling='no' />
                </Row>
            </Modal.Body>
        </>, [title, url])}
    </Modal>
}