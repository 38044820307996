
import { ConexionCompleta } from "@victorequena22/conneccion";
import conNotasCD from "../../Notas/Conexion";

class CompraConexion extends ConexionCompleta {
    clase = 'Compra';
    persistencia = 1000;
    facturar(codigo: string | number, data: any, setData?: (d: any) => void) {
        this.post("/api/Compra/facturar/" + codigo, data, data => { if (setData) setData(data) })
    }
}
export function conCompra() {
    return new CompraConexion();
}
export function conNotasCC(){
    return conNotasCD('Compra/credito');
}
export function conNotasDC(){
    return conNotasCD('Compra/debito');
}