import { getCSS } from './props';
import { Invert } from '../../Component/Commons';
import { FormControl, FormGroup, Button } from 'react-bootstrap';
import { State } from './index'

export function Panel({ config, width }: { config: State, width: number }) {
    const { backgroundColor, input, inputText, buttonText, button, padding } = config;
    return <div style={{ paddingBlock: padding + 'px', background: backgroundColor }}>
        <div className="container">
            <Invert width={width} config={config}>
                <FormGroup className={getCSS(input, inputText, button, buttonText)}>
                    <FormControl size='lg' placeholder="Ingresa tu nombre" />
                    <FormControl size='lg' placeholder="Ingresa tu correo" />
                    <Button block size='lg'>Subscribirse</Button>
                </FormGroup>
            </Invert>
        </div>
    </div>
}