import { CSSProperties } from 'react';
import { css } from '@emotion/css';
export const imagen: CSSProperties = { display: 'block', width: '100%', height: '100%', };

export function content(
    color: string, font: string, fill: string, back: string, border: boolean, iback: string) {
    return css`
        margin: 0 0 0 0 !important;
        background: ${color};
        color: ${font};
        &>.card-header{
            &>.row>.icon-content {
                width: 3rem; height: 3rem; padding-top:${border ? '.2rem' : '.5rem'};
                ${back === 'circle' ? 'border-radius: 3rem;' : back === 'rounded' ? 'border-radius: 1rem;' : ''}
                ${border && back !== 'none' ? `border: .35rem solid ${fill};` : ''}
                ${back !== 'none' ? `background: ${iback};` : ''}
                &>svg>path { fill: ${fill}; }
            }
            
        }
        `;
}