import React from "react"
import Chart from "react-google-charts"
export interface GaugeProps {
    label: String
    red: number
    yellow: number
    green: number
    value: number
}
export default function Gauge({ label, value, red, yellow, green }: GaugeProps) {
    return <Chart
        width='14rem'
        height='14rem'
        chartType="Gauge"
        loader={<div>Loading Chart</div>}
        data={[
            ['Label', 'Value'],
            [label, value],
        ]}
        options={{
            redFrom: red,
            redTo: yellow,
            yellowFrom: yellow,
            yellowTo: green,
            greenFrom: green,
            greenTo: green * 2,
            max: green * 2,
            minorTicks: 5,
        }}
        rootProps={{ 'data-testid': '1' }}
    />

}