import { SeccionContext, SeccionType } from '../../Context/Seccion';
import { Menus, MenuIten } from '../../Utiles/Componentes/Menus';
import ListDepartamento from '../../App/Inventario/Departamento';
import ListUnidad from '../../App/Inventario/Unidad';
import Utilidades from '../../App/Config/Utilidades';
import ListTipo from '../../App/Inventario/Tipos';
import Costos from '../../App/Config/Costos';
import { useContext } from 'react';
import { Ruta } from './index';
const PP = ['DEPARTAMENTO', 'TIPO', 'UNIDAD', 'PRODUCTO'];
export function MenuProductos() {
    const { data: { Permisos } } = useContext(SeccionContext) as SeccionType;
    if (Permisos.some(p => PP.some(p2 => p2 === p)))
        return <Menus title='CONFIGURACIONES PRODUCTOS'>
            <MenuIten p={Permisos} to='/config/departamentos' permiso='DEPARTAMENTO'>DEPARTAMENTOS</MenuIten>
            <MenuIten p={Permisos} to='/config/tipo_producto' permiso='TIPO'>TIPO DE PRODUCTO</MenuIten>
            <MenuIten p={Permisos} to='/config/unidades' permiso='UNIDAD'>UNIDADES DE MEDIDA</MenuIten>
            <MenuIten p={Permisos} to='/config/costos' permiso='PRODUCTO'>MANEJO DE COSTOS</MenuIten>
            <MenuIten p={Permisos} to='/config/utilidades' permiso='PRODUCTO'>UTILIDADES POR DEFECTO</MenuIten>
        </Menus>
    return <></>;
}
export function RutasProductos() {
    return <>
        <Ruta path="/config/departamentos" component={ListDepartamento} permiso='DEPARTAMENTO' />
        <Ruta path="/config/tipo_producto" component={ListTipo} permiso='TIPO' />
        <Ruta path="/config/unidades" component={ListUnidad} permiso='UNIDAD' />
        <Ruta path="/config/costos" component={Costos} permiso='PRODUCTO' />
        <Ruta path="/config/utilidades" component={Utilidades} permiso='PRODUCTO' />
    </>
}